<template>
    <!-- 멤버 보려면 peepsOn 클래스 추가 -->
    <div id="common_select_friend_and_group_team_modal" class="modal shareModal" :class="{
        peepsOn : selected_group_team != null,
        group_select : common_select_friend_and_group_team_modal_info.use_group_select_only == true,
        user_select : common_select_friend_and_group_team_modal_info.use_user_select_only == true,
        share : common_select_friend_and_group_team_modal_info.use_share_option == true,
        need_email : common_select_friend_and_group_team_modal_info.use_need_user_email == true
    }">

        <div class="titleBox">
            <h1 class="borderbot">{{ common_select_friend_and_group_team_modal_info.title ? common_select_friend_and_group_team_modal_info.title : '선택' }}</h1>

            <!-- 선택 값 -->
            <p class="total" v-if="selected_group_team == null && selected_user_ids.length > 0">사용자 <span>{{ selected_user_ids.length }}</span></p>
            <p class="total grp" v-if="selected_group_team == null && selected_group_ids.length > 0">그룹 <span>{{ selected_group_ids.length }}</span></p>
            <p class="total team" v-if="selected_group_team == null && selected_team_ids.length > 0">팀 <span>{{ selected_team_ids.length }}</span></p>

            <!-- 그룹&팀 멤버 클릭해서 들어간 목록에서 사용 -->
            <p class="total user" v-if="selected_group_team != null &&
                                        (common_select_friend_and_group_team_modal_info.use_need_user_email == null || 
                                        common_select_friend_and_group_team_modal_info.use_need_user_email == false)">사용자 
                <span>{{ group_team_members.filter(member => member.checked == true).length }}</span> / {{ selected_group_team.user_count - 1 }}
            </p>

            <p class="total user" v-if="selected_group_team != null && common_select_friend_and_group_team_modal_info.use_need_user_email == true">사용자 
                <span>{{ email_group_team_members.filter(member => member.checked == true).length }}</span> / {{ selected_group_team.user_count }}
            </p>

            <div class="schDiv">
                <!-- <a class="schBtn" id="grpSchBtn" :class="{ on : ( selected_group_team == null && (view_search_group_team == true || view_search_friend == true ) ) ||
                                                                ( selected_group_team != null && view_search_member == true )}" 
                                                                  @click="search">검색버튼</a> -->

                <input type="button" class="input_btn share_option_btn option_share" value="공유" :class="{ on : share_option == SHARE_OPTION.SHARE }" @click="shaerOptionChange(SHARE_OPTION.SHARE)" v-if="!isWork(event_sub_type) && !isReport(event_sub_type) && !isMeetingLog(event_sub_type)" />
                <input type="button" class="input_btn share_option_btn option_copy" value="복사" :class="{ on : share_option == SHARE_OPTION.COPY }" @click="shaerOptionChange(SHARE_OPTION.COPY)" v-if="!isWork(event_sub_type) && !isReport(event_sub_type) && !isMeetingLog(event_sub_type)" />
                <input type="button" class="input_btn share_option_btn option_move" value="이동" :class="{ on : share_option == SHARE_OPTION.MOVE }" @click="shaerOptionChange(SHARE_OPTION.MOVE)" v-if="!isWork(event_sub_type) && !isReport(event_sub_type) && !isMeetingLog(event_sub_type)" />
                
                <!-- 검색창 띄어두기 -->
                <!-- <div class="schGrp" :class="{ on : ( selected_group_team == null && (view_search_group_team == true || view_search_friend == true ) ) ||
                                                   ( selected_group_team != null && view_search_member == true )}">
                    <input type="text" id="searchTotalTxt" placeholder="검색어를 입력해주세요" class="input_search02"
                           :value="selected_group_team == null && view_search_friend == true ? friend_search_query :
                                   selected_group_team == null && view_search_group_team == true ? group_team_search_query : 
                                   selected_group_team != null && view_search_member == true ? group_team_member_search_query : ''"
                           @input="selected_group_team == null && view_search_friend == true ? friend_search_query = $event.target.value :
                                   selected_group_team == null && view_search_group_team == true ? group_team_search_query = $event.target.value : 
                                   selected_group_team != null && view_search_member == true ? group_team_member_search_query = $event.target.value : ''">
                    <a class="schBtn"><span class="blind">찾기버튼</span></a>
                </div> -->

            </div>

        </div>

        <div class="modal_content">

            <!-- 검색창 기본 띄어두기 -->
            <div class="sch_con">
                <input type="search" id="searchInputText" placeholder="검색어를 입력해주세요" class="input_search02"
                           :value="selected_group_team == null && view_search_friend == true ? friend_search_query :
                                   selected_group_team == null && view_search_group_team == true ? group_team_search_query : 
                                   selected_group_team != null && view_search_member == true ? group_team_member_search_query : ''"
                           @input="selected_group_team == null && view_search_friend == true ? friend_search_query = $event.target.value :
                                   selected_group_team == null && view_search_group_team == true ? group_team_search_query = $event.target.value : 
                                   selected_group_team != null && view_search_member == true ? group_team_member_search_query = $event.target.value : ''">
                <a class="schBtn"><span class="blind">찾기버튼</span></a>
            </div>

            <!-- 친구, 그룹&팀 탭 -->
            <div class="tab" v-if="selected_group_team == null">
                <input type="button" value="친구" :class="{ on : tab == 'FRIEND' }" @click.prevent="tabChange('FRIEND')">
                <input type="button" value="그룹 &amp; 팀" :class="{ on : tab == 'GROUP_AND_TEAM' }" @click.prevent="tabChange('GROUP_AND_TEAM')">
            </div>

            <!-- 친구 -->
            <div class="mo_content friend" v-show="tab == 'FRIEND'">
                <!-- 친구 리스트 헤더 -->
                <ul class="sortHeader frdHeader">
                    <li class="frdCheck" v-if="share_option != 'MOVE'">
                        <p class="frdTeamCheckAll" >
                            <input type="checkbox" id="frdTeamCheckAll" :checked="selected_friend_user_ids.length == friends.length">
                            <label for="frdTeamCheckAll" @click.prevent="friendAllCheck">전체선택</label>
                        </p>
                    </li>
                    <li class="num">No</li>
                    <li class="fNm headerSort" @click="friendSort('NAME')"
                        :class="{
                                sortOff  : friend_sort_type != 'NAME',
                                sortUp   : friend_sort_type == 'NAME' && friend_sort_direction == 'ASC', 
                                sortDown : friend_sort_type == 'NAME' && friend_sort_direction == 'DESC', 
                            }">
                        <span>이름</span>
                    </li>
                    <li class="fTel"><span>전화번호</span></li>
                    <li class="fEml"><span>이메일</span></li>
                </ul>

                <div class="shareUlDiv">
                    <div class="noResult" :class="{ on : computedFriends.length < 1 }">검색 결과가 없습니다</div>
                    <ul class="frdUl">
                        <li :key="index" v-for="(friend, index) in computedFriends">
                            <p class="frdTeamCheck">
                                <input type="checkbox" :id="`frdCheck${index + 1}`" @change="checkFriend($event, friend.friend_user_id)" :checked="selected_friend_user_ids.indexOf(friend.friend_user_id) > -1"/>
                                <label :for="`frdCheck${index + 1}`"></label>
                            </p>
                            <p class="num">{{ index + 1 }}</p>
                            <p class="fImg" :style="{ backgroundImage : `url(${make_user_profile_image_url(friend.friend_user_id)})` }">친구사진</p>
                            <img class="fPicDummy" :src="make_user_profile_image_url(friend.friend_user_id)" @error="userImageError($event)"/>
                            <p class="fNm">{{ getFriendName(friend) }}</p>
                            <p class="fTel">{{ hodu_string_validation(friend.user_phone_number) }}</p>
                            <p class="fEml">{{ hodu_string_validation(friend.user_email) }}</p>
                        </li>
                        <!-- <li>
                            <p class="frdTeamCheck">
                                <input type="checkbox" id="frdCheck1"/>
                                <label for="frdCheck1"></label>
                            </p>
                            <p class="num">1</p>
                            <p class="fImg fI01">친구사진</p>
                            <p class="fNm">장피티</p>
                            <p class="fTel">01023456789</p>
                            <p class="fEml">abcdefgh@gmail.com</p>
                        </li>
                        <li>
                            <p class="frdTeamCheck">
                                <input type="checkbox" id="frdCheck2"/>
                                <label for="frdCheck2"></label>
                            </p>
                            <p class="num">2</p>
                            <p class="fImg fI02">친구사진</p>
                            <p class="fNm">김운동</p>
                            <p class="fTel">01011223344</p>
                            <p class="fEml">vlxlgpftm@gmail.com</p>
                        </li>
                        <li>
                            <p class="frdTeamCheck">
                                <input type="checkbox" id="frdCheck3"/>
                                <label for="frdCheck3"></label>
                            </p>
                            <p class="num">3</p>
                            <p class="fImg fI03">친구사진</p>
                            <p class="fNm">강요리</p>
                            <p class="fTel">01089892323</p>
                            <p class="fEml">no1Mat@gmail.com</p>
                        </li> -->
                    </ul>
                </div>
            </div>

            <!-- 그룹&팀 탭 사용시 나타날 내용 입니다  -->
            <div class="mo_content group_and_team" v-show="tab == 'GROUP_AND_TEAM'">
                
                <!-- 그룹&팀 리스트 헤더 -->
                <ul class="sortHeader grpHeader">
                    <li class="grpCheck" v-if="!(event_crud_type == 'CREATE' && share_option == 'MOVE')">선택</li>
                    <li class="num">No</li>
                    <li class="nm headerSort" @click="groupTeamSort('NAME')"
                        :class="{
                                sortOff  : group_team_sort_type != 'NAME',
                                sortUp   : group_team_sort_type == 'NAME' && group_team_sort_direction == 'ASC', 
                                sortDown : group_team_sort_type == 'NAME' && group_team_sort_direction == 'DESC', 
                            }">
                        <span>그룹</span>
                    </li>
                    <li class="count headerSort" @click="groupTeamSort('COUNT')"
                        :class="{
                                sortOff  : group_team_sort_type != 'COUNT',
                                sortUp   : group_team_sort_type == 'COUNT' && group_team_sort_direction == 'ASC', 
                                sortDown : group_team_sort_type == 'COUNT' && group_team_sort_direction == 'DESC', 
                            }">
                        <span>멤버</span>
                    </li>
                </ul>

                <!-- 그룹&팀 멤버 리스트 헤더 -->
                <ul class="sortHeader teamHeader" v-if="selected_group_team != null && 
                                                       (common_select_friend_and_group_team_modal_info.use_need_user_email == null || 
                                                        common_select_friend_and_group_team_modal_info.use_need_user_email == false)">
                    <li class="grpCheck">
                        <p class="grpTeamCheckAll" >
                            <input type="checkbox" id="grpTeamCheckAll" :checked="isMemberAllChecked()">
                            <label for="grpTeamCheckAll" @click.prevent="memberAllCheck">전체선택</label>
                        </p>
                    </li>
                    <li class="num">No</li>
                    <li class="pNm headerSort" @click="memberSort('NAME')"
                        :class="{
                                sortOff  : member_sort_type != 'NAME',
                                sortUp   : member_sort_type == 'NAME' && member_sort_direction == 'ASC', 
                                sortDown : member_sort_type == 'NAME' && member_sort_direction == 'DESC', 
                            }">
                        <span>이름</span>
                    </li>
                    <li class="pos headerSort" @click="memberSort('ROLE')"
                        :class="{
                                sortOff  : member_sort_type != 'ROLE',
                                sortUp   : member_sort_type == 'ROLE' && member_sort_direction == 'ASC', 
                                sortDown : member_sort_type == 'ROLE' && member_sort_direction == 'DESC', 
                            }">
                        <span>권한</span>
                    </li>
                </ul>

                <ul class="sortHeader teamHeader" v-if="selected_group_team != null && common_select_friend_and_group_team_modal_info.use_need_user_email == true">
                    <li class="grpCheck">
                        <p class="grpTeamCheckAll" >
                            <input type="checkbox" id="grpTeamCheckAll" :checked="isEmailMemberAllChecked()">
                            <label for="grpTeamCheckAll" @click.prevent="emailMemberAllCheck">전체선택</label>
                        </p>
                    </li>
                    <li class="num">No</li>
                    <li class="pNm headerSort" @click="memberSort('NAME')"
                        :class="{
                                sortOff  : member_sort_type != 'NAME',
                                sortUp   : member_sort_type == 'NAME' && member_sort_direction == 'ASC', 
                                sortDown : member_sort_type == 'NAME' && member_sort_direction == 'DESC', 
                            }">
                        <span>이름</span>
                    </li>
                    <li class="email"><span>이메일</span></li>
                </ul>

                <div class="shareUlDiv" v-if="common_select_friend_and_group_team_modal_info.use_need_user_email == null || 
                                              common_select_friend_and_group_team_modal_info.use_need_user_email == false">

                    <div class="noResult" :class="{ on : computedGroupInfo.length < 1 }">검색 결과가 없습니다</div>
                    <ul class="grpUl">

                        <li :key="group.group_id" v-for="(group, group_index) in computedGroupInfo" :class="{
                            on : group.shareListOn == true, 
                            withTeams : group.teams != null && group.teams.length > 0
                        }">
                            <div class="grp">
                                <input type="checkbox" :id="`grpCheck${group_index + 1}`" v-model="group.shareChecked" @change="checkGroupAndTeam($event, group)" v-if="!(event_crud_type == 'CREATE' && share_option == 'MOVE')"/>
                                <label :for="`grpCheck${group_index + 1}`" v-if="!(event_crud_type == 'CREATE' && share_option == 'MOVE')"></label>
                                <p class="num">{{ group_index + 1 }}</p>
                                <p class="img" :style="{ backgroundImage : `url(${make_group_profile_image_url(group.group_id)})` }">그룹 사진</p>
                                <img class="fPicDummy" :src="make_group_profile_image_url(group.group_id)" @error="groupTeamImageError($event, group)"/>
                                <h2 class="nm">{{ group.group_name }}</h2>
                                <p class="cl dc0" :style="{ backgroundColor : `${hodu_hex_color_process(group.color)} !important` }">그룹 색</p>
                                <p class="count" v-if="group.user_count > 1">
                                    <a @click.prevent="showGroupTeamMember(group)">
                                        <span>{{ getGroupTeamSelectedUserCount(group) }}</span> / {{ group.user_count - 1 }}
                                    </a>
                                </p>
                                <p class="count noCount" v-if="group.user_count <= 1">
                                    멤버 없음
                                </p>
                                <a class="showTeams" v-if="group.teams != null && group.teams.length > 0" :class="{ on : group.shareListOn == true }" @click.prevent="showTeamList(group)">하위그룹 보기</a>
                            </div>

                            <ul class="teamUl" v-if="group.teams != null && group.teams.length > 0">

                                <li :key="team.team_id" v-for="(team, team_index) in group.teams">
                                    <div class="grp">
                                        <input type="checkbox" :id="`grpCheck${group_index + 1}-${team_index + 1}`" v-model="team.shareChecked" @change="checkGroupAndTeam($event, team)" v-if="!(event_crud_type == 'CREATE' && share_option == 'MOVE')">
                                        <label :for="`grpCheck${group_index + 1}-${team_index + 1}`" v-if="!(event_crud_type == 'CREATE' && share_option == 'MOVE')"></label>
                                        <p class="num">{{ `${group_index + 1}-${team_index + 1}` }}</p>
                                        <p class="img" :style="{ backgroundImage : `url(${make_team_profile_image_url(team.team_id)})` }">팀 사진</p>
                                        <img class="fPicDummy" :src="make_team_profile_image_url(team.team_id)" @error="groupTeamImageError($event, team)"/>
                                        <h3 class="nm">{{ team.team_name }}</h3>
                                        <p class="cl dc0" :style="{ backgroundColor : `${hodu_hex_color_process(team.color)} !important` }">팀 색</p>
                                        <p class="count" v-if="team.user_count > 1">
                                            <a @click.prevent="showGroupTeamMember(team)">
                                                <span>{{ getGroupTeamSelectedUserCount(team) }}</span> / {{ team.user_count - 1 }}
                                            </a>
                                        </p>
                                        <p class="count noCount" v-if="team.user_count <= 1">
                                            멤버 없음
                                        </p>
                                    </div>
                                </li>

                            </ul>
                        </li>

                        <!-- <li class="withTeams on">
                            <div class="grp">
                                <input type="checkbox" id="grpCheck1"/>
                                <label for="grpCheck1"></label>
                                <p class="num">1</p>
                                <p class="img">그룹 사진</p>
                                <img class="fPicDummy" src="" />
                                <h2 class="nm">그룹1</h2>
                                <p class="cl dc0">그룹 색</p>
                                <p class="count">
                                    <a>
                                        <span>0</span> / 5
                                    </a>
                                </p>
                                <a class="showTeams on">하위그룹 보기</a>
                            </div>

                            <ul class="teamUl">

                                <li>
                                    <div class="grp">
                                        <input type="checkbox" id="grpCheck1-1">
                                        <label for="grpCheck1-1"></label>
                                        <p class="num">1-1</p>
                                        <p class="img">팀 사진</p>
                                        <img class="fPicDummy" src="" />
                                        <h3 class="nm">팀1</h3>
                                        <p class="cl dc2">팀 색</p>
                                        <p class="count noCount">
                                            멤버 없음
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div class="grp">
                                        <input type="checkbox" id="grpCheck1-2">
                                        <label for="grpCheck1-2"></label>
                                        <p class="num">1-2</p>
                                        <p class="img">팀 사진</p>
                                        <img src="" style="display : none;" />
                                        <h3 class="nm">팀2</h3>
                                        <p class="cl dc3">팀 색</p>
                                        <p class="count">
                                            <a>
                                                <span>0</span> / 2
                                            </a>
                                        </p>
                                    </div>
                                </li>

                            </ul>

                        </li>

                        <li>
                            <div class="grp">
                                <input type="checkbox" id="grpCheck2"/>
                                <label for="grpCheck2"></label>
                                <p class="num">2</p>
                                <p class="img">그룹 사진</p>
                                <img src="" style="display : none;" />
                                <h2 class="nm">그룹2</h2>
                                <p class="cl dc1">그룹 색</p>
                                <p class="count noCount">
                                    멤버 없음
                                </p>
                            </div>
                        </li> -->

                    </ul>
                </div>

                <div class="shareUlDiv" v-if="common_select_friend_and_group_team_modal_info.use_need_user_email == true">
                    <div class="noResult" :class="{ on : computedEmailGroupInfo.length < 1 }">검색 결과가 없습니다</div>
                    <ul class="grpUl">

                        <li :key="group.group_id" v-for="(group, group_index) in computedEmailGroupInfo" :class="{
                            on : group.shareListOn == true, 
                            withTeams : group.teams != null && group.teams.length > 0
                        }">

                            <div class="grp">
                                <input type="checkbox" :id="`grpCheck${group_index + 1}`" v-model="group.shareChecked" @change="checkGroupAndTeam($event, group)"/>
                                <label :for="`grpCheck${group_index + 1}`"></label>
                                <p class="num">{{ group_index + 1 }}</p>
                                <p class="img" :style="{ backgroundImage : `url(${make_group_profile_image_url(group.group_id)})` }">그룹 사진</p>
                                <img class="fPicDummy" :src="make_group_profile_image_url(group.group_id)" @error="groupTeamImageError($event, group)"/>
                                <h2 class="nm">{{ group.group_info.group_name }}</h2>
                                <p class="cl dc0" :style="{ backgroundColor : `${hodu_hex_color_process(group.color)} !important` }">그룹 색</p>
                                <p class="count">
                                    <a @click.prevent="showEmailGroupTeamMember(group)">
                                        <span>{{ getGroupTeamSelectedUserCount(group) }}</span> / {{ group.group_member_count }}
                                    </a>
                                </p>
                                <a class="showTeams" v-if="group.teams != null && group.teams.length > 0" :class="{ on : group.shareListOn == true }" @click.prevent="showTeamList(group)">하위그룹 보기</a>
                            </div>

                            <ul class="teamUl" v-if="group.teams != null && group.teams.length > 0">

                                <li :key="team.team_id" v-for="(team, team_index) in group.teams">
                                    <div class="grp">
                                        <input type="checkbox" :id="`grpCheck${group_index + 1}-${team_index + 1}`" v-model="team.shareChecked" @change="checkGroupAndTeam($event, team)">
                                        <label :for="`grpCheck${group_index + 1}-${team_index + 1}`"></label>
                                        <p class="num">{{ `${group_index + 1}-${team_index + 1}` }}</p>
                                        <p class="img" :style="{ backgroundImage : `url(${make_team_profile_image_url(team.team_id)})` }">팀 사진</p>
                                        <img class="fPicDummy" :src="make_team_profile_image_url(team.team_id)" @error="groupTeamImageError($event, team)"/>
                                        <h3 class="nm">{{ team.team_info.team_name }}</h3>
                                        <p class="cl dc0" :style="{ backgroundColor : `${hodu_hex_color_process(team.color)} !important` }">팀 색</p>
                                        <p class="count">
                                            <a @click.prevent="showEmailGroupTeamMember(team)">
                                                <span>{{ getGroupTeamSelectedUserCount(team) }}</span> / {{ team.team_member_count }}
                                            </a>
                                        </p>
                                    </div>
                                </li>

                            </ul>

                        </li>
                    </ul>
                </div>

            </div>

        </div>

        <!-- 멤버 보려면 on 클래스 추가 -->
        <div class="peepsDiv" :class="{ on : selected_group_team != null }" v-if="common_select_friend_and_group_team_modal_info.use_need_user_email == null || 
                                                                                  common_select_friend_and_group_team_modal_info.use_need_user_email == false">
            <div class="peepsUlDiv">

                <div class="noResult" :class="{ on : computedGroupTeamMember.length < 1 }">검색 결과가 없습니다</div>

                <ul class="peeps">

                    <li :key="member.user_id" v-for="(member, index) in computedGroupTeamMember">
                        <input type="checkbox" :id="`grpPeepCheck${index}`" :checked="member.checked == true" @change="checkMember($event, member)">
                        <label :for="`grpPeepCheck${index}`"></label>
                        <p class="num">{{ index + 1 }}</p>
                        <p class="fPic" :style="{ backgroundImage : `url(${make_user_profile_image_url(member.user_id)})` }">멤버 사진</p>
                        <img class="fPicDummy" :src="make_user_profile_image_url(member.user_id)" @error="userImageError($event)"/>
                        <p class="pNm">{{ member.user_info.user_name }}</p>
                        <p class="pos" :class="{ king    : member.role[0] == 'ADMIN' || member.role[0] == 'GROUP_MANAGER' || member.role[0] == 'TEAM_MANAGER', 
                                                 manager : member.role[0] == 'GROUP_SUB_MANAGER', 
                                                 mem     : member.role[0] == 'GROUP_USER' || member.role[0] == 'TEAM_USER' }">

                            {{ member.role[0] == 'GROUP_MANAGER'     ? "최종관리자" :
                               member.role[0] == 'GROUP_MANAGER'     ? "관리자"   : member.role[0] == 'TEAM_MANAGER' ? "팀장" : 
                               member.role[0] == 'GROUP_SUB_MANAGER' ? "부관리자" :
                               member.role[0] == 'GROUP_USER'        ? "멤버"     : member.role[0] == 'TEAM_USER' ? "팀원" : "" }}

                        </p>
                    </li>

                    <!-- <li>
                        <input type="checkbox" id="grpPeepCheck1">
                        <label for="grpPeepCheck1"></label>
                        <p class="num">1</p>
                        <p class="fPic">멤버 사진</p>
                        <img class="fPicDummy"/>
                        <p class="pNm">김호두</p>
                        <p class="pos king">관리자</p>
                    </li>

                    <li>
                        <input type="checkbox" id="grpPeepCheck2">
                        <label for="grpPeepCheck2"></label>
                        <p class="num">2</p>
                        <p class="fPic">멤버 사진</p>
                        <img class="fPicDummy"/>
                        <p class="pNm">강호두</p>
                        <p class="pos manager">부관리자</p>
                    </li>

                    <li>
                        <input type="checkbox" id="grpPeepCheck3">
                        <label for="grpPeepCheck3"></label>
                        <p class="num">3</p>
                        <p class="fPic">멤버 사진</p>
                        <img class="fPicDummy"/>
                        <p class="pNm">나호두</p>
                        <p class="pos mem">멤버</p>
                    </li> -->

                </ul>
            </div>
        </div>

        <div class="peepsDiv" :class="{ on : selected_group_team != null }" v-if="common_select_friend_and_group_team_modal_info.use_need_user_email == true">
            
            <div class="peepsUlDiv">
                <div class="noResult" :class="{ on : computedEmailGroupTeamMember.length < 1 }">검색 결과가 없습니다</div>

                <ul class="peeps">

                    <li :key="member.user_id" v-for="(member, index) in computedEmailGroupTeamMember">
                        <input type="checkbox" :id="`grpPeepCheck${index}`" :checked="member.checked == true" @change="checkEmailMember($event, member)">
                        <label :for="`grpPeepCheck${index}`"></label>
                        <p class="num">{{ index + 1 }}</p>
                        <p class="fPic" :style="{ backgroundImage : `url(${make_user_profile_image_url(member.user_id)})` }">멤버 사진</p>
                        <img class="fPicDummy" :src="make_user_profile_image_url(member.user_id)" @error="userImageError($event)"/>
                        <p class="pNm">{{ member.user_name }}</p>
                        <p class="pos">{{ member.user_email }}</p>
                    </li>

                </ul>

            </div>
        
        </div>

        <div class="btns">
            <input type="button" class="input_btn" value="취소" @click.prevent="close">
            <input type="button" class="input_btn" value="확인" @click.prevent="save">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, CRUD_TYPE, EVENT_SUB_TYPE, SHARE_OPTION, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { CommonSelectFriendAndGroupTeamModalInfo } from '@/store/modules/ModalInfo';
import { GroupTeamInfo } from '@/store/modules/GroupInfo';
import { hodu_color } from '@/common/color';

@Component({
    components: {
        
    },
})
export default class CommonSelectFriendAndGroupTeamModal extends Mixins(VueHoduCommon) {

    SHARE_OPTION : any = SHARE_OPTION;

    /**
     * 친구 정보
     */
    get computedFriends() : any[] {

        let computed_friends : any[]  = JSON.parse(JSON.stringify(this.friends.filter(friend => friend.is_hide == false && friend.is_block == false)));
        
        if( this.common_select_friend_and_group_team_modal_info.use_need_user_email == true ) {
            computed_friends = computed_friends.filter(friend => (friend.user_email != null && friend.user_email.length > 0 ) );
        }
    
        if( this.friend_search_query.trim().length > 0 ) {
            computed_friends = computed_friends.filter(friend => this.hodu_string_includes(this.getFriendName(friend), this.friend_search_query) ||
                                                                 (friend.user_phone_number != null && this.hodu_string_includes(friend.user_phone_number, this.friend_search_query)) ||
                                                                 (friend.user_email != null && this.hodu_string_includes(friend.user_email, this.friend_search_query)) );
        }

        return computed_friends.sort((o1, o2) : number => {

            let o1_name = this.getFriendName(o1);
            let o2_name = this.getFriendName(o2);

            if( this.friend_sort_type == 'NAME' ) {

                if( o1_name < o2_name ) return this.friend_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else if( o1_name > o2_name ) return this.friend_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                return 0;

            }

            return 0;
        });
    }

    /**
     * 그룹 정보
     */
    get computedGroupInfo() : any[] {

        let computed_group_info = JSON.parse(JSON.stringify(this.group_info_list));

        // 미인증시 호두홈, 호두키즈는 보이지 않는다
        if( this.auth_info == null || this.auth_info.auth_check == false ) {
            computed_group_info = computed_group_info.filter(
                group => group.biz_type != 'BIZH' && group.group_info.project_type != 'kids' 
            );
        }

        // 제외 해야하는 그룹&팀 제외
        if( this.calendar_scope_group_id > -1 && this.calendar_scope_team_id > -1 ) {
            if( this.calendar_scope_team_id < 1 ) {
                computed_group_info = computed_group_info.filter(group => group.group_id != this.calendar_scope_group_id);
            }
            else {
                for( const group of computed_group_info ) {
                    if( group.group_id != this.calendar_scope_group_id ) continue;
                    const group_copy = JSON.parse(JSON.stringify(group));
                    group_copy.teams = group_copy.teams.filter(team => team.team_id != this.calendar_scope_team_id);
                    computed_group_info.splice(computed_group_info.indexOf(group), 1, group_copy);
                    break;
                }
            }
        }

        if( this.group_team_search_query.trim().length > 0 ) {

            let search_list : any[] = [];

            const group_count : number = computed_group_info.length;
            for( let i = 0; i < group_count; i++ ) {
                const group_obj : GroupTeamInfo = JSON.parse(JSON.stringify(computed_group_info[i]));
                
                // 그룹 이름이 매칭 된다면 push
                if( this.hodu_string_includes(group_obj.group_name, this.group_team_search_query) ) {
                    
                    // 팀 중에도 매칭되는게 있다면 펼쳐서 보여준다
                    if( group_obj.teams.filter(team => this.hodu_string_includes(team.team_name, this.group_team_search_query)).length > 0 ) {
                        group_obj.shareListOn = true;
                    }

                    search_list.push(group_obj);
                    continue;
                }

                group_obj.teams = group_obj.teams.filter(team => team.team_name.indexOf(this.group_team_search_query) > - 1);
                
                // 팀 이름이 하나라도 매칭 된다면 push, 팀 이름만 매칭되는 경우는 리스트를 펼쳐준다
                if( group_obj.teams.length > 0 ) {
                    group_obj.shareListOn = true;
                    search_list.push(group_obj);
                }
                
            }

            computed_group_info = JSON.parse(JSON.stringify(search_list));
        }

        if( this.group_team_sort_type != null ) {

            // 팀 정렬
            for( const group of computed_group_info ) {
                if( group.teams != null && group.teams.length > 0 ) {
                    group.teams.sort((o1, o2) : number => {
                        const o1_team_name = o1.team_name;
                        const o2_team_name = o2.team_name;

                        const o1_user_count = o1.user_count;
                        const o2_user_count = o2.user_count;

                        if( this.group_team_sort_type == "NAME" ) {
                            if( o1_team_name < o2_team_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                            else if( o1_team_name > o2_team_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                            return 0;
                        }

                        if( this.group_team_sort_type == "COUNT" ) {
                            if( o1_user_count < o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                            else if( o1_user_count > o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                            return 0;
                        }

                        return 0;
                    });
                }
            }

            // 그룹 정렬
            computed_group_info.sort((o1, o2) : number => {

                const o1_group_name = o1.group_name;
                const o2_group_name = o2.group_name;

                const o1_user_count = o1.user_count;
                const o2_user_count = o2.user_count;

                if( this.group_team_sort_type == "NAME" ) {
                    if( o1_group_name < o2_group_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                    else if( o1_group_name > o2_group_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                    return 0;
                }

                if( this.group_team_sort_type == "COUNT" ) {
                    if( o1_user_count < o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                    else if( o1_user_count > o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                    return 0;
                }

                return 0;
            });

        }

        return computed_group_info;
    }

    /**
     * 선택 된 그룹&팀의 멤버 정보
     */
    get computedGroupTeamMember() : any[] {

        let computed_group_team_member : any[] = JSON.parse(JSON.stringify(this.group_team_members));

        if( this.group_team_member_search_query.trim().length > 0 ) {
            computed_group_team_member = computed_group_team_member.filter(member => member.user_info.user_name.toUpperCase().indexOf(this.group_team_member_search_query.trim().toUpperCase()) > -1)
        }

        // 같은 그룹내 멤버중 직급이 높은 순서대로 + 같은 직급의 가나다순
        return computed_group_team_member.sort((o1, o2) : number => {

            const o1_role_level = o1.role_level;
            const o2_role_level = o2.role_level;
            const o1_user_name  = o1.user_info.user_name;
            const o2_user_name  = o2.user_info.user_name;

            if( this.member_sort_type == 'NAME' ) {
                if( o1_user_name < o2_user_name ) return this.member_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else if( o1_user_name > o2_user_name ) return this.member_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                else if( o1_role_level < o2_role_level ) return -1;
                else if( o1_role_level > o2_role_level ) return 1;
                return 0;
            }

            if( this.member_sort_type == 'ROLE' ) {
                if( o1_role_level < o2_role_level ) return this.member_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                else if( o1_role_level > o2_role_level ) return this.member_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else if( o1_user_name < o2_user_name ) return -1;
                else if( o1_user_name > o2_user_name ) return 1;
                return 0;
            }

            return 0;
        });
    }

    /**
     * 이메일이 있는 멤버가 존재하는 그룹&팀 
     */
    get computedEmailGroupInfo() : any[] {

        let computed_email_group_info : any[] = JSON.parse(JSON.stringify(this.email_group_team_list));

        // 미인증시 호두홈, 호두키즈는 보이지 않는다
        if( this.auth_info == null || this.auth_info.auth_check == false ) {
            computed_email_group_info = computed_email_group_info.filter(
                group => group.group_type != 'BIZH' && group.group_info.project_type != 'kids' 
            );
        }

        // 제외 해야하는 그룹&팀 제외
        if( this.calendar_scope_group_id > -1 && this.calendar_scope_team_id > -1 ) {
            if( this.calendar_scope_team_id < 1 ) {
                computed_email_group_info = computed_email_group_info.filter(group => group.group_id != this.calendar_scope_group_id);
            }
            else {
                for( const group of computed_email_group_info ) {
                    if( group.group_id != this.calendar_scope_group_id ) continue;
                    const group_copy = JSON.parse(JSON.stringify(group));
                    group_copy.teams = group_copy.teams.filter(team => team.team_id != this.calendar_scope_team_id);
                    computed_email_group_info.splice(computed_email_group_info.indexOf(group), 1, group_copy);
                    break;
                }
            }
        }

        if( this.group_team_search_query.trim().length > 0 ) {

            let search_list : any[] = [];

            const group_count : number = computed_email_group_info.length;
            for( let i = 0; i < group_count; i++ ) {
                const group_obj : any = JSON.parse(JSON.stringify(computed_email_group_info[i]));
                
                // 그룹 이름이 매칭 된다면 push
                if( this.hodu_string_includes(group_obj.group_info.group_name, this.group_team_search_query) ) {
                    
                    // 팀 중에도 매칭되는게 있다면 펼쳐서 보여준다
                    if( group_obj.teams.filter(team => this.hodu_string_includes(team.team_name, this.group_team_search_query)).length > 0 ) {
                        group_obj.shareListOn = true;
                    }

                    search_list.push(group_obj);
                    continue;
                }

                group_obj.teams = group_obj.teams.filter(team => this.hodu_string_includes(team.team_info.team_name, this.group_team_search_query));
                
                // 팀 이름이 하나라도 매칭 된다면 push, 팀 이름만 매칭되는 경우는 리스트를 펼쳐준다
                if( group_obj.teams.length > 0 ) {
                    group_obj.shareListOn = true;
                    search_list.push(group_obj);
                }
                
            }

            computed_email_group_info = JSON.parse(JSON.stringify(search_list));
        }

        for( const group of computed_email_group_info ) {
            group.user_count = group.group_member_count;
            for( const team of group.teams) team.user_count = team.team_member_count;

            group.teams.sort((o1, o2) : number => {

                const o1_team_id = o1.team_id;
                const o2_team_id = o2.team_id;

                const o1_team_name = o1.team_info.team_name;
                const o2_team_name = o2.team_info.team_name;

                const o1_user_count = o1.user_count;
                const o2_user_count = o2.user_count;

                if( this.group_team_sort_type == null ) {
                    if( o1_team_id < o2_team_id ) return -1;
                    else if( o1_team_id > o2_team_id ) return 1;
                    return 0;
                }

                if( this.group_team_sort_type == "NAME" ) {
                    if( o1_team_name < o2_team_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                    else if( o1_team_name > o2_team_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                    return 0;
                }

                if( this.group_team_sort_type == "COUNT" ) {
                    if( o1_user_count < o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                    else if( o1_user_count > o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                    return 0;
                }

                return 0;
            });
        }

        computed_email_group_info.sort((o1, o2) : number => {
            const o1_group_id = o1.group_id;
            const o2_group_id = o2.group_id;

            const o1_group_name = o1.group_info.group_name;
            const o2_group_name = o2.group_info.group_name;

            const o1_user_count = o1.user_count;
            const o2_user_count = o2.user_count;

            if( this.group_team_sort_type == null ) {
                if( o1_group_id < o2_group_id ) return -1;
                else if( o1_group_id > o2_group_id ) return 1;
                return 0;
            }

            if( this.group_team_sort_type == "NAME" ) {
                if( o1_group_name < o2_group_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else if( o1_group_name > o2_group_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                return 0;
            }

            if( this.group_team_sort_type == "COUNT" ) {
                if( o1_user_count < o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else if( o1_user_count > o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                return 0;
            }

            return 0;
        });

        return computed_email_group_info;
    }

    /**
     * 이메일이 있는 그룹&팀 멤버
     */
    get computedEmailGroupTeamMember() : any[] {

        let computed_email_group_team_member : any[] = JSON.parse(JSON.stringify(this.email_group_team_members));

        if( this.group_team_member_search_query.trim().length > 0 ) {
            computed_email_group_team_member = computed_email_group_team_member.filter(member => this.hodu_string_includes(member.user_name, this.group_team_member_search_query) ||
                                                                                                 this.hodu_string_includes(member.user_email, this.group_team_member_search_query));
        }

        return computed_email_group_team_member.sort((o1, o2) : number => {
            const o1_user_name  = o1.user_name;
            const o2_user_name  = o2.user_name;

            if( this.member_sort_type == 'NAME' ) {
                if( o1_user_name < o2_user_name ) return this.member_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else if( o1_user_name > o2_user_name ) return this.member_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                return 0;
            }

            return 0;
        });
    }

    /**
     * @ModalInfo.state
     */
    @ModalInfo.State common_select_friend_and_group_team_modal_info !: CommonSelectFriendAndGroupTeamModalInfo;

    share_option : SHARE_OPTION = SHARE_OPTION.SHARE;// 일정 공유 타입
    event_sub_type : EVENT_SUB_TYPE = EVENT_SUB_TYPE.SCHEDULE;
    event_crud_type : CRUD_TYPE = CRUD_TYPE.CREATE;
    event_calendar_id : string = "";
    event_scope : string = "";
    event_scope_id : number = 0;
    event_id : string = "";

    calendar_scope_group_id : number = -1;
    calendar_scope_team_id : number = -1;

    tab : string = "FRIEND"; // FRIEND, GROUP_AND_TEAM
    
    selected_user_ids        : number[] = [];
    selected_group_ids       : number[] = [];
    selected_team_ids        : number[] = [];
    selected_friend_user_ids : number[] = [];
    group_user_selected_key  : string[] = [];
    team_user_selected_key   : string[] = [];

    group_team_members : any[] = [];
    email_group_team_list : any[] = [];
    email_group_team_members : any[] = [];

    selected_group_team : GroupTeamInfo | any = null; // 멤버 리스트를 보기 위해 선택된 그룹, 팀
    view_search_friend : boolean = true; // 친구 검색 중
    view_search_group_team : boolean = false; // 그룹&팀 검색 중
    view_search_member : boolean = false; // 그룹&팀의 멤버 검색 중

    friend_search_query : string = "";
    group_team_search_query : string = "";
    group_team_member_search_query : string = "";

    friend_sort_type        : string = "NAME";
    friend_sort_direction     : SORT_TYPE = SORT_TYPE.ASC;
    group_team_sort_type    : string | null = null; // NAME, COUNT 
    group_team_sort_direction : SORT_TYPE = SORT_TYPE.ASC;
    member_sort_type        : string = "ROLE";      // NAME, ROLE
    member_sort_direction     : SORT_TYPE = SORT_TYPE.DESC;

    async mounted() : Promise<void> {
        // 탭 메뉴
        // $('.tab input').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.mo_content').hide();
        // 	$('.mo_content').eq(borderIndex).show();
        // 	$(this).addClass('on').siblings().removeClass();

        // 	return false;
        // });

        this.tab = this.common_select_friend_and_group_team_modal_info.use_group_select_only == true ? 'GROUP_AND_TEAM' : 'FRIEND'; 
        this.share_option = this.common_select_friend_and_group_team_modal_info?.share_option ?? SHARE_OPTION.SHARE;
        this.event_sub_type = this.common_select_friend_and_group_team_modal_info?.event_sub_type ?? EVENT_SUB_TYPE.SCHEDULE;
        this.event_crud_type = this.common_select_friend_and_group_team_modal_info?.event_crud_type ?? CRUD_TYPE.CREATE;
        this.event_calendar_id = this.common_select_friend_and_group_team_modal_info?.event_calendar_id ?? "";
        this.event_scope = this.common_select_friend_and_group_team_modal_info?.event_scope ?? "";
        this.event_scope_id = this.common_select_friend_and_group_team_modal_info?.event_scope_id ?? 0;
        this.event_id = this.common_select_friend_and_group_team_modal_info?.event_id ?? "";

        this.calendar_scope_group_id = this.common_select_friend_and_group_team_modal_info?.calendar_scope_group_id ?? -1;
        this.calendar_scope_team_id = this.common_select_friend_and_group_team_modal_info?.calendar_scope_team_id ?? -1;
        
        this.selected_user_ids        = this.selected_user_ids.concat(this.common_select_friend_and_group_team_modal_info?.selected_user_ids ?? [])
        this.selected_group_ids       = this.selected_group_ids.concat(this.common_select_friend_and_group_team_modal_info?.selected_group_ids ?? []);
        this.selected_team_ids        = this.selected_team_ids.concat(this.common_select_friend_and_group_team_modal_info.selected_team_ids ?? []);
        this.selected_friend_user_ids = this.selected_friend_user_ids.concat(this.common_select_friend_and_group_team_modal_info?.selected_friend_user_ids ?? []);
        this.group_user_selected_key  = this.group_user_selected_key.concat(this.common_select_friend_and_group_team_modal_info.group_user_selected_key ?? []);
        this.team_user_selected_key   = this.team_user_selected_key.concat(this.common_select_friend_and_group_team_modal_info.team_user_selected_key ?? []);

        if( this.common_select_friend_and_group_team_modal_info.use_need_user_email ) {
            this.member_sort_type = "NAME";
            this.member_sort_direction = SORT_TYPE.ASC;
        }
        else {
            this.member_sort_type = "ROLE";
            this.member_sort_direction = SORT_TYPE.DESC;
        }

        const promise_function = [this.get_group_role_service(), this.getFriends()];
        if( this.common_select_friend_and_group_team_modal_info.use_need_user_email == true ) {
            promise_function.push(this.getTargetEventShareByEmail());
        }

        this.hodu_show_indicator();
        await Promise.all(promise_function);
        await this.calculateGroupTeamShareChecked();
        this.hodu_hide_indicator();

        this.$nextTick(() => $('#searchTotalTxt').focus());
    }

    /**
     * 이메일 공유 대상 조회
     */
    async getTargetEventShareByEmail() : Promise<void> {

        const url = `api/v1/calendars/${this.event_calendar_id}/events/${this.event_scope}/${this.event_scope_id}/${this.event_id}/share/email`;

        try {
            const response = await this.hodu_api_call(url, API_METHOD.GET, null, false);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.target ) {
                throw new Error("이메일 공유 대상 조회 실패");
            }

            if( this.auth_info == null || this.auth_info.auth_check == false ) {
                response.data.data.target = response.data.data.target.filter(
                    group => group.group_type != 'BIZH' && group.group_info.project_type != 'kids' 
                );
            }

            this.email_group_team_list.splice(0, this.email_group_team_list.length);
            this.email_group_team_list = this.email_group_team_list.concat(response.data.data.target);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 공유 옵션 변경
     */
    shaerOptionChange(share_option : SHARE_OPTION) : void {
        if( this.share_option == share_option ) return;

        this.share_option = share_option;

        // 선택 정보 초기화
        this.reset();
    } 

    /**
     * 탭 변경
     */
    tabChange(tab : string) {
        
        if( this.tab == tab ) return;
        
        this.tab = tab;

        this.view_search_friend = (tab == 'FRIEND');
        this.view_search_group_team = (tab == 'GROUP_AND_TEAM');
        this.view_search_member = false;

        this.friend_search_query = "";
        this.group_team_search_query = "";
        this.group_team_member_search_query = "";
        
        for( const group of this.group_info_list ) {
            group.shareListOn = false;
            this.group_info_list.splice(this.group_info_list.indexOf(group), 1, group);
        }

        this.$nextTick(() => $('#searchTotalTxt').focus());
    }

    // search() : void {
    //     // 그룹, 팀 리스트 보는 중 일때
    //     if( this.selected_group_team == null ) {
    //         if( this.tab == 'FRIEND' ) {
    //             this.view_search_friend = !this.view_search_friend;
    //             this.view_search_group_team = this.view_search_friend;
    //             this.view_search_member = this.view_search_friend;
    //         }
    //         else {
    //             this.view_search_group_team = !this.view_search_group_team;
    //             this.view_search_friend = this.view_search_group_team;
    //             this.view_search_member = this.view_search_group_team;
    //         }
    //     }

    //     // 멤버 리스트 보는 중 일때
    //     else {
    //         this.view_search_member = !this.view_search_member;
    //         this.view_search_group_team = this.view_search_member;
    //         this.view_search_friend = this.view_search_member;
    //     }

    //     // 그룹, 팀 검색을 끄는 경우
    //     if( this.view_search_group_team == false ) {
    //         if( this.tab == 'FRIEND' ) {
    //             this.friend_search_query = '';
    //         }
    //         else {
    //             this.group_team_search_query = '';
    //         }
    //     }

    //     // 멤버 검색을 끄는 경우
    //     if( this.view_search_member == false ) {
    //         this.group_team_member_search_query = '';
    //     }
    // }

    /**
     * 해당 그룹, 팀의 선택 된 유저 수 반환
     */
    getGroupTeamSelectedUserCount(group_and_team : GroupTeamInfo) : number {
        let count = 0;

        if( group_and_team.row_type == 'GROUP' ) {

            if( group_and_team.shareChecked == true && this.selected_group_ids.indexOf(group_and_team.group_id) > -1 ) {
                if( this.common_select_friend_and_group_team_modal_info.use_need_user_email == true ) {
                    count = group_and_team.user_count;
                }
                else {
                    count = (group_and_team.user_count - 1);
                }
            }
            else {
                const target = this.group_user_selected_key.filter(key => key.includes(`${group_and_team.group_id}-`));
                count = target.length;
            }  

        }

        else if( group_and_team.row_type == 'TEAM' ) {
            
            if( group_and_team.shareChecked == true && this.selected_team_ids.indexOf(group_and_team.team_id) > -1 ) {
                if( this.common_select_friend_and_group_team_modal_info.use_need_user_email == true ) {
                    count = group_and_team.user_count;
                }
                else {
                    count = (group_and_team.user_count - 1);
                }
            }
            else {
                const target = this.team_user_selected_key.filter(key => key.includes(`${group_and_team.team_id}-`));
                count = target.length;
            }

        }

        return count;
    }

    /**
     * 그룹&팀 멤버 보기
     */
    async showGroupTeamMember(group_team : GroupTeamInfo) : Promise<void> {

        if( this.share_option == SHARE_OPTION.MOVE && group_team.shareChecked == true ) {
            
            if( group_team.row_type == "GROUP" && this.selected_group_ids.indexOf(group_team.group_id) > -1 ) {
                return;
            }

            if( group_team.row_type == "TEAM" && this.selected_team_ids.indexOf(group_team.team_id) > -1 ) {
                return;
            }

        }

        this.selected_group_team = group_team;

        try {
            const target = this.selected_group_team.row_type;
            let group_id = this.selected_group_team.group_id ? this.selected_group_team.group_id : 0;
            let team_id = this.selected_group_team.team_id ? this.selected_group_team.team_id : 0;

            const response = await this.hodu_api_call(`api/v1/${ target == "GROUP" ? 'groups' : 'teams' }/${ target == "GROUP" ? 'getGroupMember' : 'getTeamMember' }/${group_id}${ team_id > 0 ? `/${team_id}` : ''}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.list ) {
                throw new Error("멤버 조회 중 오류 발생");
            }

            // 선택 되어야하는 멤버
            if( target == "GROUP" && this.selected_group_ids.indexOf(group_id) > -1 ) {
                for( const member of response.data.data.list ) member.checked = true;
            } 
            else if( target == "GROUP" && this.group_user_selected_key.filter(key => key.includes(`${group_id}-`)).length > 0 ) {
                for( const member of response.data.data.list ) {
                    if( this.group_user_selected_key.filter(key => key.includes(`${group_id}-${member.user_id}`)).length > 0 ) member.checked = true;
                }
            }
            else if( target == "TEAM" && this.selected_team_ids.indexOf(team_id) > -1 ) {
                for( const member of response.data.data.list ) member.checked = true;
            } 
            else if( target == "TEAM" && this.team_user_selected_key.filter(key => key.includes(`${team_id}-`)).length > 0 ) {
                for( const member of response.data.data.list ) {
                    if( this.team_user_selected_key.filter(key => key.includes(`${team_id}-${member.user_id}`)).length > 0 ) member.checked = true;
                }
            }
            
            this.group_team_members.splice(0, this.group_team_members.length);
            this.group_team_members = this.group_team_members.concat(response.data.data.list.filter(member => member.user_id != this.user_id));

            if( (this.selected_group_team.user_count - 1) != this.group_team_members.length ) {
                this.selected_group_team.user_count = this.group_team_members.length + 1;
            } 

            this.view_search_friend = false;
            this.view_search_group_team = false;
            this.view_search_member = true;

        } catch(e) {
            alert("멤버 조회 중 오류 발생")
            this.hodu_error_process(e, false, false, true);
            this.selected_group_team = null;
        }
    }

    /**
     * 이메일 필수 그룹&팀 멤버 보기 
     */
    showEmailGroupTeamMember(group_team : any) : void {
        this.selected_group_team = group_team;

        this.email_group_team_members.splice(0, this.email_group_team_members.length);

        let members : any[] = [];

        if( group_team.row_type == 'GROUP' ) {
            members = this.email_group_team_members.concat(group_team.group_member);
        }
        else {
            members = this.email_group_team_members.concat(group_team.team_member);
        }

        const row_type = group_team.row_type;
        const group_id = group_team.group_id ?? 0;
        const team_id = group_team.team_id ?? 0;

        // 선택 되어야하는 멤버
        if( row_type == "GROUP" && this.selected_group_ids.indexOf(group_id) > -1 ) {
            for( const member of members ) member.checked = true;
        } 
        else if( row_type == "GROUP" && this.group_user_selected_key.filter(key => key.includes(`${group_id}-`)).length > 0 ) {
            for( const member of members ) {
                if( this.group_user_selected_key.filter(key => key.includes(`${group_id}-${member.user_id}`)).length > 0 ) member.checked = true;
                else member.checked = false;
            }
        }
        else if( row_type == "TEAM" && this.selected_team_ids.indexOf(team_id) > -1 ) {
            for( const member of members ) member.checked = true;
        } 
        else if( row_type == "TEAM" && this.team_user_selected_key.filter(key => key.includes(`${team_id}-`)).length > 0 ) {
            for( const member of members ) {
                if( this.team_user_selected_key.filter(key => key.includes(`${team_id}-${member.user_id}`)).length > 0 ) member.checked = true;
                else member.checked = false;
            }
        }
        else {
            for( const member of members ) member.checked = false;
        }

        this.email_group_team_members = this.email_group_team_members.concat(members);

        this.view_search_friend = false;
        this.view_search_group_team = false;
        this.view_search_member = true;
    }

    /**
     * 팀 리스트 펼치기 / 접기 
     */
    async showTeamList(group_team_info : GroupTeamInfo) : Promise<void> {
        group_team_info.shareListOn = group_team_info.shareListOn == null || group_team_info.shareListOn == false ? true : false;

        let group_team : GroupTeamInfo | null = null;

        for( const group of this.group_info_list ) {
            if( group.group_id != group_team_info.group_id ) continue;
            group_team = group;
            break;
        }

        if( group_team != null ) {
            group_team.shareListOn = group_team_info.shareListOn;
        }

        this.$forceUpdate();
    }

    /**
     * 친구 체크
     */
    checkFriend(event, friend_user_id : number) : void {
        const checked : boolean = event.target.checked;

        if( this.share_option == SHARE_OPTION.MOVE ) {
            this.selected_friend_user_ids.splice(0, this.selected_friend_user_ids.length);
            this.group_user_selected_key.splice(0, this.group_user_selected_key.length);
            this.team_user_selected_key.splice(0, this.team_user_selected_key.length);
            this.selected_user_ids.splice(0, this.selected_user_ids.length);
            this.selected_group_ids.splice(0, this.selected_group_ids.length);
            this.selected_team_ids.splice(0, this.selected_team_ids.length);

            if( checked == true ) {
                this.selected_friend_user_ids.push(friend_user_id);
                this.calculateUserIds();
                this.calculateGroupTeamShareChecked();
            }
            return;
        }

        if( checked == true && this.selected_friend_user_ids.indexOf(friend_user_id) < 0 ) {
            this.selected_friend_user_ids.push(friend_user_id);
        }
        else if( checked == false && this.selected_friend_user_ids.indexOf(friend_user_id) > -1 ) {
            this.selected_friend_user_ids.splice(this.selected_friend_user_ids.indexOf(friend_user_id), 1);
        }

        this.calculateUserIds();
    }

    /**
     * 친구 전체 체크
     */
    friendAllCheck() : void {
        let checked = this.friends.length == this.selected_friend_user_ids.length;

        this.selected_friend_user_ids.splice(0, this.selected_friend_user_ids.length);
        if( checked == false ) {
            for( const friend of this.friends ) this.selected_friend_user_ids.push(friend.friend_user_id);
        }

        this.calculateUserIds();
    }

    /**
     * 멤버 전체 체크가 되어 있는지 여부
     */
    isMemberAllChecked() : boolean {
        if( this.selected_group_team == null ) return false;
        console.log(this.group_team_members.length == this.group_team_members.filter(member => member.checked == true).length);
        return this.group_team_members.length == this.group_team_members.filter(member => member.checked == true).length;
    }

    /**
     * 이메일이 있는 멤버 전체 체크가 되어 있는지 여부
     */
    isEmailMemberAllChecked() : boolean {
        if( this.selected_group_team == null ) return false;
        console.log(this.email_group_team_members.length == this.email_group_team_members.filter(member => member.checked == true).length);
        return this.email_group_team_members.length == this.email_group_team_members.filter(member => member.checked == true).length;
    }

    /**
     * 그룹&팀 체크
     */
    checkGroupAndTeam(event, group_and_team : GroupTeamInfo) : void {
        let checked : boolean = event.target.checked;

        let group_id = group_and_team.group_id ?? 0;
        let team_id = group_and_team.team_id ?? 0;
        let row_type = group_and_team.row_type;
        
        if( this.share_option == SHARE_OPTION.MOVE ) {

            this.selected_friend_user_ids.splice(0, this.selected_friend_user_ids.length);
            this.group_user_selected_key.splice(0, this.group_user_selected_key.length);
            this.team_user_selected_key.splice(0, this.team_user_selected_key.length);
            this.selected_user_ids.splice(0, this.selected_user_ids.length);
            this.selected_group_ids.splice(0, this.selected_group_ids.length);
            this.selected_team_ids.splice(0, this.selected_team_ids.length);

            if( row_type == 'GROUP' && checked == true ) {
                this.selected_group_ids.push(group_id);
            }
            else if( row_type == 'TEAM' && checked == true ) {
                this.selected_team_ids.push(team_id);
            }

            this.calculateGroupTeamShareChecked();
            return;
        }

        // group_member_key 에서 제거
        if( row_type == 'GROUP' ) {
            this.group_user_selected_key = this.group_user_selected_key.filter(key => !key.includes(`${group_id}-`));
        }
        // team_member_key 에서 제거
        else {
            this.team_user_selected_key = this.team_user_selected_key.filter(key => !key.includes(`${team_id}-`));
        }
        
        if( checked == true ) {
            // group_ids에 추가
            if( row_type == 'GROUP' && this.selected_group_ids.indexOf(group_id) < 0 ) {
                this.selected_group_ids.push(group_id);
            }
            // team_ids에 추가
            else if( row_type == 'TEAM' && this.selected_team_ids.indexOf(team_id) < 0 ) {
                this.selected_team_ids.push(team_id);
            }
        }
        else if( checked == false ) {
            // group_ids에서 제거
            if( row_type == 'GROUP' && this.selected_group_ids.indexOf(group_id) > -1 ) {
                this.selected_group_ids.splice(this.selected_group_ids.indexOf(group_id), 1);
            }
            // team_ids에서 제거
            else if( row_type == 'TEAM' && this.selected_team_ids.indexOf(team_id) > -1 ) {
                this.selected_team_ids.splice(this.selected_team_ids.indexOf(team_id), 1);
            }
        }
    }

    /**
     * 멤버 전체 체크
     */
    memberAllCheck() : void {
        if( this.selected_group_team == null ) return;

        let checked : boolean = this.group_team_members.length == this.group_team_members.filter(member => member.checked == true).length;

        for( const member of this.group_team_members ) {
            const new_member = JSON.parse(JSON.stringify(member));
            new_member.checked = !checked;
            this.group_team_members.splice(this.group_team_members.indexOf(member), 1, new_member);
        }
    }

    /**
     * 이메일이 있는 멤버 전체 체크
     */
    emailMemberAllCheck() : void {
        if( this.selected_group_team == null ) return;

        let checked : boolean = this.email_group_team_members.length == this.email_group_team_members.filter(member => member.checked == true).length;

        for( const member of this.email_group_team_members ) {
            const new_member = JSON.parse(JSON.stringify(member));
            new_member.checked = !checked;
            this.email_group_team_members.splice(this.email_group_team_members.indexOf(member), 1, new_member);
        }
    }

    /**
     * 그룹&팀 멤버 체크
     */
    checkMember(event, member : any) : void {
        const checked : boolean = event.target.checked;

        console.log(checked);
        
        const new_member = JSON.parse(JSON.stringify(member));
        new_member.checked = checked;

        for( const original_member of this.group_team_members ) {
            if( original_member.user_id != member.user_id ) {
                if( this.share_option == SHARE_OPTION.MOVE ) {
                    const original_member_copy = JSON.parse(JSON.stringify(original_member));
                    original_member_copy.checked = false;
                    this.group_team_members.splice(this.group_team_members.indexOf(original_member), 1, original_member_copy);
                }
                continue;
            }
            this.group_team_members.splice(this.group_team_members.indexOf(original_member), 1, new_member);
        }
    }

    /**
     * 이메일이 있는 그룹&팀 멤버 체크
     */
    checkEmailMember(event, member : any) : void {
        const checked : boolean = event.target.checked;

        console.log(checked);
        
        const new_member = JSON.parse(JSON.stringify(member));
        new_member.checked = checked;

        for( const original_member of this.email_group_team_members ) {
            if( original_member.user_id != member.user_id ) continue;
            this.email_group_team_members.splice(this.email_group_team_members.indexOf(original_member), 1, new_member);
        }
    }

    /**
     * user_ids 계산
     */
    calculateUserIds() : void {
        this.selected_user_ids.splice(0, this.selected_user_ids.length);

        const map : Map<number, number> = new Map();
        
        for( const friend_user_id of this.selected_friend_user_ids ) {
            map.set(friend_user_id, friend_user_id);
        }

        for( const group_user_key of this.group_user_selected_key ) {
            const key_split : string[] = group_user_key.split('-');
            if( key_split.length != 2 ) continue;
            const user_id = Number(key_split[1]);
            if( isNaN(user_id) ) continue;
            map.set(user_id, user_id);
        }

        for( const group_user_key of this.team_user_selected_key ) {
            const key_split : string[] = group_user_key.split('-');
            if( key_split.length != 2 ) continue;
            const user_id = Number(key_split[1]);
            if( isNaN(user_id) ) continue;
            map.set(user_id, user_id);
        }

        for( const user_id of map.keys() ) {
            this.selected_user_ids.push(user_id);
        }

    }

    /**
     * 그룹&팀 shareChecked 계산
     */
    async calculateGroupTeamShareChecked() : Promise<void> {
        for( const group of this.group_info_list ) {

            const group_copy = JSON.parse(JSON.stringify(group));
            const group_id = group_copy.group_id;

            for( const team of group_copy.teams ) { 

                const team_copy = JSON.parse(JSON.stringify(team));

                const team_id = team_copy.team_id;
                team_copy.shareChecked = this.team_user_selected_key.filter(key => key.includes(`${team_id}-`)).length > 0 || 
                                         this.selected_team_ids.indexOf(team_id) > -1;
                group_copy.teams.splice(group_copy.teams.indexOf(team), 1, team_copy);
            }

            group_copy.shareChecked = this.group_user_selected_key.filter(key => key.includes(`${group_id}-`)).length > 0 ||
                                      this.selected_group_ids.indexOf(group_id) > -1;

            this.group_info_list.splice(this.group_info_list.indexOf(group), 1, group_copy);
        }
    }

    /**
     * 친구 정렬
     */
    friendSort(sort_type : string) {
        if( this.friend_sort_type == sort_type ) {
            this.friend_sort_direction = this.friend_sort_direction == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
            return;
        }

        this.friend_sort_type = sort_type;
        this.friend_sort_direction = SORT_TYPE.ASC;
    }

    /**
     * 그룹&팀 정렬
     */
    groupTeamSort(sort_type : string) {

        if( this.group_team_sort_type == sort_type ) {

            if( this.group_team_sort_direction == SORT_TYPE.ASC ) {
                this.group_team_sort_direction = SORT_TYPE.DESC;
            }
            else {
                this.group_team_sort_type = null;
            }
            
            return;
        }

        this.group_team_sort_type = sort_type;
        this.group_team_sort_direction = SORT_TYPE.ASC;
    }

    /**
     * 그룹&팀 멤버 정렬
     */
    memberSort(sort_type : string) {
        if( this.member_sort_type == sort_type ) {
            this.member_sort_direction = this.member_sort_direction == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
            return;
        }

        this.member_sort_type = sort_type;
        this.member_sort_direction = SORT_TYPE.ASC;
    }

    /**
     * 친구 이미지 에러
     */
    userImageError(event) : void {
        const jquery_taget = $(event.target);
        const replace_image = require('@/assets/images/contents/im_photoB.gif');
        jquery_taget.parent().find('p.fImg, p.fPic').css('background-image', `url(${replace_image})`);
    }

    /**
     * 그룹, 팀 이미지 에러
     */
    groupTeamImageError(event, group : GroupTeamInfo) : void {
        const jquery_taget = $(event.target);

        let target_class = "imgDc7";

        switch( this.hodu_hex_color_process(group.color) ) {
            case hodu_color.hodu_dc_0: target_class = 'imgDc0'; break;
            case hodu_color.hodu_dc_1: target_class = 'imgDc1'; break;
            case hodu_color.hodu_dc_2: target_class = 'imgDc2'; break;
            case hodu_color.hodu_dc_3: target_class = 'imgDc3'; break;
            case hodu_color.hodu_dc_4: target_class = 'imgDc4'; break;
            case hodu_color.hodu_dc_5: target_class = 'imgDc5'; break;
            case hodu_color.hodu_dc_6: target_class = 'imgDc6'; break;
            case hodu_color.hodu_dc_7: target_class = 'imgDc7'; break;
            case hodu_color.hodu_dc_8: target_class = 'imgDc8'; break;
            case hodu_color.hodu_dc_9: target_class = 'imgDc9'; break;
            case hodu_color.hodu_lc_0: target_class = 'imgLc0'; break;
            case hodu_color.hodu_lc_1: target_class = 'imgLc1'; break;
            case hodu_color.hodu_lc_2: target_class = 'imgLc2'; break;
            case hodu_color.hodu_lc_3: target_class = 'imgLc3'; break;
            case hodu_color.hodu_lc_4: target_class = 'imgLc4'; break;
            case hodu_color.hodu_lc_5: target_class = 'imgLc5'; break;
            case hodu_color.hodu_lc_6: target_class = 'imgLc6'; break;
            case hodu_color.hodu_lc_7: target_class = 'imgLc7'; break;
            case hodu_color.hodu_lc_8: target_class = 'imgLc8'; break;
            case hodu_color.hodu_lc_9: target_class = 'imgLc9'; break;
        }

        jquery_taget.parent().find('p.img').addClass(target_class);
    }

    /**
     * 선택 정보 초기화
     */
    reset() {
        for( const group of this.group_info_list ) {
            
            const group_copy = JSON.parse(JSON.stringify(group));

            group_copy.shareChecked = false;

            for( const team of group_copy.teams ) {
                team.shareChecked = false;
            }

            this.group_info_list.splice(this.group_info_list.indexOf(group), 1, group_copy);
        }
        
        for( const member of this.group_team_members ) {
            const member_copy = JSON.parse(JSON.stringify(member));
            member_copy.checked = false;
            this.group_team_members.splice(this.group_team_members.indexOf(member), 1, member_copy);
        }

        for( const friend of this.friends ) {
            const friend_copy = JSON.parse(JSON.stringify(friend));
            friend_copy.checked = false;
            this.friends.splice(this.friends.indexOf(friend), 1, friend_copy);
        }

        this.selected_user_ids.splice(0, this.selected_user_ids.length);
        this.selected_group_ids.splice(0, this.selected_group_ids.length);
        this.selected_team_ids.splice(0, this.selected_team_ids.length);
        this.selected_friend_user_ids.splice(0, this.selected_friend_user_ids.length);
        this.group_user_selected_key.splice(0, this.group_user_selected_key.length);
        this.team_user_selected_key.splice(0, this.team_user_selected_key.length);
    }

    /**
     * 저장
     */
    save() : void {

        // 일정 이메일 공유 일때
        if( this.common_select_friend_and_group_team_modal_info.use_need_user_email == true ) {
            
            // 그룹 내부를 보고 있을때
            if( this.selected_group_team != null ) {

                let selected_count : number = 0;
                
                let row_type = this.selected_group_team.row_type;
                let group_id = this.selected_group_team.group_id ?? 0;
                let team_id = this.selected_group_team.team_id ?? 0;

                if( row_type == 'GROUP' ) {

                    this.selected_group_ids = this.selected_group_ids.filter(selected_group_id => selected_group_id != group_id);
                    this.group_user_selected_key = this.group_user_selected_key.filter(key => !key.includes(`${group_id}-`));

                    // 전체 선택 됨
                    if( this.email_group_team_members.length == this.email_group_team_members.filter(member => member.checked == true).length ) {
                        selected_count = this.email_group_team_members.length;
                        this.selected_group_ids.push(group_id);
                    }
                    // 전부 선택 되지는 않음
                    else {
                        for( const member of this.email_group_team_members ) {
                            if( member.checked == true ) {
                                selected_count++;
                                this.group_user_selected_key.push(`${group_id}-${member.user_id}`);
                            }
                        }
                    }
                }
                else if( row_type == 'TEAM' ) {

                    this.selected_team_ids = this.selected_team_ids.filter(selected_team_id => selected_team_id != team_id);
                    this.team_user_selected_key = this.team_user_selected_key.filter(key => !key.includes(`${team_id}-`));

                    // 전체 선택 됨
                    if( this.email_group_team_members.length == this.email_group_team_members.filter(member => member.checked == true).length ) {
                        selected_count = this.email_group_team_members.length;
                        this.selected_team_ids.push(team_id);
                    }
                    // 전부 선택되지는 않음
                    else {
                        for( const member of this.email_group_team_members ) {
                            if( member.checked == true ) {
                                selected_count++;
                                this.team_user_selected_key.push(`${team_id}-${member.user_id}`);
                            }
                        }
                    }

                }

                // 선택된 멤버가 하나라도 있으면 해당 그룹은 체크 표시가 되어 있어야함
                this.selected_group_team.shareChecked = (selected_count > 0);

                // selected_user_ids 계산
                this.calculateUserIds();
                this.calculateGroupTeamShareChecked();

                this.selected_group_team = null;

                this.view_search_friend = (this.tab == 'FRIEND');
                this.view_search_group_team = (this.tab == 'GROUP_AND_TEAM');
                this.view_search_member = false;

                this.group_team_member_search_query = "";
                return;
            }

            const start_date = this.common_select_friend_and_group_team_modal_info.event_start ?? new Date();
            const end_date = this.common_select_friend_and_group_team_modal_info.event_end ?? new Date();

            let is_all_day = false;
            if( end_date.getFullYear() == start_date.getFullYear() &&
                end_date.getMonth()    == start_date.getMonth()    &&
                end_date.getDate()     == start_date.getDate() ) {
                
                let event_time = end_date.getTime() - start_date.getTime();

                // 23시간 59분 차이가 난다면 
                let all_day_time = (23 * 60 * 60 * 1000) + (59 * 60 * 1000);

                if( event_time >= all_day_time ) is_all_day = true;
            }

            const start = encodeURIComponent(`${this.hodu_date_to_format_string(start_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(start_date, '요일')}${ !is_all_day ? ` ${this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(start_date, 'hh:mm a'))}` : '' }`);
            const end = encodeURIComponent(`${this.hodu_date_to_format_string(end_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(end_date, '요일')}${ !is_all_day ? ` ${this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(end_date, 'hh:mm a'))}` : '' }`);
            const start_timestamp = start_date.getTime();
            const end_timestamp = end_date.getTime();

            const title = this.common_select_friend_and_group_team_modal_info.event_title ?? "";

            let user_info_array : any[] = [];
            let user_info_map : Map<number, any> = new Map();

            // { "email" : "email@gmail.com", "name" : "이름" }
            // friend_user_ids, group_ids, team_ids, group_user_key, team_user_key 다 찾아서 하나씩 하나씩 넣어야함...
            for( const group_id of this.selected_group_ids ) {
                const target_group = this.email_group_team_list.filter(group => group.group_id == group_id);
                if( target_group.length < 1 ) continue;

                for( const member of target_group[0].group_member ) {
                    user_info_map.set(member.user_id, {
                        "name" : member.user_name,
                        "email" : member.user_email
                    });
                }
            }

            for( const team_id of this.selected_team_ids ) {
                const target_group = this.email_group_team_list.filter(group => group.teams.filter(team => team.team_id == team_id).length > 0);
                
                if( target_group.length < 1 ) continue;

                const team = target_group[0].teams.filter(team => team.team_id == team_id)[0];

                for( const member of team.team_member ) {
                    user_info_map.set(member.user_id, {
                        "name" : member.user_name,
                        "email" : member.user_email
                    });
                }
            }

            for( const group_user_key of this.group_user_selected_key ) {

                const key_split = group_user_key.split('-');

                if( key_split.length < 2 || isNaN(Number(key_split[0])) || isNaN(Number(key_split[1])) ) continue;

                const group_id = Number(key_split[0]);
                const user_id = Number(key_split[1]);

                const target_group = this.email_group_team_list.filter(group => group.group_id == group_id);
                if( target_group.length < 1 ) continue;

                for( const member of target_group[0].group_member ) {
                    
                    if( member.user_id != user_id ) continue;
                    
                    user_info_map.set(member.user_id, {
                        "name" : member.user_name,
                        "email" : member.user_email
                    });

                    break;
                }
            }

            for( const team_user_key of this.team_user_selected_key ) {

                const key_split = team_user_key.split('-');

                if( key_split.length < 2 || isNaN(Number(key_split[0])) || isNaN(Number(key_split[1])) ) continue;

                const team_id = Number(key_split[0]);
                const user_id = Number(key_split[1]);

                const target_group = this.email_group_team_list.filter(group => group.teams.filter(team => team.team_id == team_id).length > 0);
                
                if( target_group.length < 1 ) continue;

                const team = target_group[0].teams.filter(team => team.team_id == team_id)[0];

                for( const member of team.team_member ) {

                    if( member.user_id != user_id ) continue;

                    user_info_map.set(member.user_id, {
                        "name" : member.user_name,
                        "email" : member.user_email
                    });

                    break;
                }
            }

            // 친구를 마지막에 돌린다 (똑같은 user_id 가 중첩 될 때 친구 정보에 맞춰서 보여주기 위함)
            for( const friend_user_id of this.selected_friend_user_ids ) {
                const target_friend = this.friends.filter(friend => friend.friend_user_id == friend_user_id);
                if( target_friend.length < 1 ) continue;
                
                user_info_map.set(target_friend[0].friend_user_id, {
                    "name" : this.getFriendName(target_friend[0]),
                    "email" : target_friend[0].user_email
                });
            }

            for( const key of user_info_map.keys() ) {
                user_info_array.push(user_info_map.get(key));
            }

            let user_info_query = encodeURIComponent(JSON.stringify(user_info_array));
            let query : string = `event_id=${this.common_select_friend_and_group_team_modal_info.event_id}&scope=${this.common_select_friend_and_group_team_modal_info.event_scope}&scope_id=${this.common_select_friend_and_group_team_modal_info.event_scope_id}&start=${start}&start_timestamp=${start_timestamp}&end=${end}&end_timestamp=${end_timestamp}&title=${title}&sender_email=${this.user_email}&user_info=${user_info_query}`;
            window.open(`/mail_share.html?&${query}`, "hodu_email_share", "toolbar=0, scrollbars=0, resizable=0, width=" + 1024 + ", height=" + 768);

            this.close();
            return;
        }

        // 그룹, 팀 멤버를 보고 있다면 그룹 팀 내부에서 선택 된 정보 저장후 탭에서만 나가기
        if( this.selected_group_team != null ) {

            let selected_count : number = 0;

            let row_type = this.selected_group_team.row_type;
            let group_id = this.selected_group_team.group_id;
            let team_id = this.selected_group_team.team_id;

            if( this.share_option == SHARE_OPTION.MOVE ) {
                this.selected_friend_user_ids.splice(0, this.selected_friend_user_ids.length);
                this.group_user_selected_key.splice(0, this.group_user_selected_key.length);
                this.team_user_selected_key.splice(0, this.team_user_selected_key.length);
                this.selected_user_ids.splice(0, this.selected_user_ids.length);
                this.selected_group_ids.splice(0, this.selected_group_ids.length);
                this.selected_team_ids.splice(0, this.selected_team_ids.length);
            }

            if( row_type == 'GROUP' ) {

                this.selected_group_ids = this.selected_group_ids.filter(selected_group_id => selected_group_id != group_id);
                this.group_user_selected_key = this.group_user_selected_key.filter(key => !key.includes(`${group_id}-`));

                // 유저만 선택하는 모드 아님 + 전체 선택 됨
                if( this.common_select_friend_and_group_team_modal_info.use_user_select_only != true && this.group_team_members.length == this.group_team_members.filter(member => member.checked == true).length ) {
                    selected_count = this.group_team_members.length;
                    this.selected_group_ids.push(group_id);
                }
                // 전부 선택 되지는 않음
                else {
                    for( const member of this.group_team_members ) {
                        if( member.checked == true ) {
                            selected_count++;
                            this.group_user_selected_key.push(`${group_id}-${member.user_id}`);
                        }
                    }
                }
            }
            else if( row_type == 'TEAM' ) {

                this.selected_team_ids = this.selected_team_ids.filter(selected_team_id => selected_team_id != team_id);
                this.team_user_selected_key = this.team_user_selected_key.filter(key => !key.includes(`${team_id}-`));

                // 유저만 선택하는 모드 아님 + 전체 선택 됨
                if( this.common_select_friend_and_group_team_modal_info.use_user_select_only != true && this.group_team_members.length == this.group_team_members.filter(member => member.checked == true).length ) {
                    selected_count = this.group_team_members.length;
                    this.selected_team_ids.push(team_id);
                }
                // 전부 선택되지는 않음
                else {
                    for( const member of this.group_team_members ) {
                        if( member.checked == true ) {
                            selected_count++;
                            this.team_user_selected_key.push(`${team_id}-${member.user_id}`);
                        }
                    }
                }

            }

            // 선택된 멤버가 하나라도 있으면 해당 그룹은 체크 표시가 되어 있어야함
            this.selected_group_team.shareChecked = (selected_count > 0);

            // selected_user_ids 계산
            this.calculateUserIds();

            this.calculateGroupTeamShareChecked();

            this.selected_group_team = null;
            this.group_team_members.splice(0, this.group_team_members.length);

            this.view_search_friend = (this.tab == 'FRIEND');
            this.view_search_group_team = (this.tab == 'GROUP_AND_TEAM');
            this.view_search_member = false;

            this.group_team_member_search_query = "";
            return;
        }

        // 저장 처리
        this.common_select_friend_and_group_team_modal_info.callback?.({
            "share_option" : this.share_option,
            "selected_user_ids" : this.selected_user_ids,
            "selected_group_ids" : this.selected_group_ids,
            "selected_team_ids" : this.selected_team_ids,
            "selected_friend_user_ids" : this.selected_friend_user_ids,
            "group_user_selected_key" : this.group_user_selected_key,
            "team_user_selected_key" : this.team_user_selected_key,
        });
        this.close();

    }

    /**
     * 닫기
     */
    close() : void {

        // 그룹, 팀 멤버를 보고 있다면 탭에서만 나가기
        if( this.selected_group_team != null ) {
            this.selected_group_team = null;
            this.group_team_members.splice(0, this.group_team_members.length);

            this.view_search_friend = (this.tab == 'FRIEND');
            this.view_search_group_team = (this.tab == 'GROUP_AND_TEAM');
            this.view_search_member = false;

            this.group_team_member_search_query = "";
            return;
        }

        this.doSetCommonSelectFriendAndGroupTeamModalInfo?.({
            show_modal : false
        })
    }

}
</script>

<style scoped>
    /* 모달 공통 css */
    .modal { position:absolute; z-index:10000000000000000000000000000000; background:#fff; }
    .modal { border-radius:5px;margin:0 auto;width:100%;height: auto;top:50%;right: 0;left: 0;margin-bottom: 0;overflow:hidden;}
    
    .titleBox {overflow:hidden;border-bottom: 0;padding: 40px 0 30px 40px;box-shadow: none !important;line-height: inherit;border-bottom: 1px solid #e7e9ea;}
    .titleBox h1 {font-size: 18px;float:left;}
    .titleBox .total { float:left; font-size:14px; font-weight:bold; margin-left:15px;margin-top:2px; }
    .titleBox .total span { color:#477fff; padding-left:5px;}
    .titleBox .total.team {  }
    .modal .btns {border-top:1px solid #f1f3f5; height: 70px;line-height: 70px;border-radius: 0 0 5px 5px;overflow: hidden;}
    .modal.peepsOn .btns { margin-top : 50px; }
    .modal.peepsOn.group_select .btns { margin-top: 0; }
    .modal .btns input {width: 50%;height: 70px;line-height: 60px;border: 0 none;float: left;font-size: 14px;border-radius: 0; }
    .modal .schBtn { position:absolute; right:0;top:20px;right:30px; }
    .modal .schGrp {display:none;transition:0.1s;position: absolute;top: 140px;z-index: 100000000;width: 100%;height: 60px;background: white;left: 0;}
    .modal.peepsOn .schGrp { top: 89px; }
    .modal.group_select .schGrp { top: 89px; }
    .modal .schGrp.on { display:block; }
    .modal .schGrp input {transition:0.1s;background:transparent;width: 100%;box-sizing: border-box;height: 100%;line-height: 60px;padding: 0 100px 0 30px;font-size: 14px;font-weight: bold;}
    .modal .schGrp:hover { background:#f1f3f5; }
    .modal .schGrp input:focus { background:#fff;border-bottom:1px solid #477fff; height:60px;line-height:61px; }
    .modal .schGrp a.schBtn { top:0px; }

    .modal .noResult { display:none; position: absolute; z-index: 10000001;top: 200px;height: 265px;background: #fff;line-height: 265px;font-weight: bold;font-size: 21px;color: #afb6c3;}
    .modal.group_select .noResult { top : 150px; height : 316px; }
    .modal.peepsOn .noResult { height: 305px; top: 0; }
    .modal .noResult.on { display : block; }

    /* 그룹 팀 탭만 사용 */
    #common_select_friend_and_group_team_modal.group_select .tab { display: none; }
    #common_select_friend_and_group_team_modal.group_select .friend { display: none; }
    #common_select_friend_and_group_team_modal.group_select .group_and_team { display: block; }
    #common_select_friend_and_group_team_modal.group_select .shareUlDiv { max-height: 316px; }

    /* 유저만 선택 */
    #common_select_friend_and_group_team_modal.user_select .grpHeader .grpCheck { display: none; }
    #common_select_friend_and_group_team_modal.user_select .grp input[type='checkbox'] + label { display: none; }
    /* #common_select_friend_and_group_team_modal.user_select .num { padding-left: 20px; } */
    #common_select_friend_and_group_team_modal.user_select.peepsOn .num { padding-left : 80px; }

    /* .magBtns */
    .magBtns {  overflow: hidden; }
    .magBtns input { line-height: 60px; height: 60px; width: 50%; font-weight: bold; background:#fff; box-sizing: border-box; border-bottom: 2px solid #e7e9ea; }
    .magBtns input.on { border-color:#477fff }

    /* 공유모달 css */
    .shareModal .sortHeader { position:relative; }
    .sortHeader li.grpCheck { position: absolute;left: 0;top: 0;width: 100px;text-align: center;font-weight: bold;font-size: 13px;}
    .shareModal { transition:0.3s; max-width: 800px; bottom:auto !important; margin-top: -300px !important;height: 588px !important;}
    .shareUlDiv { height: auto; display: block; width: 100%; height: 316px; max-height: 265px; overflow:hidden; overflow-y:auto;}
    .shareUlDiv ul {display: block;width:100%;}
    .shareUlDiv li .grp {width: 100%;transition:0.1s;height: 55px;line-height: 55px;border-bottom: 1px solid #f1f3f5; position:relative;}
    .shareUlDiv li .grp:hover { background:#f1f3f5; }
    .shareUlDiv li .grp * {display: inline-block;float:left;}
    .shareUlDiv li .grp:hover .count a { border:2px solid #477fff; border-radius: 5px; padding:0 10px; line-height:30px; height:30px;margin-top:11px; }
    .shareUlDiv li .grp .count.noCount { opacity:0.6 }
    .shareUlDiv li .count a { transition:0.1s; }
    .shareUlDiv li .count a:hover { background:#477fff; color:#fff; }
    .shareUlDiv li .count a:hover span { color:#fff; }
    .shareUlDiv li .img {width: 57px;font-size: 0; background-position:center center !important; background-size: cover !important;height: 42px;border-radius: 5px;border: 1px solid #f1f3f5;position: absolute;left: 180px;top: 7px;}
    .shareUlDiv .teamUl li  .img {width: 50px;height: 36px;top: 9px;left: 186px;}
    .shareUlDiv li h2 {font-size: 14px;}
    .shareUlDiv li .nm { width:64%;box-sizing:border-box; padding-left:120px; padding-right:20px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; }
    .shareUlDiv li .count { width:10%;font-weight:bold; }
    .shareUlDiv li .count span { color:#477fff; margin-right:4px; }
    .shareUlDiv li.withTeams a.showTeams {position: absolute;right: 20px;top: 0;font-size: 0;width: 35px;height: 60px;border-radius: 50%; background:  url('../../../assets/images/side_area/bt_open.png') no-repeat center center;}
    .shareUlDiv li a.showTeams.on, .shareUlDiv li.on a.showTeams {background:  url('../../../assets/images/side_area/bt_close.png') no-repeat center center;  }
    .shareUlDiv .teamUl { display:none; border-top:1px solid #f1f3f5;border-bottom:1px solid #f1f3f5;}
    .shareUlDiv li.on > .grp {  }
    .shareUlDiv li.on .teamUl { display:block; }
    .shareModal input[type='checkbox']:not(old) + label {position: absolute;left: 38px;top: 20px;z-index:10000000; }
    .shareModal .teamUl input[type='checkbox']:not(old) + label{left: 50px;}
    .shareModal input[type='checkbox']:not(old) + label, .shareModal input[type='checkbox']:not(old) + label:hover {margin-left: 5px;display: inline-block;  padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;font-size: 0;margin-right: 8px;}
    .shareModal input[type='checkbox']:not(old):checked + label { border:2px solid #477fff;background: #477fff url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;}
    .grpUl > li > .grp { height:60px; line-height:60px; }
    .grpUl > li > .grp .img  { top:8px; }
    .grpUl > li > .grp:hover > .count > a { margin-top:14px; }
    .grpUl > li > .grp input[type='checkbox']:not(old) + label { top: 19px }
    .shareUlDiv li .grp .cl {position: absolute;left: 151px;top: 26px;width: 8px;height: 8px;font-size: 0;border-radius: 50%;background: #f1f3f5;}
    .shareUlDiv li .teamUl .grp .cl { left:158px; }
    .shareModal .teamUl .num {padding-left: 90px;}

    .grpHeader .num { width:15%;  }
    .grpHeader .nm {padding-left: 14px;width: 64%;box-sizing: border-box;}
    .grpHeader .count {width: 19%;}

    .teamHeader { display:none; }
    .shareModal.peepsOn ul.grpUl {height: 447px;}
    .shareModal.peepsOn { margin-top: -300px !important; height: 588px !important;}
    .shareModal.peepsOn .teamHeader { display:block; }
    .shareModal.peepsOn .grpHeader { display:none; }
    .shareModal .peepsDiv { display:none;width:100%;position: absolute;background: #fff;z-index: 1000000000;top: 150px; }
    .shareModal .peepsDiv.on {display: block; top: 202px; }
    .shareModal .peepsDiv .peepsUlDiv { width: 100%;height: 316px;max-height: 316px;overflow: hidden;overflow-y: auto;}
    .shareModal ul.peeps li * {display: inline-block;}
    .shareModal ul.peeps li {transition:0.1s;position:relative;line-height: 50px;height: 50px;font-size: 13px;font-weight: bold;border-bottom:1px solid #f1f3f5;}
    .shareModal ul.peeps li .fPic {position:absolute;background-color:#f1f3f5; left:155px; top:10px;font-size: 0;width: 30px;height: 30px;border-radius: 50%;background-position: center center !important;background-size: cover !important;border: 1px solid #e7e9ea;}
    .shareModal ul.peeps li .pNm { padding-left:70px; padding-right:20px; width:63%; box-sizing:border-box; overflow : hidden; white-space: nowrap; text-overflow: ellipsis; }
    .shareModal ul.peeps li .pos { font-size:12px; overflow : hidden; white-space: nowrap; text-overflow: ellipsis; }
    .shareModal ul.peeps li:hover { background:#f1f3f5; }
    .shareModal .peeps input[type='checkbox']:not(old) + label { top:14px; }
    .shareModal .peeps .fPic.none { background-image : url(../../../assets/images/contents/im_photoB.gif) !important; }

    .shareModal .pNm { width:63%; box-sizing:border-box; padding-left: 20px; }
    .shareModal .num { font-weight:bold; padding-left: 80px; width: 17%; box-sizing:border-box; text-align: center; font-size:12px; overflow : hidden; }
    .shareModal .peeps input[type='checkbox']:not(old) + label:after { content: ''; display: block; width: 800px; height: 50px; position: absolute; left: -45px; top: -16px;}
    .shareModal .frdHeader li { box-sizing:border-box; padding-left: 20px; }
    .shareModal .fNm { width: 28%; }
    .shareModal .fTel { width: 19.3%; }
    .shareModal .fTel span { padding-left: 20px; }
    .shareModal .fEml { width: 30%; }
    .shareModal .fEml span { padding-left: 20px; }
    .shareModal .frdUl li { height: 60px; line-height: 60px; border-bottom: 1px solid #f1f3f5; position: relative; }
    .shareModal .frdUl li:hover { background: #f1f3f5; }
    .shareModal .frdUl li p { float: left; box-sizing:border-box; padding-left: 20px; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .shareModal .frdUl li p.frdTeamCheck { position: absolute; top: 0; left: 0; overflow: visible; }
    .shareModal .frdUl li p.fImg { width: 30px; height: 30px; font-size: 0; background: #e7e9ea; background-size: cover; border-radius: 50%; vertical-align: middle; margin-top: 15px; margin-left: 14px; }
    .shareModal .frdUl li p.num { padding-left: 60px; }
    
    
    .teamHeader .pos {width: 20%;}
    .sortHeader input[type='checkbox']:not(old) + label { top:19px;}

    .shareUlDiv.mCS_destroyed { overflow : hidden !important }

    .shareModal .schDiv .share_option_btn { display : none; }
    .shareModal.share .schDiv .share_option_btn { display : block; }
    .share_option_btn { position : absolute; float : right; right : 80px; top : 30px; }
    .share_option_btn.on { color : white !important; background-color : #477fff !important; }
    .share_option_btn.option_share { right : 210px; }
    .share_option_btn.option_copy { right : 145px; }
    .share_option_btn.option_move {}

    /* 모달 탭 */
    .modal_content .tab { display: block; overflow: hidden; height: 50px; line-height: 50px; box-sizing: border-box; }
    .modal_content .tab input { width: 50%; height: 50px; line-height: 50px; border-bottom: 2px solid #e7e9ea; text-align: center; background: #fff; font-size: 15px; display: inline-block; }
    .modal_content .tab input.on { color: #477fff; border-color: #477fff; }

    #common_select_friend_and_group_team_modal .fPicDummy { display: none !important; }

    #common_select_friend_and_group_team_modal ::-webkit-scrollbar{width: 6px;}
    #common_select_friend_and_group_team_modal ::-webkit-scrollbar-track{background-color: transparent;}
    #common_select_friend_and_group_team_modal ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}

    #common_select_friend_and_group_team_modal.need_email .pNm { width : 40%; }
    #common_select_friend_and_group_team_modal.need_email .email { width : 43%; }

    /* 모달 검색창 디폴트 */
    .sch_con { display: inline-block; width: 100%; height: 50px; border-bottom: 2px solid #f1f3f5; position: relative; }
    .modal .sch_con input { width: 100%; box-sizing: border-box; line-height: 50px; padding: 0 100px 0 30px; font-size: 13px; font-weight: bold; }
    .modal .sch_con .schBtn { top: 5px; margin: 0; }
    input::-ms-clear,
    input::-ms-reveal{
        display:none;width:0;height:0;
    }
    input::-webkit-search-decoration,
    input::-webkit-search-cancel-button,
    input::-webkit-search-results-button,
    input::-webkit-search-results-decoration{
        display:none;
    }

    .shareModal .peepsDiv.on .peepsUlDiv { height: 360px; max-height: 315px; }
</style>