<template>
    <div class="approval_content up1">
        <div class="filter_tap" id="dep4">
            
            <div class="content_top">
                <p class="appLocation appReceiveLc">
                    수신함
                </p>
                <a href="#" class="bt_hambuger" :class="{ change : is_open_filter == true }" @click.prevent="toggleFilter"></a>
                <h3>검색 옵션</h3>
                <input type="text" id="filter_search" placeholder="검색할 내용을 입력해주세요" :value="filter.search_query" @input="inputSearchQuery($event.target.value)"/>
            </div>

            <div class="nav_item" :class="{ open : is_open_filter == true }" style="position: relative;">
                <div style="width:267px;height:165px;padding:10px;font-sise:15px;overflow: auto; border-right: 1px solid #e7e9ea;box-sizing: border-box; white-space : nowrap;">
                    
                    <!-- 조직도 -->
                    <div id="wrapper">

                        <div class="chk">
                            <input type="checkbox" id="receive-all-department" @change="changeAllDepartmentSelect($event)" :checked="filter.dept_ids.length == filter.departments.length" />
                            <label for="receive-all-department"><span>전체</span></label>
                        </div>
                        
                        <!-- <div class="chk">
                            <input type="checkbox" id="receive-top-department" @change="deptFilterChange({ 'event' : $event, 'dept_id' : computedOrganization.dept_id })" :checked="filter.dept_ids.indexOf(computedOrganization.dept_id) > -1"/>
                            <label for="receive-top-department"><span>{{ computedOrganization.dept_name }}</span></label>
                        </div> -->

                        <OrganizationRecursion 
                            :level="2"
                            :content_type="'checkbox'" 
                            :departments="computedOrganization.departments"
                            :selected_department="selected_department"
                            :department_closed_map="department_closed_map"
                            :department_height_map="department_height_map"
                            :filter="filter" 
                            @select="deptFilterChange"
                            v-if="computedOrganization != null" />
                        
                        <!-- 메인  -->
                        <!-- <div class="chk">
                            <input type="checkbox" id="teamCheck"/>
                            <label for="teamCheck"><span>지누스</span></label>
                        </div> -->

                        <!-- 첫번쩨 ul (그룹 리스트) -->
                        <ul class="sub1" v-if="false">
                            <li class="fList">
                                <!-- 상단 삐져나오는 선 가리개 -->
                                <span class="topBlank"></span>
                                <!-- 하단 삐져나오는 선 가리개 -->
                                <span class="btmBlank"></span>
                                <!-- 길이에 따라 길어지는 세로 선 -->
                                <span class="vertiLine"></span>
                                <!-- 화살표 버튼 -->
                                <input type="button" class="arw" />
                                <!-- cld : checkbox list div -->
                                <div class="cld">
                                    <input type="checkbox" id="teamCheck1" />
                                    <label for="teamCheck1"><span>피닉스</span></label>
                                </div>

                                <!-- 두번째 ul (팀 리스트) -->
                                <ul class="sub2">
                                    <li>
                                        <!-- horiLine 가로선 -->
                                        <span class="horiLine"></span>
                                        <div class="cld">
                                            <input type="checkbox" id="teamCheck1_1" />
                                            <label for="teamCheck1_1"><span>개발</span></label>
                                        </div>
                                    </li>
                                    <li>
                                        <span class="horiLine"></span>
                                        <div class="cld">
                                            <input type="checkbox" id="teamCheck1_2" />
                                            <label for="teamCheck1_2"><span>교육</span></label>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li class="fList">
                                <!-- 상단 삐져나오는 선 가리개 -->
                                <span class="topBlank"></span>
                                <!-- 하단 삐져나오는 선 가리개 -->
                                <span class="btmBlank"></span>
                                <!-- 길이에 따라 길어지는 세로 선 -->
                                <span class="vertiLine"></span>
                                <!-- 화살표 버튼 -->
                                <input type="button" class="arw" />
                                <!-- cld : checkbox list div -->
                                <div class="cld">
                                    <input type="checkbox" id="teamCheck2"/>
                                    <label for="teamCheck2"><span>IRS</span></label>
                                </div>
                                <ul class="sub2">
                                    <li>
                                        <!-- horiLine 가로선 -->
                                        <span class="horiLine"></span>
                                        <div class="cld">
                                            <input type="checkbox" id="teamCheck2_1"/>
                                            <label for="teamCheck2_1"><span>개발</span></label>
                                        </div>
                                    </li>
                                    <li>
                                        <span class="horiLine"></span>
                                        <div class="cld">
                                            <input type="checkbox" id="teamCheck2_2"/>
                                            <label for="teamCheck2_2"><span>심사</span></label>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li class="fList">
                                <!-- 상단 삐져나오는 선 가리개 -->
                                <span class="topBlank"></span>
                                <!-- 하단 삐져나오는 선 가리개 -->
                                <span class="btmBlank"></span>
                                <!-- 길이에 따라 길어지는 세로 선 -->
                                <span class="vertiLine"></span>
                                <!-- 화살표 버튼 -->
                                <input type="button" class="arw" />
                                <!-- cld : checkbox list div -->
                                <div class="cld">
                                    <input type="checkbox" id="teamCheck3"/>
                                    <label for="teamCheck3"><span>모바일</span></label>
                                </div>
                                <!-- 두번째 ul (팀 리스트) -->
                                <ul class="sub2">
                                    <li>
                                        <!-- horiLine 가로선 -->
                                        <span class="horiLine"></span>
                                        <div class="cld">
                                            <input type="checkbox" id="teamCheck3_1"/>
                                            <label for="teamCheck3_1"><span>개발</span></label>
                                        </div>
                                    </li>
                                    <li>
                                        <span class="horiLine"></span>
                                        <div class="cld">
                                            <input type="checkbox" id="teamCheck3_2"/>
                                            <label for="teamCheck3_2"><span>디자인</span></label>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li class="fList">
                                <div class="cld">
                                    <input type="checkbox" id="teamCheck4"/>
                                    <label for="teamCheck4"><span>영업</span></label>
                                </div>
                            </li>
                            <li class="fList">
                                <div class="cld">
                                    <input type="checkbox" id="teamCheck5"/>
                                    <label for="teamCheck5"><span>경영지원</span></label>
                                </div>
                            </li>
                        </ul>

                        <div class="cld chk">
                            <input type="checkbox" id="receive-top-department" @change="deptFilterChange({ 'event' : $event, 'dept_id' : computedOrganization.dept_id })" :checked="filter.dept_ids.indexOf(computedOrganization.dept_id) > -1"/>
                            <label for="receive-top-department"><span style="font-size: 12px; font-weight: bold; color: #477fff; opacity: .8;">미배정</span></label>
                        </div>
                    </div>

                </div>
                <div style="height:165px;position: absolute;top: 0;left: 267px;right:0;">
                    <ul>
                        <li class="Search_li01">검색일</li>
                        <li class="Search_li02">
                            <!-- <span style="margin-right:10px;">2020.09.01 목</span> ~ <span style="margin-left:10px;">2020.09.07 월</span> -->
                            <input type="button" id="approval_receive_filter_start" class="hodu-datepicker-button" :value="start_text" style="margin-right:10px;" /> ~ <input type="button" id="approval_receive_filter_end" class="hodu-datepicker-button" style="margin-left:10px;" :value="end_text" />
                        </li>
                        <a href="#" class="restart_button" @click.prevent="reset">초기화</a>
                    </ul>
                    <ul>
                        <li class="Search_li01">중요도</li>
                        <li class="Search_li02">

                            <!-- 당장은 전체를 삭제 나중에 필요시 추가 -->
                            <!-- <p style="display: inline-block;margin-right:34px;">
                                <input type="checkbox" id="search_check01" value="" class="input_check0">
                                <label for="search_check01" style="margin: 8px 12px 10px 0px;"></label>
                                <span>전체</span>
                            </p> -->

                            <p style="display: inline-block;margin-right:30px;">
                                <input type="checkbox" id="search_check02" value="" class="input_check0" v-model="filter.is_emergency" @change="changeImportanceFilter">
                                <label for="search_check02" style="margin: 8px 12px 10px 0px;"></label>
                                <span>긴급</span>
                            </p>
                            <p style="display: inline-block;margin-right:30px;">
                                <input type="checkbox" id="search_check03" value="" class="input_check0" v-model="filter.is_importance" @change="changeImportanceFilter">
                                <label for="search_check03" style="margin: 8px 12px 10px 0px;"></label>
                                <span>중요</span>
                            </p>
                            <p style="display: inline-block;margin-right:30px;">
                                <input type="checkbox" id="search_check04" value="" class="input_check0" v-model="filter.is_normal" @change="changeImportanceFilter">
                                <label for="search_check04" style="margin: 8px 12px 10px 0px;"></label>
                                <span>일반</span>
                            </p>
                        </li>
                    </ul>
                    <ul>
                        <li class="Search_li01">문서검색</li>
                        <li class="Search_li02">
                            <p style="display: inline-block;margin-right:30px;">
                                <input type="checkbox" id="search_check05" value="" class="input_check0" v-model="filter.is_vacation" @change="changeTypeFilter">
                                <label for="search_check05" style="margin: 8px 12px 10px 0px;"></label>
                                <span>휴가</span>
                            </p>
                            <p style="display: inline-block;margin-right:30px;" v-if="isAttendanceOnly() == false">
                                <input type="checkbox" id="search_check06" value="" class="input_check0" v-model="filter.is_businesstrip" @change="changeTypeFilter">
                                <label for="search_check06" style="margin: 8px 12px 10px 0px;"></label>
                                <span>출장</span>
                            </p>
                            <p style="display: inline-block;margin-right:30px;" v-if="isAttendanceOnly() == false">
                                <input type="checkbox" id="search_check10" value="" class="input_check0" v-model="filter.is_holiday_work" @change="changeTypeFilter">
                                <label for="search_check10" style="margin: 8px 12px 10px 0px;"></label>
                                <span>휴일근무</span>
                            </p>
                            <p style="display: inline-block;margin-right:30px;" v-if="isAttendanceOnly() == false">
                                <input type="checkbox" id="search_check07" value="" class="input_check0" v-model="filter.is_cash_disbursement_voucher" @change="changeTypeFilter">
                                <label for="search_check07" style="margin: 8px 12px 10px 0px;"></label>
                                <span>지출결의서</span>
                            </p>
                            <p style="display: inline-block;margin-right:30px;" v-if="isAttendanceOnly() == false">
                                <input type="checkbox" id="search_check08" value="" class="input_check0" v-model="filter.is_free" @change="changeTypeFilter">
                                <label for="search_check08" style="margin: 8px 12px 10px 0px;"></label>
                                <span>일반 기안서</span>
                            </p>
                            <p style="display: inline-block;margin-right:30px;" v-if="isAttendanceOnly() == false">
                                <input type="checkbox" id="search_check09" value="" class="input_check0" v-model="filter.is_approval" @change="changeTypeFilter">
                                <label for="search_check09" style="margin: 8px 12px 10px 0px;"></label>
                                <span>사용자 지정 문서</span>
                            </p>
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>

        <div class="content" style="width: 100%;">
            <div id="noticeScroll" class="section_scroll">
                <div class="viewGroup">
                    <div class="schedule_box"></div>
                    
                    <div class="fixedDiv">
                        <ul id="" class="sortHeader groupNoticeHeader">
                            
                            <!-- <li class="notiCheck" style="padding-left: 20px;box-sizing: border-box;">
                                <p id="selectCheckBoxAll" class="checkBox on">
                                    <input type="checkbox" id="selectAll">
                                    <label for="selectAll">전체선택</label>
                                </p>
                            </li> -->

                            <li class="num">No</li>
                            
                            <li class="title headerSort" :class="{
                                sortOff  : sort_type != 'TIME',
                                sortUp   : sort_type == 'TIME' && sort_direction == 'ASC', 
                                sortDown : sort_type == 'TIME' && sort_direction == 'DESC', 
                            }" @click.prevent="sort(approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.TIME)">
                                <span style="margin-left:15px;">제목(기안일)</span>
                            </li>

                            <li class="group headerSort" :class="{
                                sortOff  : sort_type != 'DRAFTER',
                                sortUp   : sort_type == 'DRAFTER' && sort_direction == 'ASC', 
                                sortDown : sort_type == 'DRAFTER' && sort_direction == 'DESC', 
                            }" @click.prevent="sort(approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.DRAFTER)">
                                <span style="margin-left:45px;">기안자</span>
                            </li>

                            <li class="writtenBy headerSort" :class="{
                                sortOff  : sort_type != 'ORGANIZATION',
                                sortUp   : sort_type == 'ORGANIZATION' && sort_direction == 'ASC', 
                                sortDown : sort_type == 'ORGANIZATION' && sort_direction == 'DESC', 
                            }" @click.prevent="sort(approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.ORGANIZATION)">
                                <span style="margin-left:26px;">부서</span>
                            </li>

                            <li class="status headerSort" :class="{
                                sortOff  : sort_type != 'STATUS',
                                sortUp   : sort_type == 'STATUS' && sort_direction == 'ASC', 
                                sortDown : sort_type == 'STATUS' && sort_direction == 'DESC', 
                            }" @click.prevent="sort(approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.STATUS)">
                                <span style="margin-left:13px;">현황</span>
                            </li>
                        </ul>
                    </div>

                    <div id="approval_list" class="content">
                        <ul class="notiListUl">

                            <li :key="`${approval.approval_uid}-${approval.approval_rev}`" v-for="(approval, index) in computedApprovalReceiveList" @click="moveApprovalDetail(approval.approval_uid)">
                                <p class="num"><span >{{ index + 1 }}</span></p>
                                <p class="title">
                                    <ul class="title_info">
                                        <li class="list_title">
                                            <span :class="{ quick : approval.imp_seq == 10, important : approval.imp_seq == 20 }" v-if="approval.imp_seq < 30">{{ getImportanceText(approval.imp_seq) }}</span>
                                            {{ approval.contents.title }}
                                        </li>
                                        <li class="list_day">
                                            {{ hodu_date_to_format_string(approval.audit_created, 'YY.MM.DD') + " (" + getDayOfWeekByDate(approval.audit_created) + ") " + amPmStringToLocaleAmPmString(hodu_date_to_format_string(approval.audit_created, 'hh:mm a')) }} (D-{{ getDDay(approval.audit_created) }})
                                        </li>
                                    </ul>
                                </p>
                                <p class="group">
                                    <ul class="group_info">
                                        <li class="list_group">{{ getDrafter(approval) }}</li>
                                        <li class="list_rank">{{ getDrafterPosition(approval) }}</li>
                                    </ul>
                                </p>
                                <p class="writtenBy"><span>{{ getOrganization(approval) }}</span></p>
                                <p class="status">
                                    <ul class="status_info">
                                        <li class="list_Progress">
                                            <span class="Progress_bar">진행현황</span>
                                            <span class="Progress_ing" :style="{ 
                                                width : `${getProgress(approval)}%`,
                                                backgroundColor : getProgressColor(approval.approval_state) 
                                            }">진행현황</span>
                                        </li>
                                        <li class="list_Protext">
                                            <span style="margin-left:5px;">{{ getStateText(approval.approval_state) }}</span>
                                            <span class="ing_num">{{ approval.approval_cur }} / {{ approval.approval_total }}</span>
                                        </li>
                                    </ul>
                                </p>
                            </li>

                            <!-- <li class="">
                                <p class="num"><span >1</span></p>
                                <p class="title">
                                    <ul class="title_info">
                                        <li class="list_title">진행 예제</li>
                                        <li class="list_day">20.09.05 11:00am</li>
                                    </ul>
                                </p>
                                <p class="group">
                                    <ul class="group_info">
                                        <li class="list_group">이지인</li>
                                        <li class="list_rank">사원</li>
                                    </ul>
                                </p>
                                <p class="writtenBy"><span>피닉스</span></p>
                                <p class="status">
                                    <ul class="status_info">
                                        <li class="list_Progress">
                                            <span class="Progress_bar">진행현황</span>
                                            <span class="Progress_ing" style="background: #40c8a0;width:30%;">진행현황</span>
                                        </li>
                                        <li class="list_Protext">
                                            <span style="margin-left:5px;">진행</span>
                                            <span class="ing_num">1 / 3</span>
                                        </li>
                                    </ul>
                                </p>
                            </li>

                            <li class="">
                                <p class="num"><span>2</span></p>
                                <p class="title">
                                    <ul class="title_info">
                                        <li class="list_title"><span class="quick">긴급</span>반려 예제</li>
                                        <li class="list_day">20.09.05 11:00am</li>
                                    </ul>
                                </p>
                                <p class="group">
                                    <ul class="group_info">
                                        <li class="list_group">이지인</li>
                                        <li class="list_rank">사원</li>
                                    </ul>
                                </p>
                                <p class="writtenBy"><span>피닉스</span></p>
                                <p class="status">
                                    <ul class="status_info">
                                        <li class="list_Progress">
                                            <span class="Progress_bar">진행현황</span>
                                            <span class="Progress_ing" style="background: #ff5872;width:60%;">진행현황</span>
                                        </li>
                                        <li class="list_Protext">
                                            <span style="margin-left:5px;">반려</span>
                                            <span class="ing_num">2 / 3</span>
                                        </li>
                                    </ul>
                                </p>
                            </li>

                            <li class="">
                                <p class="num"><span>4</span></p>
                                <p class="title">
                                    <ul class="title_info">
                                        <li class="list_title">완료 예제</li>
                                        <li class="list_day">20.09.05 11:00am</li>
                                    </ul>
                                </p>
                                <p class="group">
                                    <ul class="group_info">
                                        <li class="list_group">이지인</li>
                                        <li class="list_rank">사원</li>
                                    </ul>
                                </p>
                                <p class="writtenBy"><span>피닉스</span></p>
                                <p class="status">
                                    <ul class="status_info">
                                        <li class="list_Progress">
                                            <span class="Progress_bar">진행현황</span>
                                            <span class="Progress_ing" style="background: #477fff;width:100%;">진행현황</span>
                                        </li>
                                        <li class="list_Protext">
                                            <span >완료</span>
                                            <span class="ing_num">2 / 2</span>
                                        </li>
                                    </ul>
                                </p>
                            </li> -->

                        </ul>
                    </div> 
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ApprovalInfo = namespace('ApprovalInfo');

import OrganizationRecursion from '@/components/organization/OrganizationRecursion.vue';

import { approval_enum, approval_interface } from '@/model/approval';

import moment from 'moment';

import { hodu_color } from '@/common/color';
import { hodu_local_storage } from '@/lib/HoduLocalStorage';

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        OrganizationRecursion
    },
})
export default class ApprovalReceiveBox extends Mixins(VueHoduCommon) {

    approval_enum : any = approval_enum;

    @Prop() event_bus !: Vue;
    @Prop() tab       !: approval_enum.APPROVAL_RECEIVE_BOX_TAB;
    @Prop() filter    !: approval_interface.ApprovalFilter;
    @Prop() approvals !: any[];

    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.filter.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) {
                department['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return {};
        const top_organization = top_organizations[0];

        return top_organization;
    }

    /**
     * 상신함 리스트
     */
    get computedApprovalReceiveList() : any[] {

        let apporval_list : any[] = this.approvals;
        
        // 탭 선택시 필터링
        // 결재대기 : 결재자에 본인이 포함 되어 있음 + 본인 상태가 (WAIT)
        if( this.tab == approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT ) {
            apporval_list = apporval_list.filter(item => item.approver.filter( app => app.user_id == this.user_id && app.state == 'WAIT' ).length > 0 && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL');
        }

        // 결재요청 : 결재자에 본인이 포함 되어 있음 + 본인 상태가 (ING)
        else if( this.tab == approval_enum.APPROVAL_RECEIVE_BOX_TAB.REQUEST ) {
            apporval_list = apporval_list.filter(item => item.approver.filter( app => app.user_id == this.user_id && app.state == 'ING' ).length > 0 && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL');
        }

        // 결재내역 : 결재자에 본인이 포함 되어 있음 + 본인 상태가 (CONFIRM || REJECT)
        else if( this.tab == approval_enum.APPROVAL_RECEIVE_BOX_TAB.HISTORY ) {
            apporval_list = apporval_list.filter(item => item.approver.filter( app => app.user_id == this.user_id && ( app.state == 'REJECT' || app.state == 'CONFIRM' ) ).length > 0 && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL');
        }

        // 수신참조 : 수신 참조에 본인이 포함 되어 있음
        else if( this.tab == approval_enum.APPROVAL_RECEIVE_BOX_TAB.REFERENCE ) {
            apporval_list = apporval_list.filter(item => (item.receive_reference != null && item.receive_reference.filter(ref => ref.user_id == this.user_id).length > 0) && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL');
        }

        // 정렬
        apporval_list.sort((o1, o2) : number => {

            // if( this.sort_type == approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.TITLE ) {
            //     const o1_title = o1.contents.title;
            //     const o2_title = o2.contents.title;

            //     if( o1_title == o2_title ) return 0;

            //     if( this.sort_direction == SORT_TYPE.ASC ) {
            //         return o1_title > o2_title ? 1 : -1;
            //     }
            //     return o1_title > o2_title ? -1 : 1;
            // }

            if( this.sort_type == approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.TIME ) {
                const o1_time = o1.audit_created;
                const o2_time = o2.audit_created;

                if( o1_time == o2_time ) return 0;

                if( this.sort_direction == SORT_TYPE.ASC ) {
                    return o1_time > o2_time ? 1 : -1;
                }
                return o1_time > o2_time ? -1 : 1;
            }

            else if( this.sort_type == approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.STATUS ) {

                const o1_progress = o1.approval_cur;
                const o2_progress = o2.approval_cur;

                if( o1_progress == o2_progress ) return 0;

                if( this.sort_direction == SORT_TYPE.ASC ) {
                    return o1_progress > o2_progress ? 1 : -1;
                }
                return o1_progress > o2_progress ? -1 : 1;
            }

            else if( this.sort_type == approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.DRAFTER ) {
                const o1_drafter = this.getDrafter(o1);
                const o2_drafter = this.getDrafter(o2);

                if( o1_drafter == o2_drafter ) return 0;

                if( this.sort_direction == SORT_TYPE.ASC ) {
                    return o1_drafter > o2_drafter ? 1 : -1;
                }
                return o1_drafter > o2_drafter ? -1 : 1;
            }

            else if( this.sort_type == approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.ORGANIZATION ) {
                const o1_organization = this.getOrganization(o1);
                const o2_organization = this.getOrganization(o2);

                if( o1_organization == o2_organization ) return 0;

                if( this.sort_direction == SORT_TYPE.ASC ) {
                    return o1_organization > o2_organization ? 1 : -1;
                }
                return o1_organization > o2_organization ? -1 : 1;
            }

            return 0;
        });

        

        if( this.filter.search_query != null && this.filter.search_query.trim().length > 0 ) {
            apporval_list = apporval_list.filter(approval => this.hodu_string_includes(approval.contents.title, this.filter.search_query) ||
                                                             this.hodu_string_includes(approval.contents.comment, this.filter.search_query) ||
                                                             this.hodu_string_includes(this.getOrganization(approval), this.filter.search_query) ||
                                                             this.hodu_string_includes(this.getDrafterPosition(approval), this.filter.search_query) ||
                                                             this.hodu_string_includes(this.getDrafter(approval), this.filter.search_query));
        }

        return apporval_list; 
    }

    /**
     * @ApprovalInfo.State
     */
    @ApprovalInfo.State draft_filter !: approval_interface.ApprovalFilter;
    @ApprovalInfo.State receive_filter !: approval_interface.ApprovalFilter;

    /**
     * @ApprovalInfo.Action
     */
    @ApprovalInfo.Action doSetReceiveFilter ?: (params : approval_interface.ApprovalFilter) => void;

    is_open_filter : boolean = true;

    selected_department : any = { dept_id : -1 };

    department_closed_map : Map<number, boolean> = new Map();
    department_height_map : Map<number, number> = new Map();

    start_text = "";
    end_text = "";

    // 정렬
    sort_type : approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE = approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.TIME;
    sort_direction : SORT_TYPE = SORT_TYPE.DESC;
    
    mounted() : void {
        this.event_bus?.$on("receiveResize", this.handleResize);

        this.makeDateText();
        this.datepickerInit();
        this.setScroll();

        // 버튼 클릭시 라인과 팀 보이기
        // $(".arw").click(function(){
        //     // 리스트 인덱스 가져오기
        //     var getIndex = $(this).parent().index();
            
        //     // 버튼 클릭시 라인과 팀 보이기
        //     $(".fList").eq(getIndex).toggleClass("showTeam");

        // });   
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {
            const title_height = $('.title_box').outerHeight();
            const filter_height = $('.approval_content .filter_tap').outerHeight();
            const sort_header_height = $('.content .sortHeader').outerHeight();
            const list_height = window.innerHeight - (title_height ? title_height : 0)
                                                   - (filter_height ? filter_height : 0)
                                                   - (sort_header_height ? sort_header_height : 0);

            // @ts-ignore
            $('#approval_list').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : list_height,
            });
        });
    }

    /**
     * 전자결재 상신함 문서 조회
     */
    async getApporval() : Promise<void> {
        console.log("ApprovalReceiveBox : getApporval");
        this.$emit("getApproval");
    }

    /**
     * 전체 체크 변경
     */
    async changeAllDepartmentSelect(event) : Promise<void> {
        const checked = event.target.checked;

        if( this.filter.dept_ids == null || this.filter.departments == null ) {
            return;
        }

        const filter : approval_interface.ApprovalFilter = JSON.parse(JSON.stringify(this.filter));

        filter.dept_ids.splice(0, filter.dept_ids.length)
        if( checked == true ) {
            for( const department of filter.departments ) filter.dept_ids.push(department.dept_id);
        }

        filter.is_selecet_all_departments = checked;

        await this.setFilter(filter);
        await this.getApporval();
    }

    /**
     * 부서 필터 ON / OFF
     */
    async deptFilterChange(params : any) : Promise<void> {

        const event = params.event; 
        const dept_id = params.dept_id;

        const checked = event.target.checked;

        const filter : approval_interface.ApprovalFilter = JSON.parse(JSON.stringify(this.filter));

        if( checked == false && filter.dept_ids.indexOf(dept_id) > -1 ) {
            filter.dept_ids.splice(filter.dept_ids.indexOf(dept_id), 1);
        } 

        else if( checked == true && filter.dept_ids.indexOf(dept_id) == -1 ) {
            filter.dept_ids.push(dept_id);
        }

        filter.is_selecet_all_departments = (filter.dept_ids.length == filter.departments.length);

        await this.setFilter(filter);
        await this.getApporval();
    }

    /**
     * datepicker 설정
     */
    datepickerInit() : void {
        this.$nextTick(() => {
            const datepicker_option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: async(dateText, inst) => {
                    const selected_date = new Date(dateText);
                    inst.input.val(`${this.hodu_date_to_format_string(selected_date, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(selected_date)}`);
                    
                    const filter : approval_interface.ApprovalFilter = JSON.parse(JSON.stringify(this.filter));
                    filter.start = new Date(filter.start);
                    filter.end = new Date(filter.end);

                    // 시작일 조정
                    if( inst.id == 'approval_receive_filter_start' ) {
                        filter.start = moment(selected_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();

                        if( filter.start.getTime() > filter.end.getTime() ) {
                            filter.end = moment(filter.start).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
                        }
                    }

                    // 종료일 조정
                    else if( inst.id == 'approval_receive_filter_end' ) {
                        filter.end = moment(selected_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();

                        if( filter.start.getTime() > filter.end.getTime() ) {
                            filter.start = moment(filter.end).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
                        }
                    }

                    await this.setFilter(filter);
                    await this.makeDateText();

                    // 재조회
                    await this.getApporval();
                },
            }

            // @ts-ignore
            $('#approval_receive_filter_start').datepicker(datepicker_option);

            // @ts-ignore
            $('#approval_receive_filter_end').datepicker(datepicker_option);
        });
    }

    /**
     * date 텍스트 만들기
     */
    makeDateText() : void {
        this.start_text = `${this.hodu_date_to_format_string(this.filter.start, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(this.filter.start)}`;
        this.end_text   = `${this.hodu_date_to_format_string(this.filter.end, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(this.filter.end)}`; 
    }

    /**
     * 검색
     */
    async inputSearchQuery(value) : Promise<void> {
        const filter : approval_interface.ApprovalFilter = JSON.parse(JSON.stringify(this.filter));
        filter.search_query = value;
        await this.setFilter(filter);
    }

    /**
     * 리셋
     */
    async reset() : Promise<void> {

        const filter : approval_interface.ApprovalFilter = JSON.parse(JSON.stringify(this.filter));

        filter.end   = moment().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
        filter.start = moment(filter.end).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).add('month', -1).toDate();

        filter.dept_ids.splice(0, filter.dept_ids.length);
        for( const department of filter.departments ) {
            filter.dept_ids.push(department.dept_id);
        }

        filter.is_emergency = true;
        filter.is_importance = true;
        filter.is_normal = true;
        filter.importance_filter.splice(0, filter.importance_filter.length);
        if( filter.is_emergency == true ) filter.importance_filter.push(approval_enum.APPROVAL_IMPORTANCE_FILTER.EMERGENCY);
        if( filter.is_importance == true ) filter.importance_filter.push(approval_enum.APPROVAL_IMPORTANCE_FILTER.IMPORTANCE);
        if( filter.is_normal == true ) filter.importance_filter.push(approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL);

        filter.is_approval = true;
        filter.is_vacation = true;
        filter.is_businesstrip = true;
        filter.is_cash_disbursement_voucher = true;
        filter.is_free = true;
        filter.is_holiday_work = true;

        await this.setFilter(filter);

        await this.makeDateText();

        // 재조회
        await this.getApporval();
    }

    /**
     * 중요도 설정
     */
    async changeImportanceFilter(event : any) : Promise<void> {

        const filter : approval_interface.ApprovalFilter = JSON.parse(JSON.stringify(this.filter));

        // 전부다 취소 됐을때 필터 true로 다시 고정
        if( filter.is_emergency == false && filter.is_importance == false && filter.is_normal == false ) {
            this.$nextTick(async() => {
                // alert(event.target.id);
                switch( event.target.id ) {
                    case 'search_check02':
                        console.log("is_emergency");
                        filter.is_emergency = true;
                        break;

                    case 'search_check03':
                        console.log("is_importance");
                        filter.is_importance = true;
                        break;

                    case 'search_check04':
                        console.log("is_normal");
                        filter.is_normal = true;
                        break;
                }
                $(event.target).prop("checked", true);
                await this.setFilter(filter);
            });
            return;
        }
        
        // 필터 데이터 가공
        filter.importance_filter.splice(0, filter.importance_filter.length);
        if( filter.is_emergency == true ) filter.importance_filter.push(approval_enum.APPROVAL_IMPORTANCE_FILTER.EMERGENCY);
        if( filter.is_importance == true ) filter.importance_filter.push(approval_enum.APPROVAL_IMPORTANCE_FILTER.IMPORTANCE);
        if( filter.is_normal == true ) filter.importance_filter.push(approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL);

        await this.setFilter(filter);

        // 재조회
        await this.getApporval();
    }

    /**
     * 문서 타입 설정
     */
    async changeTypeFilter(event : any) : Promise<void> {
        const filter : approval_interface.ApprovalFilter = JSON.parse(JSON.stringify(this.filter));
        await this.setFilter(filter);
        await this.getApporval();
    }

    /**
     * 필터 토글
     */
    toggleFilter() : void {
        this.is_open_filter = !this.is_open_filter;
        this.handleResize();
    }

    /**
     * 중요도 관련 텍스트 반환
     */
    getImportanceText(imp_seq : number) : string {
        switch(imp_seq) {
            case 10: return "긴급";
            case 20: return "중요";
        }

        return "";
    }

    /**
     * dday 구하기
     */
    getDDay(audit_created : string | Date) : number {
        audit_created = audit_created instanceof Date ? audit_created : moment(audit_created).toDate();
        const today = moment().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
        const diff : number = this.getDateDiff(today, audit_created);
        return diff;
    } 

    /**
     * 기안자 이름 반환
     */
    getDrafter(approval : any) : string {
        if( approval.approver == null || approval.approver[0] == null ) return "";
        return approval.approver[0].user_name;
    }

    /**
     * 기안자 직급 반환
     */
    getDrafterPosition(approval : any) : string {
        if( approval.approver == null || approval.approver[0] == null ) return "";
        return approval.approver[0].pos_name;
    }

    /**
     * 기안자 부서 반환
     */
    getOrganization(approval : any) : string {
        if( approval.approver == null || approval.approver[0] == null ) return "";
        return approval.approver[0].dept_name;
    }

    /**
     * progress 진행율 반환
     */
    getProgress(approval) : string {
        const progress = (100 * (approval.approval_cur) / (approval.approval_total)).toFixed(10);
        return Number(progress) == 0 ? '5' : progress;
    }

    // /**
    //  * 완료된 (COMPLETE 또는 REJECT) 수 반환
    //  */
    // getCompleteProgress(approvers : any) : number {
    //     let progress = 0;
    //     for( const approver of approvers ) {
    //         if( approver.state == 'CONFIRM' || approver.state == 'REJECT' ) progress++;
    //     }
    //     return progress;
    // }

    /**
     * 현재 문서의 상태에 따른 색상 반환
     */
    getProgressColor(approval_state : string) : string {
        switch(approval_state) {
            case approval_enum.APPROVAL_STATE.CANCEL   : return hodu_color.approval_cancel_color;
            case approval_enum.APPROVAL_STATE.ING      : return hodu_color.approval_ing_color;
            case approval_enum.APPROVAL_STATE.REJECT   : return hodu_color.approval_reject_color;
            case approval_enum.APPROVAL_STATE.COMPLETE : return hodu_color.approval_confirm_color;
            case approval_enum.APPROVAL_STATE.TEMP     : return hodu_color.approval_temp_color;
        }

        return "transparent";
    }

    /**
     * 현재 문서의 상태에 따른 텍스트 반환
     */
    getStateText(approval_state : string) : string {
        switch(approval_state) {
            case approval_enum.APPROVAL_STATE.CANCEL   : return "취소";
            case approval_enum.APPROVAL_STATE.ING      : return "진행";
            case approval_enum.APPROVAL_STATE.REJECT   : return "반려";
            case approval_enum.APPROVAL_STATE.COMPLETE : return "완료";
            case approval_enum.APPROVAL_STATE.TEMP     : return "임시";
        }
        return "";
    }

    /**
     * 정렬
     */
    sort(sort_type : approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_direction = SORT_TYPE.ASC;
            return;
        }

        this.sort_direction = (this.sort_direction == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 상세화면으로 이동
     */
    moveApprovalDetail(approval_uid : string) : void {
        this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval/${approval_uid}`);
    }

    /**
     * 근태관리 기능만 ON 인 상태
     */
    isAttendanceOnly() : boolean {
        return (this.isEnableGroupFeature(this.scope_group_id, 'approval') == false && this.isEnableGroupFeature(this.scope_group_id, 'attendance') == true);
    }

    async setFilter(filter) {

        await this.doSetReceiveFilter?.(filter);

        const approval_filter_string = hodu_local_storage.getItem("approval_filter");

        if( approval_filter_string != null && approval_filter_string.length < 1 ) {
            const approval_filter = JSON.parse(approval_filter_string);
            const approval_group_filter = approval_filter[`${this.user_id}_${this.scope_group_id}`];

            if( approval_group_filter != null ) {
                approval_group_filter.receive_filter = filter;
            }
            else {
                approval_filter[`${this.user_id}_${this.scope_group_id}`] = { "draft_filter" : this.draft_filter, "receive_filter" : this.receive_filter };
            }

            hodu_local_storage.setItem("approval_filter", JSON.stringify(approval_filter));
        }
        else {
            const approval_filter = {};
            approval_filter[`${this.user_id}_${this.scope_group_id}`] = { "draft_filter" : this.draft_filter, "receive_filter" : this.receive_filter };
            hodu_local_storage.setItem("approval_filter", JSON.stringify(approval_filter));
        }
        
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#approval_list').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
</script>

<style scoped>
    .hodu-datepicker-button { font-weight: bold; font-size: 14px; background : none; color: #232848; }
    .restart_button{position: absolute;top: 8px; right: 55px; border: 1px solid; padding: 10px 15px; line-height: 20px; box-sizing: border-box; border-radius: 5px; border-color: #B0B0B0; font-size: 14px;}
    #approval .title_info li.list_title { line-height: 57px;height: 48px; }
    #approval .group_info li.list_group {  line-height: 57px;height: 48px; }
    
    
    @media screen and (max-width: 1400px ) {
    .restart_button{position: absolute;top: 8px; right: 10px; border: 1px solid; padding: 10px 10px; line-height: 20px; box-sizing: border-box; border-radius: 5px; border-color: #B0B0B0; font-size: 14px;}    
}
</style>