<template>
    <div class="notice" id="noticeConDiv">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <!-- 모두 읽음 -->
        <div class="confirmNoticeModal" :class="{ on : read_all_flag == true }">
            <div class="forRel">
                <div class="confirmBg" @click="closeReadAll"></div>
                <div class="confirmNoticeDiv">
                    <p>모두 읽음 처리 하시겠습니까?<span>(단, 개인일정 공유 알림은 처리되지 않습니다)</span></p>
                    <div class="modal_btns">
                        <input type="button" class="closeBtn" value="아니오" @click="closeReadAll"/>
                        <input type="button" value="예" @click="readAllNotificationExceptPersonalShare"/>
                    </div>
                </div>
            </div>
        </div>

        <!-- 개별 읽음 -->
        <div id="singleConfirm" class="confirmNoticeModal" :class="{ on : show_notice_click_modal == true, 
                                                                     singleLine : notice_message != null && notice_message.length > 0 && notice_message.indexOf('<br>') == -1 }">
            <div class="forRel">
                <div class="confirmBg"></div>
                <div class="confirmNoticeDiv">
                    <div class="confirmNoticeText">
                        <p v-html="notice_message" /> <!-- 댓글로 이동하시겠습니까? 등 -->
                    </div>
                    <div class="modal_btns" :class="{ b1 : notice_single_button == true }">
                        <input type="button" class="closeBtn" :value="notice_negative_button_text" @click="notice_negative_click()" v-if="notice_single_button == false"/>
                        <input type="button" :value="notice_positive_button_text" @click="notice_positive_click()"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="over_hidden noti_titlebox">
            <span class="bar"></span>
            <h3 class="tit_noti">알림</h3>
            <div class="btns">
                <input type="button" class="input_btn checkAllBtn" value="모두 읽음 처리" @click="readAllNotificationButtonClick" />
            </div>
        </div>
        <div id="noticeWrap" class="notiWrap">
            
            <div class="tabs">

                <!-- 확인하지 않은 리스트가 있다면 span.new show -->
                <a href="#" @click.prevent="changeNotificationTab('ALL')" :class="{ on : noti_type == 'ALL' }">전체             
                    <span class="new" v-if="message_new_count + push_new_count + group_notice_new_count + comment_new_count > 0">NEW</span>
                </a>

                <a href="#" @click.prevent="changeNotificationTab('PUSH')" :class="{ on : noti_type == 'PUSH' }">알림            
                    <span class="new" v-if="push_new_count > 0">NEW</span>
                </a>

                <a href="#" class="comment" @click.prevent="changeNotificationTab('COMMENT')" :class="{ on : noti_type == 'COMMENT' }">댓글            
                    <span class="new" v-if="comment_new_count > 0">NEW</span>
                </a>

                <a href="#" @click.prevent="changeNotificationTab('MESSAGE')" :class="{ on : noti_type == 'MESSAGE' }">메세지       
                    <span class="new" v-if="message_new_count > 0">NEW</span>
                </a>

                <a href="#" @click.prevent="changeNotificationTab('GROUP_NOTICE')" :class="{ on : noti_type == 'GROUP_NOTICE' }">그룹
                    <span class="new" v-if="group_notice_new_count">NEW</span>
                </a>

            </div>

            <!-- 탭 클릭시 아무런 내용이 없을 시, 밸류 변경 -->
            <div class="noResult" v-if="notification_list.length < 1">
               <p>현재 {{ getNoResultValue() }} 없습니다.</p>
            </div>

            <!-- notiUlScroll 필요 -->
            <div class="notiUlScroll">
                <!--  allUl(전체), msgUl(메세지), pushUl(알림), grpUl(그룹공지), cmtUl(댓글)-->
                <ul :class="{
                    allUl  : noti_type == 'ALL',
                    msgUl  : noti_type == 'MESSAGE',
                    pushUl : noti_type == 'PUSH',
                    grpUl  : noti_type == 'GROUP_NOTICE',
                    cmtUl  : noti_type == 'COMMENT' 
                }">
                    <li :key="notification.noti_uid" v-for="notification of getNotificationListExceptHoduNotice"
                        :class="{
                            msg  : notification.noti_type == 'MESSAGE',
                            push : notification.noti_type == 'PUSH',
                            grp  : notification.noti_type == 'GROUP_NOTICE',
                            cmt  : noti_type == 'COMMENT' && notification.noti_sub_type != null && notification.noti_sub_type.includes('_COMMENT'),
                            read : notification['read_flag'] == true,
                        }">
                        
                        <div class="liDiv" v-if="noti_type == 'COMMENT' && notification.noti_sub_type != null && notification.noti_sub_type.includes('_COMMENT')">
                            <p class="icon" :style="{ backgroundImage : `url(app_images/profile/user/${Math.floor(notification.audit_user_id / 10000)}/${notification.audit_user_id}.jpg)` }">아이콘</p>
                            <img class="img_dummy" :src="`app_images/profile/user/${Math.floor(notification.audit_user_id / 10000)}/${notification.audit_user_id}.jpg`" @error="replyUserImageError($event)">
                            <p class="noti_title">
                                <!-- <span>{{ getNotiWrittenBy(notification) }}</span> -->
                                {{ getReplyNotificationTitle(notification) }}
                            </p>
                            <p class="noti_writtenBy">
                                <span>작성자</span>{{ notification.noti_info.writer != null && notification.noti_info.writer.length > 0 ? notification.noti_info.writer : '-' }}
                            </p>
                            <p class="noti_grp">
                                <span>{{ getNotiWrittenBy(notification) }}</span>
                                {{ getReplyContents(notification) }}
                            </p>
                            <!-- <p class="noti_new" ></p> -->

                            <div class="noti_timeDiv">
                                <p class="noti_ydd">{{ getNotiYdd(notification) }}</p>
                                <p class="noti_time"></p>
                            </div>
                            <!-- <a href="#" class="noti_see" @click.prevent="clickNotification(notification)">자세히 보기</a> -->
                        </div>

                        <div class="liDiv" v-else>
                            <p class="icon" :style="getIcon(notification)">아이콘</p>
                            <img class="img_dummy" :src="getIconSrc(notification)" @error="groupImageError($event, notification.color)" v-if="notification.noti_type == 'MESSAGE' || notification.noti_type == 'GROUP_NOTICE'"/>
                            <p class="noti_title">{{ getNotiTitle(notification) }}</p>
                            <p class="noti_writtenBy">{{ getNotiWrittenBy(notification) }}</p>
                            <p class="noti_grp">{{ getNotiGrp(notification) }}</p>
                            <p class="noti_new" v-if="notification.noti_type != 'PUSH' && notification['new_count'] > 0">{{ notification['new_count'] }} NEW</p>

                            <div class="noti_timeDiv" v-if="notification.noti_type != 'MESSAGE'">
                                <p class="noti_ydd">{{ getNotiYdd(notification) }}</p>
                                <p class="noti_time">{{ getNotiTime(notification) }}</p>
                            </div>

                        </div>

                        <a href="#" class="noti_see" @click.prevent="clickNotification(notification)">자세히 보기</a>
                    </li>
                </ul>
                
                <!-- cmtUl(댓글) -->
                <!-- <ul class="cmtUl">
                    <li class="cmt">
                        <div class="liDiv">
                            <p class="icon">아이콘</p>
                            <img class="img_dummy">
                            <p class="noti_title">
                                <span>HODU premium</span> 님이 주간업무 일정에 댓글을 남겼습니다
                            </p>
                            <p class="noti_writtenBy">
                                <span>작성자</span> 김호두
                            </p>
                            <p class="noti_grp">
                                <span>HODU premium</span> 이번 주에 특별사항이 없으면 이대로 진행할게요.
                            </p>
                            <p class="noti_new" ></p>

                            <div class="noti_timeDiv">
                                <p class="noti_ydd">2022.03.21</p>
                                <p class="noti_time"></p>
                            </div>
                            <a href="#" class="noti_see">자세히 보기</a>
                        </div>
                    </li>
                    <li class="cmt">
                        <div class="liDiv">
                            <p class="icon">아이콘</p>
                            <img class="img_dummy">
                            <p class="noti_title">
                                <span>마케팅 김팅장</span> 님이 실무4차 미팅 일정에 댓글을 남겼습니다
                            </p>
                            <p class="noti_writtenBy">
                                <span>작성자</span> 마케팅 이사원
                            </p>
                            <p class="noti_grp">
                                <span>마케팅 김팀장</span> 4차 미팅은 다음주로 미뤘으면 좋겠네요
                            </p>
                            <p class="noti_new" ></p>

                            <div class="noti_timeDiv">
                                <p class="noti_ydd">2022.03.21</p>
                                <p class="noti_time"></p>
                            </div>
                            <a href="#" class="noti_see">자세히 보기</a>
                        </div>
                    </li>
                    <li class="cmt">
                        <div class="liDiv">
                            <p class="icon">아이콘</p>
                            <img class="img_dummy">
                            <p class="noti_title">
                                <span>김호두</span> 님이 주간업무 일정에 댓글을 남겼습니다
                            </p>
                            <p class="noti_writtenBy">
                                <span>작성자</span> 김호두
                            </p>
                            <p class="noti_grp">
                                <span>김호두</span> 모두 확인 하시고 댓글에 의견 써주세요
                            </p>
                            <p class="noti_new" ></p>

                            <div class="noti_timeDiv">
                                <p class="noti_ydd">2022.03.21</p>
                                <p class="noti_time"></p>
                            </div>
                            <a href="#" class="noti_see">자세히 보기</a>
                        </div>
                    </li>
                    <li class="cmt read">
                        <div class="liDiv">
                            <p class="icon">아이콘</p>
                            <img class="img_dummy">
                            <p class="noti_title">
                                <span>이길동</span> 님이 실무4차 미팅 일정에 댓글을 남겼습니다
                            </p>
                            <p class="noti_writtenBy">
                                <span>작성자</span> 마케팅 이사원
                            </p>
                            <p class="noti_grp">
                                <span>이길동</span> 김팀장님 확인해주세요
                            </p>
                            <p class="noti_new" ></p>

                            <div class="noti_timeDiv">
                                <p class="noti_ydd">2022.03.21</p>
                                <p class="noti_time"></p>
                            </div>
                            <a href="#" class="noti_see">자세히 보기</a>
                        </div>
                    </li>
                </ul> -->

            </div>
        </div>

        <!-- 메세지 클릭 시 SHOW, .msgDiv height는 윈도우 100% - 116px -->
        <div class="msgDiv" :class="{ on : read_message_flag == true }" v-if="read_message_flag == true">
            <div class="msg_title">
                <p class="grp_img" :style="getMessageImage()">사진</p>
                <img class="img_dummy" :src="getMessageImageSrc()" @error="messageImageError($event)"/>
                <p class="msg_grp_title">{{ message_group_name }}</p>
                <input type="button" class="closeMsg" value="닫기" @click="closeMessage"/>
            </div>
            <!-- mCustomScroll  height값 윈도우 100% - 186px-->
            <div class="msgDiv-scroll">
                <div class="chats">
                    
                    <!-- 댓글 없을시 show -->
                    <p class="noResult" v-if="message_list.length < 1">현재 메세지가 없습니다.</p> <!-- 로직상 나올수 없는 화면이지만 혹시 몰라서 내버려둠 -->
                    
                    <!-- 댓글 있을시 div.history 안에 일로 나눔 -->
                    <div class="history" :key="render_message.message_time_for_sort" v-for="render_message in render_message_list">
                        <p class="when">{{ render_message.message_date }}</p>
                        <ul>
                            <li :key="message.noti_uid" v-for="message of render_message.message">
                                <div class="liGrp">
                                    <p class="bubble">
                                        <span v-html="message.noti_data.body"/>
                                    </p>
                                    <p class="whotime"><span class="time">{{ getTimeTextByDate(message.audit_created) }}</span></p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, CRUD_TYPE, API_METHOD, SHARE_OPTION, GROUP_TYPE, CALENDAR_TYPE } from '@/mixin/VueHoduCommon';
import { t_notification } from '@/model/osm';

import { ResizeObserver } from 'vue-resize';

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');
const HoduDocInfo = namespace('HoduDocInfo');
const OrganizationInfo = namespace('OrganizationInfo');

import moment from 'moment';

import { t_event } from '@/model/event';
import { approval_enum, approval_modal_interface } from '@/model/approval';
import { AppointmentDetailInfo } from '@/store/modules/HoduDocInfo';
import { GroupBoardReplyModalInfo, GroupNoticeReplyModalInfo } from '@/store/modules/ModalInfo';
import { hodu_home_modal_info } from '@/model/hoduhome';
import { organization_enum } from '@/model/organization';

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

@Component({
    components: {
        ResizeObserver
    },
})
export default class RightNotificationList extends Mixins(VueHoduCommon) {

    /**
     * 전체일때 호두 공지사항 제외하고 반환
     */
    get getNotificationListExceptHoduNotice() : t_notification[] {
        if( this.noti_type != 'ALL' ) {
            return this.notification_list;
        }

        return this.notification_list.filter(notification => notification.noti_type != 'HODU_NOTICE');
    }

    /**
     * EventInfo.Action
     */
    @EventInfo.Action doSetIsFirstRepeatEvent ?: any; // 조회하는 반복일정이 해당 반복일정의 첫 일정이였다면 true 아니라면 false
    @EventInfo.Action doSetEventOriginalDate  ?: any; // 반복일정 조회시 해당 일정의 원본 date들을 설정

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventReply ?: any;
    @ModalInfo.Action doSetGroupNoticeReplyModalInfo ?: (params : GroupNoticeReplyModalInfo) => void;
    @ModalInfo.Action doSetGroupBoardReplyModalInfo ?: (params : GroupBoardReplyModalInfo) => void;
    @ModalInfo.Action doSetHomeResidentJoinModalInfo ?: (params : hodu_home_modal_info.HomeResidentJoinModalInfo) => void;
    @ModalInfo.Action doSetApprovalReplyModalInfo    ?: (params : approval_modal_interface.ApprovalReplyModalInfo) => void;

    /**
     * HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetAppointmentDetailInfo ?: (parms : AppointmentDetailInfo) => void;

    /**
     * @OrganizationInfo.Action
     */
     @OrganizationInfo.Action doSetOrganizationMemberSelectedTab ?: (params : organization_enum.ORGANIZATION_SETTING_TAB) => void;

    selected_notification       : t_notification | null = null; 
    notification_list           : t_notification[] = [];
    new_count_list              : any[]            = [];
      
    message_list                : t_notification[] = [];
    render_message_list         : any[]            = []; 
      
    message_new_count           : number           = 0;
    push_new_count              : number           = 0;
    group_notice_new_count      : number           = 0;
    comment_new_count           : number           = 0;
                
    noti_type                   : string           = 'ALL';    // ALL, MESSAGE, PUSH, GROUP_NOTICE (호두 공지사항은 웹에서는 따로 보여준다)
    block_cnt                   : number           = 50;       // 50개씩 가져온다 (페이징 처리)
    last_notification_uid       : string           = "";       // 마지막 Notification의 UID (페이징 처리)
    page_end                    : boolean          = false;    // 알림 리스트 페이징이 종료되었는지 여부
    is_api_call                 : boolean          = false;    // API가 호출중인지 여부
    
    show_notice_click_modal     : boolean          = false;    // 알림 클릭시 나올 modal 영역이 보일지 여부
    notice_message              : string           = "";       // 알림 클릭시 뜰 멘트
    notice_positive_button_text : string           = "예";     // 알림 클릭 후 나오는 긍정적인 버튼 텍스트 
    notice_negative_button_text : string           = "아니오"; // 알림 클릭 후 나오는 부정적인 버튼 텍스트
    notice_positive_func        : Function         = () => {}; // 알림 클릭 후 긍정적인 쪽 버튼을 눌렀을때 실행할 함수
    notice_negative_func        : Function         = () => {}; // 알림 클릭 후 부정적인 쪽 버튼을 눌렀을때 실행할 함수
    notice_single_button        : boolean          = false;    // 버튼이 1개인지 여부 

    message_group_id            : number           = 0;        // 메세지에서 사용되는 group_id
    message_team_id             : number           = 0;        // 메세지에서 사용되는 team_id
    message_group_name          : string           = "";       // 메세지에서 사용되는 group 또는 team 이름
    message_group_color         : string           = "";       // 메세지에서 사용되는 group 또는 team 이름
    last_message_uid            : string           = "";       // 마지막 메세지 noti_uid (페이징 처리), block_cnt는 noti와 똑같은걸 사용
    message_page_end            : boolean          = false;    // 메세지 페이징 종료 여부  
         
    read_message_flag           : boolean          = false;    // 메세지를 읽고 있는 중 flag
    read_all_flag               : boolean          = false;    // 알림 모두 읽음 영역이 보일지 여부

    mounted() : void { 
        this.setScroll();
        this.selectNotificationList();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.notice .noti_titlebox').outerHeight();
        const tab_height : number | undefined = $('#noticeWrap .tabs').outerHeight();

        // @ts-ignore
        $('.notiUlScroll').mCustomScrollbar({
            axis : 'y',
            setHeight : window.innerHeight - ( title_height ? title_height : 0 ) - ( tab_height ? tab_height : 0 ),
            scrollbarPosition : 'outside',
            callbacks:{
                whileScrolling: this.whileScrolling
            }
        });

        const msg_title : number | undefined = $('.msg_title').outerHeight();

        // @ts-ignore
        $('.notice .msgDiv-scroll').mCustomScrollbar({
            axis : 'y',
            setHeight : window.innerHeight - ( title_height ? title_height : 0 ) - ( tab_height ? tab_height : 0 ) - ( msg_title ? msg_title : 0),
            scrollbarPosition : 'outside',
            callbacks:{
                whileScrolling: this.whileMessageScrolling
            }
        });
    }

    /**
     * 알림 리스트 조회
     */
    async selectNotificationList(last_notification_uid : string = "") : Promise<void> {
        if( this.is_api_call == true ) {
            return;
        }

        this.is_api_call = true;

        this.hodu_api_call(`api/v1/notification/list/${this.noti_type}?noti_uid=${last_notification_uid}&block_cnt=${this.block_cnt}&current_date=${moment(new Date()).utc().format()}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);

                // 호두 공지사항이 아닌 것만 추가
                this.notification_list = this.notification_list.concat((response.data.data.notification_list as Array<t_notification>).filter(notification => notification.noti_type != 'HODU_NOTICE'));
                const last_notification : t_notification = this.notification_list[this.notification_list.length - 1];
                this.last_notification_uid = last_notification && last_notification.noti_uid ? last_notification.noti_uid : '';
                
                this.new_count_list.splice(0, this.new_count_list.length);
                this.new_count_list = this.new_count_list.concat((response.data.data.new_count_list as Array<any>).filter(new_count => new_count.noti_type != 'HODU_NOTICE'));
                

                for( let new_count_obj of this.new_count_list ) {
                    switch(new_count_obj.noti_type) {
                        case "MESSAGE":
                            this.message_new_count = new_count_obj.new_count;
                            break;

                        case "PUSH":
                            this.push_new_count = new_count_obj.new_count;
                            break;

                        case "GROUP_NOTICE":
                            this.group_notice_new_count = new_count_obj.new_count;
                            break;

                        case "COMMENT":
                            this.comment_new_count = new_count_obj.new_count;
                            break;
                    }
                }

                this.page_end = response.data.data.is_end_of_paging;
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })
            .finally(() => {
                this.is_api_call = false;
            });
    }

    /**
     * 알림 스크롤 리스너 - 페이징 처리
     */
    @Throttle(500)
    async whileScrolling() : Promise<void> {
        
        // 페이징 끝났다면 빠져나감
        if( this.page_end == true ) {
            return;
        }

        try {
            const noti_ul_scroll_height : number | undefined = $('.notiUlScroll').height();
            const container_height      : number | undefined = $('.notiUlScroll .mCSB_container').height();
            let container_top : number = 0;
            let container_top_string : string = "";

            try {
                container_top_string = $('.notiUlScroll .mCSB_container').css('top')

                if(container_top_string) {
                    container_top_string = container_top_string.replace('px', '');
                    container_top = Math.abs(Number(container_top_string));
                } else {
                    container_top = 0;
                }
            } catch(e) {
                container_top = 0;
            }
        
            if( (container_height ? container_height : 0) - (noti_ul_scroll_height ? noti_ul_scroll_height : 0) - container_top <= 250 ) {
                this.selectNotificationList(this.last_notification_uid);
            }

        } catch(e) {
            this.hodu_error_process(e, true, false);
        }
        
    }

    /**
     * 알림 탭 변경
     */
    changeNotificationTab(tab : string) : void {
        this.closeMessage();

        this.noti_type = tab;
        this.last_notification_uid = "";
        this.notification_list.splice(0, this.notification_list.length);
        this.selectNotificationList();
    }

    /**
     * 알림이 없는 경우
     */
    getNoResultValue() : string {

        let no_result_value = '';

        switch(this.noti_type) {
            case "ALL":
            case "PUSH":
                no_result_value = '알림이';
                break;

            case "MESSAGE":
                no_result_value = '메세지가';
                break;

            case "GROUP_NOTICE":
                no_result_value = '그룹 공지사항이';
                break;
        }

        return no_result_value;
    }

    /**
     * 메세지, 그룹 공지사항 탭인경우 그룹 이미지를 사용
     */
    getIcon(notification : t_notification) : string {
        if( this.noti_type !='ALL' && (notification.noti_type == 'MESSAGE' || notification.noti_type == 'GROUP_NOTICE') ) {
            
            // 팀 이미지
            if( notification.team_id && notification.team_id > 0 ) {
                return `background-image : url(${this.getIconSrc(notification)})`;
            }
            
            // 그룹 이미지
            else if( notification.group_id && notification.group_id > 0 ) {
                return `background-image : url(${this.getIconSrc(notification)})`;
            } 
            
            // 이미지 구할 수 없음
            else {
                return 'background-image : url(imageError) !important';
            }
        }

        return '';
    }

    /**
     * 메세지, 그룹 공지사항 탭인경우 그룹 이미지 경로 얻어오기
     */
    getIconSrc(notification : t_notification) : string {
        
        if( this.noti_type !='ALL' && (notification.noti_type == 'MESSAGE' || notification.noti_type == 'GROUP_NOTICE') ) {

            // 팀 이미지
            if( notification.team_id && notification.team_id > 0 ) {
                return `/app_images/profile/team/${Math.floor(notification.team_id / 10000)}/${notification.team_id}.jpg`;
            } 
            
            // 그룹 이미지
            else if( notification.group_id && notification.group_id > 0 ) {
                return `/app_images/profile/group/${Math.floor(notification.group_id / 10000)}/${notification.group_id}.jpg`;
            } 

            // 이미지 구할 수 없음
            else {
                return 'imageError';
            }

        }

        // :src에 비어있는 값은 에러를 유발 시켜서 강제로 추가
        return require('@/assets/images/contents/im_photoB.gif');
    }

    /**
     * 그룹 이미지 에러
     */
    groupImageError(event, group_color : any) : void {
        $(event.target).parent().find('.icon').addClass(this.hodu_color_dc_lc_for_group(group_color));
    }

    /**
     * 댓글 유저 이미지 에러
     */
    replyUserImageError(event) : void {
        $(event.target).parent().find('p.icon').addClass("none");
    }

    /**
     * noti_title에 들어갈 내용 구하기
     */
    getNotiTitle(notification : t_notification) : string {

        let noti_title : string = '';

        switch( notification.noti_type ) {
            case "MESSAGE":
                noti_title = notification.group_nm ? notification.group_nm : '';
                break;

            case "PUSH":
            case "GROUP_NOTICE":
                noti_title = notification.noti_info.title;
                break;

        }

        return noti_title;
    }

    /**
     * noti_writtenBy에 들어갈 내용 구하기
     */
    getNotiWrittenBy(notification : t_notification) : string {
        let noti_written_by : string = '';

        switch( notification.noti_type ) {
            case "MESSAGE":
                noti_written_by = notification.noti_data.body.replace(/#{이메일}/g, this.user_email)
                                                             .replace(/#{그룹팀명}/g, notification.group_nm)
                                                             .replace(/#{이름}/g, this.user_name)
                                                             .replace(/#{발신일자}/g, this.amPmStringToLocaleAmPmString(moment(notification.audit_created ? notification.audit_created : new Date()).format('YYYY.MM.DD hh:mm A'))).trim();
                break;

            case "PUSH":
            case "GROUP_NOTICE":
                noti_written_by = notification.user_nm ? notification.user_nm : '';
                break;
        }

        return noti_written_by;
    }

    /**
     * noti_grp에 들어갈 내용 구하기
     */
    getNotiGrp(notification : t_notification) : string {
        let noti_grp : string = '';

        switch( notification.noti_type ) {
            case "MESSAGE":
                noti_grp = this.amPmStringToLocaleAmPmString(moment(notification.audit_created ? notification.audit_created : new Date()).format('YYYY.MM.DD hh:mm A'));
                break;

            case "PUSH":
            case "GROUP_NOTICE":
                noti_grp = notification.group_nm ? notification.group_nm : '';
                break;
        }

        return noti_grp;
    }

    /**
     * noti_ydd에 들어갈 내용 구하기
     */
    getNotiYdd(notification : t_notification) : string {
        return moment(notification.audit_created ? notification.audit_created : new Date()).format('YYYY.MM.DD');
    }

    /**
     * noti_time에 들어갈 내용 구하기
     */
    getNotiTime(notification : t_notification) : string {
        return this.amPmStringToLocaleAmPmString(moment(notification.audit_created ? notification.audit_created : new Date()).format('hh:mm A'));
    }

    /**
     * 전체읽음 묻기
     */
    readAllNotificationButtonClick() : void {
        this.read_all_flag = true;
    }

    /**
     * 전체읽음 닫기
     */
    closeReadAll() : void {
        this.read_all_flag = false;
    }

    /**
     * 알림 클릭
     */
    async clickNotification(notification : t_notification) : Promise<void> {
        if( !notification.noti_uid ) {
            return;
        }

        this.selected_notification = notification;

        const noti_uid : string = notification.noti_uid;

        let scope : string = notification.noti_info.scope && notification.noti_info.scope.length > 0 ? notification.noti_info.scope 
                             : (notification.team_id && notification.team_id > 0) ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP;

        let scope_id : number = notification.noti_info.scope_id && notification.noti_info.scope_id > 0 ? notification.noti_info.scope_id
                                : (notification.team_id && notification.team_id > 0) ? notification.team_id : (notification.group_id ? notification.group_id : 0);

        const event_id : string = notification.noti_info.event_id;
        const calendar_id : string = notification.noti_info.calendar_id;
        
        if( notification.noti_type == 'MESSAGE' ) {
            this.read_message_flag = true;
            
            // 메세지에서 필요한 데이터 세팅
            this.message_list.splice(0, this.message_list.length);
            this.render_message_list.splice(0, this.render_message_list.length);
            this.message_group_id    = notification.group_id ? notification.group_id : 0;
            this.message_team_id     = notification.team_id  ? notification.team_id  : 0;
            this.message_group_name  = notification.group_nm ? notification.group_nm : "";
            this.message_group_color = notification.color    ? notification.color    : "";
            this.last_message_uid    = "";

            // 메세지 조회
            this.getMessage();

            setTimeout(() => { this.setScroll(); }, 1);
            return;
        }

        else if( notification.noti_type == 'PUSH' ) {
            // 클릭하면 선택영역이 무조건 뜸
            this.show_notice_click_modal = true;
            this.notice_positive_button_text = '예';
            this.notice_negative_button_text = '아니오';
            this.notice_single_button = false;

            const target : string = notification.team_id && notification.team_id > 0 ? '팀' : '그룹';

            switch( notification.noti_sub_type ) {
                /**
                 * 일정 개인 공유
                 */
                case "EVENT_SHARE":
                    this.notice_message = `${notification.noti_data.body}<span>등록하시겠습니까?</span>`;

                    this.notice_positive_func = () => {
                        this.show_notice_click_modal = false;
                        this.insertRead(noti_uid);
                        
                        // 공유 처리 + 이동
                        this.insertSharedEvent(notification);
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                        this.show_notice_click_modal = false; 
                    };

                    break;

                case "EVENT_COPY":
                    this.notice_message = `${notification.noti_data.body}<span>등록하시겠습니까?</span>`;

                    this.notice_positive_func = () => {
                        this.show_notice_click_modal = false;
                        this.insertRead(noti_uid);
                        
                        // 복사 처리 + 이동
                        this.eventCopyApply(notification);
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                        this.show_notice_click_modal = false; 
                    };
                    break;

                case "EVENT_MOVE":
                    this.notice_message = `${notification.noti_data.body}<span>등록하시겠습니까?</span>`;

                    this.notice_positive_func = () => {
                        this.show_notice_click_modal = false;
                        this.insertRead(noti_uid);
                        
                        // 이동 처리 + 이동
                        this.eventMoveApply(notification);
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                        this.show_notice_click_modal = false; 
                    };
                    break;

                /**
                 * 그룹, 팀 일정 공유 && 공유된 일정 수정 && 그룹, 팀 일정 등록 또는 수정
                 */
                case "GROUP_EVENT_SHARE":
                case "GROUP_EVENT_PRIVATE_SHARE":
                case "EVENT_MODIFIED":
                case "GROUP_EVENT_CREATE":
                case "GROUP_EVENT_MODIFIED":
                    this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                    this.notice_positive_func = () => {
                        this.show_notice_click_modal = false;
                        this.insertRead(noti_uid);

                        // 일정 조회 후 해당 일정으로 이동
                        this.getAndGoEvent(calendar_id, event_id, scope, scope_id);
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                        this.show_notice_click_modal = false; 
                    };

                    break;

                /**
                 *공유된 일정, 그룹, 팀 일정 삭제
                 */
                case "EVENT_DELETED":
                case "GROUP_EVENT_DELETED":
                    this.notice_single_button = true;
                    this.notice_message = `${notification.noti_data.body}`;
                    this.notice_positive_button_text = '확인';

                    this.notice_positive_func = () => {
                        this.show_notice_click_modal = false;
                        this.insertRead(noti_uid);
                    };
                
                    this.notice_negative_func = () => {};
                    
                    break;

                /**
                 * 일정 댓글
                 */
                case "CREATE_COMMENT":
                    this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                    this.notice_positive_func = async() => {
                        this.show_notice_click_modal = false;
                        this.insertRead(noti_uid);

                        // 일정 조회 후 해당 일정으로 이동 + 댓글창 띄우기
                        await this.getAndGoEvent(calendar_id, event_id, scope, scope_id, "CREATE_COMMENT");
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                        this.show_notice_click_modal = false; 
                    };

                    break;

                /**
                 * 그룹 공지사항 댓글
                 */
                case "CREATE_NOTI_COMMENT":
                    this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                    this.notice_positive_func = async() => {
                        this.show_notice_click_modal = false;
                        this.insertRead(noti_uid);

                        let group_id = notification.group_id ? notification.group_id : 0;
                        let team_id = notification.team_id ? notification.team_id : 0;

                        this.getNotiGroupOrTeamInfo((team_id > 0 ? 'TEAM' : 'GROUP'), (team_id > 0 ? team_id : group_id), "CREATE_NOTI_COMMENT", notification.noti_info.event_id);

                        // this.doGroupId(notification.group_id);
                        // this.doTeamId(notification.team_id);

                        // this.doGroupTeamOption({
                        //     group_team_name : this.group_team_name,
                        //     group_team_color : this.group_team_color,
                        //     group_team_image : this.group_team_image,
                        //     group_team_descript : this.group_team_descript,
                        //     biz_id : this.biz_id,
                        //     noti_uid : notice.noti_uid
                        // });
                    };

                    this.notice_negative_func = () => { 
                        this.show_notice_click_modal = false; 
                        this.insertRead(noti_uid);
                    };

                    break;

                /**
                 * 그룹, 팀 사용자 역할(권한) 변경
                 */
                case "GROUP_TEAM_USER_ROLR":
                    this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                    this.notice_positive_func = () => {
                        this.show_notice_click_modal = false;
                        this.insertRead(noti_uid);

                        // 멤버 리스트로 이동
                        this.getNotiGroupOrTeamInfo(scope, scope_id, 'GROUP_TEAM_USER_ROLR');
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                        this.show_notice_click_modal = false; 
                    };
                    
                    break;

                /**
                 * 그룹, 팀 가입 승인 요청
                 */
                case "GROUP_TEAM_INFORM":
                    this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                    this.notice_positive_func = () => {
                        this.show_notice_click_modal = false;
                        this.insertRead(noti_uid);

                        // 가입 승인 페이지로 이동
                        this.getNotiGroupOrTeamInfo(scope, scope_id, 'GROUP_TEAM_INFORM');

                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                        this.show_notice_click_modal = false; 
                    };
                    
                    break;

                /**
                 * 그룹, 팀 가입 승인
                 */
                case "GROUP_TEAM_APPROV":
                    this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                    this.notice_positive_func = () => {
                        this.show_notice_click_modal = false;
                        this.insertRead(noti_uid);
                        
                        // 그룹, 팀 상세로 이동
                        this.getNotiGroupOrTeamInfo(scope, scope_id, 'GROUP_TEAM_APPROV');
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                        this.show_notice_click_modal = false; 
                    };
                    break;

                /**
                 * 그룹, 팀 초대
                 */
                case "GROUP_TEAM_INVITE":
                    this.notice_message = `${notification.noti_info.group_name} ${target}에 가입하시겠습니까?`;
                    this.notice_positive_button_text = '승인';
                    this.notice_negative_button_text = '거절';

                    this.notice_positive_func = () => {
                        this.show_notice_click_modal = false;
                        this.insertRead(noti_uid);

                        // 아파트 초대
                        if( notification.noti_info.biz_type == 'BIZH' ) {

                            // 가입된 그룹인지 체크 후 가입 된거면 그냥 이동
                            Promise.all([this.get_group_role_service()]).then(() => {

                                if( notification.group_id == null || notification.group_id < 1 ) {
                                    return;
                                }

                                let group_joined : boolean = false;
                                for( let group of this.all_group_data ) {
                                    if( group.group_id == notification.group_id ) {
                                        group_joined = true;
                                    }
                                }

                                if( group_joined == true ) {
                                    this.doGroupId(notification.group_id);
                                    this.doTeamId(0);
                                    this.doGroupStatisticsStartDate("");
                                    this.hodu_router_push(`/group/${new Date().getTime()}/detail`);
                                    return;
                                }

                                this.doSetHomeResidentJoinModalInfo?.({
                                    show_modal : true,
                                    group_id : notification.group_id,
                                    is_invite : true
                                });

                            });
                            return;
                        }

                        // 그룹, 팀에 가입처리 + 개인 달력의 group_detail로 이동!
                        if( scope == OWNER_TYPE.GROUP ) {

                            // 가입된 그룹인지 체크 후 가입 된거면 그냥 이동
                            Promise.all([this.get_group_role_service()]).then(() => {

                                if( notification.group_id == null || notification.group_id < 1 ) {
                                    return;
                                }

                                let group_joined : boolean = false;
                                for( let group of this.all_group_data ) {
                                    if( group.group_id == notification.group_id ) {
                                        group_joined = true;
                                    }
                                }

                                if( group_joined == true ) {
                                    this.doGroupId(notification.group_id);
                                    this.doTeamId(0);
                                    this.doGroupStatisticsStartDate("");
                                    this.hodu_router_push(`/group/${new Date().getTime()}/detail`);
                                    return;
                                }

                                this.groupMemberInsert((notification.group_id ? notification.group_id : 0), false);
                            });
                        } 
                        
                        else if( scope == OWNER_TYPE.TEAM ) {
                            
                            // 가입된 그룹인지 체크 후 가입 안되어있으면 그룹 가입 후 팀 가입
                            Promise.all([this.get_group_role_service()]).then(() => {
                                let group_joined : boolean = false;
                                for( let group of this.all_group_data ) {
                                    if( group.group_id == notification.group_id ) {
                                        group_joined = true;
                                    }
                                }

                                // 가입 된 그룹이라면 바로 팀 가입
                                if( group_joined == true ) {
                                    this.teamMemberInsert((notification.group_id ? notification.group_id : 0), (notification.team_id ? notification.team_id : 0), false);
                                }

                                // 가입 안 된 그룹이라면 그룹 가입 후 팀 가입
                                else {
                                    Promise.all([this.groupMemberInsert((notification.group_id ? notification.group_id : 0), false)]).then(() => {
                                        this.teamMemberInsert((notification.group_id ? notification.group_id : 0), (notification.team_id ? notification.team_id : 0), false);
                                    })
                                }
                            })

                            
                        }

                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                        this.show_notice_click_modal = false; 
                    };

                    break;

                case "GROUP_BOARD_CREATE":
                case "GROUP_BOARD_MODIFIED":

                    this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                    this.notice_positive_func = async() => {
                        this.show_notice_click_modal = false;
                        
                        if( this.scope != OWNER_TYPE.GROUP || this.scope_group_id != notification.noti_info.group_id ) {
                            await this.goGroupCalendar(notification.noti_info);
                        }

                        this.hodu_router_push(`/group/${new Date().getTime()}/board`, async() => {
                            this.hodu_router_push(`/group/${new Date().getTime()}/board/${notification.noti_info.board_id}`);
                        });
                        this.insertRead(noti_uid);
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                        this.show_notice_click_modal = false; 
                    };

                    break;

                case "GROUP_BOARD_COMMENT":
                    this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                    this.notice_positive_func = async() => {
                        this.show_notice_click_modal = false;
                        
                        if( this.scope != OWNER_TYPE.GROUP || this.scope_group_id != notification.noti_info.group_id ) {
                            await this.goGroupCalendar(notification.noti_info);
                        }
                        this.hodu_router_push(`/group/${new Date().getTime()}/board`, async() => {
                            this.hodu_router_push(`/group/${new Date().getTime()}/board/${notification.noti_info.board_id}`);
                        });
                        this.insertRead(noti_uid);

                        this.doSetGroupBoardReplyModalInfo?.({
                            "show_modal" : true,
                            "group_id" : notification.noti_info.group_id,
                            "board_id" : notification.noti_info.board_id,
                        })
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                        this.show_notice_click_modal = false; 
                    };
                    break;

                /**
                 * 그룹 가입 거절 && 그룹 강제탈퇴 && 그룹, 팀 삭제
                 */
                case "GROUP_TEAM_REJECT":
                case "GROUP_TEAM_FORCED_WITHDRAWAL":
                case "GROUP_DELETED":
                case "TEAM_DELETED":
                case "GROUP_BOARD_DELETED":
                    this.notice_single_button = true;
                    this.notice_message = `${notification.noti_data.body}`;
                    this.notice_positive_button_text = '확인';

                    this.notice_positive_func = () => {
                        this.show_notice_click_modal = false;
                        this.insertRead(noti_uid);
                    };
                
                    this.notice_negative_func = () => {}; 
                    break;

                /**
                 * 예약 내용 수정, 상태 변경
                 */
                case "APPOINTMENT_CONTENT_CHANGE":
                case "APPOINTMENT_STATUS_CHANGE":
                    this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                    this.notice_positive_func = () => {
                        this.show_notice_click_modal = false;
                        this.insertRead(noti_uid);

                        // 일정 조회 후 해당 일정으로 이동
                        this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}/${event_id}`, API_METHOD.GET)
                            .then(async(response) => {
                                console.log(response);
                                const event : t_event = response.data.data.event_data;
                                
                                if( this.doSetAppointmentDetailInfo == null ) { return; } 

                                // 개인 달력으로 이동
                                if( this.scope != OWNER_TYPE.PERSONAL ) {
                                    this.doSetGroupMenuClose(false);
                                    this.doSetCalendarId(`personal-${this.user_id}`);
                                    this.doSetScope(OWNER_TYPE.PERSONAL);  
                                    this.doSetScopeGroupId(0);  
                                    this.doSetScopeTeamId(0);  
                                    this.doSetScopeGroupTeamOption({
                                        group_team_name : '',
                                        group_team_color : '#FFFFFF',
                                        group_team_image : '',
                                        group_team_descript : '',
                                        biz_id : '',
                                        biz_type : ''
                                    });

                                    await this.hodu_loading_timer_exit();
                                    await this.hodu_loading();

                                    if( window['left_inbox02_mCustomScrollbar'] != null ) {
                                        setTimeout(() => {
                                        
                                            // @ts-ignore
                                            $('.left_inbox02').mCustomScrollbar('destroy');

                                            window['left_inbox02_mCustomScrollbar']();

                                        }, 100);
                                    }
                                    if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
                                }

                                this.doSetAppointmentDetailInfo({
                                    event : event,
                                    is_patient : true,
                                });
                                
                                this.hodu_router_push(`/hospital/${new Date()}/appointment/${notification.noti_info.event_id}`);
                            })
                            .catch((e) => {
                                this.hodu_error_process(e, true, false);
                            });

                    };

                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                        this.show_notice_click_modal = false; 
                    };
                    break;

                /**
                 * 병원 파일함에 파일 추가
                 */
                case "HOSPITAL_ADD_FILE":

                    this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                    this.notice_positive_func = async() => {
                        this.show_notice_click_modal = false;
                        this.insertRead(noti_uid);

                        // 개인 달력으로 이동
                        if( this.scope != OWNER_TYPE.PERSONAL ) {
                            this.doSetGroupMenuClose(false);
                            this.doSetCalendarId(`personal-${this.user_id}`);
                            this.doSetScope(OWNER_TYPE.PERSONAL);  
                            this.doSetScopeGroupId(0);  
                            this.doSetScopeTeamId(0);  
                            this.doSetScopeGroupTeamOption({
                                group_team_name : '',
                                group_team_color : '#FFFFFF',
                                group_team_image : '',
                                group_team_descript : '',
                                biz_id : '',
                                biz_type : ''
                            });

                            await this.hodu_loading_timer_exit();
                            await this.hodu_loading();

                            if( window['left_inbox02_mCustomScrollbar'] != null ) {
                                setTimeout(() => {
                                
                                    // @ts-ignore
                                    $('.left_inbox02').mCustomScrollbar('destroy');

                                    window['left_inbox02_mCustomScrollbar']();

                                }, 100);
                            }
                            if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
                        }

                        this.hodu_router_push(`/hospital/file/${notification.noti_info.biz_id}`);
                    };

                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                        this.show_notice_click_modal = false; 
                    };
                    break;

                /**
                 * 전자결재 기안서 생성
                 */
                case "APPROVAL_CREATE":

                    this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                    this.notice_positive_func = async() => {
                        this.show_notice_click_modal = false;
                        if( this.scope != OWNER_TYPE.GROUP || this.scope_group_id != notification.noti_info.group_id ) {
                            await this.goGroupCalendar(notification.noti_info);
                        }
                        this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.RECEIVE);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedReceiveTab", approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT);
                        this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`, async() => {
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval/${notification.noti_info.approval_uid}`);
                        });
                        this.insertRead(noti_uid);
                    }

                    this.notice_negative_func = () => {
                        this.show_notice_click_modal = false
                        this.insertRead(noti_uid);
                    };
                    break;

                /**
                 * 전자결재 기안서 수정
                 */
                case "APPROVAL_MODIFY":

                    this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                    this.notice_positive_func = async() => {
                        this.show_notice_click_modal = false;
                        if( this.scope != OWNER_TYPE.GROUP || this.scope_group_id != notification.noti_info.group_id ) {
                            await this.goGroupCalendar(notification.noti_info);
                        }
                        this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.RECEIVE);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedReceiveTab", approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT);
                        this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`, async() => {
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval/${notification.noti_info.approval_uid}`);
                        });
                        this.insertRead(noti_uid);
                    }

                    this.notice_negative_func = () => {
                        this.show_notice_click_modal = false
                        this.insertRead(noti_uid);
                    };
                    break;

                /**
                 * 전자결재 기안서 수정 (제외됨)
                 */
                case "APPROVAL_MODIFY_EXCEPT":

                    this.notice_message = `${notification.noti_data.body}<span>전자결재로 이동하시겠습니까?</span>`;

                    this.notice_positive_func = async() => {
                        this.show_notice_click_modal = false;
                        await this.goGroupCalendar(notification.noti_info);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.RECEIVE);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedReceiveTab", approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT);
                        this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`);
                        this.insertRead(noti_uid);
                    }

                    this.notice_negative_func = () => {
                        this.show_notice_click_modal = false
                        this.insertRead(noti_uid);
                    };
                    break;

                /**
                 * 전자결재 기안서 삭제(취소)
                 */
                case "APPROVAL_DELETE":

                    if( notification.noti_info.scope != null && notification.noti_info.scope.length > 0 ) {
                        this.notice_single_button = true;
                        this.notice_message = `${notification.noti_data.body}`;
                        this.notice_positive_button_text = '확인';

                        this.notice_positive_func = () => {
                            this.show_notice_click_modal = false;
                            this.insertRead(noti_uid);
                        };
                    
                        this.notice_negative_func = () => {};
                    }

                    else {
                        this.notice_message = `${notification.noti_data.body}<span>전자결재로 이동하시겠습니까?</span>`;

                        this.notice_positive_func = async() => {
                            this.show_notice_click_modal = false;
                            await this.goGroupCalendar(notification.noti_info);
                            this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.RECEIVE);
                            this.$store.dispatch("ApprovalInfo/doSetSelectedReceiveTab", approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT);
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`);
                            this.insertRead(noti_uid);
                        }

                        this.notice_negative_func = () => {
                            this.show_notice_click_modal = false
                            this.insertRead(noti_uid);
                        };
                    }
                    
                    
                    break;

                /**
                 * 전자결재 기안서 결재 순서
                 */
                case "APPROVAL_ORDER":

                    this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                    this.notice_positive_func = async() => {
                        this.show_notice_click_modal = false;
                        await this.goGroupCalendar(notification.noti_info);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.RECEIVE);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedReceiveTab", approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT);
                        this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`, async() => {
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval/${notification.noti_info.approval_uid}`);
                        });
                        this.insertRead(noti_uid);
                    }

                    this.notice_negative_func = () => {
                        this.show_notice_click_modal = false
                        this.insertRead(noti_uid);
                    };
                    break;
                    
                /**
                 * 전자결재 기안서 반려
                 */
                case "APPROVAL_REJECT":

                    this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                    this.notice_positive_func = async() => {
                        this.show_notice_click_modal = false;
                        if( this.scope != OWNER_TYPE.GROUP || this.scope_group_id != notification.noti_info.group_id ) {
                            await this.goGroupCalendar(notification.noti_info);
                        }
                        this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.DRAFT);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedDraftTab", approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL);
                        this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`, async() => {
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval/${notification.noti_info.approval_uid}`);
                        });
                        this.insertRead(noti_uid);
                    }

                    this.notice_negative_func = () => {
                        this.show_notice_click_modal = false
                        this.insertRead(noti_uid);
                    };
                    break;

                /**
                 * 전자결재 기안서 최종 승인
                 */
                case "APPROVAL_COMPLETE":

                    this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                    this.notice_positive_func = async() => {
                        this.show_notice_click_modal = false;
                        if( this.scope != OWNER_TYPE.GROUP || this.scope_group_id != notification.noti_info.group_id ) {
                            await this.goGroupCalendar(notification.noti_info);
                        }
                        this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.DRAFT);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedDraftTab", approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL);
                        this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`, async() => {
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval/${notification.noti_info.approval_uid}`);
                        });
                        this.insertRead(noti_uid);
                    }

                    this.notice_negative_func = () => {
                        this.show_notice_click_modal = false
                        this.insertRead(noti_uid);
                    };
                    break;

                /**
                 * 전자결재 휴가원, 출장신청서 최종승인으로 인한 일정 생성
                 */
                case "APPROVAL_EVENT_CREATE":

                    if( (notification.noti_info.permitted_user_ids as number[]).indexOf(this.user_id) > -1 ) {

                        this.notice_message = `${notification.noti_data.body}<span>이동하시겠습니까?</span>`;

                        this.notice_positive_func = async() => {
                            this.show_notice_click_modal = false;
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval/${notification.noti_info.approval_uid}`);
                            this.insertRead(noti_uid);
                        }

                        this.notice_negative_func = () => {
                            this.show_notice_click_modal = false
                            this.insertRead(noti_uid);
                        };

                    }

                    else {
                        this.notice_single_button = true;
                        this.notice_message = `${notification.noti_data.body}`;
                        this.notice_positive_button_text = '확인';

                        this.notice_positive_func = () => {
                            this.show_notice_click_modal = false;
                            this.insertRead(noti_uid);
                        };
                    
                        this.notice_negative_func = () => {};
                    }

                    break;

                /**
                 * 전자결재 댓글
                 */
                case "APPROVAL_COMMENT":

                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = async() => {
                        this.show_notice_click_modal = false;
                        
                        if( this.scope != OWNER_TYPE.GROUP || this.scope_group_id != notification.noti_info.group_id ) {
                            await this.goGroupCalendar(notification.noti_info);
                        }
                        this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.DRAFT);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedDraftTab", approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL);
                        this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`, async() => {
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval/${notification.noti_info.approval_uid}`);
                        });
                        this.insertRead(noti_uid);

                        this.doSetApprovalReplyModalInfo?.({
                            "show_modal" : true,
                            "group_id" : notification.noti_info.group_id,
                            "approval_uid" : notification.noti_info.approval_uid,
                        })
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                        this.show_notice_click_modal = false;
                    };
                    break;

                /**
                 * 일정 이동 완료, 예약 삭제, 지정되지 않은 타입
                 */
                case "EVENT_MOVE_COMPLETE":
                case "APPOINTMENT_DELETE":
                default:
                    this.notice_single_button = true;
                    this.notice_message = `${notification.noti_data.body}`;
                    this.notice_positive_button_text = '확인';

                    this.notice_positive_func = () => {
                        this.show_notice_click_modal = false;
                        this.insertRead(noti_uid);
                    };
                
                    this.notice_negative_func = () => {};   
                    break;
            }
        }

        else if( notification.noti_type == 'GROUP_NOTICE' ) {
            this.getNotiGroupOrTeamInfo(scope, scope_id, 'GROUP_NOTICE');
        }
    }

    /**
     * 알림 누르고 부정적인 버튼 클릭
     */
    notice_negative_click() : void {
        this.notice_negative_func();
    }

    /**
     * 알림 누르고 긍정적인 버튼 클릭
     */
    notice_positive_click() : void {
        this.notice_positive_func();
    }

    /**
     * 알림 읽기 처리
     */
    insertRead(noti_uid : string) : void {
        this.hodu_api_call(`api/v1/notification/insertRead/${noti_uid}`, API_METHOD.PUT)
            .then((response) => {
                console.log(response);

                this.last_notification_uid = "";
                this.notification_list.splice(0, this.notification_list.length);
                this.selectNotificationList();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })
    }

    /**
     * 공유 수락
     */
    insertSharedEvent(notification : t_notification) : void {

        const event_id : string = notification.noti_info.event_id;
        const scope    : string = notification.noti_info.scope;
        const scope_id : number = notification.noti_info.scope_id;

        this.hodu_api_call(`api/v1/calendars/${scope.toLowerCase()}-${scope_id}/events/${scope}/${scope_id}/${event_id}/share`, API_METHOD.PUT)
            .then(async(response) => {
                console.log(response);
                const event : t_event = response.data.data.event_data;
                this.goEvent(event);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 일정 복사 요청 수락
     */
    eventCopyApply(notification : t_notification) : void {

        const event_id : string = notification.noti_info.event_id;
        const scope    : string = notification.noti_info.scope;
        const scope_id : number = notification.noti_info.scope_id;

        this.hodu_api_call(`api/v1/calendars/${scope.toLowerCase()}-${scope_id}/events/${scope}/${scope_id}/${event_id}/share/copy`, API_METHOD.PUT)
            .then(async(response) => {
                console.log(response);
                const event : t_event = response.data.data.event_data;
                this.goEvent(event);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    /**
     * 일정 이동 요청 수락
     */
    eventMoveApply(notification : t_notification) : void {

        const event_id : string = notification.noti_info.event_id;
        const scope    : string = notification.noti_info.scope;
        const scope_id : number = notification.noti_info.scope_id;

        this.hodu_api_call(`api/v1/calendars/${scope.toLowerCase()}-${scope_id}/events/${scope}/${scope_id}/${event_id}/share/move`, API_METHOD.PUT)
            .then(async(response) => {
                console.log(response);
                const event : t_event = response.data.data.event_data;
                this.goEvent(event);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    /**
     * 일정으로 이동
     */
    async goEvent(event : t_event, noti_type : string = "") : Promise<void> {
        // EventInfo에 이벤트 등록
        this.doSetEvent(event);
        this.doSetEventCrudType(CRUD_TYPE.READ);
        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        // 반복일정이라면 첫번째 반복을 보는 취급한다
        if( event.event_data.schedule_date.rrule != null && event.event_data.schedule_date.rrule.length > 0 ) {
            this.doSetIsFirstRepeatEvent(true);

            // 음력 반복 일정 일때는 일정의 원래 음력 날짜 store EventInfo에 등록
            if( event.event_data.schedule_date.lunar_yn == true ) {
                this.doSetEventOriginalDate({
                    original_start : await this.hodu_solar_to_lunar(new Date(moment(event.event_data.schedule_date.start).format('YYYY-MM-DD HH:mm:ss'))),
                    original_end : await this.hodu_solar_to_lunar(new Date(moment(event.event_data.schedule_date.end).format('YYYY-MM-DD HH:mm:ss'))),
                });
            }

            // 그냥 반복 일정 일때는 일정의 원래 날짜 store EventInfo에 등록
            else {
                
                this.doSetEventOriginalDate({
                    original_start : new Date(moment(event.event_data.schedule_date.start).format()),
                    original_end : new Date(moment(event.event_data.schedule_date.end).format())
                })
            }
        }

        this.hodu_router_push(`/event/${event.event_id}`);

        switch( noti_type ) {
            case "CREATE_COMMENT":
                this.doSetShowEventReply(true);
                break;
        }
    }

    /**
     * 일정 조회 후 이동
     */
    getAndGoEvent(calendar_id : string, event_id : string, scope : string, scope_id : number, noti_type : string = "") : void {
        this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}/${event_id}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                const event : t_event = response.data.data.event_data;
                this.goEvent(event, noti_type);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * scope, scope_id로 그룹 또는 팀 정보 가져오기
     */
    async getNotiGroupOrTeamInfo(scope : string, scope_id : number, noti_type : string = "", noti_uid : string = "") : Promise<void> {
        this.hodu_api_call(`api/v1/groupsweb/notification/${scope}/${scope_id}/info`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                const group_or_team_info : any = response.data.data.group_or_team_info;

                console.log(group_or_team_info);

                this.doGroupId(group_or_team_info.group_id);
                this.doTeamId(group_or_team_info.team_id);
                this.doGroupTeamOption({
                    group_team_name : group_or_team_info.group_team_name,
                    group_team_color : group_or_team_info.group_team_color,
                    group_team_image : group_or_team_info.group_team_image,
                    group_team_descript : group_or_team_info.group_team_descript,
                    biz_id : group_or_team_info.biz_id,            
                    noti_uid : noti_uid ? noti_uid : ""
                });

                switch( noti_type ) {
                    case "GROUP_TEAM_USER_ROLR":
                        
                        // 전자결재 또는 근태현황 중 하나라도 사용시
                        if( this.selected_notification != null && (this.isEnableGroupFeature(group_or_team_info.group_id, 'attendance') || this.isEnableGroupFeature(group_or_team_info.group_id, 'approval')) ) {
                            await this.goGroupCalendar(this.selected_notification.noti_info);
                            this.doSetOrganizationMemberSelectedTab?.(organization_enum.ORGANIZATION_SETTING_TAB.MEMBER);
                            this.hodu_router_push(`/${scope}/${scope_id}/organization/member/setting?${new Date().getTime()}`);
                        }
                        else {
                            this.hodu_router_push(`/group/${new Date().getTime()}/member`);
                        }

                        break;

                    case "GROUP_TEAM_INFORM":
                        if( this.selected_notification?.noti_info.is_hodu_home_resident == true ) {
                            await this.goGroupCalendar(this.selected_notification.noti_info);
                            this.hodu_router_push(`/home/resident/join?${new Date().getTime()}`);
                            break;
                        }
                        this.hodu_router_push(`/group/${new Date().getTime()}/approve`);
                        break;

                    case "GROUP_TEAM_APPROV":
                        this.doGroupStatisticsStartDate("");
                        this.hodu_router_push(`/group/${new Date().getTime()}/detail`);
                        break;

                    case "GROUP_NOTICE":
                        // 개인 달력이 아니라면 개인 달력으로 이동
                        if( this.scope != OWNER_TYPE.PERSONAL ) { this.goPersonalCalendar(); }
                        this.hodu_router_push(`/group/${new Date().getTime()}/notice`);
                        break;

                    case "CREATE_NOTI_COMMENT":
                        this.hodu_router_push(`/group/${new Date().getTime()}/notice_detail`);
                        this.doSetGroupNoticeReplyModalInfo?.({
                            show_modal : true,
                            noti_uid : noti_uid
                        });
                        break;
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 그룹 가입
     */
    groupMemberInsert(group_id : number, is_appr_required : boolean) : void {
        
        this.hodu_api_call(`api/v1/groups/groupMemberInsert/${group_id}`, API_METHOD.POST, {
            "is_appr_required" : is_appr_required
        }).then(async(response) => {
            console.log(response);

            // 개인 달력이 아니라면 개인 달력으로 이동
            if( this.scope != OWNER_TYPE.PERSONAL ) { this.goPersonalCalendar(); }

            this.doGroupId(group_id);
            this.doTeamId(0);
            this.doGroupStatisticsStartDate("");
            this.hodu_router_push(`/group/${new Date().getTime()}/detail`);

        }).catch((e) => {
            this.hodu_error_process(e, true, false);
        });

    }

    /**
     * 팀 가입
     */
    teamMemberInsert(group_id : number, team_id : number, is_appr_required : boolean) : void {
        
        this.hodu_api_call(`api/v1/teams/teamMemberInsert/${group_id}/${team_id}`, API_METHOD.POST, {
            "is_appr_required" : is_appr_required
        }).then(async(response) => {
            console.log(response);

            // 개인 달력이 아니라면 개인 달력으로 이동
            if( this.scope != OWNER_TYPE.PERSONAL ) { this.goPersonalCalendar(); }

            this.doGroupId(group_id);
            this.doTeamId(team_id);
            this.doGroupStatisticsStartDate("");
            this.hodu_router_push(`/group/${new Date().getTime()}/detail`);

        }).catch((e) => {
            this.hodu_error_process(e, true, false);
        });

    }

    /**
     * 개인 달력으로 이동
     */
    async goPersonalCalendar() : Promise<void> {
        this.doSetGroupMenuClose(false);
        this.doSetCalendarId(`personal-${this.user_id}`);
        this.doSetScope(OWNER_TYPE.PERSONAL);  
        this.doSetScopeGroupId(0);  
        this.doSetScopeTeamId(0);  
        this.doSetScopeGroupTeamOption({
            group_team_name : '',
            group_team_color : '#FFFFFF',
            group_team_image : '',
            group_team_descript : '',
            biz_id : '',
            biz_type : ''
        });

        await this.hodu_loading_timer_exit();
        await this.hodu_loading();

        setTimeout(() => {
            // @ts-ignore
            $('.left_inbox02').mCustomScrollbar('destroy');
            window['left_inbox02_mCustomScrollbar']();
        }, 100);

        if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
    }

    /**
     * 그룹 달력으로 이동
     */
    async goGroupCalendar(data : any) : Promise<void> {
        const vue = this;

        const targets = vue.group_info_list.filter(item => item.group_id == data.group_id);
        if( targets.length < 1 ) return;

        const target = targets[0];

        if( $('#bt_calContr').hasClass('on') == false ) {
            $('#container').find('#left_area').find('.left_inbox').addClass('on');
            $('.ui-datepicker-calendar-wrap').hide();
            $('#bt_calContr').addClass('on');

            const left_area_height : number | undefined = $('#left_area').outerHeight();
            const left_inbox_height : number | undefined = $('.left_inbox').outerHeight();

            const left_area_height_final : number = left_area_height == null ? 0 : left_area_height;
            const left_inbox_height_final : number = left_inbox_height == null ? 0 : left_inbox_height;

            let heightSize : number = left_area_height_final - left_inbox_height_final;
            $('.left_inbox02').height(heightSize+"px");
        }

        await vue.doSetGroupMenuClose(true);
        await vue.doSetMessageMenuClose(true);
        await vue.doSetWorkMenuClose(true);
        await vue.doSetCommunityMenuClose(true);
        await this.doSetResidentMenuClose(true);

        await vue.doSetCalendarId(`group-${data.group_id}`);
        await vue.doSetScope(OWNER_TYPE.GROUP);  
        await vue.doSetScopeGroupId(data.group_id);  
        await vue.doSetScopeTeamId(0);  

        // 조회
        await vue.doSetScopeGroupTeamOption({
            group_team_name : target.group_name,
            group_team_color : target.color,
            group_team_image : `profile/group/${Math.floor(target.group_id / 10000)}/${target.group_id}.jpg`,
            group_team_descript : '',
            biz_id : target.biz_id,
            biz_type : target.biz_type
        });

        await vue.hodu_loading_timer_exit();
        await vue.hodu_loading();

        setTimeout(() => {
            // @ts-ignore
            $('.left_inbox02').mCustomScrollbar('destroy');
            window['left_inbox02_mCustomScrollbar']();
        }, 100);

        if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
    }

    /**
     * 메세지 조회
     */
    getMessage(last_message_uid : string = "") : void {
        if( this.is_api_call == true ) {
            return;
        } 
        
        this.is_api_call = true;
        this.hodu_api_call(`api/v1/notification/message/${this.message_group_id}/${this.message_team_id}?noti_uid=${last_message_uid}&block_cnt=${this.block_cnt}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                this.message_list = this.message_list.concat(response.data.data.message_list);
                this.render_message_list.splice(0, this.render_message_list.length);

                for( let message of this.message_list ) {

                    if( message.audit_created == null || message.noti_data == null || message.noti_data.body == null ) {
                        continue;
                    }

                    // 데이터 변경 (TODO 정규식 처리를 통해 링크 만들기)
                    message.noti_data.body = message.noti_data.body.replace(/\n/g, '<br>')
                                                                   .replace(/#{이메일}/g, this.user_email)
                                                                   .replace(/#{그룹팀명}/g, this.message_group_name)
                                                                   .replace(/#{이름}/g, this.user_name)
                                                                   .replace(/#{발신일자}/g, this.amPmStringToLocaleAmPmString(moment(message.audit_created).format('YYYY.MM.DD hh:mm A'))).trim(); 
                
                    // 비교용 데이터 생성 (render_message_list에 없다면 새롭게 생성 해야함)
                    const message_time_for_sort : number = new Date(moment(message.audit_created).format('YYYY-MM-DD')).getTime();
                    const message_date          : string = `${ moment(message.audit_created).format('YYYY.MM.DD') } ${ this.getDayOfWeek(new Date(message.audit_created)) }`;

                    // display_reply_list에 존재하는지 체크
                    let index_of_message_date : number = -1;
                    const display_count : number = this.render_message_list.length;
                    for( let i = 0; i < display_count; i++ ) {
                        if( message_time_for_sort == this.render_message_list[i].message_time_for_sort ) {
                            index_of_message_date = i;
                            break;
                        }
                    }

                    // 해당 날짜의 정보가 없었다면 추가한다
                    if( index_of_message_date == -1 ) {
                        index_of_message_date = this.render_message_list.push({
                            "message_time_for_sort" : message_time_for_sort,
                            "message_date"          : message_date,
                            "message"               : []
                        }) - 1;
                    }

                    // 추가
                    this.render_message_list[index_of_message_date].message.push(JSON.parse(JSON.stringify(message)));

                    if( this.message_list.indexOf(message) == this.message_list.length - 1 ) {
                        this.last_message_uid = message.noti_uid ? message.noti_uid : "";
                    }
                }

                this.message_page_end = response.data.data.is_end_of_paging;
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
                this.closeMessage();
            })
            .finally(() => {
                this.is_api_call = false;
                
                this.last_notification_uid = "";
                this.notification_list.splice(0, this.notification_list.length);
                this.selectNotificationList();
            });
    }

    /**
     * 메세지 스크롤 리스너 - 페이징 처리
     */
    @Throttle(500)
    async whileMessageScrolling() : Promise<void> {
        
        // 페이징 끝났다면 빠져나감
        if( this.message_page_end == true ) {
            return;
        }

        try {
            const msgDiv_scroll_height : number | undefined = $('.msgDiv-scroll').height();
            const container_height     : number | undefined = $('.msgDiv-scroll .mCSB_container').height();
            let container_top : number = 0;
            let container_top_string : string = "";

            try {
                container_top_string = $('.msgDiv-scroll .mCSB_container').css('top')

                if(container_top_string) {
                    container_top_string = container_top_string.replace('px', '');
                    container_top = Math.abs(Number(container_top_string));
                } else {
                    container_top = 0;
                }
            } catch(e) {
                container_top = 0;
            }
        
            if( (container_height ? container_height : 0) - (msgDiv_scroll_height ? msgDiv_scroll_height : 0) - container_top <= 250 ) {
                this.getMessage(this.last_message_uid);
            }

        } catch(e) {
            this.hodu_error_process(e, true, false);
        }
        
    }

    /**
     * 한 글자 요일 텍스트 가져오기 
     */
    getDayOfWeek(date : Date) : string {

        if( date == null ){
            return "";
        }

        switch( date.getDay() ){
            case 0:
                return "일";

            case 1:
                return "월";

            case 2:
                return "화";

            case 3:
                return "수";

            case 4:
                return "목";

            case 5:
                return "금";

            case 6:
                return "토";

            default:
                return "?";
        }
    }

    /**
     * Date로 시간 text 만들기
     */
    getTimeTextByDate(reply_date : Date) : string {
        reply_date = reply_date instanceof Date ? reply_date : new Date(moment(reply_date).format()); 
        const current_date : Date = new Date();

        const time_millis : number = current_date.getTime() - reply_date.getTime();

        // 같은 날짜일때
        if( this.getDateDiff(reply_date, current_date) < 1 ) {
            
            // 시간 차이 1분 미만
            if( time_millis < 1 * 60 * 1000 ) {
                return "방금 전"
            }

            // 시간 차이 10분 전 미만
            if( time_millis < 10 * 60 * 1000 ) {
                return `${Math.floor(time_millis / 60 / 1000)}분 전`;
            }

            // 시간 차이 1시간 전 미만
            if( time_millis < 60 * 60 * 1000 ) {
                return `${Math.floor(time_millis / 60 / 10 / 1000)}0분 전`;
            }

            // 시간 차이 6시간 전 미만
            if( time_millis < 6 * 60 * 60 * 1000 ) {
                return `${Math.floor(time_millis / 60 / 60 / 1000)}시간 전`;
            }

        }

        // 같은 날짜의 6시간 전 보다 차이가 큰 날짜 && 다른 날의 날짜는 AM, PM 시간을 그대로 리턴
        const hour : string = `0${reply_date.getHours() == 0 ? '12' : reply_date.getHours() > 12 ? reply_date.getHours() - 12 : reply_date.getHours() }`.slice(-2);
        const min  : string = `0${reply_date.getMinutes()}`.slice(-2);
        const amPm : string = `${reply_date.getHours() < 12 ? '오전' : '오후' }`;
        return `${amPm} ${hour}:${min}`;
    }

    /**
     * 메세지 image style 반환
     */
    getMessageImage() : string {
            // 팀 이미지
            if( this.message_team_id && this.message_team_id > 0 ) {
                return `background-image : url(${this.getMessageImageSrc()})`;
            }
            
            // 그룹 이미지
            else if( this.message_group_id && this.message_group_id > 0 ) {
                return `background-image : url(${this.getMessageImageSrc()})`;
            } 
            
            // 이미지 구할 수 없음
            else {
                return 'background-image : url(imageError) !important';
            }
    }

    /**
     * 메세지 이미지 src 반환
     */
    getMessageImageSrc() : string {

        // 팀 이미지
        if( this.message_team_id && this.message_team_id > 0 ) {
            return `/app_images/profile/team/${Math.floor(this.message_team_id / 10000)}/${this.message_team_id}.jpg`;
        } 
        
        // 그룹 이미지
        else if( this.message_group_id && this.message_group_id > 0 ) {
            return `/app_images/profile/group/${Math.floor(this.message_group_id / 10000)}/${this.message_group_id}.jpg`;
        } 

        // 이미지 구할 수 없음
        else {
            return 'imageError';
        }

    }
    
    /**
     * 메세지 이미지 에러
     */
    messageImageError(event) : void {
        $(event.target).parent().find('.grp_img').addClass(this.hodu_color_dc_lc_for_group(this.message_group_color));
    }

    /**
     * 메세지 닫기
     */
    closeMessage() : void {
        // 메세지 리스트 비우기 && 메세지에서 필요한 데이터 없애기
        this.message_list.splice(0, this.message_list.length);
        this.render_message_list.splice(0, this.render_message_list.length);
        this.message_group_id    = 0;
        this.message_team_id     = 0;
        this.message_group_name  = "";
        this.message_group_color = "";
        this.last_message_uid    = "";

        this.read_message_flag  = false;
    }

    /**
     * 개인일정 공유 알림을 제외하고 전부 읽기 처리
     */
    readAllNotificationExceptPersonalShare() : void {
        this.read_all_flag = false;
        this.hodu_api_call(`api/v1/notification/list/readAll?current_date=${moment(new Date()).utc().format()}&is_web=true`, API_METHOD.PUT)
            .then((response) => {
                console.log(response);

                this.last_notification_uid = "";
                this.notification_list.splice(0, this.notification_list.length);
                this.selectNotificationList();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 댓글 알림 제목
     */
    getReplyNotificationTitle(notification) {

        switch(notification.noti_sub_type) {
            case "CREATE_COMMENT":
                if( notification.noti_info.event_title != null && notification.noti_info.event_title.length > 0 ) {
                    return `${notification.noti_info.event_title} 일정에 댓글을 남겼습니다`;
                    // return `님이 ${notification.noti_info.event_title} 일정에 댓글을 남겼습니다`;
                }
                break;
            case "CREATE_NOTI_COMMENT":
                if( notification.noti_info.notice_title != null && notification.noti_info.notice_title.length > 0 ) {
                    return `${notification.noti_info.notice_title} 공지사항에 댓글을 남겼습니다`;
                    // return `님이 ${notification.noti_info.notice_title} 공지사항에 댓글을 남겼습니다`;
                }
                break;
            case "CREATE_MODN_COMMENT":
                if( notification.noti_info.modn_title != null && notification.noti_info.modn_title.length > 0 ) {
                    return `${notification.noti_info.modn_title} 청소예약에 댓글을 남겼습니다`;
                    // return `님이 ${notification.noti_info.modn_title} 청소예약에 댓글을 남겼습니다`;
                }
                break;
        }

        return notification.noti_info.title;
    }

    /**
     * 댓글 내용
     */
    getReplyContents(notification) {

        if( notification.noti_info.image_count != null && notification.noti_info.image_count > 0 ) {

            if( notification.noti_info.image_count > 1 ) {
                return `사진 ${notification.noti_info.image_count}장을 보냈습니다`;
            }

            return "사진을 보냈습니다";
        }

        if( notification.noti_info.file_count != null && notification.noti_info.file_count > 0 ) {
            
            if( notification.noti_info.file_count > 1 ) {
                return `파일 ${notification.noti_info.file_count}개를 보냈습니다`;
            }

            return "파일을 보냈습니다";
        }

        if( notification.noti_info.contents != null && notification.noti_info.contents.length > 0 ) {
            return notification.noti_info.contents;
        }

        return "";
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('.notiUlScroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('.notice .msgDiv-scroll').mCustomScrollbar('destroy');

    	this.setScroll();
    }
}

</script>

<style>
    #singleConfirm p { line-height: 80px; font-size: 16px;font-weight: bold;}
    #singleConfirm p span { display: block; margin-top: 10px; /* font-size:16px; opacity:1; position: absolute; top: 73px; left:0; width:100%; margin-top: 10px;*/ }

</style>

<style scoped>
    .notiWrap .tabs a { position: relative; width:20%; }
    #noticeConDiv .btns { float:right; margin: 10px 20px 0 0;}
    .tab_txt { padding-left: 5px; }
    .tabs a span.new { position:absolute; top:15px;right:15px; display:inline-block; width:7px; height: 7px; font-size:0;background:#ff6060; border-radius:50%;  }
    .notiWrap .noResult { position: static; margin-top:30px; }
    .notiWrap .noResult p { background:#f1f3f5; line-height: 60px; height:60px; font-size:13px;color:#8b8e92 }
    .notiWrap ul { overflow:hidden;     margin-bottom: 150px;}
    .notiWrap ul li { position: relative; border-bottom: 1px solid #e3e7ed; min-height:105px; transition:0.2s;}
    .notiWrap ul li.read { background:#f1f3f5; }
    .notiWrap ul li.read .liDiv { opacity: 0.6; }
    .notiWrap ul li.read:hover .liDiv { opacity: 1; }
    .notiWrap ul li:hover { background:#f1f3f5; }
    .liDiv { position:static; padding: 20px 20px 20px 70px;width:100%; box-sizing:border-box }
    .liDiv .icon { width: 30px; height:20px; font-size: 0; background: red;position:absolute; left:20px; top:20px; }
    .msg .liDiv .noti_grp, .msg .liDiv .noti_writtenBy { padding-right: 0; box-sizing:border-box }
    .grp .liDiv .noti_title { padding-right:100px; box-sizing:border-box; }
    .liDiv .noti_writtenBy, .liDiv .noti_grp { padding-right: 100px; box-sizing:border-box }
    .liDiv .noti_title, .liDiv .noti_writtenBy, .liDiv .noti_grp, .liDiv .noti_ydd, .liDiv .noti_time  { font-size: 14px; width:100%; font-weight:bold;height:25px;line-height:20px;overflow:hidden; text-overflow:ellipsis;white-space: nowrap; }
    .liDiv .noti_writtenBy, .liDiv .noti_grp, .liDiv .noti_ydd, .liDiv .noti_time { height:20px;font-size:12px; width:100%; margin-right:0; font-weight:normal; }
    .noti_timeDiv { position:absolute; right:30px; top:43px; text-align:right; width:20%; }
    .noti_new { background:#ff6060; color:#fff; font-weight: bold; font-size:11px;border-radius: 3px; display: inline-block; padding:0 5px; height:20px; line-height:20px;position:absolute;top:21px; right:30px; }
    .push .icon {background: url(../../assets/images/contents/ic_alarm_bk.png)no-repeat center center;}
    .grp .icon {background: url(../../assets/images/contents/ic_notice_bk.png) no-repeat center center;background-size: 22px; }
    .msg .icon {background: url(../../assets/images/contents/ic_cmmnt_bk.png) no-repeat center center;background-size: 23px; }
    .noti_see { position:absolute; width:100%; height:100%;display:block; font-size:0;top:0;left:0; }
    .msgUl .liDiv, .grpUl .liDiv { padding-left: 100px;  }
    .msgUl .push, .msgUl .grp { display:none !important }
    .pushUl .msg, .pushUl .grp { display:none !important }
    .grpUl .push, .grpUl .msg { display:none !important }

    .confirmNoticeModal.on { display:block; }
    .confirmNoticeModal { position:absolute;display:none; left: 0; top:0; width:100%; height:100%; left:0; ;z-index: 1000000; }
    .confirmNoticeModal .forRel { width:100%; height:100%; }
    .confirmBg { background:#000; opacity:0.1;width:100%; height:100%; }
    .confirmNoticeDiv { overflow:hidden; width: 400px; height:180px; background:#fff; text-align:center; background:#fff; position:absolute; top:50%; left:50%; margin-left:-200px; margin-top:-90px; border-radius:5px;box-shadow: 0 5px 10px rgba(0,0,0,0.1) }
    .confirmNoticeModal .modal_btns { overflow:hidden; height:70px; line-height:70px; border-top:1px solid #f1f3f5; }
    .confirmNoticeModal .modal_btns input { width:50%; float:left; height:100%;font-size:14px; font-weight:bold; background:#fff; transition:0.2s;}
    .confirmNoticeModal .modal_btns.b1 input { width:100% }
    .confirmNoticeModal .modal_btns input:hover { background:#f1f3f5; }
    .confirmNoticeModal p {height: 109px;line-height: 90px;font-size: 16px;font-weight: bold;}
    .confirmNoticeModal p span { display: block;font-size:13px;opacity:0.5; position: absolute; top: 30px; left:0; width:100%; }
    .confirmNoticeModal.singleLine p {line-height: 109px !important;}
    
    .msgUl .icon, .grpUl .icon { width: 60px; height:40px; border-radius:3px;border:1px solid #e3e7ed; background-size: cover; background-position:center center; background-repeat:no-repeat; }
    .img_dummy { display : none; }

    .msgDiv.on { display:block; }
    .msgDiv { display:none; position:absolute; top: 116px;left:0; background:#fff;  z-index:100000; width:100%; box-sizing:border-box; }
    .msgDiv .msg_title { position:relative; width:100%; line-height:70px; height:70px; border-bottom:1px solid #e3e7ed; }
    .msgDiv .msg_grp_title { width:100%; padding: 0 60px 0 105px; font-size:14px; font-weight:bold; box-sizing:border-box; overflow:hidden; text-overflow:hidden; white-space: nowrap; }
    .msgDiv .grp_img { position:absolute;top: 13px; left:25px;font-size:0; width: 60px; height:40px; border-radius:3px;border:1px solid #e3e7ed; background-size: cover; background-position:center center; background-repeat:no-repeat; }
    .msgDiv .closeMsg { position:absolute;top: 13px; right:25px; transition:0.2s; width: 40px;height: 40px;border-radius:50%; background: url(../../assets/images/contents/ic_x_new.png) no-repeat center center; border:1px solid #e3e7ed; font-size:0;}
    .msgDiv .closeMsg:hover { background-color:#f1f3f5; }
    #noticeConDiv .msgDiv  .chats {padding: 20px;}
    #noticeConDiv .msgDiv .remodal .md_titleBox {position:absolute;top:0;left:0;width:100%;z-index: 100000;line-height: 61px;}
    #noticeConDiv .msgDiv .remodal .md_titleBox h1 {padding: 0;font-size: 16px;text-align: left;padding-left: 20px;border-bottom: 2px solid #e6e5e5;}
    #noticeConDiv .msgDiv .md_titleBox a {display:none;border: 1px solid #f1f3f5;border-radius: 5px;background: #fff;height: 35px;line-height: 35px;padding: 0 10px;font-weight: bold;margin-left: 10px;margin-top: 11px;}
    #noticeConDiv .msgDiv .chats .noResult { position: static;background: #f1f3f5;font-size: 13px;font-weight: bold;width: 100%;margin: 0 auto;border-radius: 5px;line-height: 70px;color: #9aa0b1;height: 70px;}
    #noticeConDiv .msgDiv .grp {position: absolute;bottom: 0;width: 100%;background: #fff;border-top: 1px solid #f1f3f5;height: auto;padding-bottom:10px;padding: 10px 90px 30px 20px;box-sizing: border-box;}
    #noticeConDiv .msgDiv .grp .txt {overflow-y: hidden;resize: none;border:0 none;padding-bottom: 15px;line-height:1.6;padding: 2.5em;padding-top: 15px;font-weight: bold;font-size:14px;width: 100%;padding-left: 15px;box-sizing: border-box;background: #f1f3f5;border-radius: 5px;}
    #noticeConDiv .msgDiv .grp:hover {box-shadow: 0 0px 10px rgba(0,0,0,0.1);transition: 0.1s;}
    #noticeConDiv .msgDiv .when {margin: 10px 0 20px;padding: 10px 20px;border: 2px solid #f1f3f5;border-radius:20px;display:inline-block;background: #fff;font-weight: bold;}
    #noticeConDiv .msgDiv .when:after {content: '';display: block;width: 100%;height: 2px;position: absolute;background: #f1f3f5;left: 0;z-index: -1;top: 28px;}
    #noticeConDiv .msgDiv .history {position: relative;margin-bottom:30px;}
    #noticeConDiv .msgDiv .history:hover .when { color:#477fff; border-color:#477fff; }
    #noticeConDiv .msgDiv .chats {padding: 20px 30px;}
    #noticeConDiv .msgDiv .liGrp { position:relative; overflow:hidden; height:100%; }
    #noticeConDiv .msgDiv .liGrp img {width: 40px;border-radius: 50%;border: 1px solid #f1f3f5;display: inline-block;float: left;background-color:#f1f3f5;margin-top: 3px;}
    #noticeConDiv .msgDiv .liGrp p {display: inline-block;float: left;}
    #noticeConDiv .msgDiv .chats li {overflow: hidden;position: relative;margin-bottom:25px;transition:0.2s;}
    #noticeConDiv .msgDiv .chats li.me .bubble {background: #477fff;color: #fff;margin-right: 20px;margin-left: 0;float: right;border-radius: 10px 0px 10px 10px; padding: 7px 11px;}
    #noticeConDiv .msgDiv .chats li.me .bubble span { color:#fff; }
    #noticeConDiv .msgDiv .chats li .bubble span { color:#283240;    line-height: 20px; }
    #noticeConDiv .msgDiv .liGrp .bubble {position: relative;padding: 11px 14px;background: #f1f3f5;font-weight: bold;border-radius: 0px 10px 10px 10px;margin-top: 23px;max-width: 260px;text-align: left;line-height: 20px;}
    #noticeConDiv .msgDiv .liGrp p.whotime {position: absolute;top: 0;left: 0;opacity: 0.5;font-weight: bold;}
    #noticeConDiv .msgDiv .liGrp p.whotime span {width: 80px;display: inline-block;text-align: left;}
    #noticeConDiv .msgDiv .chats li:hover .whotime {color: #283240;opacity: 1;font-weight: bold;transition: 0.1s;}

    /* 넘치는 일정 문구 수정 */
    #singleConfirm p { line-height: 26px !important; box-sizing: border-box; display: table-cell; vertical-align: middle; }
    #singleConfirm .confirmNoticeText { width: 100%; height: 100px; display: table; }

    /* .notiWrap ul { display: none;}  댓글알림때문에 잠시 숨김 */
    /* 댓글 알림 */
    /* .notiWrap ul.cmtUl { display: none; } */
    .notiWrap ul.cmtUl .noti_time { display: none; }
    .notiWrap ul.cmtUl .liDiv { padding-left: 85px; }
    .notiWrap ul.cmtUl .liDiv .icon { width: 45px; height: 45px; border-radius: 50%; border: 2px solid #e7e9ea; background: url('../../assets/images/footer/f5.jpg') no-repeat center center; background-size: cover; }
    .notiWrap ul.cmtUl .liDiv .icon.none { background-image : url('../../assets/images/contents/ic_approval_user.png') !important; }
    .notiWrap ul.cmtUl .liDiv .noti_title { font-weight: normal; }
    .notiWrap ul.cmtUl .liDiv .noti_title > span { font-weight: bold; }
    .notiWrap ul.cmtUl .liDiv .noti_writtenBy > span { margin-right: 12px; }
    .notiWrap ul.cmtUl .liDiv .noti_grp { padding-right: 20px; }
    .notiWrap ul.cmtUl .liDiv .noti_grp > span { max-width: 90px; line-height: 20px; display: inline-block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-weight: bold; color: #477fff; vertical-align: top; margin-top: -1px; }
    .notiWrap ul.cmtUl .liDiv .noti_grp > span:before { content:''; width: 13px; height: 12px; display: inline-block; background: url('../../assets/images/contents/ic_txt_on.png') no-repeat center center; background-size: cover; margin-right: 6px; vertical-align: middle; }
</style>