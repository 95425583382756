<template>
    <div id="attendance_request_modal" class="modal">
        <div class="titleBox">
            <h1 class="ing">근태 현황</h1>
        </div>
        <div class="content"> 
            <div class="detail">
                <div class="leftContent" v-if="my_employee_info != null">
                    <p class="img" :style="{ backgroundImage : `url(${getProfileImage(my_employee_info)})` }">사진</p>
                    <img class="dummy" :src="getProfileImage(my_employee_info)" @error="userImageError($event)"/>
                    <p class="name">{{ my_employee_info.user_name }}</p>
                    <p class="pos">{{ my_position_info ? my_position_info.pos_name : '-' }}</p>
                    <p class="team">{{ my_department_info ? (my_department_info.dept_id == 0 ? '미배정' : my_department_info.dept_name) : '-' }}</p>
                    <p class="email">{{ my_employee_info.user_email ? my_employee_info.user_email : '-' }}</p>
                    <p class="tel">{{ my_employee_info.user_phone_number ? my_employee_info.user_phone_number : '-' }}</p>
                    <!-- <p class="img">사진</p>
                    <p class="name">홍길동</p>
                    <p class="pos">사원</p>
                    <p class="team">모바일팀</p>
                    <p class="email">hong@hoduware.com</p>
                    <p class="tel">01012345678</p> -->
                </div>
                <div class="context">
                    <ul class="detailUl">
                        <li class="">
                            <p class="title">날짜</p>
                            <p class="descript">{{ hodu_date_to_format_string(date, 'YYYY년 MM월 DD일') }} {{ getDayOfWeekByDate(date, '요일') }}</p>
                        </li>
                        <li class="statusLi">
                            <p class="title">상태</p>
                            <div class="descript">
                                <select class="rtl" v-model="attend_state" v-if="attend == null">
                                    <option value="20">미체크</option>
                                    <option value="10">정상</option>
                                    <option value="30">지각</option>
                                    <option value="40">결근</option>
                                </select>
                                <select class="rtl" v-model="attend_state" v-else>
                                    <option value="20" :selected="isNormal() == false && attend.attend_state != '30' && attend.attend_state != '40'">미체크</option>
                                    <option value="10" :selected="isNormal() == true">정상</option>
                                    <option value="30" :selected="attend.attend_state == '30'">지각</option>
                                    <option value="40" :selected="attend.attend_state == '40'">결근</option>
                                </select>
                            </div>
                        </li>
                        <li class="timeLi">
                            <p class="title">출근</p>
                            <div class="descript">
                                <!-- 미체크일시 unchecked만 보여주기 -->
                                <div class="unchecked" v-if="is_start_update_mode == false">
                                    <p title="수정" @click.prevent="is_start_update_mode = true">
                                        <span v-if="(attend == null || attend.start_time == null) && getWorkCnt() != 0">?</span>
                                        {{ (attend == null || attend.start_time == null) ? (getWorkCnt() != 0 ? '미체크' : getOffText()) : getTimeText(attend.start_time) }}
                                    </p>
                                    <input type="button" class="input_btn" value="미체크" v-if="!(attend == null || attend.start_time == null)" @click.prevent="() => { attend.start_time = null; }"/>
                                </div>
                                <div class="grp" v-if="is_start_update_mode == true">
                                    <select class="ampm" v-model="start_am_pm">
                                        <option value="AM">오전</option>
                                        <option value="PM">오후</option>
                                    </select>

                                    <select v-model="start_hour">
                                        <option :value="(n == 1 ? '12' : `0${n - 1}`.slice(-2))" :key="n" v-for="n in 12" :selected="(n == 1 ? '12' : `0${n - 1}`.slice(-2)) == '09'">
                                            {{ (n == 1 ? '12' : `0${n - 1}`.slice(-2)) }}
                                        </option>
                                    </select>
                                    :
                                    <select v-model="start_min">
                                        <option :value="`0${(n - 1)}`.slice(-2)" :key="n" v-for="(n) in 60" :selected="`0${(n - 1)}`.slice(-2) == '00'">
                                            {{ `0${(n - 1)}`.slice(-2) }}
                                        </option>
                                    </select>
                                    
                                    <input type="button" title="취소" class="xBtn" value="X" @click.prevent="cancelUpdateStartTime"/>
                                </div>
                            </div>
                        </li>
                        <li class="timeLi">
                            <p class="title">퇴근</p>
                            <div class="descript">
                                <!-- 미체크일시 unchecked만 보여주기 -->
                                <div class="unchecked" v-if="is_end_update_mode == false">
                                    <p title="수정" @click.prevent="is_end_update_mode = true">
                                        <span v-if="(attend == null || attend.end_time == null) && getWorkCnt() != 0">?</span>
                                        {{ (attend == null || attend.end_time == null) ? (getWorkCnt() != 0 ? '미체크' : getOffText()) : getTimeText(attend.end_time) }}
                                    </p>
                                    <input type="button" class="input_btn" value="미체크" v-if="!(attend == null || attend.end_time == null)" @click.prevent="() => { attend.end_time = null; }"/>
                                </div>
                                <div class="grp" v-if="is_end_update_mode == true">
                                    <select class="ampm" v-model="end_am_pm">
                                        <option value="AM">오전</option>
                                        <option value="PM">오후</option>
                                    </select>

                                    <select v-model="end_hour">
                                        <option :value="(n == 1 ? '12' : `0${n - 1}`.slice(-2))" :key="n" v-for="n in 12" :selected="(n == 1 ? '12' : `0${n - 1}`.slice(-2)) == '06'">
                                            {{ (n == 1 ? '12' : `0${n - 1}`.slice(-2)) }}
                                        </option>
                                    </select>
                                    :
                                    <select v-model="end_min">
                                        <option :value="`0${(n - 1)}`.slice(-2)" :key="n" v-for="(n) in 60" :selected="`0${(n - 1)}`.slice(-2) == '00'">
                                            {{ `0${(n - 1)}`.slice(-2) }}
                                        </option>
                                    </select>
                                    
                                    <input type="button" title="취소" class="xBtn" value="X" @click.prevent="cancelUpdateEndTime"/>
                                </div>
                            </div>
                        </li>
                        <li class="offLi">
                            <p class="title">휴가</p>
                            <div class="descript">
                                <ul v-if="vacations == null || vacations.length < 1"><li><p>없음</p></li></ul>
                                <ul v-else>
                                    <li class="withBtn" :key="index" v-for="(vacation, index) in vacations">
                                        <p>
                                            {{ getVacationName(vacation.type_id) }}
                                            <span class="nums" v-if="Number(vacation.daycount) == 0.5">({{ getTimeText(vacation.start_time) }} ~ {{ getTimeText(vacation.end_time) }})</span>
                                        </p>
                                        <input type="button" title="휴가 취소" class="xBtn" value="X" @click.prevent="deleteVacation(vacation)" />
                                    </li>
                                    <!-- 클래스 withBtn 있을시에 사용버튼 보이고 p태그 없앰 -->
                                    <!-- <li class="withBtn">
                                        <p>반차 <span class="nums">(오전 09:00 ~ 오전 10:00)</span></p>
                                        <input type="button" class="xBtn" value="X" />
                                    </li>
                                    <li class="withBtn">
                                        <p>반차 <span class="nums">(오전 09:00 ~ 오전 10:00)</span></p>
                                        <input type="button" class="xBtn" value="X" />
                                    </li> -->
                                </ul>
                            </div>                          
                        </li>
                        <li class="btLi">
                            <p class="title">출장</p>
                            <div class="descript">
                                <ul v-if="businesstrip == null || businesstrip.length < 1"><li><p>없음</p></li></ul>
                                <ul v-else>
                                    <li class="withBtn" :key="index" v-for="(bt, index) in businesstrip">
                                        <p>
                                            {{ getBusinessTripName(bt.type_id) }}
                                            <span class="nums" v-if="Number(bt.daycount) == 0.5">({{ getTimeText(bt.start_time) }} ~ {{ getTimeText(bt.end_time) }})</span>
                                        </p>
                                        <input type="button" title="출장 취소" class="xBtn" value="X" @click.prevent="deleteBusinessTrip(bt)"/>
                                    </li>
                                </ul>
                            </div> 
                        </li>
<!-- reason 리스트에 disabled 넣어주기 (요청 내용이 없을 시) -->
                        <!-- <li class="reason">
                            <p class="title">요청 내용</p>
                            <textarea class="descript" disabled>
                                8시 출근 하였지만 출근카드를 찍지 않았습니다.
                                변경 부탁드립니다.
                            </textarea>
                        </li> -->
                    </ul>
                </div>
            </div>
            
            <div class="btns">
                <input type="button" value="닫기" @click.prevent="close"/>
                <input type="button" class="save" value="저장" @click.prevent="save"/>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
import { attendance_modal_interface } from '@/model/attendance';
import moment from 'moment';
import { approval_enum } from '@/model/approval';
const ModalInfo = namespace('ModalInfo');

@Component({
    components: {
        
    },
})
export default class AttendanceRequestModal extends Mixins(VueHoduCommon) {
    
    /**
     * @ModalInfo.State
     */
    @ModalInfo.State attendance_request_modal_info !: attendance_modal_interface.AttendanceRequestModalInfo

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAttendanceRequestModalInfo ?: (params : attendance_modal_interface.AttendanceRequestModalInfo) => void;

    attend_info : any[] = [];
    vacation_types : any[] = [];
    work_types : any[] = [];

    date = new Date();

    my_employee_info : any = null;
    my_position_info : any = null;
    my_department_info : any = null;

    is_start_update_mode = false;
    is_end_update_mode = false;

    attend : any = null;
    copy_attend : any = null;

    vacations : any[] = [];
    businesstrip : any[] = [];

    delete_vacation : any[] = [];
    delete_businesstrip : any[] = [];

    attend_state = "20";
    
    start_am_pm = 'AM';
    start_hour = "09";
    start_min = "00";

    end_am_pm = "PM";
    end_hour = "06";
    end_min = "00";

    mounted() : void {

        // $(document).ready(function(){
            
            // $(".statusLi .descript").click(function(){
            //     $(this).parent().find(".dropdown").toggleClass("on");

            //     return false;
            // });

        // });
        
        const yyyymmdd = this.attendance_request_modal_info.yyyymmdd;
        this.date = new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));

        this.getTotalOrganizationData();
    }

    /**
     * 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.hodu_show_indicator();
            await this.getOrganizationAttend();
            await this.getOrganizationEmp();
            await Promise.all([this.getOrganizationPosition(), this.getOrganization(), this.getOrganizationVacationTypeInfo()]);

            // if( !this.my_employee_info || !this.my_position_info || !this.my_department_info ) {
            //     throw new Error("근태 상태 조회 중 오류 발생");   
            // }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("근태 상태 조회 중 오류 발생");
            this.close();
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            for( const department of response.data.data.dept_info ) {
                if( this.my_employee_info.dept_id == department.dept_id ) {
                    this.my_department_info = department;
                    break;
                }
            }

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            for( const pos of response.data.data.pos_info ) {
                if( this.my_employee_info.pos_id == pos.pos_id ) {
                    this.my_position_info = pos;
                    break;
                }
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 전체 직원 조회 중 오류 발생");
            }

            for( const employee of response.data.data.emp_info ) {
                if( employee.user_id == this.attendance_request_modal_info.user_id ) {
                    this.my_employee_info = employee;
                    break;
                }
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 휴가타입 조회
     */
    async getOrganizationVacationTypeInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/vacationtype`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.vacation_type_info ) {
                throw new Error("휴가타입 조회 중 오류 발생")
            }

            this.vacation_types.splice(0, this.vacation_types.length);
            this.vacation_types = this.vacation_types.concat(response.data.data.vacation_type_info);
            this.vacation_types.sort((o1, o2) : number => {
                if( o1.vacation_type_id > o2.vacation_type_id ) return 1;
                else return -1;
            });

        } catch(e) {
            throw e
        }
    }

    /**
     * 근태 상태 조회
     */
    async getOrganizationAttend() : Promise<void> {
        
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/${this.attendance_request_modal_info.yyyymmdd}?user_id=${this.attendance_request_modal_info.user_id}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_info ) {
                throw new Error("근태 상태 조회 중 오류 발생");
            }

            this.attend_info.splice(0, this.attend_info.length);
            this.attend_info = this.attend_info.concat(response.data.data.attend_info);

            this.attend = null;
            this.vacations.splice(0, this.vacations.length);
            this.businesstrip.splice(0, this.businesstrip.length);

            for( const attend of this.attend_info ) {
                if( attend.attend_type == 10 ) {
                    this.attend = attend;

                    if( this.attend.attend_state == null ) this.attend.attend_state = '20';

                    this.attend_state = attend.attend_state;
                    
                    if( this.isNormal() == true ) {
                        this.attend_state = "10";
                    }
                    
                    if( attend.start_time != null ) {
                        let start_hour = attend.start_time.substring(0,2);
                        const start_min = attend.start_time.substring(2,4);
                        const start_am_pm = Number(start_hour >= 12) ? 'PM' : 'AM';

                        if( Number(start_hour) >= 13 ) start_hour = `${(Number(start_hour) - 12)}`;
                        if( Number(start_hour) == 0  ) start_hour = `12`;

                        this.start_am_pm = start_am_pm;
                        this.start_hour  = `0${String(start_hour)}`.slice(-2);
                        this.start_min   = start_min;
                    }

                    if( attend.end_time != null ) {
                        let end_hour = attend.end_time.substring(0,2);
                        const end_min = attend.end_time.substring(2,4);
                        const end_am_pm = Number(end_hour >= 12) ? 'PM' : 'AM';

                        if( Number(end_hour) >= 13 ) end_hour = `${(Number(end_hour) - 12)}`;
                        if( Number(end_hour) == 0  ) end_hour = `12`;

                        this.end_am_pm = end_am_pm;
                        this.end_hour  = `0${String(end_hour)}`.slice(-2);
                        this.end_min   = end_min;
                    }

                }
                else if( attend.attend_type == '20' ) {
                    this.vacations.push(attend);
                }
                else if( attend.attend_type == '30' ) {
                    this.businesstrip.push(attend);
                }
            }

            this.copy_attend = this.attend ? JSON.parse(JSON.stringify(this.attend)) : null;

            // 출근 기록이 없다면
            if( this.attend == null ) {
                if( this.isNormal() ) {
                    this.attend_state = '10';
                }
                else {
                    this.attend_state = '20';
                }
            }

        } catch(e) {
            throw e;
        }

    }

    /**
     * 시간 텍스트 가져오기
     */
    getTimeText(time_text : string) : string {
        if( time_text == null || time_text.length < 6 ) return '';

        const yyyymmdd = this.attendance_request_modal_info.yyyymmdd;

        const selected_date = new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));

        const time = [time_text.substring(0,2), time_text.substring(2,4), time_text.substring(4,6)].join(':');
        const target_date = moment(moment(selected_date).format('YYYY-MM-DD') + ` ${time}`).toDate();
        return this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(target_date, 'hh:mm a'));
    }

    /**
     * 휴가 이름 반환
     */
    getVacationName(type_id : number) : string {

        try {
            const target = this.vacation_types.filter(vacation => vacation.vacation_type_id == type_id);
            if( target.length > 0 ) return target[0].vacation_type_name;
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return '';
    }

    /**
     * 출장 타입 이름 반환
     */
    getBusinessTripName(type_id : number) : string {
        if( type_id == 1 ) return "종일";
        else if( type_id == 2 ) return "오전출장";
        else if( type_id == 3 ) return "오후출장";
        return '';
    }

    /**
     * 하루 중 얼마나 근무하는가를 반환
     */
    getWorkCnt() : number {

        let attend_daycount = this.attend ? Number(this.attend.daycount) : 1; // TODO work_type에 따라서 값이 바뀌어야함
        let vacation_daycount = 0;
        let businesstrip_daycount = 0;

        for( const vacation of this.vacations ) vacation_daycount += Number(vacation.daycount);
        for( const bt of this.businesstrip ) businesstrip_daycount += Number(bt.daycount);

        return (attend_daycount - (vacation_daycount + businesstrip_daycount) ) <= 0 ? 0 : (attend_daycount - (vacation_daycount + businesstrip_daycount));
    }

    /**
     * '정상' 상태인지 판단
     */
    isNormal() : boolean {

        // 오늘 날짜면 
        const current_date_string = this.attendance_request_modal_info.yyyymmdd;
        const today_date_string = moment().format('YYYYMMDD');

        // 미래 날짜는 무조건 false
        if( Number(current_date_string) > Number(today_date_string) ) {
            return false;
        }

        // 해당 일자의 work_cnt == 0 일때 (휴가 or 근무하는날 아님?) 무조건 정상
        let attend_daycount = this.attend ? Number(this.attend.daycount) : 1; // TODO work_type에 따라서 값이 바뀌어야함
        let vacation_daycount = 0;
        let businesstrip_daycount = 0;

        for( const vacation of this.vacations ) vacation_daycount += Number(vacation.daycount);
        for( const bt of this.businesstrip ) businesstrip_daycount += Number(bt.daycount);

        if( (attend_daycount - (vacation_daycount + businesstrip_daycount) ) <= 0 ) {
            return true;
        }

        // 오늘 날짜는 출근 시각만 찍혔어도 정상 취급
        // if( current_date_string == today_date_string && this.attend.attend_state == '20' ) {
        //     return true;
        // }

        if( this.attend == null ) {
            return false;
        }

        // 그 외 정상 상태인 경우 true, 아니라면 false
        return this.attend.attend_state == '10';
    }

    /**
     * start_time 수정 취소
     */
    cancelUpdateStartTime() : void {

        this.is_start_update_mode = false;

        // 수정 이전으로 변경
        if( this.attend.start_time != null ) {
            let start_hour = this.attend.start_time.substring(0,2);
            const start_min = this.attend.start_time.substring(2,4);
            const start_am_pm = Number(start_hour >= 12) ? 'PM' : 'AM';

            if( Number(start_hour) >= 13 ) start_hour = `${(Number(start_hour) - 12)}`;
            if( Number(start_hour) == 0  ) start_hour = `12`;

            this.start_am_pm = start_am_pm;
            this.start_hour  = start_hour;
            this.start_min   = start_min;
        }
        else {
            this.start_am_pm = "AM";
            this.start_hour  = "09";
            this.start_min   = "00";
        }
    }

    /**
     * end_time 수정 취소
     */
    cancelUpdateEndTime() : void {

        this.is_end_update_mode = false;

        // 수정 이전으로 변경
        if( this.attend.end_time != null ) {
            let end_hour = this.attend.end_time.substring(0,2);
            const end_min = this.attend.end_time.substring(2,4);
            const end_am_pm = Number(end_hour >= 12) ? 'PM' : 'AM';

            if( Number(end_hour) >= 13 ) end_hour = `${(Number(end_hour) - 12)}`;
            if( Number(end_hour) == 0  ) end_hour = `12`;

            this.end_am_pm = end_am_pm;
            this.end_hour  = end_hour;
            this.end_min   = end_min;
        }
        else {
            this.end_am_pm = "PM";
            this.end_hour  = "06";
            this.end_min   = "00";
        }

    }

    /**
     * 근무 안하는날 텍스트 반환 
     */
    getOffText() : string {

        if( this.vacations.length > 0 && this.businesstrip.length > 0 ) {
            return '휴가·출장';
        }

        if( this.vacations.length > 0 ) {
            return '휴가';
        }

        if( this.businesstrip.length > 0 ) {
            return '출장';
        }

        return '휴일';
    }

    /**
     * 휴가 삭제
     */
    deleteVacation(vacation) : void {
        this.vacations.splice(this.vacations.indexOf(vacation), 1);
        this.delete_vacation.push(vacation);
    }

    /**
     * 출장 삭제
     */
    deleteBusinessTrip(businesstrip) : void {
        this.businesstrip.splice(this.businesstrip.indexOf(businesstrip), 1);
        this.delete_businesstrip.push(businesstrip);
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_taget = $(event.target);
        const replace_image = require('@/assets/images/contents/ic_approval_user_on.png');
        jquery_taget.parent().find('p.img').css('background-image', `url(${replace_image})`);
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetAttendanceRequestModalInfo?.({ show_modal : false, user_id : 0, yyyymmdd : '' });
    }

    /**
     * 취소 처리 함수 (병렬처리용)
     */
    async cancelApproval(approval_uid : string) : Promise<void> {
        try {
            //! 1. 기안서 조회
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${approval_uid}`, API_METHOD.GET);
        
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("기안서 조회 중 오류 발생");
            }

            const approval = response.data.data.approval_info;

            //! 2. 기안서 취소
            approval.approval_state = approval_enum.APPROVAL_STATE.CANCEL;
            
            const response_update = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${approval_uid}?is_not_send_push=true`, API_METHOD.PUT, approval);

            console.log(response_update);

            if( !response_update || !this.isHttpStatusSuccess(response_update.status) ) {
                throw new Error("기안서 취소 중 오류 발생");
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 저장
     */
    async save() : Promise<void> {

        const attend : any = {
            group_id : this.scope_group_id,
            approval_uid : null,
            attend_state : this.attend ? this.attend.attend_state : "20",
            attend_type : 10,
            date_string : this.attendance_request_modal_info.yyyymmdd,

            // TODO 클라이언트에서 daycount 넣을때 현재 work_type에 맞도록 들어가야함
            daycount : this.attend ? this.attend.daycount : 1,

            start_time : this.attend ? this.attend.start_time : null,
            end_time : this.attend ? this.attend.end_time : null,
            type_id : this.attend ? this.attend.end_time : this.my_employee_info.work_type_id,
            user_id : this.my_employee_info.user_id,
        }

        attend.attend_state = this.attend_state;

        // 출근시간 수정 중
        if( this.is_start_update_mode == true ) {
            let start_hour = Number(this.start_hour);

            if( this.start_am_pm == 'PM' && start_hour < 12 ) {
                start_hour = start_hour + 12;
            }

            attend.start_time = `${`0${start_hour}`.slice(-2)}${this.start_min}00`;
        }

        // 퇴근시간 수정 중
        if( this.is_end_update_mode == true ) {
            let end_hour = Number(this.end_hour);

            if( this.end_am_pm == 'PM' && end_hour < 12 ) {
                end_hour = end_hour + 12;
            }

            attend.end_time = `${`0${end_hour}`.slice(-2)}${this.end_min}00`;
        }
        
        // (출근시간 미입력, 퇴근시간 입력)
        if( attend.start_time == null && attend.end_time != null ) {
            alert("출근시간을 입력해주세요");
            return;
        }

        // (출근시간이 퇴근시간 이후)
        if( attend.start_time && attend.end_time && Number(attend.start_time) > Number(attend.end_time) ) {
            alert("출근시간은 퇴근시간 이전 시간으로 입력되어야 합니다");
            return;
        }

        // deleted_vacation (휴가 취소) 
        const approval_promise : Promise<any>[] = [];
        for( const target of this.delete_vacation ) {
            approval_promise.push(this.cancelApproval(target.approval_uid));
        }

        // deleted_businesstrip (출장 취소)
        for( const target of this.delete_businesstrip ) {
            approval_promise.push(this.cancelApproval(target.approval_uid));
        }

        if( approval_promise.length > 0 ) {
            try {
                await this.hodu_show_indicator();
                await Promise.all(approval_promise);

                // 원래부터 attend 정보가 비어있는 null 이였다면
                if( this.attend == null && this.copy_attend == null ) {
                    this.attendance_request_modal_info.callback?.();
                    this.close();
                    return;
                }
                
            } catch(e) {
                this.hodu_error_process(e, false, false, true);
                alert("근태 정보 입력 중 오류 발생");
                await this.hodu_hide_indicator();
                return;
            } 
        }

        // insert attend_type 10
        if( this.attend == null ) {

            // 정상, 지각인데 시간정보가 안들어가는 경우
            if( (attend.attend_state == '10' || attend.attend_state == '30') && attend.start_time == null ) {
                alert("출근 시간 없이 새로운 출근 데이터를 추가 할 수 없습니다");
                return;
            }

            try {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/${this.attendance_request_modal_info.yyyymmdd}`, API_METHOD.POST, attend);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("근태 정보 입력 중 오류 발생");
                }

                this.attendance_request_modal_info.callback?.();
                this.close();

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
                alert("근태 정보 입력 중 오류 발생");
            }

        }
        
        // update attend_type 10
        else {

            // 기존 데이터 삭제
            if( attend.start_time == null ) {

                try {
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/${this.attendance_request_modal_info.user_id}/${this.attendance_request_modal_info.yyyymmdd}`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("근태현황 출·퇴근 현황 삭제 중 오류 발생");
                    }

                    this.attendance_request_modal_info.callback?.();
                    this.close();
                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    alert("근태현황 출·퇴근 현황 삭제 중 오류 발생");
                }

            }

            else {

                try {
                    // 기존 데이터 수정
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/${this.attendance_request_modal_info.yyyymmdd}`, API_METHOD.PUT, attend);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("근태 정보 입력 중 오류 발생");
                    }

                    this.attendance_request_modal_info.callback?.();
                    this.close();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    alert("근태 정보 입력 중 오류 발생");
                }

            }

        }
    }

}
</script>

<style scoped>

    .modal { position:absolute; left:50%; top:50%; margin-top:-243px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height:486px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); box-sizing: border-box;}
    .modal h1 { text-align: left; font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; width: 100%; border-bottom:1px solid #e7e9ea; box-sizing: border-box }
    .modal .detail { overflow:hidden; }

    .modal .context { float:left; border-left:1px solid #e7e9ea; width: 60%; height:324px; box-sizing: border-box; }
    
    .modal .btns { overflow: hidden; border-top:1px solid #e7e9ea;border-radius: 0 0 5px 5px; width: 100% }
    .modal .btns input {  line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold;; }
    .modal .btns input.save:hover { color:#477fff; }
    .modal .btns input:hover { background:#f1f3f5; }

    .modal textarea { transition:0.2s; border-color:#f1f3f5; resize: none; background:#f1f3f5; width: 100%; padding: 15px; min-height: 113px; max-height: 113px; font-size: 13px;line-height: 20px; padding: 15px; height:auto; box-sizing: border-box; font-weight:bold; }
    .modal textarea:hover { border-color:#477fff }
    .modal textarea:focus { background:#fff; border-color:#477fff;outline: none;  }
    
    #attendance_request_modal { width: 800px; margin-left: -400px; }
    #attendance_request_modal .titleBox { position: relative; text-align: center; }
    #attendance_request_modal h1 { display: inline-block; }
    #attendance_request_modal h1 .icon { float: left; margin: 34px 15px 0 0; display: inline-block;  width: 27px; height: 27px; border-radius: 50%;background-repeat: no-repeat; background-position: center center;  }

    #attendance_request_modal h1.approved:after { background-color: #477fff; }
    #attendance_request_modal h1.rejected:after { background-color: #ff6363 }
    #attendance_request_modal h1.ing .icon {background-color: #ffbc49;border: 1px solid #ffa70e;}
    #attendance_request_modal h1.rejected .icon {background-color: #ff6363;border: 1px solid #ff2e2e; background-image: url(../../../assets/images/contents/ic_close_fff.gif); background-size: 15px;}
    #attendance_request_modal h1.approved .icon {background-color: #477fff;border: 1px solid #0b55ff;background-image: url('../../../assets/images/contents/checked_fff.png');}
    #attendance_request_modal .btns.b1 input { width : 100% }

    #attendance_request_modal .context { overflow: hidden; float: left; width:60%;    overflow-y: auto; }
    #attendance_request_modal .leftContent { float:left; width: 40%; background:#f1f3f5; text-align: center; height: 324px; box-sizing: border-box; padding: 0 40px }
    #attendance_request_modal .leftContent p { width: 100%; font-weight: bold; line-height: 25px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
    #attendance_request_modal .leftContent p.name { font-size: 18px; margin: 16px 0 10px; }
    #attendance_request_modal .leftContent p.pos { margin-top:-8px; font-size: 14px; }
    #attendance_request_modal .leftContent p.team { padding-bottom: 10px; border-bottom: 1px solid #fff; margin-bottom: 10px; }
    #attendance_request_modal .leftContent p.email, #attendance_request_modal .leftContent p.tel {color:#989bab}
    #attendance_request_modal .leftContent .img { margin:0 auto; margin-top:29px; width: 90px; height: 90px; border-radius: 50%; background: url(../../../assets/images/contents/ic_approval_user_on.png) no-repeat center center; background-size: cover; box-shadow: 0 5px 10px rgba(0,0,0,0.1); border:7px solid #fff; font-size: 0; }
    #attendance_request_modal .detailUl { width: 100%; box-sizing: border-box; }
    #attendance_request_modal .detailUl li { transition: 0.2s; overflow: hidden; line-height: 53px; height: 53px; font-size: 14px; font-weight: bold; border-bottom: 1px solid #f1f3f5; box-sizing: border-box; padding: 0 30px; width:100%; }
    #attendance_request_modal .detailUl li .descript { float: right; font-weight: bold; width: 320px; font-size: 13px; text-align: right; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    #attendance_request_modal .detailUl li.reason {height: 156px; padding-right:0;}
    #attendance_request_modal .detailUl li.reason .descript {  background:transparent; padding-right: 30px !important; width: 100%; float: left; text-align: left; line-height: 20px; margin-top: -10px;overflow:auto; text-overflow: unset; white-space: normal; }
    #attendance_request_modal .detailUl li.reason .descript:disabled { border:0 none; padding: 0; color:#232848 }
    #attendance_request_modal .detailUl li.reason.disabled { font-size: 0; background:#f1f3f5;}
    #attendance_request_modal .detailUl li.reason.disabled  .descript:disabled { font-size: 0; background:transparent; color:#f1f3f5 !important }
    #attendance_request_modal .detailUl li:last-child { border-bottom: 0 none; }
    #attendance_request_modal .detailUl li * {  display: inline-block; }
    #attendance_request_modal .detailUl li:hover * {  }
    /* #attendance_request_modal .detailUl li:hover { background: #f7f9fb} */

    #attendance_request_modal .dropdown { display: none !important; width: 157px; height: auto !important; right: 30px;top: 192px;left: auto;}
    #attendance_request_modal .dropdown.on { display: block !important; } 
    #attendance_request_modal .dropdown ul { float: left; }
    #attendance_request_modal .dropdown ul li { transition: 0.1s; float: left; box-sizing: border-box; padding: 0 0 !important; height: 45px !important; line-height: 45px !important; }
    #attendance_request_modal .dropdown ul li:hover { background:#f1f3f5; }
    #attendance_request_modal .dropdown ul li a { display: inline-block; width: 100%; box-sizing: border-box; }

    .unchecked { cursor: pointer; }
    .unchecked span { background: #ffbc49;border: 1px solid #ffa70e;display: inline-block;width: 20px;height: 20px;line-height: 20px;border-radius: 50%;text-align: center;font-weight: bold;margin-right: 10px; }
    .ampm { margin-right: 10px; }
    select { cursor: pointer;-webkit-appearance: none; box-sizing: border-box;height: 51px; line-height: 51px; padding-left: 10px; -webkit-appearance: none; width: auto; text-align: left;font-weight: bold; height: 30px; line-height: 30px; }
    .timeLi select { padding: 0 2px; }
    select:focus { background: none; }

    #attendance_request_modal.notRejected .detailUl li { height: 62px; line-height: 62px; }

    img.dummy { display : none; }

    /* 휴가 출장 추가사항 */
    #attendance_request_modal .offLi, #attendance_request_modal .btLi { height: auto !important; line-height: initial !important; }
    #attendance_request_modal .offLi .title, #attendance_request_modal .btLi .title { line-height: 53px;; }
    #attendance_request_modal .offLi ul, #attendance_request_modal .btLi ul { width: 100%; box-sizing: border-box; padding-top: 10px; }
    #attendance_request_modal .offLi ul li, #attendance_request_modal .btLi ul li { display: block; border-bottom: 0 none; padding-right: 0; height: 35px; line-height: 35px; }
    #attendance_request_modal .offLi ul li:last-child, #attendance_request_modal .btLi ul li:last-child { margin-bottom: 4px;; }
     #attendance_request_modal .offLi ul li .nums, #attendance_request_modal .btLi ul li .nums { opacity: 0.5; font-size: 12px; }
    #attendance_request_modal .offLi ul li p, #attendance_request_modal .btLi ul li p { opacity: 0.5; }
    #attendance_request_modal .offLi ul li.withBtn p, #attendance_request_modal .btLi ul li.withBtn p { opacity: 1;; }
    .xBtn { transition:0.1s; display: inline-block;width: 22px;line-height: 22px; font-size: 12px;; height: 22px; font-size: 12px; background: #ff6363; color: #fff; border-radius: 50%; margin-left: 10px; }
    .xBtn:hover, .xBtn:hover {  background: #ff2e2e }

    /* 추가사항 */
    #attendance_request_modal .detailUl li:hover select { padding: 0 10px; border: 1px solid; border-radius: 5px; }

    #attendance_request_modal ::-webkit-scrollbar{width: 6px;}
    #attendance_request_modal ::-webkit-scrollbar-track{background-color: transparent;}
    #attendance_request_modal ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}
    
    #attendance_request_modal .timeLi .input_btn { display: none; margin-left : 10px; padding: 0 10px !important; }
    #attendance_request_modal .timeLi:hover .input_btn { display: inline-block; margin-left : 10px; padding: 0 10px !important; }

    #attendance_request_modal select.rtl { text-align-last: right; }
    #attendance_request_modal select.rtl option { direction: rtl; }
</style>