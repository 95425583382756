<template>
    <div class="cmmntModal modal remodal" id="cmmntModal" :class="{ image_preview : show_event_image_preview == true }" @dragover.prevent="fileDragOver($event)" @dragleave.prevent="fileDragLeave" @drop.prevent="fileDrop($event)">
        
        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />
        
        <button class="remodal-close" @click="close"><span></span><span></span></button>
    
        <div id="reply_title" class="md_titleBox">
            <h1 class="borderbot">댓글</h1>
            <!-- <div class="clearFix">
                <a href="#" class="editBtn">수정</a>
            </div> -->
        </div>

        <div class="controller">
            <p class="checkGroup"><input type="checkbox" id="cmmnt_0" name="" value="" class="cmmntCheck"><label for="cmmnt_0"></label></p>
            <p class="total"><span>10</span> / 20</p>
            <input type="button" class="delSelected" value="삭제" />
        </div>

        <div class="remodal-content  remodal-content03 remodal-content04">
            <div id="" class="modal-scroll">
                <div class="chats">
                    
                    <!-- 댓글 없을시 show -->
                    <p class="noResult" v-if="display_reply_list.length < 1" :style="{ display : display_reply_list.length < 1 ? 'block' : 'none' }">현재 댓글이 없습니다.</p>
                    
                    <div v-else>
                        
                        <!-- 날짜 정보 시작 -->
                        <div class="history" :key="dayInfo.reply_time_for_sort" v-for="(dayInfo, dayIndex) in display_reply_list">
                            <p class="when">{{ dayInfo.reply_date }}</p>

                            <!-- 댓글 ul 시작 -->
                            <ul>
                                <li :key="reply.event_reply_id" v-for="(reply, reply_index) in dayInfo.reply"
                                    :class="{ 
                                        me : reply.user_id == user_id, 
                                        other : reply.user_id != user_id,
                                        normal : reply.reply_data.contents.trim().length > 0,
                                        image : reply.reply_data.images != null && reply.reply_data.images.length == 1,
                                        images : reply.reply_data.images != null && reply.reply_data.images.length > 1,
                                        file : reply.reply_data.files != null && reply.reply_data.files.length >= 1
                                    }">
                                    
                                    <!-- 댓글 삭제용 체크 시작 -->
                                    <p class="checkGroup">
                                        <input type="checkbox" :id="`cmmnt_${reply_index}`" class="check">
                                        <label :for="`cmmnt_${reply_index}`"></label>
                                    </p>
                                    <!-- 댓글 삭제용 체크 끝 -->

                                    <!-- 댓글 정보 영역 시작 -->
                                    <div class="liGrp">
                                        
                                        <!-- 유저 이미지 -->
                                        <img :src="`/app_images/profile/user/${Math.floor(reply.user_id / 10000)}/${reply.user_id}.jpg`" alt="" @error="imageError($event)" />

                                        <!-- 댓글 이미지 1장 -->
                                        <div class="replyImageDiv" v-if="reply.reply_data.images != null && reply.reply_data.images.length == 1">
                                            <p class="replyImage" :style="{ 
                                                    backgroundImage : `url(/app_images/${reply.reply_data.images[0].url})`,
                                                    width : getSingleImageWidth(reply.reply_data.images[0]),
                                                    height : getSingleImageHeight(reply.reply_data.images[0]), 
                                                }" @click="showImageModal(reply.reply_data.images, 0, reply, dayIndex, reply_index, (reply.user_id == user_id))"/>
                                        </div>

                                        <!-- 댓글 이미지 2장 이상 -->
                                        <div class="replyImagesDiv" v-if="reply.reply_data.images != null && reply.reply_data.images.length > 1">
                                            <p :key="image.url" v-for="(image, index) in reply.reply_data.images" :class="getImageClassByIndex(reply.reply_data.images, index)"
                                               :style="{ backgroundImage : `url(/app_images/${image.url})` }" @click="showImageModal(reply.reply_data.images, index, reply, dayIndex, reply_index, (reply.user_id == user_id))" />
                                        </div>

                                        <!-- 파일 -->
                                        <div class="replyFilesDiv" v-else-if="reply.reply_data.files != null && reply.reply_data.files.length > 0">
                                            <p class="msg file format">
                                                
                                                <span class="msg-span" :key="index" v-for="(file, index) in reply.reply_data.files">
                                                    <a @click.prevent="download(file)" :title="file.name">{{ file.name.substr(file.name.lastIndexOf('.') + 1) }}</a>
                                                    <em>{{ file.name }}</em>
                                                    <em>{{ 
                                                        file.size >= 1 * 1024 * 1024 ? `${ (file.size / 1024 / 1024).toFixed(2) } MB` :
                                                        file.size >= 1 * 1024        ? `${ (file.size / 1024       ).toFixed(2) } KB` :
                                                                                        `${ file.size } Byte`         
                                                    }}</em>
                                                </span>

                                                <!-- 삭제버튼 -->
                                                <input type="button" class="delBtn"/>
                                            </p>
                                        </div>

                                        <!-- 내용 -->
                                        <p class="bubble" v-if="reply.reply_data.contents.trim().length > 0">
                                            <span v-html="hodu_make_href(reply.reply_data.contents)"></span>
                                            <input type="button" class="delBtn" @mouseover="hoverDelete($event)" @mouseleave="leaveDelete($event)" @click="deleteReply($event, reply, dayIndex, reply_index)"/>
                                        </p>

                                        <!-- 시간 및 작성자 -->
                                        <p class="whotime">
                                            <span class="time">{{ getTimeTextByDate(new Date(reply.audit_modified)) }}</span>
                                            <span class="whom">{{ reply.reply_data.user_name }}</span>
                                        </p>

                                        <p class="unread_count" v-if="Number(getUnreadCount(reply.idx)) > 0 && reply.user_id != user_id">{{ getUnreadCount(reply.idx) }}</p>
                                        <p class="unread_count" v-if="Number(getUnreadCount(reply.idx)) > 0 && reply.user_id == user_id">{{ getUnreadCount(reply.idx) }}</p>

                                    </div>
                                    <!-- 댓글 정보 영역 끝 -->

                                </li>
                            </ul>
                            <!-- 댓글 ul 끝 -->
                        </div>
                        <!-- 날짜 정보 끝 -->

                    </div>

                </div>
            </div>
        </div>
        <div id="reply_write_area" class="grp">
            <textarea placeholder="댓글을 작성해주세요 (shift + enter 개행)" class="txt" :value="reply_string" @input="reply_string = $event.target.value" @keyup="keyUpTextArea" @keydown="keyDownTextArea"></textarea>
            <input type="button" class="sendBtn" value="등록" @click="saveReply"/>
            <!-- 이미지 전송 홀딩 상태 진행해야하면 v-if false 제거 -->
            <input type="file" class="cdInput" id="replyImage" @change="addImages($event)" multiple="multiple" />
            <label for="replyImage" class="replyImageLabel" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const GroupInfo = namespace('GroupInfo');
const ModalInfo = namespace('ModalInfo');

import { t_event, t_event_reply, t_event_file } from '@/model/event';

import moment from 'moment';

import { ResizeObserver } from 'vue-resize';
import { GroupNoticeReplyModalInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {
        ResizeObserver
    },
}) export default class GroupNoticeReplyModal extends Mixins(VueHoduCommon) {
    
    /**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id !: number;

    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetEventImagePreviewInfo ?: any;

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_notice_reply_modal_info !: GroupNoticeReplyModalInfo;
    @ModalInfo.State show_event_image_preview !: boolean;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupNoticeReplyModalInfo ?: (params : GroupNoticeReplyModalInfo) => void;
    @ModalInfo.Action doSetShowEventImagePreview ?: any;

    reply_list : t_event_reply[] = [];
    read_count : number[] = [];
    display_reply_list : any[] = [];

    reply_string : string = "";

    isShift : boolean = false; // 쉬프트가 눌렸는지 여부
    
    // 이미지 1장 최대 너비, 높이
    image_max_width  : number = 300;
    image_max_height : number = 0;
    image_min_width  : number = 100;
    image_min_height : number = 100;

    get_reply_interval : number | undefined;

    async mounted() : Promise<void> {
        
        // 이미지뷰 1장 일때 max-height 설정
        const cmmntModal : HTMLElement | null = document.getElementById("cmmntModal");
        if( cmmntModal ) { this.image_max_height = Math.round(screen.height * 0.5); }

        // 최초 댓글 조회
        await Promise.all([this.getAllReply(), this.getAllReplyRead()]);
        
        // 2초마다 댓글 조회
        this.get_reply_interval = window.setInterval(async() => {
            Promise.all([this.getAllReply(), this.getAllReplyRead()]);
        }, 2000);

        this.setScroll();

        // @ts-ignore
        setTimeout(() => { $('.modal-scroll').mCustomScrollbar('scrollTo', 'bottom', { scrollInertia : 0 }); }, 1);
    }

    /**
     * 종료 전 interval 제거
     */
    beforeDestroy() : void {
        if( this.get_reply_interval ) { clearInterval(this.get_reply_interval); }
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        // @ts-ignore
        setTimeout(() => { $('.modal-scroll').mCustomScrollbar('scrollTo', 'bottom', { scrollInertia : 0 }); }, 1);

        const title_height : number | undefined = $('#reply_title').outerHeight();
        const write_height : number | undefined = $('#reply_write_area').outerHeight();
        // @ts-ignore
        $('.modal-scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 120,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - ( title_height == null ? 0 : title_height ) - ( write_height == null ? 0 : write_height )
        });
    }

    /**
     * 모든 댓글 불러오기
     */
    async getAllReply() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/reply/${this.group_notice_reply_modal_info.noti_uid}?isGroupNotiMode=true`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.event_reply ) {
                throw new Error("댓글 조회 실패");
            }

            this.reply_list.splice(0, this.reply_list.length);
            this.display_reply_list.splice(0, this.display_reply_list.length);
            this.reply_list = this.reply_list.concat(JSON.parse(JSON.stringify(response.data.data.event_reply)));

            // 댓글 날짜별 분류
            const reply_length : number = this.reply_list.length;
            for( let i = 0; i < reply_length; i++ ) {
                const reply : t_event_reply = this.reply_list[i];

                if( reply == null || reply.reply_data == null || reply.reply_data.contents == null || reply.audit_modified == null ) {
                    continue;
                }
                
                reply.reply_data.contents = reply.reply_data.contents.split('\n').join("<br>");
                
                // 비교용 데이터 생성 (display_reply_list에 없다면 새롭게 생성 해야함)
                const reply_time_for_sort : number = new Date(moment(this.reply_list[i].audit_modified).format('YYYY-MM-DD')).getTime();
                const reply_date          : string = `${ moment(this.reply_list[i].audit_modified).format('YYYY.MM.DD') } ${ this.getDayOfWeek(new Date(reply.audit_modified)) }`;

                // display_reply_list에 존재하는지 체크
                let index_of_reply_date : number = -1;
                const display_count : number = this.display_reply_list.length;
                for( let j = 0; j < display_count; j++ ) {
                    if( reply_time_for_sort == this.display_reply_list[j].reply_time_for_sort ) {
                        index_of_reply_date = j;
                        break;
                    }
                }

                // 해당 날짜의 정보가 없었다면 추가한다
                if( index_of_reply_date == -1 ) {
                    index_of_reply_date = this.display_reply_list.push({
                        "reply_time_for_sort" : reply_time_for_sort,
                        "reply_date"          : reply_date,
                        "reply"               : []
                    }) - 1;
                }

                // 추가
                this.display_reply_list[index_of_reply_date].reply.push(JSON.parse(JSON.stringify(reply)));
            }

        } catch(e) {
            alert("조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
            this.close();
        }
    }

    /**
     * 댓글 읽음 여부 조회
     */
    async getAllReplyRead() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/reply/${this.group_notice_reply_modal_info.noti_uid}/read?isGroupNotiMode=true`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.user_read ) {
                throw new Error("댓글 읽음 여부 조회 실패");
            }

            this.read_count.splice(0, this.read_count.length);

            for (const [key, value] of Object.entries(response.data.data.user_read)) {
                this.read_count.push(Number(value));
            }            

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 한 글자 요일 텍스트 가져오기 
     */
    getDayOfWeek(date : Date) : string {

        if( date == null ){
            return "";
        }

        switch( date.getDay() ){
            case 0:
                return "일";

            case 1:
                return "월";

            case 2:
                return "화";

            case 3:
                return "수";

            case 4:
                return "목";

            case 5:
                return "금";

            case 6:
                return "토";

            default:
                return "?";
        }
    }

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        event.target.src = require('@/assets/images/contents/im_photoB.gif');
    }

    /**
     * Date로 시간 text 만들기
     */
    getTimeTextByDate(reply_date : Date) : string {
        const current_date : Date = new Date();

        const time_millis : number = current_date.getTime() - reply_date.getTime();

        // 같은 날짜일때
        if( this.getDateDiff(reply_date, current_date) < 1 ) {
            
            // 시간 차이 1분 미만
            if( time_millis < 1 * 60 * 1000 ) {
                return "방금 전"
            }

            // 시간 차이 10분 전 미만
            if( time_millis < 10 * 60 * 1000 ) {
                return `${Math.floor(time_millis / 60 / 1000)}분 전`;
            }

            // 시간 차이 1시간 전 미만
            if( time_millis < 60 * 60 * 1000 ) {
                return `${Math.floor(time_millis / 60 / 10 / 1000)}0분 전`;
            }

            // 시간 차이 6시간 전 미만
            if( time_millis < 6 * 60 * 60 * 1000 ) {
                return `${Math.floor(time_millis / 60 / 60 / 1000)}시간 전`;
            }

        }

        // 같은 날짜의 6시간 전 보다 차이가 큰 날짜 && 다른 날의 날짜는 AM, PM 시간을 그대로 리턴
        const hour : string = `0${reply_date.getHours() == 0 ? '12' : reply_date.getHours() > 12 ? reply_date.getHours() - 12 : reply_date.getHours() }`.slice(-2);
        const min  : string = `0${reply_date.getMinutes()}`.slice(-2);
        const amPm : string = `${reply_date.getHours() < 12 ? '오전' : '오후' }`;
        return `${amPm} ${hour}:${min}`;
    }

    /**
     * 삭제 버튼에 mouseover
     */
    hoverDelete(event) : void {
        if( $(event.target).parent().parent().parent().hasClass('xHover') == true ) {
            return;
        }

        $(event.target).parent().parent().parent().addClass('xHover');
    }

    /**
     * 삭제 버튼에 mouseover
     */
    leaveDelete(event) : void {
        if( $(event.target).parent().parent().parent().hasClass('xHover') == false ) {
            return;
        }

        $(event.target).parent().parent().parent().removeClass('xHover');
    }

    /**
     * 댓글 삭제
     */
    async deleteReply(event, reply : t_event_reply, dayIndex : number, reply_index : number) : Promise<void> {

        const reply_uuid : string = reply.event_reply_id == null ? "" : reply.event_reply_id

        // 삭제 효과
        if( event ) { $(event.target).parent().parent().parent().addClass('delChat'); }
        setTimeout(() => this.display_reply_list[dayIndex].reply.splice(reply_index, 1), 200);

        // API 호출
        try {
            const response = await this.hodu_api_call(`api/v1/reply/${this.group_notice_reply_modal_info.noti_uid}?isGroupNotiMode=true`, API_METHOD.DELETE, { "reply_uuid" : reply_uuid }, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("댓글 삭제 실패");
            }

            this.group_notice_reply_modal_info.callback?.();
            await setTimeout(async() => { await Promise.all([this.getAllReply(), this.getAllReplyRead()]); }, 100);

        } catch(e) {
            alert("댓글 삭제 중 오류 발생");
            this.hodu_error_process(e, false, false, true);

            // 삭제 실패시 다시 원래대로
            await setTimeout(async() => {
                if( event ) { $(event.target).parent().parent().parent().removeClass('delChat'); }
                this.display_reply_list[dayIndex].reply.splice(reply_index, 0, reply); 
            } , 100);
        }
    }

    /**
     * shift + event 개행
     * enter         댓글 등록
     */
    keyUpTextArea(event) : void {
        if( event.keyCode == 16 ) { this.isShift = false; } 
    }

    /**
     * shift + event 개행
     * enter         댓글 등록
     */
    keyDownTextArea(event) : boolean {
        if( event.keyCode == 16 ) { this.isShift = true; } 
        if( event.keyCode == 13 && this.isShift == false ) { 
            this.saveReply(event);
            return false; 
        } 

        return true;
    }

    /**
     * 댓글 저장
     */
    async saveReply(event : any = null) : Promise<void> {

        if( event != null ) {
            event.stopPropagation();
        }
        
        // 아무것도 입력 안했다면 종료
        if( this.reply_string.trim().length < 1 ) {
            return;
        }

        try {
            const response = await this.hodu_api_call(`api/v1/reply/${this.group_notice_reply_modal_info.noti_uid}?isGroupNotiMode=true`, API_METHOD.POST, { 
                user_name : this.user_name,  
                contents : this.reply_string.trim()
            }, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("댓글 저장 실패");
            }

            this.group_notice_reply_modal_info.callback?.();
            await Promise.all([this.getAllReply(), this.getAllReplyRead()]);

            this.reply_string = "";

            // @ts-ignore
            this.$nextTick(() => { setTimeout(() => { $('.modal-scroll').mCustomScrollbar('scrollTo', 'bottom'); }, 1); });

        } catch(e) {
            alert("댓글 등록 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 댓글 모달 닫기
     */
    close() : void {
        this.doSetGroupNoticeReplyModalInfo?.({
            show_modal : false,
            noti_uid : ""
        });
    }

    /**
     * 파일을 드래그해서 이미지 영역에 올려놨을때
     */
    fileDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
    }

    /**
     * 파일을 드래그해서 이미지 영역에서 벗어났을때
     */
    fileDragLeave() : void {
        // DO NOTHING, 나중에 효과 생길때 사용
        // this.file_drag = false;
    }

    /**
     * 파일을 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    fileDrop(event) : void {
        this.addFileEvent(event.dataTransfer.files);
    }

    /**
     * 파일 추가
     */
    addImages(event :any) {
        this.addFileEvent(event.target.files);
    }

    /**
     * 댓글 파일 추가
     */
    async addFileEvent(files : File[]) : Promise<void> {
        const vue = this;

        const event_files : t_event_file[] = [];
        const resize_files : File[] = [];
        if( files.length == 0 ) { return; }

        // 이미지는 15장까지만 올릴 수 있다 
        if( files.length > 15 ) {
            if( files instanceof FileList ) {
                files = Array.prototype.slice.call(files, 0, 15);
            }

            else {
                alert(`파일은 한번에 15개까지 업로드 가능합니다`);
                $('#replyImage').val("");
                return;
            }
        }

        await this.hodu_show_indicator();
        const file_count : number = files.length;
        let end_count  : number = 0;
        for( let i = 0; i < file_count; i++ ) {

            const file = files[i];

            let max_size : number = this.is_premium_group('GROUP', this.group_id) ? this.DEFAULT_FILE_MAX_SIZE : this.NORMAL_GROUP_FILE_MAX_SIZE;
            let max_size_text : string = this.is_premium_group('GROUP', this.group_id) ? this.DEFAULT_FILE_MAX_SIZE_TEXT : this.NORMAL_GROUP_FILE_MAX_SIZE_TEXT;

            // 파일 용량 체크
            if( file.size > max_size ) {
                await this.hodu_hide_indicator();
                this.hodu_show_dialog("alert", `${max_size_text} 이하의 파일만 업로드 가능 합니다`, ['확인']);
                $('#replyImage').val("");
                return;
            }

            // 확장자가 없는 파일
            if( file.name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#replyImage').val("");
                await this.hodu_hide_indicator();
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(file.name.substring(file.name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ file.name.substring(file.name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#replyImage').val("");
                await this.hodu_hide_indicator();
                return;
            }

            // 이미지인지 파일인지 구분해서 이미지라면 리사이즈 한다
            if( new RegExp('image').test(file.type) == true ) {

                await this.fileReaderPromise(files[i])
                    .then(async(pe_fr : any) => {

                        if( pe_fr.target == null || pe_fr.target.result == null ){
                            return;
                        }

                        let base64url : string = "";

                        if( pe_fr.target.result instanceof ArrayBuffer ){
                            const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                            const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                            base64url = decodeURIComponent(url);
                        } else {
                            base64url = pe_fr.target.result;
                        }

                        const resize_data = await vue.hodu_image_resize_return_with_image_size(base64url);
                        const blob : Blob = resize_data.return_blob;

                        // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                        let resize_file : File = files[i];
                        try{
                            resize_file = await this.hodu_blob_to_file(blob, files[i].name);
                        }catch(e){
                            try {
                                (blob as any).lastModifiedDate = new Date();
                                (blob as any).name = files[i].name;
                                resize_file = (blob as any);
                            } catch(e) {
                                this.hodu_error_process(e, false, false, true);
                            }
                        }

                        event_files.push({
                            name: resize_file.name,            // 원본 파일명
                            mimeType: resize_file.type,        // MIME TYPE
                            url: base64url,                    // 파일 경로
                            size: resize_file.size,            // 파일 크기
                            date: new Date(),                  // 저장 날짜
                            image_info: resize_data.image_info // 이미지 정보
                        });

                        resize_files.push(resize_file);

                    })
                    .catch((e) => {
                        console.log('filereader promise error',e);
                    });
            }
            else {

                event_files.push({
                    name: file.name,     // 원본 파일명
                    mimeType: file.type, // MIME TYPE
                    url : "",            // 파일 경로
                    size: file.size,     // 파일 크기
                    date: new Date(),    // 저장 날짜
                });

                resize_files.push(file);
            }
        }

        // 임시 파일 업로드
        const form_data : FormData = new FormData();
            
        // FormData 내용 생성
        const image_files_count : number = resize_files.length;
        for( let i = 0; i < image_files_count; i++ ) {
            form_data.append('file', resize_files[i]);
        }

        await this.hodu_temp_upload(form_data)
            .then(async(response) => {
                console.log(response);
                const temp_files : t_event_file[] = response.data.data.temp_files;

                // 임시파일 데이터로 객체 대체하기
                const image_files_count : number = event_files.length;
                for( let i = 0; i < image_files_count; i++ ) {
                    const temp = temp_files[i];

                    if( new RegExp('image').test(event_files[i].mimeType) ) {
                        temp.image_info = event_files[i].image_info; 
                    }

                    event_files.splice(i, 1, temp);
                }

            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            })
            .finally(() => {
                $('#replyImage').val("");
            });

        // 일반 파일과 이미지가 겹치지 않도록 한다
        const img : t_event_file[] = event_files.filter(item => new RegExp('image').test(item.mimeType) == true);
        const file : t_event_file[] = event_files.filter(item => new RegExp('image').test(item.mimeType) == false);

        const calendar_id = this.team_id > 0 ? `team-${this.team_id}` : `group-${this.group_id}`;

        if( img.length > 0 ) {

            // 내용이 없는 댓글 등록
            try {

                const response = await this.hodu_api_call(`api/v1/reply/${this.group_notice_reply_modal_info.noti_uid}?calendar_id=${calendar_id}&isGroupNotiMode=true`, API_METHOD.POST, {
                    user_name : this.user_name, 
                    contents : "",
                    images : img
                }, false);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                    throw new Error("댓글 이미지 등록 실패");
                }

                this.group_notice_reply_modal_info.callback?.();
                await Promise.all([this.getAllReply(), this.getAllReplyRead()]);

                // @ts-ignore
                this.$nextTick(() => { setTimeout(() => { $('.modal-scroll').mCustomScrollbar('scrollTo', 'bottom'); }, 1); });

            } catch(e) {
                alert("댓글 이미지 등록 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
            }
        }

        if( file.length > 0 ) {
            
            try {
                // 내용이 없는 댓글 등록
                const response = await this.hodu_api_call(`api/v1/reply/${this.group_notice_reply_modal_info.noti_uid}?calendar_id=${calendar_id}&isGroupNotiMode=true`, API_METHOD.POST, {
                    user_name : this.user_name, 
                    contents : "",
                    files : file
                }, false);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                    throw new Error("댓글 파일 등록 실패");
                }

                this.group_notice_reply_modal_info.callback?.();
                await Promise.all([this.getAllReply(), this.getAllReplyRead()]);

                // @ts-ignore
                this.$nextTick(() => { setTimeout(() => { $('.modal-scroll').mCustomScrollbar('scrollTo', 'bottom'); }, 1); });

            } catch(e) {
                alert("댓글 파일 등록 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
            }
        }
        await this.hodu_hide_indicator();
    }

    /**
     * 파일리더 Promise
     */
    fileReaderPromise(file : File) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }
    
    /**
     * 이미지 다이얼로그 보이기
     */
    showImageModal(image_files : t_event_file[], image_index : number, reply, dayIndex, reply_index, is_me : boolean) : void {
        if( image_index == -1 || image_files.length <= image_index ){
            return;
        }

        if( is_me ) {
            this.doSetEventImagePreviewInfo({
                "selected_index" : image_index,
                "files" : image_files,
                "reply_delete_function": this.deleteReply,
                "js_event" : null,   
                "reply" : reply,
                "dayIndex" : dayIndex, 
                "reply_index" : reply_index
            });
        }

        else {
            this.doSetEventImagePreviewInfo({
                "selected_index" : image_index,
                "files" : image_files
            });
        }

        this.doSetShowEventImagePreview(true);
    }

    /**
     * 이미지 인덱스와 개수를 통해 무슨 클래스가 들어갈지 계산해서 보내준다
     */
    getImageClassByIndex(files : t_event_file[], index : number) : string {
        // 2랑 3은 고정
        if( files.length == 2 ) { return "two_in_row"; }
        if( files.length == 3 ) { return "three_in_row"; }

        // 3으로 나눈 나머지가 1이라면 끝에서 4개의 index는 two_in_row, 나머지는 three_in_row
        if( files.length % 3 == 1 ) {
            return index <= ( files.length - 1 ) - 4 ? "three_in_row" : "two_in_row";
        }
        
        // 3으로 나눈 나머지가 2인 경우 마지막 row 2개만 two_in_row, 나머지는 three_in_row
        if( files.length % 3 == 2 ) {
            return index <= ( files.length - 1 ) - 2 ? "three_in_row" : "two_in_row";
        }

        // 3으로 나눠 떨어지면 무조건 three_in_row
        return "three_in_row";
    }
    
    /**
     * width 구하기
     */
    getSingleImageWidth(image : t_event_file) : string {
        if( image == null || image.image_info == null ) { return "0"; }

        let width : number = 0;
        let height : number = 0;

        const original_width : number = image.image_info.width;
        const original_height : number = image.image_info.height;

        width = original_width;
        height = original_height;

        const image_raito : number = width / height;

        // 최소 너비보다 짧은 경우
        if( width < this.image_min_width ) {
            width = this.image_min_width;
            height = Math.round(this.image_min_width / image_raito);
        }

        // 최소 높이보다 낮은 경우
        if( height < this.image_min_height ) {
            width = Math.round(this.image_min_height * image_raito);
            height = this.image_min_height;
        }

        // 최대 너비보다 긴 경우
        if( width > this.image_max_width ) {
            width = this.image_max_width;
            height = Math.round(this.image_max_width / image_raito);
        }

        // 최대 높이보다 큰 경우
        if( height > this.image_max_height ) {
            width = Math.round(this.image_max_height * image_raito);
            height = this.image_max_height;
        }
        
        return `${width}px`;
    }

    /**
     * height 구하기
     */
    getSingleImageHeight(image : t_event_file) : string {
        if( image == null || image.image_info == null ) { return "0"; }

        let width : number = 0;
        let height : number = 0;

        const original_width : number = image.image_info.width;
        const original_height : number = image.image_info.height;

        width = original_width;
        height = original_height;

        const image_raito : number = width / height;

        // 최소 너비보다 짧은 경우
        if( width < this.image_min_width ) {
            width = this.image_min_width;
            height = Math.round(this.image_min_width / image_raito);
        }

        // 최소 높이보다 낮은 경우
        if( height < this.image_min_height ) {
            width = Math.round(this.image_min_height * image_raito);
            height = this.image_min_height;
        }

        // 최대 너비보다 긴 경우
        if( width > this.image_max_width ) {
            width = this.image_max_width;
            height = Math.round(this.image_max_width / image_raito);
        }

        // 최대 높이보다 큰 경우
        if( height > this.image_max_height ) {
            width = Math.round(this.image_max_height * image_raito);
            height = this.image_max_height;
        }

        return `${height}px`;
    }

    /**
     * 안 읽은 수 반환
     */
    getUnreadCount(index : number) : string {

        let count = 0;

        for( const num of this.read_count ) {
            if( num < index ) { count++; }
        }

        return `${count}`;
    }

    /**
     * 파일 다운로드
     */
    download(file : t_event_file) : void {
        this.hodu_download(`app_images/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('.modal-scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }
}
</script>

<style>
    .cmmntModal li.me a.hodu-link { color : #16da89 !important; }
    .cmmntModal li.other a.hodu-link { color : #477fff !important; }
</style>

<style scoped>
    .modal { position:absolute;z-index: 10000000000000000000000;background:#fff;right:0; }
    .cmmntModal input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 16px;height: 16px;border-radius:3px;border:2px solid #e5e8ea;background: #e5e8ea url('../assets/images/contents/checked_gray.png') no-repeat -1px -1px;background-size: 18px;font-size: 0;}
    .cmmntModal input[type='checkbox']:not(old):checked + label {background: #477fff url('../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;border: 2px solid #477fff;}
    .cmmntModal.remodal {height: 100%;float: right;border-radius: 0;width: 100%;max-width: 800px;margin-bottom: 0;}
    .cmmntModal.remodal .mCSB_inside > .mCSB_container { margin-right:0 }
    .cmmntModal.remodal .modal-scroll { padding-right:0; }
    .cmmntModal .chats {padding: 20px;}
    .cmmntModal.remodal .remodal-close {top: 10px;right: auto;left: -60px;}
    .cmmntModal.remodal .md_titleBox {position:absolute;top:0;left:0;width:100%;z-index: 100000;line-height: 61px;}
    .cmmntModal.remodal .md_titleBox h1 {padding: 0;font-size: 16px;text-align: left;padding-left: 20px;border-bottom: 2px solid #e6e5e5;}
    .cmmntModal .md_titleBox a {display:none;border: 1px solid #f1f3f5;border-radius: 5px;background: #fff;height: 35px;line-height: 35px;padding: 0 10px;font-weight: bold;margin-left: 10px;margin-top: 11px;}
    .cmmntModal.remodal .md_titleBox .clearFix {top: 0;}
    .cmmntModal.remodal .remodal-content {height: 100%;padding: 0;top: 0;margin: 0;padding-top: 62px;box-sizing: border-box;padding-bottom: 135px;}
    .chats .noResult {display:none; position: static;background: #f1f3f5;font-size: 13px;font-weight: bold;width: 100%;margin: 0 auto;border-radius: 5px;line-height: 70px;color: #9aa0b1;height: 70px;}
    .cmmntModal .grp {position: absolute;bottom: 0;width: 100%;background: #fff;border-top: 1px solid #f1f3f5;height: auto;padding-bottom:10px;padding: 10px 90px 30px 20px;box-sizing: border-box;}
    .cmmntModal .grp .txt {overflow-y: hidden;resize: none;border:0 none;padding-bottom: 15px;line-height:1.6;padding: 2.5em;padding-top: 15px;font-weight: bold;font-size:14px;width: 100%;padding-left: 15px;box-sizing: border-box;background: #f1f3f5;border-radius: 5px;}
    .cmmntModal .grp .sendBtn {position: absolute;right: 25px;top: 12px;width: 45px;background: #477fff url('../assets/images/contents/ic_send_on.png') no-repeat center center;height: 45px;border-radius: 50px;font-size:0;}
    .cmmntModal .grp .sendBtn:hover { background-color: #3471fd }
    .cmmntModal .grp:hover {box-shadow: 0 0px 10px rgba(0,0,0,0.1);transition: 0.1s;}
    .cmmntModal .when {margin: 10px 0 20px;padding: 10px 20px;border: 2px solid #f1f3f5;border-radius:20px;display:inline-block;background: #fff;font-weight: bold;}
    .cmmntModal .when:after {content: '';display: block;width: 100%;height: 2px;position: absolute;background: #f1f3f5;left: 0;z-index: -1;top: 28px;}
    .cmmntModal .history {position: relative;margin-bottom:30px;}
    .cmmntModal .history:hover .when { color:#477fff; border-color:#477fff; }
    .cmmntModal .chats {padding: 20px 30px;}
    .cmmntModal .liGrp { position:relative; overflow:hidden; height:100%; }
    .cmmntModal .liGrp img {width: 40px;border-radius: 50%;border: 1px solid #f1f3f5;display: inline-block;float: left;background-color:#f1f3f5;margin-top: 3px;}
    .cmmntModal .liGrp p {display: inline-block;float: left;}
    .cmmntModal .chats li {overflow: hidden;position: relative;margin-bottom:25px;transition:0.2s;}
    .cmmntModal .chats li.delChat { margin-right: -100%;}
    .cmmntModal .chats li.me .bubble {background: #477fff;color: #fff;margin-right: 20px;margin-left: 0;float: right;border-radius: 10px 0px 10px 10px; padding: 7px 11px;}
    .cmmntModal .chats li.me .bubble span { color:#fff; }
    .cmmntModal .chats li .bubble span { color:#283240;    line-height: 20px; word-break: break-all; }
    .cmmntModal .me .liGrp img {float: right;}
    .cmmntModal .me .liGrp p.whotime {right: 62px;text-align: right;left: auto;}
    .cmmntModal .me .liGrp p.whotime span { text-align: right; }
    .cmmntModal .liGrp .bubble {position: relative;padding: 11px 14px;background: #f1f3f5;font-weight: bold;border-radius: 0px 10px 10px 10px;margin-left: 20px;margin-top: 18px;max-width: 450px;text-align: left;line-height: 20px;}
    .cmmntModal .liGrp p.whotime {position: absolute;top: 0;left: 62px;opacity: 0.5;font-weight: bold;}
    .cmmntModal .liGrp p.whotime span {width: 80px;display: inline-block;text-align: left;}
    .cmmntModal .chats li:hover .whotime {color: #283240;opacity: 1;font-weight: bold;transition: 0.1s;}
    .cmmntModal .liGrp p.whotime span.whom { width:auto; }
    .cmmntModal .chats li .delBtn {display:none;position: absolute;width: 33px;height: 33px;border:1px solid #e6e5e5;background: #fff url(../assets/images/contents/ic_x_new.png) no-repeat center center;font-size: 0;border-radius: 50%;top: 0;right: -45px;padding: 0;background-size:20px 20px;}
    .cmmntModal .chats li .delBtn:hover { background-color:#f1f3f5; }
    .cmmntModal .chats li.me .delBtn {right:auto;left: -55px;}
    .cmmntModal .chats li.me:hover .delBtn { display:block; }
    .cmmntModal.writer .chats li:hover .delBtn { display:block; }
    .cmmntModal.edit.writer .chats li:hover .delBtn { display:none; }
    .cmmntModal.writer .md_titleBox .editBtn { display:block; }
    .cmmntModal .chats li.xHover .bubble:after {display:block;content:'';width:100%;height:100%;background:#fff;opacity:0.3;z-index: 100;position: absolute;top: 0;left: 0;}
    .cmmntModal.edit .controller { display:block; }
    .cmmntModal.edit .editBtn { background:#f1f3f5; }
    .cmmntModal input.delSelected {float: right;border: 1px solid #f1f3f5;border-radius: 5px;background: #fff;height: 35px;line-height: 35px;padding: 0 10px;font-weight: bold;margin-left: 10px;margin-top: 11px;}
    .cmmntModal input.delSelected:hover { background-color:#f1f3f5; }
    .cmmntModal .controller .total {font-size: 14px;font-weight: bold;}
    .cmmntModal .controller .total span { color:#477fff }
    .cmmntModal.edit .remodal-content {padding-top: 122px;padding-bottom:0;}
    .cmmntModal.edit .grp { display:none; }
    .cmmntModal.edit li { padding-left:35px }
    .cmmntModal.edit li .liGrp { opacity:0.6 }
    .cmmntModal.edit li:hover .liGrp { opacity:0.8; transition:0.1s;}
    .cmmntModal.edit li.checked .liGrp { opacity:1; }
    .cmmntModal.edit .chats li:hover .delBtn { display:none }
    .cmmntModal.edit li.me { padding-left:0; padding-right:35px; }
    .cmmntModal ul li p.checkGroup {position: absolute;left: 0;top: 12px; display:none;}
    .cmmntModal.edit ul li p.checkGroup {display:block;}
    .cmmntModal ul li.me p.checkGroup {left: auto;right: 0;}
    .cmmntModal .controller {padding:0 20px; text-align:left;box-sizing:border-box;}
    .cmmntModal .controller * {display: inline-block;}
    .cmmntModal .controller {display:none;box-shadow: 0 7px 13px rgba(0,0,0,0.1);height: 60px;line-height: 60px;top: 62px;background: #fff;position: absolute;left: 0;border-bottom: 1px solid #f1f3f5;z-index: 10000;width: 100%;}
    .cmmntModal .controller .checkGroup {padding: 21px 15px 0 0;float: left;}
    .cmmntModal .me .liGrp .whotime .whom { display : none; }

    .replyImageLabel { width: 45px; height: 45px; position: absolute; border: 2px solid #f1f3f5; top: 70px; right: 23px; border-radius: 10px; cursor: pointer; background: url("../assets/images/contents/ic_plus_new.png") no-repeat center center; }
    .cmmntModal .liGrp p img {cursor: pointer;border-radius: 5px !important;width: auto !important;max-width: 150px;display: inline-block !important;border: 1px solid #f1f3f5 !important;float: none !important;}
    .cmmntModal .liGrp .replyImages {display: block !important;float: none !important;}
    .cmmntModal .liGrp .replyImageDiv {display: block;margin-top: 20px;width: 100%;text-align: left;}
    .cmmntModal .me .liGrp .replyImageDiv {text-align: right;}
    .cmmntModal .liGrp .replyImage {display: block !important;float: none !important;padding-right: 0 !important;margin-left: 61px !important;box-sizing: border-box; background-position: center center; background-size: cover; border-radius: 12px; border: solid 1px #f1f3f5; cursor: pointer; }
    .cmmntModal .me .liGrp .replyImage {margin-right: 19px !important;margin-left: 10px !important;float:right !important;}
    .cmmntModal .me .liGrp > img {display: none;}
    .cmmntModal .me .liGrp p.whotime {right: 20px !important;}

    .cmmntModal .replyImagesDiv { width : 300px; height: auto; overflow: hidden; border-radius: 12px; margin-top: 20px; border: 1px solid #f1f3f5;}
    .cmmntModal .me .replyImagesDiv { float : right; margin-right : 20px; }
    .cmmntModal .replyImagesDiv p { float : left; background-size : cover; background-position: center center; box-sizing: border-box; border: solid 1px #f1f3f5; cursor : pointer;}
    .cmmntModal .replyImagesDiv .three_in_row { width: 100px; height: 100px; }
    .cmmntModal .replyImagesDiv .two_in_row { width: 150px; height: 150px;}

    .cmmntModal .replyFilesDiv { margin-top: 20px; width: 150px; text-align: left; } 
    .cmmntModal .me .replyFilesDiv { float : right; width : 179px; }    
    .cmmntModal .other > .liGrp > .replyFilesDiv { float: left !important; margin-left: 21px !important; }

    .cmmntModal .replyFilesDiv .msg span { background: #fff; text-align: left; display:inline-block; max-width: 150px; width: auto; box-sizing:border-box; padding: 9px 13px; font-size: 13px;font-weight: bold; line-height: 19px; }
    .cmmntModal .replyFilesDiv .msg span { border-radius: 15px 15px 15px 15px;border:1px solid #e7e9ea; }
    .cmmntModal .me .replyFilesDiv .msg span { background:#477fff; color:#fff; }
    
    .cmmntModal .me .replyFilesDiv .msg.file { margin-right: 19px !important; margin-left: 10px !important;}
    .cmmntModal .replyFilesDiv .msg.file span  { text-align: center;  position: relative; overflow: hidden; width:150px; margin : 0 3px; }
    .cmmntModal .replyFilesDiv .msg.file.vid span  {  position: relative; overflow: hidden; width:150px; }
    .cmmntModal .replyFilesDiv .msg.file a { width: 123px;height: 80px; line-height: 80px;text-align: center; font-size: 0; box-sizing: border-box;border: 1px solid #e7e9ea;padding: 0;display: block; border-radius: 10px; font-size: 0; margin-bottom:7px; }
    .cmmntModal .replyFilesDiv .msg.file.format a { transition:0.1s; font-size: 13px !important; color:#477fff; background:#fff; text-transform: uppercase; text-decoration: none; height: 60px; line-height: 60px; font-size:12px; }
    .cmmntModal .replyFilesDiv .msg.file.format a { background-color:#f1f3f5; }
    .cmmntModal .replyFilesDiv .msg.file.format a:hover {  background-color:#fff; }
    .cmmntModal .me .replyFilesDiv .msg.file.format a { background-color:#fff; }
    .cmmntModal .replyFilesDiv .msg.file a:after { transition:0.2s; opacity:0.15; content:''; display:block; position: absolute; left:0; top:0; width:100%; height: 100%; }
    .cmmntModal .replyFilesDiv .msg.file a:hover:after { opacity:0; }
    .cmmntModal .replyFilesDiv .msg.file.vid a { height: 70px; border-radius: 10px; background-size: cover; background-position: center center; background-repeat: no-repeat; }
    .cmmntModal .replyFilesDiv .msg.file.vid a { overflow: hidden; position:relative; top:-10px; left:-14px;margin-bottom:-3px; width: 150px; height: 90px; border-radius: 15px 15px 0 0;}
    .cmmntModal .replyFilesDiv .msg.file.vid a:after { opacity:0.25; background:#000; }
    .cmmntModal .replyFilesDiv .msg.file.vid a span { z-index: 10000; left:50%; margin-left: -22.5px; top:50%; margin-top:-22.5px; width: 45px; height: 45px; border-radius: 50%; position: absolute; border:3px solid #fff; display: block; background: url(../assets/images/contents/ic_playBtn.png) no-repeat center center; background-size:30px; font-size:0; box-sizing: border-box; box-shadow: 0 3px 3px rgba(0,0,0,0.3); }
    .cmmntModal .replyFilesDiv .msg.file.vid a:hover:after { opacity:0; }
    .cmmntModal .replyFilesDiv .msg.file em { display:block; font-style: normal; width:100%; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
    
    .cmmntModal.image_preview { z-index : 100 !important; }

    .unread_count { color : #477fff; font-weight: 700; }

    .other > .liGrp > .replyImageDiv > .replyImage { float: left !important; margin-left: 21px !important; }
    .other > .liGrp > .replyImagesDiv { float: left !important; margin-left: 21px !important; }
    .other > .liGrp > .unread_count { margin: 21px 0 0 5px; }

    .me > .liGrp > p { float:right; }
    .me > .liGrp > .unread_count { padding: 21px 3px 0 0px; }
    .me > .liGrp > .replyImagesDiv { margin-left: 10px; }
</style>