<template>
    <div id="organization_emp_off_setting" class="dis member_set_3" :class="{ dept_close : is_close_organization == true }">

        <div class="approval_content">
            <div class="filter_tap" id="dep4" :class="{ close : is_close_organization == true }">
                <div class="content_top">
                    <input type="button" class="mag_btn" :class="{ on : is_close_organization == true }" @click="is_close_organization = !is_close_organization">
                    <h3 style="margin-left: 60px;">직원 별 연차 설정</h3>
                    <!-- <input type="text" id="filter_search" placeholder="직원 이름 또는 직급으로 검색하세요." style="left:250px" :value="search_query" @input="search_query = $event.target.value"/> -->
                    <div class="daysLeft">
                        <input type="checkbox" id="is_denote_annual_count" class="checkbox" :checked="isDenoteAnnualCount() == true" @change="changeIsDenoteAnnualCount($event)" :disabled="get_group_role(scope_group_id) != 'GROUP_MANAGER' && get_group_role(scope_group_id) != 'ADMIN'"/>
                        <label for="is_denote_annual_count"><span>사용일수 보이기</span></label>
                    </div>

                    <input type="button" class="input_btn" value="연차 기준 설정" @click.prevent="showOrganizationDefaultEmpOffModal" v-if="get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN'"/>
                    
                    <!-- <div class="btnDiv"> -->
                        <!-- <input type="button" class="chooAllBtn" value="일괄설정" @click.prevent="addOrEditCheckedAnnual(false)" title="기간 + 연차수 일괄 수정" :disabled="existSelectedEmployee() == false" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')"/> -->
                        <!-- <input type="button" class="chooAllBtn" value="기간설정" @click.prevent="addOrEditCheckedAnnual(true)" title="기간 일괄 수정" :disabled="existSelectedEmployeeWithAnnual() == false" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')"/> -->
                        <!-- <input type="button" class="chooAllBtn" value="연차수설정" @click.prevent="editCheckedAnnualCount()" title="연차수 일괄 수정" :disabled="existSelectedEmployeeWithAnnual() == false" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')"/> -->
                        <!-- <input type="button" class="minusBtn" value="-" @click.prevent="editCheckedAnnualCount(-1)" title="연차수 일괄 -1" :disabled="existSelectedEmployeeWithAnnual() == false" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')"/> -->
                        <!-- <input type="button" class="plusBtn" value="+" @click.prevent="editCheckedAnnualCount(1)" title="연차수 일괄 +1" :disabled="existSelectedEmployeeWithAnnual() == false" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')"/> -->
                    <!-- </div> -->
                </div>

                <div class="wrapper" style="padding-top : 0;">

                    <div class="chk all" style="padding-top : 20px; border-bottom:1px solid #e7e9ea; background: #f2f5f7;">
                        <p style="top:10px; font-size:13px; font-weight:bold;">부서</p>
                    </div>

                    <div id="organization_setting_scroll" :style="{ height : `${department_scroll_height}px` }">

                        <div class="chk all" style="padding-top : 0; cursor:pointer;" :class="{ click_on : selected_department.dept_id == -1 }" @click="select({ dept_id : -1 })">
                            <p style="top:0px;">전체</p>
                        </div>
                        
                        <!-- 메인  -->
                        <!-- <div class="chk" style="cursor:pointer;" :class="{ click_on : computedOrganization != null && selected_department.dept_id == computedOrganization.dept_id }" @click="select(computedOrganization)">
                            <p>{{ computedOrganization != null ? computedOrganization.dept_name : '' }}</p>
                        </div> -->

                        <OrganizationRecursion 
                            :level="2"
                            :content_type="'p'" 
                            :departments="computedOrganization.departments"
                            :selected_department="selected_department"
                            :department_closed_map="department_closed_map"
                            :department_height_map="department_height_map" 
                            @select="select"
                            v-if="computedOrganization != null" />

                        <!-- 미배정 -->
                        <div class="chk" style="cursor:pointer;" :class="{ click_on : computedOrganization != null && selected_department.dept_id == computedOrganization.dept_id }" @click="select(computedOrganization)">
                            <p style="font-size: 13px; font-weight: bold; left: 60px; color: #477fff; opacity: .8;">미배정</p>
                        </div>


                    </div>
                </div>
                
                <div id="noticeScroll3" class="section_scroll">
                    <div class="viewGroup">
                        <div class="schedule_box"></div>
                        <div class="fixedDiv">
                            <ul class="sortHeader groupNoticeHeader">
                                <!-- <li class="checkbox" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                                    <input type="checkbox" id="hodu-employee-off-check-all" v-model="check_all" @change="changeCheckAll($event)"/>
                                    <label for="hodu-employee-off-check-all"></label>
                                </li> -->
                                <li class="num" :class="{ notPermitted : (get_group_role(scope_group_id) != 'GROUP_MANAGER' && get_group_role(scope_group_id) != 'ADMIN') }" style="padding-left: 20px;box-sizing: border-box;">No</li>
                                <li class="title4 headerSort"  :class="{
                                    sortOff  : sort_type != 'NAME',
                                    sortUp   : sort_type == 'NAME' && sort_direction == 'ASC', 
                                    sortDown : sort_type == 'NAME' && sort_direction == 'DESC', 
                                }" @click.prevent="sort(organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.NAME)" style="width:28%;">
                                    <span style="margin-left:23px;">이름</span>
                                </li>
                                <li class="group4 headerSort" :class="{
                                    sortOff  : sort_type != 'POSITION',
                                    sortUp   : sort_type == 'POSITION' && sort_direction == 'ASC', 
                                    sortDown : sort_type == 'POSITION' && sort_direction == 'DESC', 
                                }" @click.prevent="sort(organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.POSITION)" style="width:14%;">
                                    <span style="margin-left:20px;">직급</span>
                                </li>
                                <li class="writtenBy4 headerSort" style="width:20%;" :class="{
                                    sortOff  : sort_type != 'ANNUAL_DATE',
                                    sortUp   : sort_type == 'ANNUAL_DATE' && sort_direction == 'ASC', 
                                    sortDown : sort_type == 'ANNUAL_DATE' && sort_direction == 'DESC', 
                                }" @click.prevent="sort(organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.ANNUAL_DATE)">
                                    <span style="margin-left:20px;">기간</span>
                                </li>
                                <li class="lastday4 headerSort" style="width:23%;" :class="{
                                    sortOff  : sort_type != 'ANNUAL_COUNT',
                                    sortUp   : sort_type == 'ANNUAL_COUNT' && sort_direction == 'ASC', 
                                    sortDown : sort_type == 'ANNUAL_COUNT' && sort_direction == 'DESC', 
                                }" @click.prevent="sort(organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.ANNUAL_COUNT)">
                                    <span style="margin-left:20px;">연차수</span>
                                </li>
                            </ul>
                        </div>

                        <div id="organization_emp_scroll" class="content">
                            <ul class="notiListUl">
                                <li :key="employee.user_id" v-for="(employee, index) in computedEmployee">
                                    <a href="#" class="vactionOption" :class="{ not_manager : get_group_role(scope_group_id) != GroupAuth.GROUP_MANAGER && get_group_role(scope_group_id) != GroupAuth.ADMIN }" @click.prevent="showOffModal(employee.user_id)">설정</a>
                                    <a href="#" class="vacationListPage" :class="{ not_manager : get_group_role(scope_group_id) != GroupAuth.GROUP_MANAGER && get_group_role(scope_group_id) != GroupAuth.ADMIN }" @click.prevent="moveVacationList(employee)" style="display : inline-block; width : 100%; height : 100%;">
                                        <p class="num" :class="{ notPermitted : (get_group_role(scope_group_id) != 'GROUP_MANAGER' && get_group_role(scope_group_id) != 'ADMIN') }" style="padding-left: 20px;box-sizing: border-box;"><span>{{ index + 1 }}</span></p>
                                        <p class="title4" style="">
                                            <img :src="getProfileImage(employee)" @error="userImageError($event)">
                                            <span>{{ employee.user_name }}</span>
                                        </p>
                                        <p class="group4">{{ getPositionName(employee.pos_id) }}</p>
                                        <p class="writtenBy4">
                                            {{ (employee.annual_start == null || employee.annual_end == null || employee.annual_count == null) ? '-' : processDate(employee.annual_start, employee.annual_end) }}
                                        </p>
                                        <p class="lastday4">
                                            {{ (employee.annual_start == null || employee.annual_end == null || employee.annual_count == null) ? '-' : (isDenoteAnnualCount() == true ? employee.use_annual_count : processDayCount(employee.annual_count)) }}
                                        </p>
                                    </a>
                                </li>
                                <!-- <li>
                                    <a href="#" class="vactionOption">설정</a>
                                    <a href="#" class="vacationListPage" style="display : inline-block; width : 100%; height : 100%;">
                                        <p class="num"  style="padding-left: 20px;box-sizing: border-box;"><span >1</span></p>
                                        <p class="title4" style="">
                                            <img src="../../assets/images/contents/ic_approval_user_on.png">
                                            <span>김성림</span>
                                        </p>
                                        <p class="group4">부장</p>
                                        <p class="writtenBy4">20.09,01 ~ 21.09.01</p>
                                        <p class="lastday4">23</p>
                                    </a>
                                </li> -->
                                <!-- <li :key="employee.user_id" v-for="(employee, index) in computedEmployee">
                                    <a href="#" :class="{ not_manager : get_group_role(scope_group_id) != GroupAuth.GROUP_MANAGER && get_group_role(scope_group_id) != GroupAuth.ADMIN }" style="display : inline-block; width : 100%; height : 100%;" @click.prevent="showOffModal(employee.user_id)">
     
                                        <p class="num" :class="{ notPermitted : (get_group_role(scope_group_id) != 'GROUP_MANAGER' && get_group_role(scope_group_id) != 'ADMIN') }" style="padding-left: 20px;box-sizing: border-box;"><span>{{ index + 1 }}</span></p>
                                        <p class="title4" style="">
                                            <img :src="`app_images/profile/user/${Math.floor((employee.user_id ? employee.user_id : 0) / 10000)}/${employee.user_id}.jpg`" @error="userImageError($event)">
                                            <span>{{ employee.user_name }}</span>
                                        </p>
                                        <p class="group4">{{ getPositionName(employee.pos_id) }}</p>
                                        <p class="writtenBy4">
                                            {{ (employee.annual_start == null || employee.annual_end == null || employee.annual_count == null) ? '-' : processDate(employee.annual_start, employee.annual_end) }}
                                        </p>
                                        <p class="lastday4">
                                            {{ (employee.annual_start == null || employee.annual_end == null || employee.annual_count == null) ? '-' : (isDenoteAnnualCount() == true ? employee.use_annual_count : employee.annual_count) }}
                                        </p>
                                    </a>
                                </li> -->
                                <!-- <li>
                                    <p class="num"  style="padding-left: 20px;box-sizing: border-box;"><span >1</span></p>
                                    <p class="title4" style="">
                                        <img src="../../assets/images/contents/ic_approval_user_on.png">
                                        <span>김성림</span>
                                    </p>
                                    <p class="group4">부장</p>
                                    <p class="writtenBy4">20.09,01 ~ 21.09.01</p>
                                    <p class="lastday4">23</p>
                                    <a href="#" class="modi_look" style="">
                                        <img src="../../assets/images/contents/ic_modi.png" class="modi">
                                    </a>
                                </li>
                                <li>
                                    <p class="num"  style="padding-left: 20px;box-sizing: border-box;"><span >2</span></p>
                                    <p class="title4" style="">
                                        <img src="../../assets/images/contents/ic_approval_user_on.png" style="" alt="" >
                                        <span>최일준</span>
                                    </p>
                                    <p class="group4">차장</p>
                                    <p class="writtenBy4">20.09,01 ~ 21.09.01</p>
                                    <p class="lastday4">15</p>
                                    <a href="#" class="modi_look" style="">
                                        <img src="../../assets/images/contents/ic_modi.png" class="modi">
                                    </a>
                                </li>
                                <li>
                                    <p class="num"  style="padding-left: 20px;box-sizing: border-box;"><span >3</span></p>
                                    <p class="title4" style="">
                                        <img src="../../assets/images/contents/ic_approval_user_on.png" style="" alt="" >
                                        <span>심왕용</span>
                                    </p>
                                    <p class="group4">과장</p>
                                    <p class="writtenBy4"><a href="#" style="font-weight:bold;color:rgb(162,162,162);">추가</a></p>
                                    <p class="lastday4"><a href="#" style="font-weight:bold;color:rgb(162,162,162);">추가</a></p>
                                    <a href="#" class="modi_look" style="">
                                        <img src="../../assets/images/contents/ic_modi.png" class="modi">
                                    </a>
                                </li>
                                <li>
                                    <p class="num"  style="padding-left: 20px;box-sizing: border-box;"><span >4</span></p>
                                    <p class="title4" style="">
                                        <img src="../../assets/images/contents/ic_approval_user_on.png" style="" alt="" >
                                        <span>Sarah Paulson</span>
                                    </p>
                                    <p class="group4">Senior Manager</p>
                                    <p class="writtenBy4">20.09,01 ~ 21.09.01</p>
                                    <p class="lastday4">15</p>
                                    <a href="#" class="modi_look" style="">
                                        <img src="../../assets/images/contents/ic_modi.png" class="modi">
                                    </a>
                                </li>
                                <li>
                                    <p class="num"  style="padding-left: 20px;box-sizing: border-box;"><span >5</span></p>
                                    <p class="title4" style="">
                                        <img src="../../assets/images/contents/ic_approval_user_on.png" style="" alt="" >
                                        <span>박기태</span>
                                    </p>
                                    <p class="group4">대리</p>
                                    <p class="writtenBy4">20.09,01 ~ 21.09.01</p>
                                    <p class="lastday4">15</p>
                                    <a href="#" class="modi_look" style="">
                                        <img src="../../assets/images/contents/ic_modi.png" class="modi">
                                    </a>
                                </li>
                                <li>
                                    <p class="num"  style="padding-left: 20px;box-sizing: border-box;"><span >6</span></p>
                                    <p class="title4" style="">
                                        <img src="../../assets/images/contents/ic_approval_user_on.png" style="" alt="" >
                                        <span>김민지</span>
                                    </p>
                                    <p class="group4">대리</p>
                                    <p class="writtenBy4">20.09,01 ~ 21.09.01</p>
                                    <p class="lastday4">15</p>
                                    <a href="#" class="modi_look" style="">
                                        <img src="../../assets/images/contents/ic_modi.png" class="modi">
                                    </a>
                                </li> -->
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const OrganizationInfo = namespace('OrganizationInfo');

import OrganizationRecursion from '@/components/organization/OrganizationRecursion.vue';

import { organization_enum, organization_modal_interface } from '@/model/organization';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        OrganizationRecursion
    },
})
export default class OrganizationEmpOffSetting extends Mixins(VueHoduCommon) {

    organization_enum : any = organization_enum;

    @Prop() event_bus !: Vue;

    /**
     * 부서 정보
     */
    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) {
                department['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }

    /**
     * 직원 정보
     */
    get computedEmployee() : any[] {
        let employees = JSON.parse(JSON.stringify(this.employees));

        const today_yyyymmdd = this.hodu_date_to_format_string(new Date(), 'YYYYMMDD');
        for( const employee of employees ) {
            
            // 오늘이 현재 설정된 연차 기간에 포함된다면 넘어감
            if( employee.annual_start <= today_yyyymmdd && today_yyyymmdd <= employee.annual_end ) {
                continue;
            }

            // 오늘이 현재 설정된 연차 기간에 포함되는것을 찾고 해당 기간을 보여주도록 바꿈
            if( employee.past_emp_info != null ) {
                for( const past_annual of employee.past_emp_info ) {
                    if( past_annual.annual_start <= today_yyyymmdd && today_yyyymmdd <= past_annual.annual_end ) {
                        employee.annual_start = past_annual.annual_start;
                        employee.annual_end = past_annual.annual_end;
                        employee.annual_count = past_annual.annual_count;
                        employee.use_annual_count = past_annual.use_annual_count;
                        employee.contents.before_annual_count = past_annual.contents.before_annual_count;
                        break;
                    }
                }
            }
            
        }

        employees.sort((o1, o2) => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            const o1_pos_seq = this.getPositionSeq(o1.pos_id);
            const o2_pos_seq = this.getPositionSeq(o2.pos_id);

            if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.NAME ) {

                // 같은 이름은 직급순
                if( o1_name == o2_name ) {
                    if( o1_pos_seq == o2_pos_seq ) return 0;
                    else if( o1_pos_seq > o2_pos_seq ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
                    else if( o1_pos_seq < o2_pos_seq ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                }
                else if( o1_name > o2_name ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_name < o2_name ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.POSITION ) {
                
                // 같은 직급은 이름순
                if( o1_pos_seq == o2_pos_seq ) {
                    if( o1_name > o2_name ) return 1;
                    else if( o1_name < o2_name ) return -1;
                    return 0
                }
                else if( o1_pos_seq > o2_pos_seq ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
                else if( o1_pos_seq < o2_pos_seq ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.ANNUAL_DATE ) {
                const o1_annual_start = o1.annual_start ? new Date([o1.annual_start.substring(0,4), o1.annual_start.substring(4,6), o1.annual_start.substring(6,8)].join('-')).getTime() : new Date(0).getTime();
                const o2_annual_start = o2.annual_start ? new Date([o2.annual_start.substring(0,4), o2.annual_start.substring(4,6), o2.annual_start.substring(6,8)].join('-')).getTime() : new Date(0).getTime();

                // 같은 연차기간은 직급순
                if( o1_annual_start == o2_annual_start ) {

                    // 같은 직급은 이름순
                    if( o1_pos_seq == o2_pos_seq ) {
                        if( o1_name > o2_name ) return 1;
                        else if( o1_name < o2_name ) return -1;
                        return 0
                    }
                    else if( o1_pos_seq > o2_pos_seq ) return  1;
                    else if( o1_pos_seq < o2_pos_seq ) return -1;
                    return 0;
                }
                else if( o1_annual_start > o2_annual_start ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_annual_start < o2_annual_start ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.ANNUAL_COUNT ) {
                const o1_annual_count = o1.annual_count ? o1.annual_count : -1;
                const o2_annual_count = o2.annual_count ? o2.annual_count : -1;

                // 같은 연차수는 직급순
                if( o1_annual_count == o2_annual_count ) {

                    // 같은 직급은 이름순
                    if( o1_pos_seq == o2_pos_seq ) {
                        if( o1_name > o2_name ) return 1;
                        else if( o1_name < o2_name ) return -1;
                        return 0
                    }
                    else if( o1_pos_seq > o2_pos_seq ) return  1;
                    else if( o1_pos_seq < o2_pos_seq ) return -1;
                    return 0;
                }
                else if( o1_annual_count > o2_annual_count ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_annual_count < o2_annual_count ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            return 0;
        });

        // 검색
        if( this.search_query != null && this.search_query.length > 0 ) {
            employees = employees.filter(item => // 이름   
                                                 this.hodu_string_includes(item.user_name, this.search_query) ||

                                                 // 직급
                                                 this.hodu_string_includes(this.getPositionName(item.pos_id), this.search_query));
        }

        return employees;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationOffModalInfo ?: (params : organization_modal_interface.OrganizationOffModalInfo) => void;
    @ModalInfo.Action doSetOrganizationDefaultEmpOffModalInfo ?: (params : organization_modal_interface.OrganizationDefaultEmpOffModalInfo) => void;

    /**
     * @OrganizationInfo.Action
     */
    @OrganizationInfo.Action doSetOrganizationSelectedVacationTab ?: (params : organization_enum.ORGANIZATION_VACATION_TAB) => void;

    departments : any[] = [];
    selected_department : any = { dept_id : -1 };

    department_closed_map : Map<number, boolean> = new Map();
    department_height_map : Map<number, number> = new Map();

    position : any[] = [];
    employees : any[] = [];
    search_query : string = "";

    sort_type : organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE = organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.POSITION;
    sort_direction : SORT_TYPE = SORT_TYPE.DESC;

    department_scroll_height : number = 0;

    is_close_organization : boolean = false;

    check_all : boolean = false;
    checked_user_ids : number[] = [];
    
    async mounted() : Promise<void> {
        this.event_bus?.$on("OrganizationEmpOffSettingResize", this.handleResize);
        
        await this.getTotalOrganizationData();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {
            // 조직도 스크롤
            const title_box_height = $('#organization_setting .title_box').outerHeight();
            const title_box2_height = $('#organization_setting .title_box2').outerHeight();
            const all_height = $('#organization_setting .approval_content .wrapper .chk.all').outerHeight();
            const content_top_height = $('#organization_setting .approval_content .content_top').outerHeight();

            this.department_scroll_height = window.innerHeight - (title_box_height ? title_box_height : 0)
                                                            - (title_box2_height ? title_box2_height : 0)
                                                            - (content_top_height ? content_top_height : 0)
                                                            - (all_height ? all_height : 0);

            // 부서원 리스트 스크롤
            const fixed_div_height = $('#organization_setting .approval_content .section_scroll .fixedDiv').outerHeight();

            // @ts-ignore
            $('#organization_emp_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (title_box2_height ? title_box2_height : 0)
                                            - (content_top_height ? content_top_height : 0)
                                            - (fixed_div_height ? fixed_div_height : 0)
            });
        });
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganizationPosition();
            await this.getOrganization();
            await this.getOrganizationEmp();
        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            // map에 담겨있는 vertical_height 정보로 업데이트
            for( const department of response.data.data.dept_info ) {
                const vertical_height = this.department_height_map.get(department.dept_id);
                if( vertical_height != null ) {
                    department.vertical_height = vertical_height;
                }
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            if( this.selected_department == null || this.selected_department.dept_id == -1 ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                    throw new Error("조직도 전체 직원 조회 중 오류 발생");
                }

                this.employees.splice(0, this.employees.length);
                this.employees = this.employees.concat(response.data.data.emp_info);
                return;
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/${this.selected_department.dept_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 부서 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 부서 선택
     */
    async select(department : any) : Promise<void> {
        this.selected_department = department;
        this.$forceUpdate();

        this.checked_user_ids.splice(0, this.checked_user_ids.length);
        this.check_all = false;

        this.hodu_show_indicator();
        await this.getTotalOrganizationData();
        this.hodu_hide_indicator();
    }

    /**
     * 부서 이름 반환
     */
    getDepartmentName(dept_id : number) : string {
        try {
            const target = this.departments.filter(item => item.dept_id == dept_id);
            if( target.length < 1 ) return "-";
            if( target[0].dept_id == 0 ) return "미배정";
            return target[0].dept_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 이름 반환
     */
    getPositionName(pos_id : number) : string {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return "-";
            return target[0].pos_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 날짜 가공
     */
    processDate(start : string, end : string) : string {
        if( start == null || end == null ) return '';

        const start_date_string = ([start.substring(0, 4), start.substring(4, 6), start.substring(6, 8)] as string[]).join('-');
        const end_date_string = ([end.substring(0, 4), end.substring(4, 6), end.substring(6, 8)] as string[]).join('-');
        
        const start_string = moment(start_date_string).format('YY.MM.DD');
        const end_string = moment(end_date_string).format('YY.MM.DD');

        return `${start_string} ~ ${end_string}`;
    }

    /**
     * 연차 설정 추가
     */
    // addAnnual(user_id : number) : void {
    //     this.doSetOrganizationOffModalInfo?.({
    //         show_modal : true,
    //         // is_create : true,
    //         user_id : user_id,
    //         // user_ids : [],
    //         // is_only_setting_term : false,
    //         callback : this.getTotalOrganizationData
    //     });
    // }

    /**
     * 연차 설정 변경
     */
    // editAnnual(user_id : number) : void {
    //     this.doSetOrganizationOffModalInfo?.({
    //         show_modal : true,
    //         // is_create : false,
    //         user_id : user_id,
    //         // user_ids : [],
    //         // is_only_setting_term : false,
    //         callback : this.getTotalOrganizationData
    //     });
    // }

    /**
     * 연차 일괄 설정
     */
    // addOrEditCheckedAnnual(is_only_setting_term : boolean) : void {
    //     this.doSetOrganizationOffModalInfo?.({
    //         show_modal : true,
    //         // is_create : false,
    //         user_id : 0,
    //         // user_ids : this.checked_user_ids,
    //         // is_only_setting_term : is_only_setting_term,
    //         callback : this.getTotalOrganizationData
    //     });
    // }

    showOffModal(user_id : number) : void {

        if( this.get_group_role(this.scope_group_id) != this.GroupAuth.GROUP_MANAGER && this.get_group_role(this.scope_group_id) != this.GroupAuth.ADMIN ) return;

        this.doSetOrganizationOffModalInfo?.({
            show_modal : true,
            user_id : user_id,
            callback : this.getTotalOrganizationData
        });
    }

    moveVacationList(emp) : void {
        if( this.get_group_role(this.scope_group_id) != this.GroupAuth.GROUP_MANAGER && this.get_group_role(this.scope_group_id) != this.GroupAuth.ADMIN ) return;

        this.doSetOrganizationSelectedVacationTab?.(organization_enum.ORGANIZATION_VACATION_TAB.ADMIN);
        this.hodu_router_push(`/GROUP/${this.scope_group_id}/organization/vacation?user_id=${emp.user_id}&annual_start=${emp.annual_start}&annual_end=${emp.annual_end}`);
    }

    /**
     * 연차수 일괄 조절
     */
    async editCheckedAnnualCount(dir : number) : Promise<void> {
        
        if( this.checked_user_ids.length < 1 ) return;

        if( dir == null ) {
            this.doSetCommonInputModalInfo?.({
                show_modal : true,
                title : "연차수 입력",
                subtitle : "연차수",
                placeholder : "연차수를 입력해주세요",
                save : async(annual_count) => {
                    try {
                        
                        if( new RegExp(/[0-9]{1,}/).test(annual_count) == false || new RegExp(/\.+/).test(annual_count) == true || new RegExp(/-+/).test(annual_count) == true ) {
                            this.hodu_show_dialog("cancel", "연차수에는 정수인 숫자만 입력해주세요", ['확인']);
                            return;
                        }

                        // 체크 된 employee 정보 들고오기
                        const target_emp = this.computedEmployee.filter(emp => this.checked_user_ids.indexOf(emp.user_id) >= 0);

                        if( target_emp.length < 1 ) return;

                        // Promise 제작
                        const promise : Promise<void>[] = [];
                        for( const emp of target_emp ) {
                            if( emp.annual_count == null ) continue;
                            promise.push(this.modifyEmployeePromise(emp, Number(annual_count) - Number(emp.annual_count)));
                        }

                        // 병렬처리 & 재조회
                        this.hodu_show_indicator();
                        await Promise.all(promise);
                        await this.getTotalOrganizationData();
                        this.hodu_hide_indicator();

                    } catch(e) {
                        this.hodu_show_dialog("cancel", "분류항목 추가 중 오류 발생", ['확인']);
                        this.hodu_error_process(e, false, false, true);
                    }
                }
            });
            return;
        }
        
        // 체크 된 employee 정보 들고오기
        const target_emp = this.computedEmployee.filter(emp => this.checked_user_ids.indexOf(emp.user_id) >= 0);

        if( target_emp.length < 1 ) return;

        // Promise 제작
        const promise : Promise<void>[] = [];
        for( const emp of target_emp ) {
            if( emp.annual_count == null ) continue;
            promise.push(this.modifyEmployeePromise(emp, dir));
        }

        // 병렬처리 & 재조회
        this.hodu_show_indicator();
        await Promise.all(promise);
        await this.getTotalOrganizationData();
        this.hodu_hide_indicator();
    }

    /**
     * 직원 변경 Promise
     */
    async modifyEmployeePromise(employee, dir : number, is_promise : boolean = true) : Promise<void> {
        try {

            // API 실행
            const data = JSON.parse(JSON.stringify(employee));
            data.annual_count = Number(data.annual_count) + dir; 
            if( data.annual_count < 0 ) data.annual_count = 0;

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp?is_annual=true&is_update=true`, API_METHOD.POST, data);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("조직도 직원 연차 설정 중 오류 발생");
            }

            if( is_promise == false ) {
                this.hodu_show_indicator();
                await this.getTotalOrganizationData();
                this.hodu_hide_indicator();
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            if( is_promise == false ) this.hodu_show_dialog("cancel", "조직도 직원 연차 설정 중 오류 발생", ['확인']);
        }
    }

    /**
     * 체크 변경
     */
    changeCheck(event, employee) : void {
        let checked : boolean = event.target.checked;

        const index = this.checked_user_ids.indexOf(employee.user_id);

        if( checked == true && index < 0 ) {
            this.checked_user_ids.push(employee.user_id);
        }
        else if( checked == false && index >= 0 ) {
            this.checked_user_ids.splice(index, 1);
        }

        this.check_all = (this.checked_user_ids.length >= this.computedEmployee.length);
    }

    /**
     * 전체 체크 변경
     */
    changeCheckAll(event) : void {
        let checked : boolean = event.target.checked;

        this.checked_user_ids.splice(0, this.checked_user_ids.length);
        if( checked == true ) {
            for( const emp of this.computedEmployee ) {
                this.checked_user_ids.push(emp.user_id);
            }
        }
    }

    /**
     * 정렬
     */
    sort(sort_type : organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_direction = SORT_TYPE.ASC;
            return;
        }

        this.sort_direction = (this.sort_direction == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 이미지 에러
     */
    userImageError(event) : void {
        event.target.src = require('@/assets/images/contents/ic_approval_user_on.png');
    }

    /**
     * 직원이 선택 되어 있는지 여부
     */
    existSelectedEmployee() : boolean {
        return this.computedEmployee.filter(employee => this.checked_user_ids.indexOf(employee.user_id) > -1).length > 0;
    }

    /**
     * 설정 된 연차가 있는 직원이 선택 되어 있는지 여부
     */
    existSelectedEmployeeWithAnnual() : boolean {
        return this.checked_user_ids.length > 0 && this.computedEmployee.filter(employee => this.checked_user_ids.indexOf(employee.user_id) > -1 && employee.annual_count != null && employee.annual_start != null && employee.annual_end != null).length == this.checked_user_ids.length;
    }

    /**
     * 작성시 연차수 표시여부
     */
    isDenoteAnnualCount() : boolean {
        const feature = this.get_group_feature(this.scope_group_id);
        return feature != null ? feature['approval']['is_denote_annual_count'] : false;
    }

    /**
     * 작성시 연차수 표시여부 설정 
     */
    async changeIsDenoteAnnualCount(event) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/features/approval?key=is_denote_annual_count&feature_flag=${event.target.checked}`, API_METHOD.PUT)

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("연차 수 표시 여부 변경 중 오류 발생");
            }

        }
        catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', '연차 수 표시 여부 변경 중 오류 발생', ['확인']);
        } finally {
            this.get_group_role_service();
        }

    } 

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;

        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    showOrganizationDefaultEmpOffModal() {
        this.doSetOrganizationDefaultEmpOffModalInfo?.({
            show_modal : true,
            group_id : this.scope_group_id,
            callback : async() => {
                await this.hodu_show_indicator();
                await this.getTotalOrganizationData();
                await this.hodu_hide_indicator();
            }
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#organization_emp_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style>
    #organization_emp_off_setting .click_on { z-index: 200;background: rgb(237, 242, 255, 0.8); }
</style>

<style scoped>
    .member_set_3 .viewGroup .num{width: 15%;text-align: center;font-weight: bold;max-width: 130px;}
    #organization_emp_off_setting #noticeScroll,#noticeScroll2{position: absolute; top: 0px; left: 15%; right: 0; border-right: 1px solid #f1f3f5;}
    #container #section_ce .section_scroll { min-width: 70%; left : 15%; }
    #organization_setting .approval_content {
        opacity: 1;
        -webkit-transition: .1s;
        transition: .1s;
        position: relative;
        height: 100%;
        padding: 0 0 50px 0;
        /* border-right: 1px solid #e1e4e6; */
        z-index: 100;
    }

    @media screen and (max-width: 1655px) {
        #container #section_ce .section_scroll { max-width: 85%; left : 15%;}
    }
    
    @media screen and (max-width: 1400px) {
        #organization_setting .approval_content {
            opacity: 1;
            -webkit-transition: .1s;
            transition: .1s;
            position: relative;
            height: 100%;
            padding: 0 0 50px 0;
            /* border-right: 1px solid #e1e4e6; */
            z-index: 100;

        }
    }

    #organization_emp_off_setting .mag_btn { position: absolute; margin-left: 20px;padding: 11px 0 0 30px;display: inline-block;width: 40px;height: 100%;background: url(../../assets/images/contents/bt_hambuger.png) no-repeat center center;box-sizing: border-box; }
    #organization_emp_off_setting .mag_btn.on { background: url(../../assets/images/contents/ic_filtercan_btn.png) no-repeat center center !important;background-size: 35px !important; }
    #organization_emp_off_setting .filter_tap.close .wrapper { display : none; }
    #organization_emp_off_setting.dept_close .section_scroll { left : 0 !important; max-width: 100% !important; }    


    #organization_emp_off_setting .num { width: 15% !important; padding: 0 !important; }
    /* #organization_emp_off_setting .num { width: 7% !important; padding: 0 !important; } */
    #organization_emp_off_setting .num.notPermitted { width: 15% !important; padding: 0 !important; }
    #organization_emp_off_setting .checkbox { width: 8%; text-align: center;padding:0 !important;  }
    #organization_emp_off_setting .sortHeader .checkbox label { margin-top: 19px !important; }
    #organization_emp_off_setting .checkbox label { margin-right:0 !important; margin-top: 23px !important; }
    #organization_emp_off_setting .viewGroup .title{width: 24%;}/*체크박스 텍스트*/

    .btnDiv { float: right; padding-right:30px }
    .btnDiv input { padding:0 20px; line-height: 35px; height: 35px; border-radius: 3px; background:#fff; border: 1px solid #e7e9ea; font-weight: bold; margin-left: 10px; margin-top:13px; }
    .btnDiv input:disabled { opacity: 0.9; cursor: default; }

    /* .notiListUl > li { height: 70px !important; line-height: 70px !important; }
    .lastday4 { height: 70px !important; line-height: 70px !important; } */
    .lastday4 { overflow: hidden; }
    li:hover .lastday4 .text { background:#fff; display: inline-block; box-sizing: border-box; margin-left: 0 !important; border-top:1px solid #e7e9ea; border-bottom: 1px solid #e7e9ea; line-height: 30px; height: 30px; padding: 0 10px; }
    li:hover .lastday4 input { display:inline-block; margin-top: -6px; font-size: 0; border-radius: 15px 0 0 15px; padding: 0 7px; box-sizing: border-box; width: 35px; height: 30px; line-height: 30px; background-color:#fff; border:1px solid #e7e9ea; background: #fff url(../../assets/images/side_area/bt_open.png) no-repeat center center}
    .lastday4 .text {  }
    .lastday4 input { display: none; margin-top: -6px; font-size: 0; border-radius: 15px 0 0 15px; padding: 0 7px; box-sizing: border-box; width: 35px; height: 30px; line-height: 30px; background-color:#fff; border:1px solid #e7e9ea; }
    .lastday4 input:hover  { background-color:#f1f3f5 !important; }
    .lastday4 input.plusBtn { border-radius: 0 15px 15px 0 !important; background-image: url(../../assets/images/side_area/bt_close.png) !important; }
    .lastday4:hover input {  }


    .daysLeft { z-index: 10; position: absolute; top: 0; left: 245px; display: inline-block; height: 65px; line-height: 65px; font-weight: bold; width: 150px;  }
    .daysLeft * {float: left; display: inline-block; font-weight: bold; }
    .daysLeft span { opacity:0.5; width: 100px; margin-left: 30px; display: block; font-size: 13px; }
    .daysLeft label { margin-top:21px !important; margin-right: 15px !important; }
    .daysLeft label:after { content: ''; position: absolute; left: 0; top:0; width: 100%; height: 100%; }

    .daysLeft input[type='checkbox']:not(old)+label{margin-top: 6px;display: inline-block;padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid rgb(210, 211, 217);background: rgb(210, 211, 217) url(../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;margin-right: 8px;}
    .daysLeft input[type='checkbox']:not(old):checked + label{background-color: #477fff !important;border-color: #477fff;}
    .daysLeft input[type='checkbox']:not(old):checked + label span { opacity: 1; }

    a.not_manager { cursor: default; }

    .member_set_3 .input_btn { float: right; margin: 12px 40px 0 0; }

    /* 설정 버튼 */
    a.vactionOption { display: none; position: absolute; top: 18px; right: 4%; width: 65px; height: 30px; line-height: 30px; text-align: center; background: #ffc72f; border-radius: 30px; color: #232848; font-weight: bold; z-index: 9999; transition: .2s; }
    #organization_setting .content .notiListUl > li:hover a.vactionOption { display: block; }
    a.vactionOption:hover { background: #ffa70e; }


    #organization_setting .content .notiListUl > li:hover a.vactionOption.not_manager { display : none; }
</style>