<template>

    <!-- .section_ce_fix에 초대장일시 addClass(invite), 일정상세일시 (forView) -->
    <div id="hodu_event" class="section_ce_fix" v-if="event != null" :class="{ 
            invite : isCard(event.event_sub_type), 
            work : isWork(event.event_sub_type), 
            forView : isRead(event_crud_type),
            report : isReport(event.event_sub_type),
            meetinglog : isMeetingLog(event.event_sub_type), 
        }">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />
        
        <div class="bt_shareBox" v-if="isRead(event_crud_type) && !isWork(event.event_sub_type)">
            <div class="shareInnbox" :style="{ display : read_share_flag == true ? 'block' : 'none' }">
                
                <div class="share_btn btn00">
                    <input type="button" class="bt_share00" @click="emailShare"/> <!-- 메일 공유 -->
                    <p>메일</p>
                </div>

                <div class="share_btn btn01">
                    <input type="button" id="kakao_link_btn" class="bt_share01"/> <!-- 카카오 공유 -->
                    <p>카카오</p>
                </div>

                <div class="share_btn btn02">
                    <input type="button" class="bt_share02" @click="shareModalOpen"/> <!-- 호두 공유 -->
                    <p>호두</p>
                </div>

                <!-- 작성자 본인 + 그룹, 팀 일정이라면 관리자만 수신자 보기 가능 -->
                <div class="share_btn btn03" v-if="isManager() == true">
                    <input type="button" class="bt_share03" @click="shareStatusModalOpen" :class="{ 
                        on : event != null && ( event.subscribe_users  && event.subscribe_users.length  > 0 ||
                                                event.subscribe_groups && event.subscribe_groups.length > 0 ||
                                                event.subscribe_teams  && event.subscribe_teams.length  > 0 )
                    }" /> <!-- 수신자 보기 -->
                    <p>공유자 보기</p>
                </div>
            </div>
            <input type="button" class="bt_share" @click="readShareButtonGroupToggle">
        </div>

        <!-- title_box 시작 -->
        <div class="title_box" style="">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">{{ getViewTitle() }}</h3>
            <p class="btn_group" style="">
                <input type="button" id="btnSave" value="저장" class="input_btn" @click="saveEvent"          v-if="event != null && !isCard(event.event_sub_type) && ( isCreate(event_crud_type) && is_createable == true || isUpdate(event_crud_type) && is_editable == true )" />
                <input type="button" id="btnTemp" value="임시저장" class="input_btn" @click="tempSaveEvent"  v-if="event != null && !isCard(event.event_sub_type) && ( isCreate(event_crud_type) && is_createable == true )" />
                <input type="button" id="btnMod"  value="수정" class="input_btn" @click="modifyEvent"        v-if="event != null && !isCard(event.event_sub_type) && ( isRead(event_crud_type)   && is_editable   == true )"/>
                <input type="button" id="btnDel"  value="삭제" class="input_btn" @click="deleteEvent"        v-if="event != null && !isCard(event.event_sub_type) && ( isRead(event_crud_type)   && is_deleteable == true )"/>
                <input type="button" id="btnCopy" value="복사" class="input_btn" @click="copyEventModalCall" v-if="event != null && !isCard(event.event_sub_type) && !isWork(event.event_sub_type) && !isReport(event.event_sub_type) && !isMeetingLog(event.event_sub_type) && isRead(event_crud_type) && is_copyable == true"/>
                <input type="button" id="btVideo" value="화상회의" class="input_btn" @click="videoConferencing" v-if="event != null && !isCard(event.event_sub_type) && !isWork(event.event_sub_type) && isRead(event_crud_type) && isVideoConference()"/>
                <input type="button" id="btDocumentation" value="문서화" class="input_btn" @click="workDocumentation" v-if="event != null && (isReport(event.event_sub_type) || isMeetingLog(event.event_sub_type)) && isRead(event_crud_type)"/>
            </p>
        </div>
        <!-- title_box 끝 -->

        <!-- schDetailScroll 시작 -->
<!--************* 업무 시작버튼 클릭시 addClass(started) 체크업무는 addClass (checkTask) 단계식은 addClass (stepTask)-->
        <div id="schDetailScroll" class="section_scroll"
             :class="{
                started : isWorkStarted(),
                checkTask : selected_work_template != null && selected_work_template.template_type == 'CHECK',
                stepTask : selected_work_template != null && selected_work_template.template_type == 'FLOW',
                workWriter : isWork(event.event_sub_type) && is_editable == true 
             }" :style="{ height : `${detail_scroll_height}px` }">    
            <div class="workStatusTxtDiv" v-if="event.event_data.work != null && isWork(event.event_sub_type) && isRead(event_crud_type)">
                <p class="workStatusTxt" :style="{ color : getWorkStatusColor(true) }">{{ getWorkStatusMessage() }}</p>
                <div class="wstBtns" v-if="isWork(event.event_sub_type) && is_editable == true">
                    
                    <!-- 취소 버튼 (중단, 완료 상태 때만 나오고 누르면 상황에 맞게 대기 또는 진행으로 변경 ) -->
                    <input type="button" class="input_btn canBtn" :class="{ started : work_status_list.length > 0 }" value="취소" @click="updateWorkEventStatus($event, work_status_list.length > 0 ? 'START' : 'WAIT')"
                           v-if="event.event_data.work.work_status_code == 'CANCEL' || event.event_data.work.work_status_code == 'END'" />

                    <!-- 중단 버튼 (대기, 시작, 완료 상태 때만 나오고 누르면 중단으로 변경 ) -->
                    <input type="button" class="input_btn pauBtn" value="중단" @click="updateWorkEventStatus($event, 'CANCEL')" 
                           v-if="event.event_data.work.work_status_code == 'WAIT' || event.event_data.work.work_status_code == 'START' ||
                                 event.event_data.work.work_status_code == 'END'" />

                    <!-- 완료 버튼 (대기, 시작, 중단 상태 때만 나오고 누르면 완료로 변경 ) -->
                    <input type="button" class="input_btn comBtn" value="완료" @click="updateWorkEventStatus($event, 'END')" 
                           v-if="event.event_data.work.work_status_code == 'WAIT' || event.event_data.work.work_status_code == 'START' ||
                                 event.event_data.work.work_status_code == 'CANCEL'" />
                </div>
            </div>
            <div class="schedule_box writeSche">
<!--************* 업무 진행 상태 텍스트 -->
                <div class="statusText">
<!--************* 시작 전, 미정, 종료, 완료 된 업무입니다. -->
                    <p>{{ getWorkUserStatusMessage() }}</p>
                    <div class="btns">
                        <input type="button" class="startBtn" value="프로젝트 시작" @click="workStart" v-if="getWorkStartButtonVisibility()"/>
                        <input type="button" class="cancelWorkBtn" value="프로젝트 수행 취소" @click="workCancel" v-if="getWorkCancelButtonVisibility()" />
<!--************ 현황있는 업무일때 보이기 -->
                        <input type="button" class="seeWorkStatus" value="프로젝트 현황" @click="workStatusModalOpen" v-if="getWorkStatusButtonVisibility()"/>
                    </div>
                </div>
<!--************ 업무 진행 상태에 따라 색 변화 -->
                <div class="statusClrBar" :style="{ backgroundColor : `${getWorkStatusColor()} !important` }"></div>
                <!-- 카드 영역 시작 -->
                <div class="inviteImg" v-if="isCard(event.event_sub_type)" :style="{ backgroundImage : event.event_data.card ? `url(${ event.event_data.card.card_type == 'user' && event.event_data.card.card_img != null ? `/app_images/${event.event_data.card.card_img.url}` : require(`@/assets/images/card/img_invite_${event.event_data.card.card_type}.png`) })` : 'none' }"><p>카드 이미지</p></div>
                <div class="inviteContent" v-if="isCard(event.event_sub_type)">
                    <span></span><span class="end"></span>
                    <textarea rows="1" class="input_textarea input_gray fl w100per" placeholder="초대내용을 작성하세요" 
                              :value="event.event_data.card ? event.event_data.card.card_data.card_content : ''" @input="inputCardContent($event)" autocomplete="off" spellcheck="false"></textarea>
                </div>
                <!-- 카드 영역 끝 -->

                <!-- 일정 정보 영역 시작 -->
                <div class="tableDefault tableBorder th-right ">
                    
                    <!-- 일정 테이블 시작 -->
                    <table border="0" cellpadding="0" cellspacing="0">
                        <colgroup>
                            <col style="width:65px;"/>
                            <col style="*"/>
                        </colgroup>

                        <!-- tbody 시작 -->
                        <tbody>
                            
                            <!-- 작성자 영역 시작 -->
                            <tr class="writtenBy">
                                <th>
                                    <!-- 작성자 프로필 사진 = event.event_owner_id 로 구한다 -->
                                    <label for=""><img src="@/assets/images/contents/im_photoB.gif" alt="작성자"></label>
                                </th>
                                <td>
                                    <input type="text" id="writtenBy" class="input_txt" disabled />
                                </td>
                            </tr>
                            <!-- 작성자 영역 끝 -->

                            <!-- 업무 템플릿 영역 시작 -->
                            <tr id="workTr" class="forRel enabled" v-if="isWork(event.event_sub_type) && !isUpdate(event_crud_type)">
                                <th><label for="" title="프로젝트">프로젝트</label></th>
                                <td>
                                    <!-- 기본업무는 p태그 안에 span show, 다른 업무 선택시 span hide -->
                                    <p class="basic" @click="workTemplateOnOff">
                                        {{ selected_work_template == null ? '프로젝트를 생성 해주세요' : selected_work_template.template_info.name }}
                                        <span v-show="selected_work_template == null"></span>
                                    </p>
                                    <!-- 다른 업무 선택시 show, span 갯수 별로 추가 후 색상 변경 -->
                                    <p class="three" :title="`${selected_work_template != null ? selected_work_template.template_info.content.length : 0} ${selected_work_template == null ? '' : selected_work_template.template_type == 'FLOW' ? '단계' : '체크'}`"
                                       :style="{ display : selected_work_template != null ? 'block' : 'none'}" v-if="!isRead(event_crud_type)">
                                        <!-- <span class="line"></span> -->
                                        <span :key="index" v-for="(content, index) in ( selected_work_template != null ? selected_work_template.template_info.content : [] )"
                                              :style="{ backgroundColor : `${ hodu_hex_color_process(content.color) } !important` }" />
                                    </p>
                                    <div class="dropdown" :class="{ on : show_work_template == true }">
                                        <ul>
                                            <li :class="{ on : view_template_type == 'ALL' }">
                                                <a href="#" @click.prevent="changeWorkType('ALL')"><p>전체 <span>{{ work_template_list.length }}</span></p></a>
                                                <ul class="allTypes">

                                                    <!-- <li @click="selectDefaultWork" :class="{ on : event.event_data.work != null && event.event_data.work.template_type == 'DEFAULT' }"><em>기본</em>선택없음<p class="one"><span></span></p></li> -->
                                                    <li @click="selectWorkTemplate(template)" :class="{ on : event.event_data.work != null && event.event_data.work.template_id == template.template_id }" 
                                                        :key="template.template_id" v-for="template in work_template_list">
                                                        <em>{{ template.template_info.is_multi_select ? '다중 선택(병행 작업)' : '단일 선택' }}</em>{{ template.template_info.name }}
                                                        <p>
                                                            <!-- <span class="line"></span> -->
                                                            <span :key="content_index" v-for="(content, content_index) in template.template_info.content" 
                                                                  :style="{ backgroundColor : `${ hodu_hex_color_process(content.color) } !important` }" />
                                                        </p>
                                                    </li>

                                                    <!-- <li class="flow"><em>단계</em>선택없음<p class="two"><span></span><span></span></p></li>
                                                    <li class="check"><em>체크</em>선택없음<p class="five"><span></span><span></span><span></span><span></span><span></span></p></li> -->
                                                </ul>
                                            </li>
                                            <li class="plusWork"><a href="#" @click.prevent="workTemplateAdd">프로젝트 형식 추가</a></li>
                                        </ul>
                                    </div>
<!-- ****************** 상세화면에서 사용  -->
                                    <div class="taskView">
                                        <!-- <div class="bg">
                                            <input type="button" class="startBtn" value="업무 시작" @click="workModalOpen"/>
                                        </div> -->
                                        <div class="bg"></div>
                                        <!-- <p class="taskType" v-if="selected_work_template != null">{{ selected_work_template.template_type == 'FLOW' ? '단계식' : '체크식' }}
                                            <span>X {{ selected_work_template.template_info.content.length }}</span>
                                        </p> -->
                                        <div class="taskBtns">
                                            <input type="button" class="done" value="프로젝트 완료" @click="workComplete" v-if="getWorkCompleteButtonVisibility()"/>
                                        </div>
                                        
                                        <div class="taskSection">
                                            <ul class="taskSectionUl" v-if="selected_work_template != null">
                                                
                                                <li :key="index" v-for="(content, index) in selected_work_template.template_info.content">
                                                    <!-- 단계식 -->
                                                    <a class="dc0" :style="{ backgroundColor : `${hodu_hex_color_process(content.color)} !important` }" @click="workModalOpen"
                                                       :class="{ on : getWorkOn(index), stepNow : getStepNow(index) }" v-if="selected_work_template.template_type == 'FLOW'">{{ index + 1 }}
                                                        <span class="title">{{ content.title }}</span>
                                                    </a>
                                                    <!-- 체크식 -->
                                                    <a :class="`${hodu_color_dc_lc(content.color)} ${getWorkOn(index) ? 'on' : ''}`"  @click="workModalOpen"
                                                       v-if="selected_work_template.template_type == 'CHECK'">{{ index + 1 }}
                                                        <span class="title">{{ content.title }}</span>
                                                    </a>
                                                    <!-- 구분선 -->
                                                    <span class="seper" v-if="index + 1 != selected_work_template.template_info.content.length"></span>
                                                </li>
                                                
                                            </ul>
                                        </div>

<!--********************* 체크식, 단계식 일 시 배송 타이틀로 변경, span.typeOfTask에는 X3 단계 X3 체크식 단어 들어감  -->
                                        <div class="taskName">
                                            <span class="typeOfTask" v-if="selected_work_template != null">
                                                <em>X{{ selected_work_template.template_info.content.length }}</em> {{ selected_work_template.template_info.is_multi_select ? '다중 선택(병행 작업)' : '단일 선택' }}
                                            </span>{{ selected_work_template != null ? selected_work_template.template_info.name : '' }}
                                            <p class="three taskCircle" :title="`${selected_work_template != null ? selected_work_template.template_info.content.length : 0} ${selected_work_template == null ? '' : selected_work_template.template_info.is_multi_select ? '다중' : '단일'}`">
                                                <!-- <span class="line"></span> -->
                                                <span></span><span></span><span></span>
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <!-- 업무 템플릿 영역 끝 -->

                            <!-- 그룹 선택 영역 시작 -->
                            <!-- 개인달력 = 모든 그룹&팀, 그룹,팀 달력 = 해당 그룹&팀 -->
                            <tr id="grupTr" class="enabled" v-show="( !isUpdate(event_crud_type) && !isWork(event.event_sub_type) ) || 
                                                                    ( isRead(event_crud_type)    &&  isWork(event.event_sub_type) )">
                                <th><label for="" title="그룹 선택">그룹선택</label></th>

                                <td>
  
                                    <h2 :class="{ on : group_select_open == true }" @click="groupSelectListOpen">
                                        <span :class="{ cl  : group_color != 'transparent' }" :style="{ backgroundColor : `${new String(group_color).toUpperCase().slice(0, 7)} !important`}"/>
                                        
                                        <!-- 일정 조회에서만 나오는 작성자 이름 -->
                                        {{
                                            isRead(event_crud_type) ? `${event.event_data.event_owner_name} /` : ''
                                        }}

                                        <!-- 일정 생성에서만 나옴 -->
                                        {{  
                                            isRead(event_crud_type)           ? '' :  
                                            isPersonalScope(event.event_type) ? "개인일정" : 
                                            isGroupScope(event.event_type)    ? event.event_data.event_owner_group_name : event.event_data.event_owner_team_name
                                        }}
                                        
                                        <!-- 일정 조회에서만 나옴 -->
                                        {{ (share_info == null || !isRead(event_crud_type) ) ? '' : isPersonalScope(share_info.share_scope) ? '개인일정' : share_info.share_scope_name }}
                                        
                                        <!-- 그룹선택 arrow -->
                                        <span class="grupArrow"></span>

                                        <!-- forView일때만 히스토리 버튼이 활성 -->
                                        <span class="scheduleHisBtn" @click.prevent="showEventHistoryModal" v-if="event.event_sub_type == 'BASIC'">히스토리</span>
                                    </h2>

                                    <div id="schedule_group_div" :class="{ on : group_select_open == true }">
                                        <ul class="dropdown" id="s_group" :class="{ on : group_select_open == true }">
                                            <li :key="`${group_team.target}-${group_team.target_id}`" v-for="group_team in group_team_list">
                                                <a @click="group_team_select(group_team)" :class="{ team : group_team.target == 'TEAM'}">
                                                    <span :class="{ cl  : group_team.target_color != 'transparent' }" :style="{ backgroundColor : `${new String(group_team.target_color).toUpperCase().slice(0, 7)} !important`}"/> 
                                                    {{ group_team.target_name }}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </td>

                            </tr>
                            <!-- 그룹 선택 영역 끝 -->

                            <!-- 일정 제목 및 색상 영역 시작 -->
                            <tr id="titleTr" class="forRel" :class="{ enabled : event.event_data.title != null && event.event_data.title.length > 0 }">
                                <th><label for="" title="제목">일정제목</label></th>
                                <td>
                                    <input type="text" id="s_mainTitle" style="width:640px;" class="input_txt" placeholder="제목을 입력하세요" 
                                           :value="event.event_data.title" @input="event.event_data.title = $event.target.value" :disabled="isRead(event_crud_type)" autocomplete="off" spellcheck="false"/>
                                    <input type="button" class="clr" :class="{ on : color_select_open == true }" @click="colorSelectListOpen" :style="{ background : event.event_data.color }" />
                                    <div id="pickColour" :class="{ on : color_select_open == true }">
                                        <ul>
                                            <li :class="{ on : colorToneMode == 'rc' }">
                                                <a>기본색</a>
                                                <ul>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#FF6363' }"><a href="#" class="dc0" @click.prevent="changeColor($event, '#FF6363', 'rc')">연어</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#FFA70E' }"><a href="#" class="dc1" @click.prevent="changeColor($event, '#FFA70E', 'rc')">주황</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#FFC72F' }"><a href="#" class="dc2" @click.prevent="changeColor($event, '#FFC72F', 'rc')">노랑</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#FF198B' }"><a href="#" class="dc3" @click.prevent="changeColor($event, '#FF198B', 'rc')">자주</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#00B2C7' }"><a href="#" class="dc4" @click.prevent="changeColor($event, '#00B2C7', 'rc')">청록</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#13D08B' }"><a href="#" class="dc5" @click.prevent="changeColor($event, '#13D08B', 'rc')">초록</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#4DBAFF' }"><a href="#" class="dc6" @click.prevent="changeColor($event, '#4DBAFF', 'rc')">하늘</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#477FFF' }"><a href="#" class="dc7" @click.prevent="changeColor($event, '#477FFF', 'rc')">파랑</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#6854FF' }"><a href="#" class="dc8" @click.prevent="changeColor($event, '#6854FF', 'rc')">보라</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#35405A' }"><a href="#" class="dc9" @click.prevent="changeColor($event, '#35405A', 'rc')">검정</a></li>
                                                </ul>
                                            </li>
                                            <li :class="{ on : colorToneMode == 'lc' }">
                                                <a>파스텔</a>
                                                <ul>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#B9A494' }"><a href="#" class="lc0" @click.prevent="changeColor($event, '#B9A494', 'lc')">연한 갈색</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#E67D9B' }"><a href="#" class="lc1" @click.prevent="changeColor($event, '#E67D9B', 'lc')">연한 자주</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#FF9898' }"><a href="#" class="lc2" @click.prevent="changeColor($event, '#FF9898', 'lc')">연한 빨강</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#AB8DD6' }"><a href="#" class="lc3" @click.prevent="changeColor($event, '#AB8DD6', 'lc')">연한 보라</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#F3AC77' }"><a href="#" class="lc4" @click.prevent="changeColor($event, '#F3AC77', 'lc')">연한 주황</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#E3D37A' }"><a href="#" class="lc5" @click.prevent="changeColor($event, '#E3D37A', 'lc')">연한 노랑</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#8DD6A0' }"><a href="#" class="lc6" @click.prevent="changeColor($event, '#8DD6A0', 'lc')">연한 초록</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#7197ED' }"><a href="#" class="lc7" @click.prevent="changeColor($event, '#7197ED', 'lc')">연한 파랑</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#8DAED6' }"><a href="#" class="lc8" @click.prevent="changeColor($event, '#8DAED6', 'lc')">연한 하늘</a></li>
                                                    <li :class="{ on : new String(event.event_data.color).toUpperCase().slice(0, 7) == '#6F7A93' }"><a href="#" class="lc9" @click.prevent="changeColor($event, '#6F7A93', 'lc')">연한 검정</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <!-- 일정 제목 및 색상 영역 끝 -->

                            <!-- 시간 영역 시작 -->
                            <tr id="timeTr" class="enabled">
                                <th><label title="시간">시간</label></th>
                                <td class="timeTd">
                                    <div class="inputgroup">
                                        
                                        <!-- 시작 시간 영역 시작 -->
                                        <input type="button" class="input_txt fl input_gray radius04" id="s_eventFrYmd" :value="start_date"/>
                                        <input type="button" class="input_txt fl input_gray radius04" id="s_FrTm"  :value="start_time" :class="{ on : time_from_open == true }" @click="timeFromListOpen" v-show="event.event_data.schedule_date.lunar_yn == false && event.event_data.schedule_date.isAllDay == false" />
                                        <div class="chooseTime from" :class="{ on : time_from_open == true }" v-show="event.event_data.schedule_date.lunar_yn == false && event.event_data.schedule_date.isAllDay == false">
                                            <div class="selectboxWrap">
                                                <label for="s_frAmpm" class="">{{ start_am_pm == "AM" ? "오전" : "오후" }}</label> 
                                                <select id="s_frAmpm" title="오전 오후 선택" @change="selectStartTimeAmPm($event)">
                                                    <option :selected="start_am_pm == 'AM'" value="AM">오전</option>
                                                    <option :selected="start_am_pm == 'PM'" value="PM">오후</option>
                                                </select>
                                            </div>
                                            <div class="selectboxWrap">
                                                <label for="s_frHour" class="">{{ start_hour }}</label>
                                                <select id="s_frHour" title="시간선택" @change="selectStartTimeHour($event)">

                                                    <option :value="(n == 1 ? '12' : `0${n - 1}`.slice(-2))" :key="n" v-for="n in 12" :selected="start_hour == (n == 1 ? '12' : `0${n - 1}`.slice(-2))">
                                                        {{ (n == 1 ? '12' : `0${n - 1}`.slice(-2)) }}
                                                    </option>

                                                    <!-- <option value="12" :selected="start_hour == '12'">12</option>
                                                    <option value="01" :selected="start_hour == '01'">01</option>
                                                    <option value="02" :selected="start_hour == '02'">02</option>
                                                    <option value="03" :selected="start_hour == '03'">03</option>
                                                    <option value="04" :selected="start_hour == '04'">04</option>
                                                    <option value="05" :selected="start_hour == '05'">05</option>
                                                    <option value="06" :selected="start_hour == '06'">06</option>
                                                    <option value="07" :selected="start_hour == '07'">07</option>
                                                    <option value="08" :selected="start_hour == '08'">08</option>
                                                    <option value="09" :selected="start_hour == '09'">09</option>
                                                    <option value="10" :selected="start_hour == '10'">10</option>
                                                    <option value="11" :selected="start_hour == '11'">11</option> -->
                                                    
                                                </select>
                                            </div>
                                            <div class="selectboxWrap">
                                                <label for="s_frMin" class="">{{ start_min }}</label> 
                                                <select id="s_frMin" title="분 선택" @change="selectStartTimeMin($event)">
                                                    <option :value="`0${(n - 1) * 10}`.slice(-2)" :key="n" v-for="(n) in 6" :selected="start_min == `0${(n - 1) * 10}`.slice(-2)">
                                                        {{ `0${(n - 1) * 10}`.slice(-2) }}
                                                    </option>

                                                    <!-- <option value="00" :selected="start_min == '00'">00</option>
                                                    <option value="10" :selected="start_min == '10'">10</option>
                                                    <option value="20" :selected="start_min == '20'">20</option>
                                                    <option value="30" :selected="start_min == '30'">30</option>
                                                    <option value="40" :selected="start_min == '40'">40</option>
                                                    <option value="50" :selected="start_min == '50'">50</option> -->

                                                </select>
                                            </div>
                                        </div>
                                        <!-- 시작 시간 영역 끝 -->

                                        <span class="inputgroup_txt01 arw" v-show="event.event_data.schedule_date.lunar_yn == false">에서</span>

                                        <!-- 종료 시간 영역 시작 -->
                                        <input type="button" class="input_txt fl input_gray radius04" id="s_eventToYmd" :value="end_date" v-show="event.event_data.schedule_date.lunar_yn == false" />
                                        <input type="button" class="input_txt fl input_gray radius04" id="s_ToTm"  :value="end_time" :class="{ on : time_to_open == true }" @click="timeToListOpen" v-show="event.event_data.schedule_date.lunar_yn == false && event.event_data.schedule_date.isAllDay == false" />
                                        <div class="chooseTime to" :class="{ on : time_to_open == true }" v-show="event.event_data.schedule_date.lunar_yn == false && event.event_data.schedule_date.isAllDay == false" >
                                            <div class="selectboxWrap">
                                                <label for="s_toAmpm" class="">{{ end_am_pm == "AM" ? "오전" : "오후" }}</label> 
                                                <select id="s_toAmpm" title="오전 오후 선택" @change="selectEndTimeAmPm($event)">
                                                    <option :selected="end_am_pm == 'AM'" value="AM">오전</option>
                                                    <option :selected="end_am_pm == 'PM'" value="PM">오후</option>
                                                </select>
                                            </div>
                                            <div class="selectboxWrap">
                                                <label for="s_toHour" class="">{{ end_hour }}</label> 
                                                <select id="s_toHour" title="시간 선택" @change="selectEndTimeHour($event)">
                                                    <option :value="(n == 1 ? '12' : `0${n - 1}`.slice(-2))" :key="n" v-for="n in 12" :selected="end_hour == (n == 1 ? '12' : `0${n - 1}`.slice(-2))">
                                                        {{ (n == 1 ? '12' : `0${n - 1}`.slice(-2)) }}
                                                    </option>

                                                    <!-- <option value="12" :selected="end_hour == '12'">12</option>
                                                    <option value="01" :selected="end_hour == '01'">01</option>
                                                    <option value="02" :selected="end_hour == '02'">02</option>
                                                    <option value="03" :selected="end_hour == '03'">03</option>
                                                    <option value="04" :selected="end_hour == '04'">04</option>
                                                    <option value="05" :selected="end_hour == '05'">05</option>
                                                    <option value="06" :selected="end_hour == '06'">06</option>
                                                    <option value="07" :selected="end_hour == '07'">07</option>
                                                    <option value="08" :selected="end_hour == '08'">08</option>
                                                    <option value="09" :selected="end_hour == '09'">09</option>
                                                    <option value="10" :selected="end_hour == '10'">10</option>
                                                    <option value="11" :selected="end_hour == '11'">11</option> -->

                                                </select>
                                            </div>
                                            <div class="selectboxWrap">
                                                <label for="s_toMin" class="">{{ end_min }}</label> 
                                                <select id="s_toMin" title="분 선택" @change="selectEndTimeMin($event)">
                                                    <option :value="`0${(n - 1) * 10}`.slice(-2)" :key="n" v-for="(n) in 6" :selected="end_min == `0${(n - 1) * 10}`.slice(-2)">
                                                        {{ `0${(n - 1) * 10}`.slice(-2) }}
                                                    </option>

                                                    <!-- <option value="00" :selected="end_min == '00'">00</option>
                                                    <option value="10" :selected="end_min == '10'">10</option>
                                                    <option value="20" :selected="end_min == '20'">20</option>
                                                    <option value="30" :selected="end_min == '30'">30</option>
                                                    <option value="40" :selected="end_min == '40'">40</option>
                                                    <option value="50" :selected="end_min == '50'">50</option> -->

                                                </select>
                                            </div>
                                        </div>
                                        <!-- 종료 시간 영역 끝 -->

                                        <!-- 음력 텍스트 -->
                                        <span id="lunarToSolarTxt" class="fl inputgroup_txt02" v-show="event.event_data.schedule_date.lunar_yn == true">
                                            {{ `음력 ${lunar_date_text}` }}
                                        </span>

                                        <!-- 양력/음력 -->
                                        <div class="selectboxWrap fr input_gray w61 lunarDiv" v-show="!isRead(event_crud_type) && !isWork(event.event_sub_type)">
                                            <label for="lunarYmd" :class="{ disabled : event.event_data.schedule_date.lunar_yn == true }">
                                                {{ event.event_data.schedule_date.lunar_yn == true ? "음력" : "양력" }}
                                            </label> <!--셀렉트 박스 disabled일때-->
                                            <select id="lunarYmd" v-model="event.event_data.schedule_date.lunar_yn">
                                                <option :value="false">양력</option>
                                                <option :value="true">음력</option>
                                            </select>
                                        </div>
                                        
                                        <!-- 종일 -->
                                        <p class="fr" v-show="event.event_data.schedule_date.lunar_yn == false && !isRead(event_crud_type)">
                                            <input type="checkbox" id="allday" class="input_check" v-model="event.event_data.schedule_date.isAllDay"/>
                                            <label for="allday">종일</label>
                                        </p>
                                        
                                        <!-- 오늘 -->
                                        <input type="button" id="time_today" value="오늘" class="fr" @click="goToday" v-show="!isRead(event_crud_type)" />

                                    </div>
                                </td>
                            </tr>
                            <!-- 시간 영역 끝 -->

                            <!-- 반복 영역 시작 -->
                            <tr id="repeatTr" :class="{ enabled : (event.event_data.schedule_date.rrule != null && event.event_data.schedule_date.rrule != '') || repeat_modify_code == 'E' }"
                                v-if="( !isRead(event_crud_type) ) || ( isRead(event_crud_type) && event.event_data.schedule_date.rrule != null && event.event_data.schedule_date.rrule != '' )">
                                <th><label for="" title="반복">반복</label></th>
                                <td id="repeatTd">
                                    <p :class="{ added : ( !isRead(event_crud_type) && event.event_data.schedule_date.rrule != null && event.event_data.schedule_date.rrule != ''), 
                                                 read  : isRead(event_crud_type) }" 
                                        class="inputgroup_txt02"  v-html="(event.event_data.schedule_date.rrule != null && event.event_data.schedule_date.rrule != '') ? recurrence_html : `반복없음`"
                                        @click="removeRepeat" v-if="repeat_modify_code != 'E'">
                                        
                                    </p>
                                    <p class="inputgroup_txt02 read" v-else>
                                        <span>반복 일정 중 이 일정만 수정 </span>
                                    </p>
                                    <a id="btnRepeat" href="#" class="bt_noti" @click.prevent="repeatOpen" :class="{ on : repeat_open}" v-if="repeat_modify_code != 'E'">반복설정</a>
                                    <div class="repeatDivScroll" :class="{ on : repeat_open}">
                                        <ul class="repeatUl" :class="{ lunar : event.event_data.schedule_date.lunar_yn == true }">
                                            
                                            <!-- 매일 반복 영역 시작 -->
                                            <li class="everyD" :class="{ on : day_repeat == true }">
                                                <a @click="repeatDayOpen">매일</a>
                                                <ul>
                                                    
                                                    <li>
                                                        <span class="intro">반복 시작일</span>
                                                        {{ recurrence_start_text }} 부터
                                                    </li>
                                                    
                                                    <li class="descript wInput">
                                                        <span class="intro">반복 시간</span>매 
                                                        <input type="text" v-model="repeat_value" placeholder="예) 4" onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="3" autocomplete="off" spellcheck="false"/> 
                                                        <em>일</em> 마다 반복
                                                    </li>

                                                    <li class="descript endInput">
                                                        <span class="intro">반복 종료일</span>
                                                        <!-- 수정 -->
                                                        <!-- <input type="button" id="dayRecurrenceEnd" :value="recurrence_end_text" class="timeInput" :style="{ 'display' : repeat_infinite == false ? 'block' : 'none' }">
                                                        {{ repeat_infinite == false ? "까지" : "종료일 없음" }} 
                                                        <input type="checkbox" id="continueBtn1" class="" value="계속" v-model="repeat_infinite" />
                                                        <label for="continueBtn1">계속</label> -->
                                                        <div class="drEndcheck_box">
                                                            <input type="radio" name="recurrenceInput" id="dayRecurrenceEnd" :checked="repeat_infinite == false">
                                                            <label for="dayRecurrenceEnd" id="dayRecurrenceEnd_check" @click="setRepeatDefault">
                                                                <span class="dayRecurrenceText repeatdayEnd">
                                                                    {{ event ? `${hodu_date_to_format_string(recurrence_end, 'YYYY.MM.DD')} ${getDayOfWeekByDate(recurrence_end, '요일')}` : '' }}
                                                                </span>
                                                            </label>

                                                            <input type="radio" name="recurrenceInput" id="continueBtn1" :checked="repeat_infinite == true">
                                                            <label for="continueBtn1" id="dayRecurrenceEnd_none" @click.prevent="setRepeatInfinite"><span class="dayRecurrenceText">종료일 없음</span></label>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </li>
                                            <!-- 매일 반복 영역 끝 -->

                                            <!-- 매주 반복 영역 시작 -->
                                            <li class="everyW" :class="{ on : week_repeat == true }">
                                                <a @click="repeatWeekOpen">매주</a>
                                                <ul>

                                                    <li>
                                                        <span class="intro">반복 시작일</span>
                                                        {{ recurrence_start_text }} 부터
                                                    </li>

                                                    <li class="descript wInput">
                                                        <span class="intro">반복 시간</span>매 
                                                        <input type="text" v-model="repeat_value" placeholder="예) 4" onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="3" autocomplete="off" spellcheck="false"/> 
                                                        <em>주</em> 마다 반복
                                                        <ul class="cntrllr">
                                                            <li><a :class="{ on : selected_day_of_week.indexOf(6) != -1 }" @click="dayOfWeekClick(6)">일</a></li>
                                                            <li><a :class="{ on : selected_day_of_week.indexOf(0) != -1 }" @click="dayOfWeekClick(0)">월</a></li>
                                                            <li><a :class="{ on : selected_day_of_week.indexOf(1) != -1 }" @click="dayOfWeekClick(1)">화</a></li>
                                                            <li><a :class="{ on : selected_day_of_week.indexOf(2) != -1 }" @click="dayOfWeekClick(2)">수</a></li>
                                                            <li><a :class="{ on : selected_day_of_week.indexOf(3) != -1 }" @click="dayOfWeekClick(3)">목</a></li>
                                                            <li><a :class="{ on : selected_day_of_week.indexOf(4) != -1 }" @click="dayOfWeekClick(4)">금</a></li>
                                                            <li><a :class="{ on : selected_day_of_week.indexOf(5) != -1 }" @click="dayOfWeekClick(5)">토</a></li>
                                                        </ul>
                                                    </li>

                                                    <li class="descript endInput">
                                                        <span class="intro">반복 종료일</span>
                                                        <!-- <input type="button" id="weekRecurrenceEnd" :value="recurrence_end_text" class="timeInput" :style="{ 'display' : repeat_infinite == false ? 'block' : 'none' }">
                                                        {{ repeat_infinite == false ? "까지" : "종료일 없음" }}  
                                                        <input type="checkbox" id="continueBtn2" class="" value="계속" v-model="repeat_infinite" />
                                                        <label for="continueBtn2">계속</label> -->
                                                        <div class="drEndcheck_box">
                                                            <input type="radio" name="recurrenceInput2" id="weekRecurrenceEnd" :checked="repeat_infinite == false">
                                                            <label for="weekRecurrenceEnd" id="weekRecurrenceEnd_check" @click="setRepeatDefault">
                                                                <span class="dayRecurrenceText repeatdayEnd">
                                                                    {{ event ? `${hodu_date_to_format_string(recurrence_end, 'YYYY.MM.DD')} ${getDayOfWeekByDate(recurrence_end, '요일')}` : '' }}
                                                                </span>
                                                            </label>

                                                            <input type="radio" name="recurrenceInput2" id="continueBtn2" :checked="repeat_infinite == true">
                                                            <label for="continueBtn2" id="weekRecurrenceEnd_none" @click.prevent="setRepeatInfinite"><span class="dayRecurrenceText">종료일 없음</span></label>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </li>
                                            <!-- 매주 반복 영역 끝 -->

                                            <!-- 매월 반복 영역 시작 -->
                                            <li class="everyM" :class="{ on : month_repeat == true }">
                                                <a @click="repeatMonthOpen">매월</a>
                                                <ul>
                                                    <li>
                                                        <span class="intro">반복 시작일</span>
                                                        <a :class="{ on : month_year_left_option == true  }" @click="changeRepeatModeForMonthAndYear(true)">{{ recurrence_start_for_month_text }}</a>
                                                        <a :class="{ on : month_year_left_option == false }" @click="changeRepeatModeForMonthAndYear(false)">{{ recurrence_start_for_month_th_text }}</a>
                                                    </li>

                                                    <li class="descript wInput">
                                                        <span class="intro">반복 시간</span>매 
                                                        <input type="text" v-model="repeat_value" placeholder="예) 4" onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="2" autocomplete="off" spellcheck="false"/> 
                                                        <em>개월</em> 마다 
                                                        <span class="spanTxt">
                                                            {{ month_year_left_option == true ? `${new Date(event.event_data.schedule_date.start).getDate()}일` 
                                                                                              : `${getWeekOfMonthNumber(new Date(event.event_data.schedule_date.start))}번째 ${getDayOfWeek(new Date(event.event_data.schedule_date.start))}요일` }}
                                                        </span> 반복
                                                    </li>

                                                    <li class="descript endInput">
                                                        <span class="intro">반복 종료일</span>
                                                        <!-- <input type="button" id="monthRecurrenceEnd" :value="recurrence_end_text" class="timeInput" :style="{ 'display' : repeat_infinite == false ? 'block' : 'none' }">
                                                        {{ repeat_infinite == false ? "까지" : "종료일 없음" }}  
                                                        <input type="checkbox" id="continueBtn3" class="" value="계속" v-model="repeat_infinite" />
                                                        <label for="continueBtn3">계속</label> -->
                                                        <div class="drEndcheck_box">
                                                            <input type="radio" name="recurrenceInput3" id="monthRecurrenceEnd" :checked="repeat_infinite == false">
                                                            <label for="monthRecurrenceEnd" id="monthRecurrenceEnd_check" @click="setRepeatDefault">
                                                                <span class="dayRecurrenceText repeatdayEnd">
                                                                    {{ event ? `${hodu_date_to_format_string(recurrence_end, 'YYYY.MM.DD')} ${getDayOfWeekByDate(recurrence_end, '요일')}` : '' }}
                                                                </span>
                                                            </label>

                                                            <input type="radio" name="recurrenceInput3" id="continueBtn3" :checked="repeat_infinite == true">
                                                            <label for="continueBtn3" id="monthRecurrenceEnd_none" @click.prevent="setRepeatInfinite"><span class="dayRecurrenceText">종료일 없음</span></label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                            <!-- 매월 반복 영역 끝 -->

                                            <!-- 매년 반복 영역 시작 -->
                                            <li class="everyY" :class="{ on : year_repeat == true }">
                                                <a @click="repeatYearOpen">매년</a>
                                                <ul>

                                                    <li>
                                                        <span class="intro">반복 시작일</span>
                                                        <a :class="{ on : month_year_left_option == true  }" @click="changeRepeatModeForMonthAndYear(true)">{{ recurrence_start_for_year_text }}</a>
                                                        <a :class="{ on : month_year_left_option == false }" @click="changeRepeatModeForMonthAndYear(false)">{{ recurrence_start_for_year_th_text }}</a>
                                                    </li>

                                                    <li class="descript wInput">
                                                        <span class="intro">반복 시간</span>매 
                                                        <input type="text" v-model="repeat_value" placeholder="예) 4" onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="2" autocomplete="off" spellcheck="false"/> 
                                                        <em>년</em> 마다 
                                                        <span class="spanTxt">
                                                            {{ month_year_left_option == true ? `${new Date(event.event_data.schedule_date.start).getMonth() + 1}월 ${new Date(event.event_data.schedule_date.start).getDate()}일`
                                                                                              : `${new Date(event.event_data.schedule_date.start).getMonth() + 1}월 ${getWeekOfMonthNumber(new Date(event.event_data.schedule_date.start))}번째 ${getDayOfWeek(new Date(event.event_data.schedule_date.start))}요일` }}
                                                        </span>반복
                                                    </li>

                                                    <li class="descript endInput">
                                                        <span class="intro">반복 종료일</span>
                                                        <!-- <input type="button" id="yearRecurrenceEnd" :value="recurrence_end_text" class="timeInput" :style="{ 'display' : repeat_infinite == false ? 'block' : 'none' }">
                                                        {{ repeat_infinite == false ? "까지" : "종료일 없음" }}  
                                                        <input type="checkbox" id="continueBtn4" value="계속" v-model="repeat_infinite" />
                                                        <label for="continueBtn4">계속</label> -->
                                                        <div class="drEndcheck_box">
                                                            <input type="radio" name="recurrenceInput4" id="yearRecurrenceEnd" :checked="repeat_infinite == false">
                                                            <label for="yearRecurrenceEnd" id="yearRecurrenceEnd_check" @click="setRepeatDefault">
                                                                <span class="dayRecurrenceText repeatdayEnd">
                                                                    {{ event ? `${hodu_date_to_format_string(recurrence_end, 'YYYY.MM.DD')} ${getDayOfWeekByDate(recurrence_end, '요일')}` : '' }}
                                                                </span>
                                                            </label>

                                                            <input type="radio" name="recurrenceInput4" id="continueBtn4" :checked="repeat_infinite == true">
                                                            <label for="continueBtn4" id="yearRecurrenceEnd_none" @click.prevent="setRepeatInfinite"><span class="dayRecurrenceText">종료일 없음</span></label>
                                                        </div>
                                                    </li>

                                                </ul>

                                            </li>
                                            <!-- 매년 반복 영역 끝 -->

                                            <!-- 음력 매년 반복 영역 시작 -->
                                            <li class="everyL">
                                                <a>매년 (음력)</a>
                                                <ul>
                                                    
                                                    <li>
                                                        <span class="intro">반복 시작일</span>
                                                        <span class="normal">양력 {{recurrence_start_text}}</span>
                                                        <span class="lunar">{{ '음력 ' + lunar_date_text }}</span>
                                                    </li>

                                                    <li class="descript wInput">
                                                        <span class="intro">반복 시간</span>매 년 
                                                        <em>음력 {{ `${lunar_date_text.split('.')[1]}월 ${lunar_date_text.split('.')[2]}일`}}</em>에 반복
                                                    </li>

                                                    <li class="descript endInput">
                                                        <span class="intro">반복 종료일</span>
                                                        <!-- <input type="button" id="lunarRecurrenceEnd" :value="recurrence_end_text" class="timeInput" :style="{ 'display' : repeat_infinite == false ? 'block' : 'none' }" > 
                                                        {{ repeat_infinite == false ? "까지" : "종료일 없음" }} 
                                                        <input type="checkbox" id="continueBtn5" value="계속" v-model="repeat_infinite" />
                                                        <label for="continueBtn5">계속</label> -->
                                                        <div class="drEndcheck_box">
                                                            <input type="radio" name="recurrenceInput5" id="lunarRecurrenceEnd" :checked="repeat_infinite == false">
                                                            <label for="lunarRecurrenceEnd" id="lunarRecurrenceEnd_check" @click="setRepeatDefault">
                                                                <span class="dayRecurrenceText repeatdayEnd">
                                                                    {{ event ? `${hodu_date_to_format_string(recurrence_end, 'YYYY.MM.DD')} ${getDayOfWeekByDate(recurrence_end, '요일')}` : '' }}
                                                                </span>
                                                            </label>

                                                            <input type="radio" name="recurrenceInput5" id="continueBtn5" :checked="repeat_infinite == true">
                                                            <label for="continueBtn5" id="lunarRecurrenceEnd_none" @click.prevent="setRepeatInfinite"><span class="dayRecurrenceText">종료일 없음</span></label>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </li>
                                            <!-- 음력 매년 반복 영역 끝 -->

                                        </ul>

                                        <!-- 반복 버튼 영역 시작 -->
                                        <div class="btns">
                                            <input type="button" value="취소" @click="repeatClose" />
                                            <input type="button" value="저장" @click="saveRepeat" />
                                        </div>
                                        <!-- 반복 버튼 영역 끝 -->

                                    </div>
                                </td>
                            </tr>
                            <!-- 반복 영역 끝 -->

                            <!-- 알람 영역 시작 -->
                            <tr id="alarmTr" :class="{ enabled : event.event_data.alarm != null && event.event_data.alarm.length > 0 }">
                                <th><label for="" title="알림">알림</label></th>
                                <td id="alramTd">
                                    
                                    <span class="inputgroup_txt02 ml10" v-if="event.event_data.alarm == null || event.event_data.alarm.length < 1">알림없음</span>
                                    
                                    <div class="alarmDiv">
                                        <span class="inputgroup_txt02 ml10 added" v-show="event.event_data.alarm != null && event.event_data.alarm.length > 0" 
                                            :key="alarm.trigger" v-for="alarm in event.event_data.alarm" @click="alarmOff(alarm)">
                                            {{alarm.description}}
                                        </span>
                                    </div>

                                    <a id="btnAlarm" href="#" class="bt_noti" :class="{ on : alarm_list_open == true }" @click.prevent="alarmListOpen">알림설정</a>
                                    <div class="alarmDivScroll" :class="{ on : alarm_list_open == true, toTheBottom : ( event.event_data.alarm != null && event.event_data.alarm.length > 5 ) }">
                                        <ul class="alarmUl">
                                            <li :class="{ on : alarmCheck('0 day', '0') == true }" >
                                                <p><input type="checkbox" id="alarmCheck" value="0 day" v-model="alarm_model"><label for="alarmCheck" @click="alarmSetting('0 day', '0', '정시알림')"></label><span>정시 알림</span></p>
                                            </li>
                                            <li :class="{ on : alarmCheck('10 minute', 'M') == true }">
                                                <p><input type="checkbox" id="alarmCheck01" value="10 minute" v-model="alarm_model"><label for="alarmCheck01" @click="alarmSetting('10 minute', 'M', '10분 전')"></label><span>10분 전</span></p>
                                            </li>
                                            <li :class="{ on : alarmCheck('30 minute', 'M') == true }">
                                                <p><input type="checkbox" id="alarmCheck02" value="30 minute" v-model="alarm_model"><label for="alarmCheck02" @click="alarmSetting('30 minute', 'M', '30분 전')"></label><span>30분 전</span></p>
                                            </li>
                                            <li :class="{ on : alarmCheck('1 hour', 'H') == true }">
                                                <p><input type="checkbox" id="alarmCheck03" value="1 hour" v-model="alarm_model"><label for="alarmCheck03" @click="alarmSetting('1 hour', 'H', '1시간 전')"></label><span>1시간 전</span></p>
                                            </li>
                                            <li :class="{ on : alarmCheck('2 hour', 'H') == true }">
                                                <p><input type="checkbox" id="alarmCheck03_1" value="2 hour" v-model="alarm_model"><label for="alarmCheck03_1" @click="alarmSetting('2 hour', 'H', '2시간 전')"></label><span>2시간 전</span></p>
                                            </li>
                                            <li :class="{ on : alarmCheck('3 hour', 'H') == true }">
                                                <p><input type="checkbox" id="alarmCheck03_2" value="3 hour" v-model="alarm_model"><label for="alarmCheck03_2" @click="alarmSetting('3 hour', 'H', '3시간 전')"></label><span>3시간 전</span></p>
                                            </li>
                                            <li :class="{ on : alarmCheck('6 hour', 'H') == true }">
                                                <p><input type="checkbox" id="alarmCheck04" value="6 hour" v-model="alarm_model"><label for="alarmCheck04" @click="alarmSetting('6 hour', 'H', '6시간 전')"></label><span>6시간 전</span></p>
                                            </li>
                                            <li :class="{ on : alarmCheck('12 hour', 'H') == true }">
                                                <p><input type="checkbox" id="alarmCheck05" value="12 hour" v-model="alarm_model"><label for="alarmCheck05" @click="alarmSetting('12 hour', 'H', '12시간 전')"></label><span>12시간 전</span></p>
                                            </li>
                                            <li :class="{ on : alarmCheck('1 day', 'D') == true }">
                                                <p><input type="checkbox" id="alarmCheck06" value="1 day" v-model="alarm_model"><label for="alarmCheck06" @click="alarmSetting('1 day', 'D', '1일 전')"></label><span>1일 전</span></p>
                                            </li>
                                            <li :class="{ on : alarmCheck('2 day', 'D') == true }">
                                                <p><input type="checkbox" id="alarmCheck06_1" value="2 day" v-model="alarm_model"><label for="alarmCheck06_1" @click="alarmSetting('2 day', 'D', '2일 전')"></label><span>2일 전</span></p>
                                            </li>
                                            <li :class="{ on : alarmCheck('3 day', 'D') == true }">
                                                <p><input type="checkbox" id="alarmCheck06_2" value="3 day" v-model="alarm_model"><label for="alarmCheck06_2" @click="alarmSetting('3 day', 'D', '3일 전')"></label><span>3일 전</span></p>
                                            </li>
                                            <li :class="{ on : alarmCheck('7 day', 'D') == true }">
                                                <p><input type="checkbox" id="alarmCheck07" value="7 day" v-model="alarm_model"><label for="alarmCheck07" @click="alarmSetting('7 day', 'D', '7일 전')"></label><span>1주일 전</span></p>
                                            </li>
                                            <li :class="{ on : alarmCheck('15 day', 'D') == true }">
                                                <p><input type="checkbox" id="alarmCheck08" value="15 day" v-model="alarm_model"><label for="alarmCheck08" @click="alarmSetting('15 day', 'D', '15일 전')"></label><span>보름 전</span></p>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <!-- 알람 영역 끝 -->

                            <!-- 장소 영역 시작 -->
                            <tr id="mapTr" :class="{ enabled : event.event_data.location != null && event.event_data.location.length > 0 }"
                                v-if="( !isRead(event_crud_type) ) || ( isRead(event_crud_type) && event.event_data.location != null && event.event_data.location.length > 0 )">
                                <th><label for="" title="장소">장소</label></th>
                                <td>
                                    <!-- 인풋버튼 td 바로 하단에 추가, 이버튼을 클릭시 .map toggleClass(on) (기본세팅으로 on추가되어있음) -->

                                    <div class="over_hidden searchPl">
                                        <input type="text" id="mapSearchKey" class="input_txt fl" placeholder="검색할 장소를 입력하세요" @keydown="locationKeyDown" 
                                               :value="mapSearchQuery" @input="mapSearchQuery = $event.target.value" autocomplete="off" spellcheck="false"/>
                                        <a id="mapSearch" href="#" class="bt_search_place" @click.prevent="locationSearchModalOpen">
                                            <span class="blind">찾기버튼</span>
                                        </a>
                                    </div>
                                    <ul class="mapUl" v-if="event.event_data.location != null && event.event_data.location.length > 0">
                                        <li :key="location.geo != null ? `${location.geo.longitude}-${location.geo.latitude}` : index" v-for="(location, index) in event.event_data.location">
                                            <p class="mainAddress">{{ location.place }}</p>
                                            <p class="add1" v-if="location.address != null && location.address.length > 0">{{ location.address }}</p>
                                            <p class="add2" v-if="location.address_old != null && location.address_old.length > 0"><span>지번</span>{{ location.address_old }}</p>
                                            <p class="tel add2" v-if="location.address_old != null && location.address_old.length > 0"><span>tel</span>{{ location.tel == null || location.tel.length == 0 ? "없음" : location.tel }}</p>
                                            <input type="button" class="btn seeMap" value="자세히 보기" @click="viewLocation(location)" v-if="location.address_old != null && location.address_old.length > 0"/>
                                            <input type="button" class="btn del"    value="삭제" @click="deleteLocation(location)" />
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <!-- 장소 영역 끝 -->

                            <!-- 전화번호 영역 시작 -->
                            <!-- TODO 전화번호 양식 이외의 것이 들어가면 안들어가도록 해야함 현재 당장은 숫자와 - 만 들어가게 되어있음 -->
                            <tr id="telTr" :class="{ enabled : event.event_data.contacts != null && event.event_data.contacts.length > 0 && event.event_data.contacts[0].tel != null && event.event_data.contacts[0].tel.length > 0 }"
                                v-if="( !isRead(event_crud_type) ) || ( isRead(event_crud_type) && event.event_data.contacts != null && event.event_data.contacts.length > 0 && event.event_data.contacts[0].tel != null && event.event_data.contacts[0].tel.length > 0 )">
                                <th><label for="" title="연락처">전화번호</label></th>
                                <td><input type="tel" id="telNum" class="input_txt" maxlength="15" placeholder="예) 00-0000-0000" :value="event.event_data.contacts != null && event.event_data.contacts.length > 0 ? event.event_data.contacts[0].tel : ''" :disabled="isRead(event_crud_type)"
                                           @input="inputTel($event)"/></td>
                            </tr>
                            <!-- 전화번호 영역 끝 -->
                            <!-- 회의록 장소 영역 시작 -->
                            <tr id="meetingLocationTr" :class="{ enabled : event.event_data.meeting_location != null && event.event_data.meeting_location.length > 0 }" v-if="isMeetingLog(event.event_sub_type)">
                                <th><label for="" title="장소">장소</label></th>
                                <td>
                                    <div class="over_hidden">
                                        <input type="text" id="attendees" style="width:100%;" class="input_txt" placeholder="장소" 
                                            :value="event.event_data.meeting_location" @input="event.event_data.meeting_location = $event.target.value" :disabled="isRead(event_crud_type)" autocomplete="off" spellcheck="false"/>
                                    </div>
                                </td>
                            </tr>
                            <!-- 회의록 장소 영역 끝 -->

                            <!-- 참석자 영역 시작 -->
                            <tr id="attendeesTr" :class="{ enabled : event.event_data.attendees != null && event.event_data.attendees.length > 0 }" v-if="isMeetingLog(event.event_sub_type)">
                                <th><label for="" title="참석자">참석자</label></th>
                                <td>
                                    <div class="over_hidden">
                                        <input type="text" id="attendees" style="width:100%;" class="input_txt" placeholder="참석자" 
                                           :value="event.event_data.attendees" @input="event.event_data.attendees = $event.target.value" :disabled="isRead(event_crud_type)" autocomplete="off" spellcheck="false"/>
                                    </div>
                                </td>
                            </tr>
                            <!-- 참석자 영역 끝 -->

                            <!-- 메모 영역 시작 -->
                            <tr id="memoTr" :class="{ enabled : event.event_data.memo != null && event.event_data.memo.length > 0, memo : isSchedule(event.event_sub_type) || isCard(event.event_sub_type) || isWork(event.event_sub_type)  }">
                                <th><label for="" :title="getMemoTitle(event.event_sub_type)">{{ getMemoTitle(event.event_sub_type) }}</label></th>
                                <td>
                                    <div class="over_hidden">
                                        <pre id="shareMemo" class="input_textarea input_gray fl w100per" :class="{ placeholder : event.event_data.memo == null || event.event_data.memo.trim().length < 1 }" v-html="event.event_data.memo != null && event.event_data.memo.trim().length > 0 ? hodu_make_href(event.event_data.memo) : getMemoPlaceholder(event.event_sub_type)" v-if="isRead(event_crud_type)"></pre>
                                        <textarea id="shareMemo" :rows="event.event_data.memo == null ? 1 : ( (event.event_data.memo.match(/\n/gim) || []).length + 1)" class="input_textarea input_gray fl w100per" :placeholder="getMemoPlaceholder(event.event_sub_type)" 
                                                  :value="event.event_data.memo" @input="event.event_data.memo = $event.target.value" :disabled="isRead(event_crud_type)" autocomplete="off" spellcheck="false" v-else/>
                                    </div>
                                </td>
                            </tr>
                            <!-- 메모 영역 끝 -->

                            <!-- 노트 영역 시작 -->
                            <tr id="noteTr" :class="{ enabled : event.event_data.note != null && event.event_data.note.length > 0 }">
                                <th><label for="" title="메모(비공개)">메모(비공개)</label></th>
                                <td>
                                    <div class="over_hidden">
                                        <textarea id="note" :rows="event.event_data.note == null ? 1 : ( (event.event_data.note.match(/\n/gim) || []).length + 1)" class="input_textarea input_gray fl w100per" placeholder="비공개 (나에게만 보이는 메모)" 
                                                  :value="event.event_data.note" @input="event.event_data.note = $event.target.value" autocomplete="off" spellcheck="false"/>
                                    </div>
                                </td>
                            </tr>
                            <!-- 노트 영역 끝 -->

                            <!-- 비고 영역 시작 -->
                            <tr id="remarkTr" :class="{ enabled : event.event_data.remark != null && event.event_data.remark.length > 0 }" v-if="isReport(event.event_sub_type)">
                                <th><label for="" title="비고">비고</label></th>
                                <td>
                                    <div class="over_hidden">
                                        <pre id="remark" class="input_textarea input_gray fl w100per" :class="{ placeholder : event.event_data.remark == null || event.event_data.remark.trim().length < 1 }" v-html="event.event_data.remark != null && event.event_data.remark.trim().length > 0 ? hodu_make_href(event.event_data.remark) : '비고'" v-if="isRead(event_crud_type)"></pre>
                                        <textarea id="remark" :rows="event.event_data.remark == null ? 1 : ( (event.event_data.remark.match(/\n/gim) || []).length + 1)" class="input_textarea input_gray fl w100per" placeholder="비고" 
                                                  :value="event.event_data.remark" @input="event.event_data.remark = $event.target.value" :disabled="isRead(event_crud_type)" autocomplete="off" spellcheck="false" v-else/>
                                    </div>
                                </td>
                            </tr>
                            <!-- 비고 영역 끝 -->

                            <!-- 이미지 영역 시작 -->
                            <tr id="addImageTr" class="addPicNfile" :class="{ enabled : event.event_data.attachment != null && event.event_data.attachment.imgs != null && event.event_data.attachment.imgs.length > 0 }"
                                v-show="( !isRead(event_crud_type) ) || ( isRead(event_crud_type) && event.event_data.attachment != null && event.event_data.attachment.imgs != null && event.event_data.attachment.imgs.length > 0 )">
                                <th><label for="" title="사진">사진</label></th>
                                <td>
                                    <span class="inputgroup_txt02">사진</span>
                                    <!-- 퍼블 추가, 사진 카운팅-->
                                    <p class="count"><span>{{ event.event_data.attachment != null && event.event_data.attachment.imgs != null ? event.event_data.attachment.imgs.length : 0 }}</span> / {{ image_max_cnt }}</p>
                                    <div id="scheduleImageUploadBasic">
                                        <label for="cdImg" id="image_label" class="clickOrDropBg forImg" :class="{ dragging : image_drag == true }" @dragover.prevent="imageDragOver($event)" @dragleave.prevent="imageDragLeave" @drop.prevent="imageDrop($event)" >클릭 또는 끌어넣기</label>
                                        <input type="file" id="cdImg" class="cdInput" value="click or drop" multiple="multiple" accept="image/*" @change="addImageNormal($event)" />
                                    </div>
                                    
                                    <div class="grp imgGrp" :key="`${img.name}-${index}`" v-for="(img, index) in (event.event_data.attachment != null && event.event_data.attachment.imgs != null ? event.event_data.attachment.imgs : [])">
                                        <label :for="`${img.name}-${index}`" class="withPic" @click="imagePreviewOpen(img)">사진첨부</label>
                                        <p :id="`${img.name}-${index}`" class="schImg" :style="{ 'background-image': `url(${(img.url.indexOf('calendar/') == -1 && img.url.indexOf('tempbox/') == -1) ? img.url : `/app_images/${img.url}` })`}">일정 사진 첨부</p>
                                        <input type="button" class="del btn_imageDel" value="삭제" @click="deleteImage(img)" v-if="!isRead(event_crud_type)"/>
                                        <div class="download">
                                            <div class="bg" id="imagePreviewArea"></div>
                                            <input type="button" class="downloadFile btn_imageDown" value="다운로드" title="다운로드">
                                            <input type="button" class="preview dpnone" value="미리보기" title="미리보기">
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <!-- 이미지 영역 끝 -->

                            <!-- 파일 영역 시작 (비즈에서만 사용 가능)) -->
                            <tr id="addFileTr" class="addPicNfile" :class="{ enabled : event.event_data.attachment != null && event.event_data.attachment.files != null && event.event_data.attachment.files.length > 0 }" 
                                v-if="( !isRead(event_crud_type) && file_permission == true ) || 
                                      (  isRead(event_crud_type) && event.event_data.attachment != null && event.event_data.attachment.files != null && event.event_data.attachment.files.length > 0)">
                                      
                                <th><label title="파일 추가">파일</label></th>
                                <td>
                                    <div class="description">
                                        <span class="inputgroup_txt02">파일</span>
                                        <!-- 퍼블 추가, 파일 카운팅-->
                                        <p class="count descript">한 파일당 {{ is_premium_group(event.group_id > 0 ? 'GROUP' : event.team_id > 0 ? 'TEAM' : '', event.group_id > 0 ? event.group_id : event.team_id > 0 ? event.team_id : 0) ? DEFAULT_FILE_MAX_SIZE_TEXT : NORMAL_GROUP_FILE_MAX_SIZE_TEXT }} 이하만 업로드 가능</p>
                                        <label for="cdFile" id="file_label" class="clickOrDropBg forFile" :class="{ dragging : file_drag == true }" @dragover.prevent="fileDragOver($event)" @dragleave.prevent="fileDragLeave" @drop.prevent="fileDrop($event)" >클릭 또는 끌어넣기</label>
                                        <div id="scheduleUploadBasic">
                                            <input type="file" multiple="multiple"  id="cdFile" class="cdInput" value="click or drop" @change="addFileNormal($event)" />
                                        </div>
                                    </div>
                                    
                                    <div id="fileBox">
                                        <ul>
                                            <li :key="`${file.name}-${index}`" v-for="(file, index) in (event.event_data.attachment != null && event.event_data.attachment.files != null ? event.event_data.attachment.files : [])">
                                                <p class="ex">{{ file.name.lastIndexOf('.') == -1 ? "x" : file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) }}</p>
                                                <p class="fileNm" @click.prevent="fileDownload(file)">{{ file.name }}</p>
                                                <p class="size">{{ 
                                                    file.size >= 1 * 1024 * 1024 ? `${ (file.size / 1024 / 1024).toFixed(3) } MB` :
                                                    file.size >= 1 * 1024        ? `${ (file.size / 1024       ).toFixed(3) } KB` :
                                                                                   `${ file.size } Byte`         
                                                }}</p>
                                                <input type="button" class="dwnldBtn" value="다운로드" v-if="isRead(event_crud_type)" @click.prevent="fileDownload(file)"/>
                                                <input type="button" class="delBtn" value="삭제" @click="deleteFile(file)" v-if="!isRead(event_crud_type)"/>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                </td>
                            </tr>
                            <!-- 파일 영역 끝 -->

                            <!-- 
                            공개여부 / 공유 영역 시작 
                            <tr id="shareTr" :class="{ enabled : ( event.subscribe_users  != null && event.subscribe_users.length  > 0 ) ||
                                                                 ( event.subscribe_groups != null && event.subscribe_groups.length > 0 ) ||
                                                                 ( event.subscribe_teams  != null && event.subscribe_teams.length  > 0 )}"
                                                                 v-if="!isWork(event.event_sub_type) && isCreate(event_crud_type)">
                                                                
                                <th><label for="">공유</label></th>
                                <td>
                                    <span class="inputgroup_txt02" 
                                    v-if="( event.subscribe_users  == null || event.subscribe_users.length  < 1 ) && 
                                          ( event.subscribe_groups == null || event.subscribe_groups.length < 1 ) &&
                                          ( event.subscribe_teams  == null || event.subscribe_teams.length  < 1 )">
                                        공유
                                    </span>
                                    <span class="inputgroup_txt02 added" @click="deleteShare" v-else>
                                        
                                        {{ event.subscribe_users != null && event.subscribe_users.length > 0 ? '사용자' : '' }}
                                        <em v-if="event.subscribe_users  != null && event.subscribe_users.length > 0">
                                        {{ event.subscribe_users.length }}</em>{{ ( event.subscribe_users != null && event.subscribe_users.length > 0 ) && 
                                        ( ( event.subscribe_groups != null && event.subscribe_groups.length > 0 ) || ( event.subscribe_teams != null && event.subscribe_teams.length > 0 ) ) ? ',' : '' }}

                                        {{ event.subscribe_groups != null && event.subscribe_groups.length > 0 ? '그룹' : '' }}
                                        <em v-if="event.subscribe_groups != null && event.subscribe_groups.length > 0">
                                        {{ event.subscribe_groups.length }}</em>{{ ( event.subscribe_groups != null && event.subscribe_groups.length > 0 ) && ( ( event.subscribe_teams != null && event.subscribe_teams.length > 0 ) ) ? ',' : '' }} 

                                        {{ event.subscribe_teams != null && event.subscribe_teams.length > 0 ? '팀' : '' }} 
                                        <em v-if="event.subscribe_teams != null && event.subscribe_teams.length > 0">{{ event.subscribe_teams.length }}</em>

                                    </span>
                                    배정일 시 show, 디폴트 hide
                                    <a href="#" class="result"><span>10</span> / 20</a> 
                                    <input type="button" class="shareBtn" @click="shareModalOpen" />	
                                </td>
                            </tr>
                        -->

                            <!-- 공개여부 / 공유 영역 시작 -->
                            <tr id="shareTr" :class="{ enabled : ( event.subscribe_users  != null && event.subscribe_users.length  > 0 ) ||
                                                                 ( event.subscribe_groups != null && event.subscribe_groups.length > 0 ) ||
                                                                 ( event.subscribe_teams  != null && event.subscribe_teams.length  > 0 )}"
                                                                 v-if="!isWork(event.event_sub_type) && (isCreate(event_crud_type) || isUpdate(event_crud_type))">
                                <!-- 프로젝트에도 공유 / 공개 활성화 -->                          
                                <th><label for="">공유</label></th>
                                <td>
                                    <div class="shareDiv">
                                        <span class="inputgroup_txt02" 
                                        v-if="( event.subscribe_users  == null || event.subscribe_users.length  < 1 ) && 
                                            ( event.subscribe_groups == null || event.subscribe_groups.length < 1 ) &&
                                            ( event.subscribe_teams  == null || event.subscribe_teams.length  < 1 )">
                                            공유
                                        </span>
                                        <span class="inputgroup_txt02 added" @click="deleteShare" v-else>
                                            
                                            {{ event.subscribe_users != null && event.subscribe_users.length > 0 ? '사용자' : '' }}
                                            <em v-if="event.subscribe_users  != null && event.subscribe_users.length > 0">
                                            {{ event.subscribe_users.length }}</em>{{ ( event.subscribe_users != null && event.subscribe_users.length > 0 ) && 
                                            ( ( event.subscribe_groups != null && event.subscribe_groups.length > 0 ) || ( event.subscribe_teams != null && event.subscribe_teams.length > 0 ) ) ? ',' : '' }}

                                            {{ event.subscribe_groups != null && event.subscribe_groups.length > 0 ? '그룹' : '' }}
                                            <em v-if="event.subscribe_groups != null && event.subscribe_groups.length > 0">
                                            {{ event.subscribe_groups.length }}</em>{{ ( event.subscribe_groups != null && event.subscribe_groups.length > 0 ) && ( ( event.subscribe_teams != null && event.subscribe_teams.length > 0 ) ) ? ',' : '' }} 

                                            {{ event.subscribe_teams != null && event.subscribe_teams.length > 0 ? '팀' : '' }} 
                                            <em v-if="event.subscribe_teams != null && event.subscribe_teams.length > 0">{{ event.subscribe_teams.length }}</em>

                                        </span>
                                        <!-- 배정일 시 show, 디폴트 hide -->
                                        <!-- <a href="#" class="result"><span>10</span> / 20</a> -->
                                        <input type="button" class="shareBtn" @click="shareModalOpen" v-if="isUpdate(event_crud_type) == false" />
                                    </div>
                                    <div class="showDiv">
                                        <!-- 공개를 default로 -->
                                        <p class="inputgroup_txt02 show_txt">특정인 지정
                                            <span class="tooltipSpan" @mouseover="show_private_tooltip = true" @mouseleave="show_private_tooltip = false">?</span>
                                        </p>
                                        <label for="ann_nowCheck" class="switch">
                                            <input type="checkbox" id="ann_nowCheck" v-model="event.event_data.is_private">
                                            <span class="slider round"></span>
                                        </label>
                                        <div class="requiredToolBox" v-if="show_private_tooltip == true">
                                            <p>
                                                기본 그룹의 모든 사람이 게시물을 확인할 수 있습니다<br>
                                                특정인 지정 시 별도의 공유자만 게시물을 확인할 수 있습니다
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <!-- 공개여부 / 공유 영역 끝 -->

                            <!-- 담당자 배정 영역 시작 -->
                            <tr id="workerTr" class="enabled" v-if="isWork(event.event_sub_type) && event.event_data.work != null">
                                <th><label for="">공유</label></th>
                                <td>
                                    <span class="inputgroup_txt02">담당자</span>
                                    <div class="selectboxWrap fr">
                                        <!-- event.event_data.work.assignment_type == 'UNDEF'  ? '미정' : -->
                                        <label for="work_assignment">{{ event.event_data.work.assignment_type == 'ASSIGN' ? '배정' : '모두' }}</label>
                                        <select id="work_assignment" @change="workAssignmentChange($event)">
                                            <!-- <option value="UNDEF" :selected="event.event_data.work.assignment_type == 'UNDEF'">미정</option> -->
                                            <option value="ASSIGN" :selected="event.event_data.work.assignment_type == 'ASSIGN'">배정</option>
                                            <option value="ALL" :selected="event.event_data.work.assignment_type == 'ALL'">모두</option>
                                        </select>
                                    </div>
                                    <!-- 배정일 시 show, 디폴트 hide -->
                                    <a href="#" class="result" @click.prevent="workAssign" v-if="event.event_data.work != null && event.event_data.work.assignment_type != 'UNDEF'">
                                        <span>{{ assignment_user_list.length }}</span> / {{ all_member_count }}</a>
                                    <div class="showDiv fr">
                                        <!-- 공개를 default로 -->
                                        <p class="inputgroup_txt02 show_txt">특정인 지정
                                            <span class="tooltipSpan" @mouseover="show_private_tooltip = true" @mouseleave="show_private_tooltip = false">?</span>
                                        </p>
                                        <label for="ann_nowCheck" class="switch">
                                            <input type="checkbox" id="ann_nowCheck" v-model="event.event_data.is_private">
                                            <span class="slider round"></span>
                                        </label>
                                        <div class="requiredToolBox" v-if="show_private_tooltip == true">
                                            <p>
                                                기본 그룹의 모든 사람이 게시물을 확인할 수 있습니다<br>
                                                특정인 지정 시 담당자만 게시물을 확인할 수 있습니다
                                            </p>
                                        </div>
                                    </div>
                                    <div class="chart">
                                        <p>담당자에게 현황을 보이시겠습니까?</p>
                                        <label class="switch">
                                        <input type="checkbox" v-model="event.event_data.work.work_status_visible"/>
                                        <span class="slider round"></span>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <!-- 담당자 배정 영역 끝 -->

                            <!-- 상대방에게 알림 영역 시작 -->
                            <tr id="notificationTr" v-if="(isCreate(event_crud_type) || isUpdate(event_crud_type)) && (event.group_id > 0 || event.team_id > 0)">
                                <th></th>
                                <td>
                                    <span class="inputgroup_txt02">저장시 상대방에게 알림</span>
                                    <input type="radio" id="push_n" name="push_yn" value="N" v-model="push_yn"/>
                                    <label for="push_n" class="push_radio">아니오</label>
                                    <input type="radio" id="push_y" name="push_yn" value="Y" v-model="push_yn"/>
                                    <label for="push_y" class="push_radio" >예</label>
                                </td>
                            </tr>
                            <!-- 상대방에게 알림 영역 끝 -->
                            
                            <!-- 참석여부 영역 시작 -->
                            <tr id="attendTr" :class="{ enabled : event.event_data.attend == true }" 
                                v-show="( ( ( event.event_data.schedule_date.rrule == null ) || ( event.event_data.schedule_date.rrule == '' ) ) &&
                                        (     isCreate(event_crud_type)                                                                          &&  
                                        ( (   event.subscribe_users                != null   &&   event.subscribe_users.length  > 0 )            ||
                                        (     event.subscribe_groups               != null   &&   event.subscribe_groups.length > 0 )            ||
                                        (     event.subscribe_teams                != null   &&   event.subscribe_teams.length  > 0 )            ||
                                        (     event.group_id > 0)                            || ( event.team_id > 0 ) ) )                        ||   
                                              isRead(event_crud_type)                        && event.event_data.attend == true                  ||
                                        ( ( ( event.event_data.schedule_date.rrule == null ) || ( event.event_data.schedule_date.rrule == '' ) ) &&  
                                              isUpdate(event_crud_type) ) )"> 
                                        <!-- 작성 : 반복일정이 아니고 그룹, 팀 일정이거나 공유한 사용자, 그룹, 팀이 있을때만 존재 -->
                                        <!-- 조회 : 반복일정이 아니고 attend가 존재한다면 -->
                                        <!-- 수정 : 반복일정이 아니라면 항상 존재 -->

                                <th><label for="" title="참석여부">참석여부</label></th>
                                <td>										
                                    <span class="inputgroup_txt02 ml10">참석여부</span>		
                                    <!-- 참석여부 결과 -->
                                    <a href="#" class="result" @click.prevent="attendResultModalOpen"><span>{{ attend_user_count }}</span> / {{ attendable_user_count }}</a>	
                                    <input type="checkbox" id="attendCheck" name="attend-radio" v-model="event.event_data.attend" />
                                    <label for="attendCheck" id="btnAttendInput" class="pt0 backPosi-Y03">참석여부결정</label>
                                    
                                    <!-- 읽음일때 보이기 -->
                                    <div id="attendInput" class="round_radiobox fl" v-if="is_attendable == true">
                                        <div class="btns">
                                            <input type="radio" id="attendCheck01" name="attend-radio1" class="radio_round" value="Y" :checked="attend_data != null && attend_data.attend == 'Y'" @click="clickAttend($event, 'Y')"><label for="attendCheck01">참석</label>
                                            <input type="radio" id="attendCheck02" name="attend-radio1" class="radio_round" value="N" :checked="attend_data != null && attend_data.attend == 'N'" @click="clickAttend($event, 'N')"><label for="attendCheck02">불참</label>
                                            <input type="radio" id="attendCheck03" name="attend-radio1" class="radio_round" value="F" :checked="attend_data != null && attend_data.attend == '-'" @click="clickAttend($event, '-')"><label for="attendCheck03">미정</label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <!-- 참석여부 영역 끝 -->

                            <!-- 투표 영역 시작 -->
                            <tr id="voteTr" :class="{ enabled : event.event_data.vote != null && event.event_data.vote.length > 0 }"
                                v-show="( ( ( event.event_data.schedule_date.rrule == null ) || ( event.event_data.schedule_date.rrule == '' ) )                  &&
                                        (     isCreate(event_crud_type)                                                                                           &&  
                                        ( (   event.subscribe_users                != null   &&   event.subscribe_users.length  > 0 )                             ||
                                        (     event.subscribe_groups               != null   &&   event.subscribe_groups.length > 0 )                             ||
                                        (     event.subscribe_teams                != null   &&   event.subscribe_teams.length  > 0 )                             ||
                                        (     event.group_id > 0)                            || ( event.team_id > 0 ) ) )                                         ||   
                                              isRead(event_crud_type)                        && event.event_data.vote != null && event.event_data.vote.length > 0 ||
                                        ( ( ( event.event_data.schedule_date.rrule == null ) || ( event.event_data.schedule_date.rrule == '' ) )                  &&  
                                              isUpdate(event_crud_type) ) )">
                                        <!-- 작성 : 반복일정이 아니고 그룹, 팀 일정이거나 공유한 사용자, 그룹, 팀이 있을때만 존재 -->
                                        <!-- 조회 : 반복일정이 아니고 vote가 존재한다면 -->
                                        <!-- 수정 : 반복일정이 아니라면 항상 존재 -->

                                <th><label for="" title="투표/설문">투표/설문</label></th>
                                <td class="voteTD">
                                    <span class="inputgroup_txt02 ml10">투표/설문</span>
                                    <div id="voteInput" class=""><a id="btnVoteInput" href="#" class="bt_vote" @click.prevent="voteMakeModalOpen(-1)">투표/설문 설정</a></div>
                                    <ul id="voteList" class="doc_voteBox" v-if="event.event_data.vote != null && event.event_data.vote.length > 0">
                                        
                                        <li :key="index" v-for="(vote_object, index) in event.event_data.vote"
                                            :class="{ newVote  : isCreate(event_crud_type) || ( isUpdate(event_crud_type) && new_vote_index.indexOf(index) > -1 ),
                                                      waiting  : vote_object.is_end == false && new Date().getTime() <  new Date(vote_object.end_date).getTime() && ( vote_result == null || vote_result.vote[index].length < 1 ),
                                                      voted    : vote_object.is_end == false && new Date().getTime() <  new Date(vote_object.end_date).getTime() && vote_result != null && vote_result.vote[index].length > 0, 
                                                      finished : vote_object.is_end == true  || new Date().getTime() >= new Date(vote_object.end_date).getTime() }">
                                            <p class="status">
                                                {{ 
                                                    isCreate(event_crud_type) || ( isUpdate(event_crud_type) && new_vote_index.indexOf(index) > -1 ) ? 'NEW'
                                                                                : getVoteStatus(vote_object, index)
                                                }}
                                            </p>
                                            <p class="voteTitle">{{ vote_object.title }}</p>
                                            <a href="#" class="result" @click.prevent="voteResultModalOpen(index)"><span>{{ (vote_user_count[index] != null ? vote_user_count[index] : 0) }}</span> / {{ attendable_user_count }}</a>
                                            <a class="seeDetail" @click="voteMakeModalOpen(index)">자세히 보기</a>

                                            <ul class="iVoted" v-if="isRead(event_crud_type) && vote_result != null && vote_result.vote[index].length > 0">
                                                <li :key="n" v-for="n in vote_result.vote[index]" @click="voteItemDelete(index, n)">
                                                    <span>{{ String.fromCharCode(65 + n) }}</span>
                                                    {{ vote_object.items[n] }}
                                                    <a>삭제</a>
                                                </li>
                                            </ul>

                                        </li>

                                    </ul>
                                </td>
                            </tr>
                            <!-- 투표영역 끝 -->

                        </tbody>
                        <!-- tbody 끝 -->

                    </table>
                    <!-- 일정 테이블 끝 -->

                    <!-- 업무버튼 -->
                    <a class="nextStepBtn" style="display:none;">다음 업무 완료</a>
                    <!-- 업무버튼 끝 -->
                    
                    <!-- 댓글 관리자일 경우 markDiv 클래스 추가-->
                    <div class="cmmDiv markDiv">
                        <input type="button" class="cmmntBtn" value="댓글" @click="showEventReply"
                            :class="{ on : is_exist_reply == true }" 
                            v-if="( isRead(event_crud_type) ) && ( event.group_id > 0 || event.team_id > 0 || 
                                                                    ( event.subscribe_users  != null && event.subscribe_users.length  > 0 ) || 
                                                                    ( event.subscribe_groups != null && event.subscribe_groups.length > 0 ) ||
                                                                    ( event.subscribe_teams  != null && event.subscribe_teams.length  > 0 ) )">
                        <p class="readMark" v-if="( isRead(event_crud_type) ) && ( event.group_id > 0 || event.team_id > 0 || 
                                                                ( event.subscribe_users  != null && event.subscribe_users.length  > 0 ) || 
                                                                ( event.subscribe_groups != null && event.subscribe_groups.length > 0 ) ||
                                                                ( event.subscribe_teams  != null && event.subscribe_teams.length  > 0 ) )">
                            <a href="#" @click.prevent="showEventRead">
                                읽음
                                <span>{{ readCount }}</span>
                            </a>
                        </p>
                    </div>

                    <!-- 예제 메세지모달-->
                    <!-- <div class="msgModalDiv">
                        <a class="msg1" @click="exampleMsg('success', '성공적으로 저장이 완료 되었습니다', ['확인'])">완료 MessageModal</a>
                        <a class="msg3" @click="exampleMsg('cancel', '영구적으로 삭제 완료 되었습니다', ['확인'])">삭제 MessageModal</a>
                        <a class="msg3" @click="exampleMsg('alert', '에러! 잠시 후 다시 시도해주세요', ['확인'])">경고 MessageModal</a>
                    </div> -->
                </div>
                <!-- 일정 정보 영역 끝 -->
                

            </div>
        </div>
        <!-- schDetailScroll 끝 -->

    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { OWNER_TYPE, EVENT_SUB_TYPE, CRUD_TYPE, API_METHOD, SHARE_OPTION } from "@/mixin/VueHoduCommon";

import { t_event, t_event_file, t_event_alarm, t_event_location, t_event_vote } from '@/model/event';
import { t_vote_item, t_event_attend, t_work_template, t_work_status, t_user } from '@/model/osm';

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const WorkInfo  = namespace('WorkInfo');
const ModalInfo = namespace('ModalInfo');

import { EventOriginalDate, EventShareInfo } from '@/store/modules/EventInfo';
import { EventEmailShareModalInfo, EventHistoryModalInfo, EventReadModalInfo } from '@/store/modules/ModalInfo';

import moment from 'moment';
import 'moment-lunar';

import { RRule, RRuleSet, rrulestr } from 'rrule'
import { GroupTeamInfo } from '@/store/modules/GroupInfo';

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

import { ResizeObserver } from 'vue-resize';
import { hodu_local_storage, local_storage_info, LocalStorageInfo } from '../lib/HoduLocalStorage';

declare let Kakao : any;
import '@/assets/js/kakao';

@Component({
    components: {
        ResizeObserver
    },
}) export default class Event extends Mixins(VueHoduCommon) {

    get readCount() : number {
        
        for( const read of this.read_info ) {
            if( read.repeat_seq == this.repeat_seq ) {
                return read.count;
            }
        }

        return 0;
    }

    /**
     * EventInfo.State
     */
    @EventInfo.State  event                      !: t_event;
    @EventInfo.State  event_crud_type            !: string
    @EventInfo.State  event_original_date        !: EventOriginalDate;
    @EventInfo.State  selected_location          !: t_event_location;
    @EventInfo.State  event_share_info           !: EventShareInfo;
    @EventInfo.State  is_moved_by_short_create   !: boolean;

    /**
     * EventInfo.Action
     */
    @EventInfo.Action doSetRepeatModifyAction    ?: any;  
    @EventInfo.Action doSetLocationSearchQuery   ?: any;
    @EventInfo.Action doSetSelectedLocation      ?: any;
    @EventInfo.Action doSetEventImagePreviewInfo ?: any;
    @EventInfo.Action doSetVote                  ?: any;
    @EventInfo.Action doSetVoteEndDateLimit      ?: any;
    @EventInfo.Action doSetVoteModifyIndex       ?: any;

    /**
     * @WorkInfo.Action
     */
    @WorkInfo.Action doSetWorkTemplateId ?: any;
    @WorkInfo.Action doSetWorkStatusInfo ?: any;

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventRepeatModify    ?: any;
    @ModalInfo.Action doSetShowEventImagePreview    ?: any;
    @ModalInfo.Action doSetShowEventDaumMap         ?: any;
    @ModalInfo.Action doSetShowEventShare           ?: any;
    @ModalInfo.Action doSetEventShareStatusInfo     ?: any;
    @ModalInfo.Action doSetEventEmailShareModalInfo ?: (parms : EventEmailShareModalInfo) => void;
    @ModalInfo.Action doSetShowEventAttendResult    ?: any;
    @ModalInfo.Action doSetShowEventVoteCreate      ?: any;
    @ModalInfo.Action doSetShowEventVoteResult      ?: any;
    @ModalInfo.Action doSetShowEventReply           ?: any;
    @ModalInfo.Action doSetShowEventWorkProcess     ?: any;
    @ModalInfo.Action doSetEventWorkAssignmentInfo  ?: any;
    @ModalInfo.Action doSetShowWorkStatus           ?: any;
    @ModalInfo.Action doSetShowWorkDefaultStatus    ?: any;
    @ModalInfo.Action doSetEventHistoryModalInfo    ?: (params : EventHistoryModalInfo) => void;
    @ModalInfo.Action doSetEventReadModalInfo       ?: (params : EventReadModalInfo) => void;

    share_infos : any[] = [];
    share_info : any = null;

    date_term : number = 0;
    group_team_list : any = [];
    colorToneMode : string = "dc";
    group_color : string = "transparent";

    start_date  : string = "";
    start_time  : string = "";
    start_hour  : string = "";
    start_min   : string = "";
    start_am_pm : string = "";

    end_date  : string = "";
    end_time  : string = "";
    end_hour  : string = "";
    end_min   : string = "";
    end_am_pm : string = "";

    lunar_date_text                    : string = ""; // 음력 텍스트

    recurrence_html                    : string = ""; // 일반적으로 가운데 텍스트와 같음
    recurrence_start_text              : string = ""; // ex) 2019.10.17 목요일 [반복 시작일 텍스트]   
    recurrence_start_for_month_text    : string = ""; // ex) 매 월 17일 [매월 반복 ~일 텍스트]
    recurrence_start_for_month_th_text : string = ""; // ex) 매 월 3번째 목요일 [매월 반복 ~번째 ~요일 텍스트] 
    recurrence_start_for_year_text     : string = ""; // ex) 매 10월 17일 [매년 반복 ~월 ~일 텍스트 ]
    recurrence_start_for_year_th_text  : string = ""; // ex) 매 10월 3번째 목요일 [매년 반복 ~월 ~번째 주 ~ 요일 텍스트]
    recurrence_end_text                : string = ""; // ex) 2019.10.17 목요일 [반복종료일 텍스트]

    day_repeat   : boolean = true;
    week_repeat  : boolean = false;
    month_repeat : boolean = false;
    year_repeat  : boolean = false;

    repeat_infinite : boolean = true;
    month_year_left_option : boolean = true;
    repeat_value : number | null = null;

    selected_day_of_week : number[] = []; // ex) [0, 1, 2, 3, 4, 5, 6] => 일 월 화 수 목 금 토

    alarm_model : string[] = [];

    mapSearchQuery : string = "";

    // 새로 첨부할 이미지, 파일 객체 담는 곳  { index : number, file : File }
    image_files : any = []; 
    all_files   : any = [];

    image_max_cnt : number = 4;
    image_drag : boolean = false;

    file_permission : boolean = false;
    file_drag : boolean = false;

    // 공유 옵션
    share_option : SHARE_OPTION = SHARE_OPTION.SHARE;
    selected_friend_user_ids : number[] = [];
    group_user_selected_key : string[] = [];
    team_user_selected_key : string[] = [];
    selected_user_ids : number[] = [];
    selected_group_ids : number[] = [];
    selected_team_ids : number[] = [];
    
    push_yn : string = 'Y';

    // 업무
    work_template_list  : t_work_template[] = []; // 업무 템플릿 리스트 전체
    flow_template_list  : t_work_template[] = []; // 단계 업무 템플릿 리스트
    check_template_list : t_work_template[] = []; // 체크 업무 템플릿 리스트

    target_user_list       : t_user[]               = [];    // 업무 대상이 될수있는 그룹원, 팀원   
    selected_work_template : t_work_template | null = null;  // 선택된 업무 템플릿
    assignment_user_list   : number[]               = [];    // 업무자 user_id 리스트
    work_status_list       : t_work_status[]        = [];    // 업무자별 업무 상태
    user_work_status       : t_work_status | null   = null;  // 사용자의 업무 상태

    all_member_count : number = 0;

    show_work_template : boolean = false;
    view_template_type : string  = 'ALL';

    // 조회에서 사용하는 변수
    is_attendable   : boolean = false;
    attend_data     : t_event_attend | null = null;
    vote_result     : t_vote_item | null = null;
    new_vote_index  : number[] = [];
    is_exist_reply  : boolean = false;

    attendable_user_count : number = 0;
    attend_user_count : number = 0;
    vote_user_count : number[] = [];
    read_info : any[] = [];

    // 수정할때 사용되는 변수
    repeat_modify_code : string = ''; // 비어있음 : 일반 수정 
                                      // A : 전체 일정 수정 
                                      // F : 이후 일정 수정
                                      // E : 이 일정만 수정

    // layout 조건
    group_select_open : boolean = false;
    color_select_open : boolean = false;
    time_from_open    : boolean = false;
    time_to_open      : boolean = false;
    repeat_open       : boolean = false;
    alarm_list_open   : boolean = false;
    read_share_flag   : boolean = false;

    // 권한
    is_createable : boolean = false; // 생성 권한
    is_copyable   : boolean = false; // 복사 권한
    is_editable   : boolean = false; // 수정 권한 (일정 수정)
    is_deleteable : boolean = false; // 삭제 권한 (일정 삭제)
    is_shared     : boolean = false; // 공유 받아서 볼 수 있는 일정 임을 나타내는 플래그
    is_premium_event : boolean = false;

    detail_scroll_height : number = 0;

    recurrence_end : Date = new Date();

    repeat_seq : number = 0;

    show_private_tooltip : boolean = false;

    async beforeMount() : Promise<void> {

        // 들어올때 event_id가 있는 경우 조회
        if( this.event_crud_type != CRUD_TYPE.CREATE ) {
            await this.getEvent();
            await this.makeDateString();
            await this.makeStringByRrule();
        }
    }

    async mounted() : Promise<void> {

        //hover
        // $('.tooltipSpan').hover(function(){
        //     $('.requiredToolBox').css("display", "block");
        // }, function(){
        //     $('.requiredToolBox').css("display", "none");
        // });

        const vue = this;
        await this.setDatePicker();

        // 생성일때만 
        if( this.event_crud_type == CRUD_TYPE.CREATE ) {
            this.makeDateString();

            // 반복값이 있는 일정을 복사하고 새로고침했을때
            if( this.event.event_data.schedule_date.rrule != null ) {
                this.makeStringByRrule();
            }

            // 프로젝트가 아닌 임시보관함 이벤트들
            if( this.event.is_temp == true && this.event.event_sub_type != EVENT_SUB_TYPE.WORK ) {
                this.share_option = (this.event.share_option ? this.event.share_option : SHARE_OPTION.SHARE);
                this.selected_friend_user_ids = JSON.parse(JSON.stringify((this.event.selected_friend_user_ids ? this.event.selected_friend_user_ids : [])));
                this.group_user_selected_key = JSON.parse(JSON.stringify((this.event.group_user_selected_key ? this.event.group_user_selected_key : [])));
                this.team_user_selected_key = JSON.parse(JSON.stringify((this.event.team_user_selected_key ? this.event.team_user_selected_key : [])));
                this.selected_user_ids = JSON.parse(JSON.stringify((this.event.selected_user_ids ? this.event.selected_user_ids : [])));
                this.selected_group_ids = JSON.parse(JSON.stringify((this.event.selected_group_ids ? this.event.selected_group_ids : [])));
                this.selected_team_ids = JSON.parse(JSON.stringify((this.event.selected_team_ids ? this.event.selected_team_ids : [])));

                // selected_friend_user_ids, group_user_selected_key, team_user_selected_key 실제 데이터와 비교해서 선택못하는건 제외하자
                this.selected_friend_user_ids = this.selected_friend_user_ids.filter(user_id => this.getFriend(user_id) != null);
                this.group_user_selected_key = this.group_user_selected_key.filter(key => this.group_info_list.filter(group_info => (group_info.group_id == Number(key.split[0]))).length > 0);
                this.team_user_selected_key = this.team_user_selected_key.filter(key => this.group_info_list.filter(group_info => (group_info.team_id == Number(key.split[0]))).length > 0);

                this.selected_user_ids = this.selected_user_ids.filter(user_id => (this.selected_friend_user_ids.indexOf(user_id) > -1) ||
                                                                                  (this.group_user_selected_key.filter(key => user_id == Number(key.split[1]))).length > 0 || 
                                                                                  (this.team_user_selected_key.filter(key => user_id == Number(key.split[1]))).length > 0 );
                this.selected_group_ids = this.selected_group_ids.filter(group_id => this.group_info_list.filter(group_info => (group_info.team_id == group_id)).length > 0);
                this.selected_team_ids = this.selected_team_ids.filter(team_id => this.group_info_list.filter(group_info => (group_info.team_id == team_id)).length > 0);
            }
        }

        // 일정 조회 일때만
        else if( this.event_crud_type == CRUD_TYPE.READ && this.event.event_sub_type != EVENT_SUB_TYPE.WORK ) {
            await this.kakaoShareInit();
        }
        
        await this.get_group_role_service();
        await this.permissionCheck();

        // 일정 등록시 시간선택부분 마우스 오버시 가운데 화살표 데코
		$("#s_eventFrYmd, #s_FrTm").mouseover(() => { $(".timeTd .inputgroup").addClass("left"); });
		$("#s_eventFrYmd, #s_FrTm").mouseleave(() => { $(".timeTd .inputgroup").removeClass("left"); });
		$("#s_eventToYmd, #s_ToTm").mouseover(() => { $(".timeTd .inputgroup").addClass("right"); });
		$("#s_eventToYmd, #s_ToTm").mouseleave(() => { $(".timeTd .inputgroup").removeClass("right"); });

        /**
         * 선택된 영역이 ~~이 아니라면 팝업 닫기
         */
        $('html').on("click", (e) => {
            if( e.target.id != "s_FrTm" && e.target.id != "s_frHour" && e.target.id != "s_frMin" && e.target.id != "s_frAmpm" ) { this.time_from_open = false; }
            if( e.target.id != "s_ToTm" && e.target.id != "s_toHour" && e.target.id != "s_toMin" && e.target.id != "s_toAmpm" ) { this.time_to_open   = false; }
        });

        await this.setScroll();
        
        // 그룹 정보 가져오기
        await this.getGroupInformation();

        // 알람
        if( this.event.event_data.alarm != null ){
            const alarm_count : number = this.event.event_data.alarm.length;
            for( let i = 0; i < alarm_count; i++ ){
                this.alarm_model.push(this.event.event_data.alarm[i].trigger);
            }
        }

        // 업무 생성
        if( this.event_crud_type == CRUD_TYPE.CREATE && this.event.event_sub_type == EVENT_SUB_TYPE.WORK ) {

            const temp_template_id = this.event.event_data.work?.template_id ?? "";

            await this.getAllAssignmentUser();
            await this.getWorkTepmplate();

            if( this.event.is_temp == true && this.event.event_data.work != null ) {

                // 선택된 프로젝트 템플릿
                for( const template of this.work_template_list ) {
                    if( template.template_id == temp_template_id ) {
                        this.selectWorkTemplate(template);
                        break;
                    }
                }

                // 선택된 담당자
                let users_temp : number[] | undefined = this.event.event_data.work.assign_user_ids;
                let users : number[] = users_temp ? users_temp : [];

                // 전체
                const all_users = JSON.parse(JSON.stringify(this.assignment_user_list));

                this.assignment_user_list.splice(0, this.assignment_user_list.length);

                // 그롭 또는 팀 멤버와 저장된 담당자 목록 비교해서 그룹 또는 팀에 존재하는 사람만 추가
                for( const all_user_id of all_users ) {
                    for( const selected_user_id of users ) {
                        if( all_user_id == selected_user_id ) {
                            this.assignment_user_list.push(selected_user_id);
                            break;
                        }
                    }
                }

            }
        }

        window["changeSeletedLocation"] = this.changeSeletedLocation;
        window["setShareInfo"]          = this.setShareInfo;
        window["createVote"]            = this.createVote;
        window["updateVote"]            = this.updateVote;
        window["deleteVote"]            = this.deleteVote;
        window["getEvent"]              = this.getEvent;
        window["repeatModify"]          = this.repeatModify;
        window["repeatDelete"]          = this.repeatDelete;
        window["setEventWorkData"]      = this.setEventWorkData;

        await this.getPushYnPreference();

        this.recurrence_end = this.event.event_data.schedule_date.recurrence_end;
    }

    beforeDestroy() : void {
        $('html').unbind();
    }

    /**
     * 스크롤 설정
     */
    async setScroll() : Promise<void> {
        const windowOuterHeight : number | undefined = $(window).outerHeight();
        const titleBoxOuterHeight : number | undefined = $('.title_box').outerHeight();
        
        this.detail_scroll_height = (windowOuterHeight == null ? 0 : windowOuterHeight) - (titleBoxOuterHeight == null ? 0 : titleBoxOuterHeight);

        // @ts-ignore schDetailScroll 스크롤
        // $('#schDetailScroll').mCustomScrollbar({
        //     axis : 'y',
        //     setHeight : (windowOuterHeight == null ? 0 : windowOuterHeight) - (titleBoxOuterHeight == null ? 0 : titleBoxOuterHeight),
        //     scrollbarPosition : 'outside',
        //     mouseWheelPixels:350,
        // });
        
        // 업무에서는 그룹TR 사용 안함
        if( this.event.event_sub_type != EVENT_SUB_TYPE.WORK ) {
            // @ts-ignore schedule_group_div 스크롤
            $('#schedule_group_div').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels:200,
                autoDraggerLength : false,
            });
        }

        // @ts-ignore alarmDivScroll 스크롤
        $('.alarmDivScroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 200,
            autoDraggerLength : false,
        });
    }

    /**
     * push_yn 플래그 업데이트
     */
    async getPushYnPreference() : Promise<void> {
        try {
            let push_yn_preference = await this.get_user_preference("push_yn", false);

            if( push_yn_preference == null ) {
                push_yn_preference = { "user_id" : this.user_id, "cate" : "push_yn", "preference" : { "WEB" : { "report" : true, "meetinglog" : true, "work" : true, "basic" : true } }, "audit_modified" : new Date() };
            }

            let preference = push_yn_preference['preference'];

            if( preference == null ) {
                preference = { "WEB" : { "report" : true, "meetinglog" : true, "work" : true, "basic" : true } };
            }

            let web_push_yn_preference = preference['WEB'];

            if( web_push_yn_preference == null ) {
                web_push_yn_preference = { "report" : true, "meetinglog" : true, "work" : true, "basic" : true };
            }
            
            if( this.isReport(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                const report_push_yn = web_push_yn_preference['report'];
                this.push_yn = report_push_yn == null || report_push_yn == true ? 'Y' : 'N';
            }

            else if( this.isMeetingLog(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                const meetinglog_push_yn = web_push_yn_preference['meetinglog'];
                this.push_yn = meetinglog_push_yn == null || meetinglog_push_yn == true ? 'Y' : 'N';
            }

            else if( this.isWork(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                const work_push_yn = web_push_yn_preference['work'];
                this.push_yn = work_push_yn == null || work_push_yn == true ? 'Y' : 'N';
            }

            else if( this.isEvent(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                const event_push_yn = web_push_yn_preference['basic'];
                this.push_yn = event_push_yn == null || event_push_yn == true ? 'Y' : 'N';
            }

            console.log(this.push_yn);
            
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.push_yn = 'Y';
        }
    }

    /**
     * datepicker 설정
     */
    async setDatePicker() : Promise<void> { 
        const vue = this;

        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: async function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');

                if( id == null ){
                    return;
                }
                
                const select_date : Date = new Date(dateText); 

                inst.input.val(`${dateText} ${vue.getDayOfWeek(select_date)}`);

                // 시작일 변경
                if( id == 's_eventFrYmd'){
                    
                    // start 원래의 시간, 분 유지
                    select_date.setHours(vue.event.event_data.schedule_date.start instanceof Date 
                                       ? vue.event.event_data.schedule_date.start.getHours() 
                                       : new Date(vue.event.event_data.schedule_date.start).getHours());

                    select_date.setMinutes(vue.event.event_data.schedule_date.start instanceof Date 
                                         ? vue.event.event_data.schedule_date.start.getMinutes() 
                                         : new Date(vue.event.event_data.schedule_date.start).getMinutes()); 

                    // date_term 유지 준비 
                    const term_date : Date = new Date(select_date.getTime());

                    // end 원래의 시간, 분 유지
                    term_date.setHours(vue.event.event_data.schedule_date.end instanceof Date 
                                       ? vue.event.event_data.schedule_date.end.getHours() 
                                       : new Date(vue.event.event_data.schedule_date.end).getHours());

                    term_date.setMinutes(vue.event.event_data.schedule_date.end instanceof Date 
                                         ? vue.event.event_data.schedule_date.end.getMinutes() 
                                         : new Date(vue.event.event_data.schedule_date.end).getMinutes()); 

                    // date_term 유지
                    term_date.setDate(term_date.getDate() + vue.date_term);

                    // 종료일이 시작일 보다 느릴때 시작일의 +1일을 해준다 ex) 시작일이 오후 11:50, 종료일이 다음날 오전 12:00 일때 시작 년월일을 바꾸는 경우
                    if( term_date.getTime() < select_date.getTime() ) {
                        term_date.setDate(select_date.getDate() + 1);
                    }

                    // 반복이 없다면 recurrence_end를 변경 시켜줌
                    if( vue.event.event_data.schedule_date.rrule == null || vue.event.event_data.schedule_date.rrule == "" ){
                        vue.event.event_data.schedule_date.recurrence_end = term_date;
                    } 
                    
                    // 반복이 있다면 시작일은 반복종료일 보다 느릴 수 없다
                    else {
                        const start_for_compare : Date = new Date(select_date);
                        start_for_compare.setHours(0);
                        start_for_compare.setMinutes(0);
                        start_for_compare.setSeconds(0);
                        start_for_compare.setMilliseconds(0);

                        const recurrence_end_for_compare : Date = new Date(vue.event.event_data.schedule_date.recurrence_end);
                        recurrence_end_for_compare.setHours(0);
                        recurrence_end_for_compare.setMinutes(0);
                        recurrence_end_for_compare.setSeconds(0);
                        recurrence_end_for_compare.setMilliseconds(0);

                        if( vue.repeat_infinite == false && start_for_compare.getTime() > recurrence_end_for_compare.getTime() ) {
                            vue.hodu_show_dialog('alert', '시작일은 반복 종료일 보다 느릴 수 없습니다', ['확인']);
                            inst.input.val(`${moment(new Date(vue.event.event_data.schedule_date.start)).format('YYYY.MM.DD')} ${vue.getDayOfWeek(new Date(vue.event.event_data.schedule_date.start))}`);
                            return;
                        }
                    }

                    // date 적용
                    vue.event.event_data.schedule_date.start = select_date;
                    vue.event.event_data.schedule_date.end   = term_date;

                // 종료일 변경
                } else {

                    // end 원래의 시간, 분 유지
                    select_date.setHours(vue.event.event_data.schedule_date.end instanceof Date 
                                       ? vue.event.event_data.schedule_date.end.getHours() 
                                       : new Date(vue.event.event_data.schedule_date.end).getHours());

                    select_date.setMinutes(vue.event.event_data.schedule_date.end instanceof Date 
                                         ? vue.event.event_data.schedule_date.end.getMinutes() 
                                         : new Date(vue.event.event_data.schedule_date.end).getMinutes()); 

                    // 시작일과 종료일이 완벽히 같은 시간이라면 => 종료일의 시간을 + 10분 함
                    if( new Date(vue.event.event_data.schedule_date.start).getTime() == select_date.getTime() ) {
                        select_date.setMinutes(select_date.getMinutes() + 10);
                    
                    // 종료일이 더 옛날 날짜라면 => 시작일은 종료일로 변경 시작일 시간의 + 1시간
                    } else if( new Date(vue.event.event_data.schedule_date.start).getTime() > select_date.getTime() ){
                        const new_start : Date = new Date(select_date.getTime());
                        new_start.setHours(new Date(vue.event.event_data.schedule_date.start).getHours());
                        new_start.setMinutes(new Date(vue.event.event_data.schedule_date.start).getMinutes());
                        vue.event.event_data.schedule_date.start = new_start;

                        select_date.setHours(new Date(vue.event.event_data.schedule_date.start).getHours() + 1);
                        select_date.setMinutes(new Date(vue.event.event_data.schedule_date.start).getMinutes());
                    }

                    // date 적용
                    vue.event.event_data.schedule_date.end = select_date;

                    // 반복이 없다면 recurrence_end를 변경 시켜줌
                    if( vue.event.event_data.schedule_date.rrule == null || vue.event.event_data.schedule_date.rrule == "" ){
                        vue.event.event_data.schedule_date.recurrence_end = select_date;
                    }

                    // date-term 변경
                    vue.date_term = vue.getDateDiff(vue.event.event_data.schedule_date.start, vue.event.event_data.schedule_date.end);

                    // date_term이 0보다 클때 연속일정 아니라면 일반 일정
                    vue.event.event_data.schedule_date.isContinuos = ( vue.date_term > 0 );
                    
                }
                
                // 음력인 경우
                if( vue.event.event_data.schedule_date.lunar_yn == true ) {
                    vue.event.event_data.schedule_date.lunar_start = await vue.hodu_solar_to_lunar(vue.event.event_data.schedule_date.start);
                    vue.event.event_data.schedule_date.lunar_end   = vue.event.event_data.schedule_date.lunar_start;
                }
                
                // rrule이 존재한다면 Rrule을 다시 만든다
                if( vue.event.event_data.schedule_date.rrule != null && vue.event.event_data.schedule_date.rrule.length > 0 ) {
                    vue.makeRrule();
                }

                vue.makeDateString();
            },
        };
        
        // 일정 조회가 아닐때만
        if( this.event_crud_type != CRUD_TYPE.READ ) {
            // @ts-ignore
            $('#s_eventFrYmd, #s_eventToYmd').datepicker(option);
        }

        let recurrence_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                const select_date : Date = new Date(dateText);
                
                const start_for_compare : Date = new Date(vue.event.event_data.schedule_date.start);
                start_for_compare.setHours(0);
                start_for_compare.setMinutes(0);
                start_for_compare.setSeconds(0);
                start_for_compare.setMilliseconds(0);

                if( select_date.getTime() < start_for_compare.getTime() ) {
                    vue.hodu_show_dialog('alert', '반복 종료일이 시작일 보다 빠를 수 없습니다', ['확인']);
                    inst.input.val(`${moment(new Date(vue.event.event_data.schedule_date.recurrence_end)).format('YYYY.MM.DD')} ${vue.getDayOfWeek(new Date(vue.event.event_data.schedule_date.recurrence_end))}요일`);
                    return;
                }

                inst.input.val(`${dateText} ${vue.getDayOfWeek(select_date)}요일`);

                vue.recurrence_end = new Date(select_date);
                vue.recurrence_end.setHours(new Date(vue.event.event_data.schedule_date.end).getHours());
                vue.recurrence_end.setMinutes(new Date(vue.event.event_data.schedule_date.end).getMinutes());
                
                // setTimeout(() => { vue.makeRrule() }, 1);
                vue.makeDateString();
            }
        }

        // @ts-ignore
        $('#dayRecurrenceEnd, #weekRecurrenceEnd, #monthRecurrenceEnd, #yearRecurrenceEnd, #lunarRecurrenceEnd').datepicker(recurrence_option);
    }

    /**
     * 날짜 텍스트 만들기
     */
    async makeDateString() : Promise<void> {
        const start : Date = this.event.event_data.schedule_date.start instanceof Date 
                             ? this.event.event_data.schedule_date.start 
                             : new Date(this.event.event_data.schedule_date.start);

        this.start_date  = `${start.getFullYear()}.${`0${start.getMonth() + 1}`.slice(-2)}.${`0${start.getDate()}`.slice(-2)} ${this.getDayOfWeek(start)}`;
        this.start_hour  = `0${start.getHours() <= 12 ? ( start.getHours() == 0 ? 12 : start.getHours() ) : start.getHours() - 12}`.slice(-2);
        this.start_min   = `0${start.getMinutes()}`.slice(-2);
        this.start_am_pm = start.getHours() < 12 ? "AM" : "PM";
        const string_start_am_pm : string = start.getHours() < 12 ? "오전" : "오후";
        this.start_time  = `${string_start_am_pm} ${this.start_hour}:${this.start_min}`;

        const end : Date = this.event.event_data.schedule_date.end instanceof Date 
                           ? this.event.event_data.schedule_date.end 
                           : new Date(this.event.event_data.schedule_date.end);

        this.end_date  = `${end.getFullYear()}.${`0${end.getMonth() + 1}`.slice(-2)}.${`0${end.getDate()}`.slice(-2)} ${this.getDayOfWeek(end)}`;
        this.end_hour  = `0${end.getHours() <= 12 ? ( end.getHours() == 0 ? 12 : end.getHours() ) : end.getHours() - 12}`.slice(-2);
        this.end_min   = `0${end.getMinutes()}`.slice(-2);
        this.end_am_pm = end.getHours() < 12 ? "AM" : "PM";
        const string_end_am_pm : string = end.getHours() < 12 ? "오전" : "오후";
        this.end_time  = `${string_end_am_pm} ${this.end_hour}:${this.end_min}`;

        const weekOfMonthNumber : number = this.getWeekOfMonthNumber(new Date(this.event.event_data.schedule_date.start));

        // 음력 날짜 적용
        this.lunar_date_text = (await this.hodu_solar_to_lunar(this.event.event_data.schedule_date.start)).replace(/-/ig, '.');
        
        // 반복 날짜 적용
        this.recurrence_start_text              = `${moment(this.event.event_data.schedule_date.start).format('YYYY.MM.DD')} ${this.getDayOfWeek(new Date(this.event.event_data.schedule_date.start))}요일`;
        this.recurrence_start_for_month_text    = `매 월 ${new Date(this.event.event_data.schedule_date.start).getDate()}일`;
        this.recurrence_start_for_month_th_text = `매 월 ${weekOfMonthNumber}번째 ${this.getDayOfWeek(new Date(this.event.event_data.schedule_date.start))}요일`;
        this.recurrence_start_for_year_text     = `매 ${new Date(this.event.event_data.schedule_date.start).getMonth() + 1}월 ${new Date(this.event.event_data.schedule_date.start).getDate()}일`;
        this.recurrence_start_for_year_th_text  = `매 ${new Date(this.event.event_data.schedule_date.start).getMonth() + 1}월 ${weekOfMonthNumber}번째 ${this.getDayOfWeek(new Date(this.event.event_data.schedule_date.start))}요일`; 
        this.recurrence_end_text                = `${moment(this.event.event_data.schedule_date.recurrence_end).format('YYYY.MM.DD')} ${this.getDayOfWeek(new Date(this.event.event_data.schedule_date.recurrence_end))}요일`;
    }

    /**
     * 한 글자 요일 텍스트 가져오기 
     */
    getDayOfWeek(date : Date) : string {

        if( date == null ){
            return "";
        }

        switch( date.getDay() ){
            case 0:
                return "일";

            case 1:
                return "월";

            case 2:
                return "화";

            case 3:
                return "수";

            case 4:
                return "목";

            case 5:
                return "금";

            case 6:
                return "토";

            default:
                return "?";
        }
    }

    /**
     * 해당 월의 몇 번째 요일인지 구하기
     */
    getWeekOfMonthNumber(target_date : Date) : number {
        const first_date : Date = new Date(target_date.getTime());
        first_date.setDate(1);

        const first_day_of_week : number = first_date.getDay();
        let first_target_week_date : number = 0; // 처음 같은 요일이 나오는 일 

        // 해당 월의 첫번째 같은 요일을 구한다
        if( first_day_of_week > target_date.getDay() ) {
            first_target_week_date = 7 - ( first_day_of_week - target_date.getDay() );
        } else {
            first_target_week_date = 1 + ( target_date.getDay() - first_day_of_week );
        }

        // 해당 월의 몇 번째 주인지 구한다
        let week_of_month_number = 0;
        let target_date_number = target_date.getDate();
        for( let i = first_target_week_date; i <= target_date_number; i += 7) {
            week_of_month_number++;
        }

        return week_of_month_number;
    }

    /**
     * 그룹 정보 가공
     */
    async getGroupInformation() : Promise<void> {
        
        // 임시저장이 아니고 개인 캘린더일때만 개인일정을 넣는다 && 임시보관함일때 일반 일정이라면 개인 일정을 넣는다
        if( (this.event.is_temp != true && this.scope == OWNER_TYPE.PERSONAL) || (this.event.is_temp == true && this.isSchedule(this.event.event_sub_type as EVENT_SUB_TYPE) == true) ) {
            this.group_team_list.push({
                "target" : OWNER_TYPE.PERSONAL,
                "target_id" : this.user_id,
                "target_name" : "개인일정",
                "target_color" : "transparent",
                "max_image_count" : 4
            });
        }

        // 로컬 데이터 받아오기
        const local_data : string | null = hodu_local_storage.getItem(`${this.user_id}`);
        const temp_local_data : LocalStorageInfo = JSON.parse(local_data ?? '{}');
        if( this.event_crud_type == CRUD_TYPE.CREATE ) {
            this.event.event_data.color = temp_local_data.last_event_created_color != null && temp_local_data.last_event_created_color.length > 0 ? temp_local_data.last_event_created_color : "#477FFF";
        }

        // group_info가 없으면 수행 할 필요 없음
        if( this.group_info_list == null ){
            return;
        }
        
        // 가공 시작
        const group_info_list_length : number = this.group_info_list.length;
        const user_group_role_length : number = this.user_group_role.length;
        for( let i = 0; i < group_info_list_length; i++ ) {

            const group_info = this.group_info_list[i];

            // 인증 안된 상태라면 호두홈, 호두키즈 일정 생성 불가
            if( (this.auth_info == null || this.auth_info.auth_check == false) && (group_info.biz_type == 'BIZH' || group_info.group_info.project_type == 'kids') ) {
                continue;
            }
            
            // group_role 가져와서 그룹 권한 체크 후 데이터 쌓기
            for( let k = 0; k < user_group_role_length; k++) {
                if( group_info.group_id != this.user_group_role[k].group_id ) continue;
                    
                // 일정인 경우 일정 권한체크
                if( this.isSchedule(this.event.event_sub_type as EVENT_SUB_TYPE) == true && this.user_group_role[k].group_permmision.event.create == false ){
                    break;
                }

                // 업무일지인 경우 업무일지 권한체크
                if( this.isReport(this.event.event_sub_type as EVENT_SUB_TYPE) == true && this.user_group_role[k].group_permmision.report.create == false ){
                    break;
                }

                // 회의록인 경우 회의록 권한체크
                if( this.isMeetingLog(this.event.event_sub_type as EVENT_SUB_TYPE) == true && this.user_group_role[k].group_permmision.meetinglog.create == false ){
                    break;
                }

                // 프로젝트인 경우 프로젝트 권한체크
                if( this.isWork(this.event.event_sub_type as EVENT_SUB_TYPE) == true && this.user_group_role[k].group_permmision.work.create == false ){
                    break;
                }

                // 임시저장된 일정은 제외
                if( this.event.is_temp != true ) {
                    // 그룹&팀 달력인데 BIZ_ID가 같지않다면 제외시킨다
                    if( this.scope != OWNER_TYPE.PERSONAL && group_info.biz_id != this.scope_group_team_option.biz_id ) {
                        break;
                    }
                }

                // 그룹 가공 정보 추가
                this.group_team_list.push({
                    "target" : "GROUP",
                    "target_id" : group_info.group_id,
                    "target_name" : group_info.group_name,
                    "target_color" : group_info.color,
                    "max_image_count" : group_info.limit_event_image_count,
                    "max_file_count" : group_info.limit_event_attachment
                });
            }

            if( group_info.teams == null || group_info.teams.length < 1 ){
                continue;
            }
            
            const teams_length : number = group_info.teams.length
            const user_team_role_length : number = this.user_team_role.length;
            for( let j = 0; j < teams_length; j++ ) {

                const team_info = group_info.teams[j];

                for( let k = 0; k < user_team_role_length; k++) {

                    const team_role = this.user_team_role[k];

                    if( team_info.team_id != team_role.team_id ) continue;
                        
                    // 일정인 경우 일정 권한체크
                    if( this.isSchedule(this.event.event_sub_type as EVENT_SUB_TYPE) == true && team_role.team_permmision.event.create == false ){
                        break;
                    }

                    // 업무일지인 경우 업무일지 권한체크
                    if( this.isReport(this.event.event_sub_type as EVENT_SUB_TYPE) == true && team_role.team_permmision.report.create == false ){
                        break;
                    }

                    // 회의록인 경우 회의록 권한체크
                    if( this.isMeetingLog(this.event.event_sub_type as EVENT_SUB_TYPE) == true && team_role.team_permmision.meetinglog.create == false ){
                        break;
                    }

                    // 프로젝트인 경우 프로젝트 권한체크
                    if( this.isWork(this.event.event_sub_type as EVENT_SUB_TYPE) == true && team_role.team_permmision.work.create == false ){
                        break;
                    }

                    // 임시저장된 일정은 제외
                    if( this.event.is_temp != true ) {
                        // 그룹&팀 달력인데 BIZ_ID가 같지않다면 제외시킨다
                        if( this.scope != OWNER_TYPE.PERSONAL && team_info.biz_id != this.scope_group_team_option.biz_id ) {
                            break;
                        }
                    }

                    // 팀 가공 정보 추가
                    this.group_team_list.push({
                        "target" : "TEAM",
                        "target_id" : team_info.team_id,
                        "target_name" : team_info.team_name,
                        "target_color" : team_info.color,
                        "max_image_count" : group_info.limit_event_image_count,
                        "max_file_count" : group_info.limit_event_attachment
                    });
                }
            }
        }

        // 그룹 정보
        let target_group_team : any = null;
        const group_team_list_count : number = this.group_team_list.length;

        // 개인 일정인 경우
        if( this.event.event_type == OWNER_TYPE.PERSONAL ) {
            this.image_max_cnt = 4;
            this.file_permission = true;
            this.group_color = 'transparent';
            target_group_team = {};
        }

        // 그룹, 팀 일정인 경우
        else {

            for( let i = 0; i < group_team_list_count; i++ ){
                switch( this.event.event_type ){

                    case OWNER_TYPE.GROUP:
                        if( this.group_team_list[i].target != "GROUP" || this.event.group_id != this.group_team_list[i].target_id ){
                            continue;
                        }

                        this.image_max_cnt = this.group_team_list[i].max_image_count;
                        this.file_permission = this.group_team_list[i].max_file_count > 0;
                        if( this.share_info == null || this.share_info.share_scope != OWNER_TYPE.PERSONAL || (this.is_shared == false && this.share_info.share_scope == OWNER_TYPE.PERSONAL) ) {
                            this.group_color = this.group_team_list[i].target_color;
                        }
                        target_group_team = this.group_team_list[i];
                        break;

                    case OWNER_TYPE.TEAM:
                        if( this.group_team_list[i].target != "TEAM" || this.event.team_id != this.group_team_list[i].target_id ){
                            continue;
                        }

                        this.image_max_cnt = this.group_team_list[i].max_image_count;
                        this.file_permission = this.group_team_list[i].max_file_count > 0;
                        if( this.share_info == null || this.share_info.share_scope != OWNER_TYPE.PERSONAL || (this.is_shared == false && this.share_info.share_scope == OWNER_TYPE.PERSONAL) ) {
                            this.group_color = this.group_team_list[i].target_color;
                        }
                        target_group_team = this.group_team_list[i];
                        break;
                }
            }
        } 

        // 없는 경우 공유된 일정이므로 group_role, team_role 에서 찾는다
        if( target_group_team == null ) {
            switch( this.event.event_type ) {
                case OWNER_TYPE.GROUP:
                    for( const group of this.group_info_list ) {
                        if( group.group_id != this.event.group_id ) continue;
                        this.image_max_cnt = group.limit_event_image_count;
                        this.file_permission = group.limit_event_attachment > 0;
                        if( this.share_info == null || this.share_info.share_scope != OWNER_TYPE.PERSONAL || (this.is_shared == false && this.share_info.share_scope == OWNER_TYPE.PERSONAL) ) {
                            this.group_color = group.color;
                        }
                        target_group_team = group;
                        break;
                    } 
                    break;

                case OWNER_TYPE.TEAM:
                    for( const group of this.group_info_list ) {
                        if( group.teams == null ) continue;

                        for( const team of group.teams ) {
                            if( team.team_id != this.event.team_id ) continue;
                            this.image_max_cnt = team.limit_event_image_count;
                            this.file_permission = team.limit_event_attachment > 0;
                            if( this.share_info == null || this.share_info.share_scope != OWNER_TYPE.PERSONAL || (this.is_shared == false && this.share_info.share_scope == OWNER_TYPE.PERSONAL) ) {
                                this.group_color = team.color;
                            }
                            target_group_team = team;
                            break;
                        }

                        if( target_group_team != null ) break;
                        
                        break;
                    }
                    break;
            }
        }

        // 개인달력에서 일정 생성이 아니라면 나간다, EventShortCreate에서 들어온 경우에도 바뀌지 않고 나간다
        if( this.scope != OWNER_TYPE.PERSONAL || this.event.event_sub_type != EVENT_SUB_TYPE.SCHEDULE || this.event_crud_type != CRUD_TYPE.CREATE || this.is_moved_by_short_create == true ) {
            return;
        }

        local_storage_info.last_event_created_scope = temp_local_data.last_event_created_scope;

        // 이전에 선택한 그룹&팀이 없다면 나간다
        if( local_storage_info == null || local_storage_info.last_event_created_scope == null || local_storage_info.last_event_created_scope.scope == null || 
            local_storage_info.last_event_created_scope.scope.length < 1 || local_storage_info.last_event_created_scope.scope_id == null || local_storage_info.last_event_created_scope.scope_id < 1 ) {
            return;
        }

        // 마지막에 선택한 그룹&팀 정보 찾기
        for( let i = 0; i < group_team_list_count; i++ ){
            switch( local_storage_info.last_event_created_scope.scope ) {
                case "GROUP":
                    if( this.group_team_list[i].target != "GROUP" || local_storage_info.last_event_created_scope.scope_id != this.group_team_list[i].target_id ){
                        continue;
                    }

                    this.event.event_type = local_storage_info.last_event_created_scope.scope;
                    this.event.calendar_id = `group-${this.group_team_list[i].target_id}`;
                    this.event.user_id = 0;
                    this.event.group_id = local_storage_info.last_event_created_scope.scope_id;
                    this.event.event_data.event_owner_group_id = local_storage_info.last_event_created_scope.scope_id;
                    this.event.team_id = 0;
                    this.event.event_data.event_owner_team_id = 0;
                    this.event.event_data.event_owner_group_name = this.group_team_list[i].target_name;

                    this.image_max_cnt = this.group_team_list[i].max_image_count;
                    this.file_permission = this.group_team_list[i].max_file_count > 0;
                    if( this.share_info == null || this.share_info.share_scope != OWNER_TYPE.PERSONAL || (this.is_shared == false && this.share_info.share_scope == OWNER_TYPE.PERSONAL) ) {
                        this.group_color = this.group_team_list[i].target_color;
                    }
                    break;

                case "TEAM":
                    if( this.group_team_list[i].target != "TEAM" || local_storage_info.last_event_created_scope.scope_id != this.group_team_list[i].target_id ){
                        continue;
                    }

                    this.event.event_type = local_storage_info.last_event_created_scope.scope;
                    this.event.calendar_id = `team-${this.group_team_list[i].target_id}`;
                    this.event.user_id = 0;
                    this.event.group_id = 0;
                    this.event.event_data.event_owner_group_id = 0;
                    this.event.team_id = local_storage_info.last_event_created_scope.scope_id;
                    this.event.event_data.event_owner_team_id = local_storage_info.last_event_created_scope.scope_id;
                    this.event.event_data.event_owner_team_name = this.group_team_list[i].target_name;

                    this.image_max_cnt = this.group_team_list[i].max_image_count;
                    this.file_permission = this.group_team_list[i].max_file_count > 0;
                    if( this.share_info == null || this.share_info.share_scope != OWNER_TYPE.PERSONAL || (this.is_shared == false && this.share_info.share_scope == OWNER_TYPE.PERSONAL) ) {
                        this.group_color = this.group_team_list[i].target_color;
                    }
                    break;

                default:
                    this.image_max_cnt = 4;
                    this.file_permission = true;
                    this.group_color = 'transparent';
                    break;
            }
        }
    }

    /**
     * 그룹 선택창 열기
     */
    groupSelectListOpen() : void {
        if( this.event_crud_type == CRUD_TYPE.READ ) {
            return;
        }

        this.group_select_open = !this.group_select_open;
    }

    /**
     * 그룹, 팀 선택
     */
    async group_team_select(group_team : any) : Promise<void> {
        this.event.event_type = group_team.target;
        this.group_color      = group_team.target_color;
        
        switch(group_team.target) {
            case OWNER_TYPE.PERSONAL:
                this.event.calendar_id                       = `personal-${this.user_id}`;
                this.event.user_id                           = this.user_id;
                this.event.group_id                          = 0;
                this.event.team_id                           = 0;
                this.event.event_data.event_owner_group_id   = 0;
                this.event.event_data.event_owner_group_name = "";
                this.event.event_data.event_owner_team_id    = 0;
                this.event.event_data.event_owner_team_name  = "";

                // if( this.event.event_data.attachment != null && this.event.event_data.attachment.files != null ) {
                //     this.event.event_data.attachment.files.splice(0, this.event.event_data.attachment.files.length);
                // }

                this.event.event_data.attend = false;
                this.event.event_data.vote.splice(0, this.event.event_data.vote.length);
                break;

            case OWNER_TYPE.GROUP:
                this.event.calendar_id                       = `group-${group_team.target_id}`;
                this.event.user_id                           = 0;
                this.event.group_id                          = group_team.target_id;
                this.event.team_id                           = 0;
                this.event.event_data.event_owner_group_id   = group_team.target_id;
                this.event.event_data.event_owner_group_name = group_team.target_name;
                this.event.event_data.event_owner_team_id    = 0;
                this.event.event_data.event_owner_team_name  = "";
                break;

            case OWNER_TYPE.TEAM:
                this.event.calendar_id                       = `team-${group_team.target_id}`;
                this.event.user_id                           = 0;
                this.event.group_id                          = 0;
                this.event.team_id                           = group_team.target_id;
                this.event.event_data.event_owner_group_id   = 0;
                this.event.event_data.event_owner_group_name = "";
                this.event.event_data.event_owner_team_id    = group_team.target_id;
                this.event.event_data.event_owner_team_name  = group_team.target_name;
                break;
        }

        // 사진 첨부 최대 이미지 개수 관련 설정 (정해진 이미지 개수 보다 많다면 없앤다)
        this.image_max_cnt = group_team.max_image_count;
        this.file_permission = this.event.group_id == 0 && this.event.team_id == 0 ? true : group_team.max_file_count > 0;
        if( this.event.event_data.attachment != null && this.event.event_data.attachment.imgs != null && this.event.event_data.attachment.imgs.length > this.image_max_cnt ){
            const file_count : number = this.event.event_data.attachment.imgs.length;
            for( let i = (file_count - 1); i >= this.image_max_cnt; i-- ) {
                this.event.event_data.attachment.imgs.splice(i, 1);
            }
        }

        this.group_select_open = false;

        await this.permissionCheck();
    }

    /**
     * 일정 색상 변경 창 열기
     */
    colorSelectListOpen() : void {
        this.color_select_open = !this.color_select_open;
    }

    /**
     * 일정 색상 변경
     */
    changeColor(e : Event, color : string, colorMode : string) : void {
        this.event.event_data.color = color;
        this.colorToneMode          = colorMode;
        this.color_select_open      = false;

        // 조회 일때 일정 개인설정 데이터 변경
        if( this.event_crud_type == CRUD_TYPE.READ ) {
            this.updateEventIndividual();
        }
    }

    /**
     * 시작 시간 변경 창 열기
     */
    timeFromListOpen() : void {
        if( this.event_crud_type == CRUD_TYPE.READ ) {
            return;
        }

        this.time_from_open = !this.time_from_open;
    }

    /**
     * 종료 시간 변경 창 열기
     */
    timeToListOpen() : void {
        if( this.event_crud_type == CRUD_TYPE.READ ) {
            return;
        }

        this.time_to_open = !this.time_to_open;
    }

    /**
     * 시작일 시간 변경
     */
    selectStartTimeHour(event) : void {
        const startHours : number = this.start_am_pm == "AM" && Number(event.target.value) == 12 
                                    ? 0 
                                    : (this.start_am_pm == "AM" && Number(event.target.value) != 12) || (this.start_am_pm == "PM" && Number(event.target.value) == 12)
                                    ? Number(event.target.value)
                                    : Number(event.target.value) + 12;

        const start : Date = new Date(this.event.event_data.schedule_date.start);
        start.setHours(startHours)
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 

        // 23시 일때
        if( start.getHours() == 23 ){
            
            // 50분인 경우
            if( start.getMinutes() == 50 ){
               end.setTime(start.getTime());
               end.setMinutes(start.getMinutes() + 10);

            // 0~40분 인 경우
            } else {
               end.setTime(start.getTime());
               end.setMinutes(50);
            }
           
        // 23시가 아니라면 + 1시간
        } else {
            end.setTime(start.getTime());
            end.setHours(start.getHours() + 1);
            end.setMinutes(start.getMinutes());
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        if( this.date_term < 1 ) { this.event.event_data.schedule_date.end = end; }

        this.makeDateString();
    }

    /**
     * 시작일 분 변경
     */
    selectStartTimeMin(event) : void {
        const start : Date = new Date(this.event.event_data.schedule_date.start);
        start.setMinutes(Number(event.target.value))
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 

        // 23시 일때
        if( start.getHours() == 23 ){
            
            // 50분인 경우
            if( start.getMinutes() == 50 ){
               end.setTime(start.getTime());
               end.setMinutes(start.getMinutes() + 10);

            // 0~40분 인 경우
            } else {
               end.setTime(start.getTime());
               end.setMinutes(50);
            }
           
        // 23시가 아니라면 + 1시간
        } else {
            end.setTime(start.getTime());
            end.setHours(start.getHours() + 1);
            end.setMinutes(start.getMinutes());
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        if( this.date_term < 1 ) { this.event.event_data.schedule_date.end = end; }

        this.makeDateString();
    }

    /**
     * 시작일 오전 오후 변경
     */
    selectStartTimeAmPm(event) : void {
        
        // 이미 같은 값이라면 return
        if( this.start_am_pm == event.target.value ){
            return;
        }

        const start : Date = new Date(this.event.event_data.schedule_date.start);
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 

        switch( event.target.value ){
            case "AM":
                start.setHours(start.getHours() - 12);
                break;
            
            case "PM":
                start.setHours(start.getHours() + 12);
                break;
        }
        
        // 23시 일때
        if( start.getHours() == 23 ){
            
            // 50분인 경우
            if( start.getMinutes() == 50 ){
               end.setTime(start.getTime());
               end.setMinutes(start.getMinutes() + 10);

            // 0~40분 인 경우
            } else {
               end.setTime(start.getTime());
               end.setMinutes(50);
            }
           
        // 23시가 아니라면 + 1시간
        } else {
            end.setTime(start.getTime());
            end.setHours(start.getHours() + 1);
            end.setMinutes(start.getMinutes());
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        if( this.date_term < 1 ) { this.event.event_data.schedule_date.end = end; }

        this.makeDateString();
    }

    /**
     * 종료일 시간 변경
     */
    selectEndTimeHour(event) : void {
        const endHours : number = this.end_am_pm == "AM" && Number(event.target.value) == 12 
                                  ? 0 
                                  : (this.end_am_pm == "AM" && Number(event.target.value) != 12) || (this.end_am_pm == "PM" && Number(event.target.value) == 12)
                                    ? Number(event.target.value)
                                    : Number(event.target.value) + 12;

        const start : Date = new Date(this.event.event_data.schedule_date.start);
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 
        end.setHours(endHours);
        
        // 완벽히 같은 시간은 10분을 더해준다
        if( start.getTime() == end.getTime() ) {
            end.setMinutes(end.getMinutes() + 10);

        // 종료일이 시작일보다 작다면 alert
        } else if ( start.getTime() > end.getTime() ) {
            $(event.target).val(this.end_hour);
            alert("일정 종료시간을 확인하세요");
            return;
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        this.event.event_data.schedule_date.end   = end;

        this.makeDateString();
    }

    /**
     * 종료일 분 변경
     */
    selectEndTimeMin(event) : void {
        const start : Date = new Date(this.event.event_data.schedule_date.start);
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 
        end.setMinutes(Number(event.target.value));

        // 완벽히 같은 시간은 10분을 더해준다
        if( start.getTime() == end.getTime() ) {
            end.setMinutes(end.getMinutes() + 10);

        // 종료일이 시작일보다 작다면 alert
        } else if ( start.getTime() > end.getTime() ) {
            $(event.target).val(this.end_min);
            alert("일정 종료시간을 확인하세요");
            return;
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        this.event.event_data.schedule_date.end   = end;

        this.makeDateString();
    }

    /**
     * 종료일 오전 오후 변경
     */
    selectEndTimeAmPm(event) : void {

        // 이미 같은 값이라면 return
        if( this.end_am_pm == event.target.value ){
            return;
        }

        const start : Date = new Date(this.event.event_data.schedule_date.start);
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 

        switch( event.target.value ){
            case "AM":
                end.setHours(end.getHours() - 12);
                break;
            
            case "PM":
                end.setHours(end.getHours() + 12);
                break;
        }

        // 완벽히 같은 시간은 10분을 더해준다
        if( start.getTime() == end.getTime() ) {
            end.setMinutes(end.getMinutes() + 10);

        // 종료일이 시작일보다 작다면 alert
        } else if ( start.getTime() > end.getTime() ) {
            $(event.target).val(this.end_am_pm);
            alert("일정 종료시간을 확인하세요");
            return;
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        this.event.event_data.schedule_date.end   = end;

        this.makeDateString();
    }

    /**
     * 날짜 영역에서 '오늘' 눌렀을때 
     */
    goToday() : void {
        
        const target_date : Date = new Date();
        let start_date : Date = new Date(target_date.getTime());
        let end_date   : Date = new Date(target_date.getTime());

        // 시작 시간이 '정시' 거나 '23시' 일 경우는 그대로 사용한다 
        if( target_date.getMinutes() != 0 && target_date.getHours() != 23 ){
           start_date.setHours(target_date.getHours() + 1);
        }
        
        start_date.setMinutes(0);
        start_date.setSeconds(0);
        start_date.setMilliseconds(0);

        // 시작시간이 23시라면 23시 50분 고정, 아니라면 시작시간 + 1시간에 0분
        if( start_date.getHours() == 23 ){
            end_date.setHours(23);
            end_date.setMinutes(50);
        } else {
            end_date.setHours(start_date.getHours() + 1);
            end_date.setMinutes(0);
        }

        end_date.setSeconds(0);
        end_date.setMilliseconds(0);

        this.event.event_data.schedule_date.start = start_date;
        this.event.event_data.schedule_date.end   = end_date;

        this.makeDateString();
    }

    @Watch('event.event_data.schedule_date.lunar_yn')
    async changeRepeatInfinite() : Promise<void> {
        this.event.event_data.schedule_date.rrule = undefined;
        this.recurrence_html = "";

        // lunar_start, lunar_end 관리
        if( this.event.event_data.schedule_date.lunar_yn == true ) {
            const lunar_date : string = await this.hodu_solar_to_lunar(this.event.event_data.schedule_date.start);
            
            // const lunar_start : Date = new Date(lunar_date.getTime());
            // const lunar_end   : Date = new Date(lunar_date.getTime());

            // lunar_start.setHours(0);
            // lunar_start.setMinutes(0);
            // lunar_start.setSeconds(0);
            // lunar_start.setMilliseconds(0);

            // lunar_end.setHours(23);
            // lunar_end.setMinutes(59);
            // lunar_end.setSeconds(59);
            // lunar_end.setMilliseconds(0);

            this.event.event_data.schedule_date.lunar_start = lunar_date;
            this.event.event_data.schedule_date.lunar_end   = lunar_date;

            this.day_repeat = false;
            this.week_repeat = false;
            this.month_repeat = false;
            this.year_repeat = true;

        } else {
            this.event.event_data.schedule_date.lunar_start = undefined;
            this.event.event_data.schedule_date.lunar_end   = undefined;
            
            this.day_repeat = true;
            this.week_repeat = false;
            this.month_repeat = false;
            this.year_repeat = false;
        }
    }
    
    /**
     * 반복 설정 창 열기
     */
    repeatOpen() : void {
        this.repeat_open     = true;
        this.alarm_list_open = false;

        // 기본값 1로
        if( !this.repeat_value || this.repeat_value == null ) {
            this.repeat_value = 1;
        }
    }

    /**
     * 반복 설정 창 닫기
     */
    async repeatClose() : Promise<void> {
        this.repeat_open = false;
        
        if( this.event.event_data.schedule_date.rrule == null ) {
            this.recurrence_end = this.event.event_data.schedule_date.recurrence_end;
            this.repeat_infinite = true;
            this.repeat_value = null;
            this.day_repeat = true;
            this.week_repeat = false;
            this.month_repeat = false;
            this.year_repeat = false;
            return;
        }

        // 원래대로 돌려놓음
        await this.makeStringByRrule();
        
        if( this.repeat_infinite == false ) this.recurrence_end = this.event.event_data.schedule_date.recurrence_end;
    }

    /**
     * 일 반복 열기
     */
    repeatDayOpen() : void {
        if( this.day_repeat == true ) {
            return;
        }

        this.day_repeat   = true;
        this.week_repeat  = false;
        this.month_repeat = false;
        this.year_repeat  = false;

        this.repeat_value = 1;
        this.month_year_left_option = true;
    }   

    /**
     * 주 반복 열기
     */
    repeatWeekOpen() : void {
        if( this.week_repeat == true ) {
            return;
        }

        this.day_repeat   = false;
        this.week_repeat  = true;
        this.month_repeat = false;
        this.year_repeat  = false;

        this.repeat_value = 1;
        this.month_year_left_option = true;
    }

    /**
     * 월 반복 열기
     */
    repeatMonthOpen() : void {
        if( this.month_repeat == true ) {
            return;
        }
        
        this.day_repeat   = false;
        this.week_repeat  = false;
        this.month_repeat = true;
        this.year_repeat  = false;

        this.repeat_value = 1;
        this.month_year_left_option = true;
    }

    /**
     * 년 반복 열기
     */
    repeatYearOpen() : void {
        if( this.year_repeat == true ) {
            return;
        }

        this.day_repeat   = false;
        this.week_repeat  = false;
        this.month_repeat = false;
        this.year_repeat  = true;

        this.repeat_value = 1;
        this.month_year_left_option = true;
    }
    
    /**
     * 주반복 요일 클릭
     */
    dayOfWeekClick( day_of_week_index : number ) : void {
        if( this.selected_day_of_week.indexOf(day_of_week_index) == -1 ) {
            this.selected_day_of_week.push(day_of_week_index);
        } else {
            this.selected_day_of_week.splice(this.selected_day_of_week.indexOf(day_of_week_index), 1);
        }

        this.selected_day_of_week.sort((o1, o2) : number => {

            const o1_sort_value = (o1 + 1) % 7;
            const o2_sort_value = (o2 + 1) % 7;

            if( o1_sort_value > o2_sort_value ) return  1;
            if( o1_sort_value < o2_sort_value ) return -1;

            return 0;
        });
    }

    /**
     * 월, 년 반복 모드 바꾸기
     */
    changeRepeatModeForMonthAndYear(mode : boolean) : void {
        this.month_year_left_option = mode;
    }

    /**
     * 반복 저장
     */
    async saveRepeat() : Promise<void> {
        // 음력이 아니라면 input값이 있어야 한다 || 주반복일때 선택된 요일이 없다면 rrule 없음
        if( ( this.event.event_data.schedule_date.lunar_yn == false && ( this.repeat_value == null || !this.repeat_value || this.repeat_value == 0 ) ) ||
              this.week_repeat == true && this.selected_day_of_week.length < 1 ) {
            return;
        }

        if( this.repeat_infinite == true ) {
            this.event.event_data.schedule_date.recurrence_end = new Date('2050-12-31T23:59:00');
        }
        else {
            this.event.event_data.schedule_date.recurrence_end = this.recurrence_end;
        }

        await this.makeDateString();
        await this.makeRrule();

        this.repeat_open = false;
    }

    /**
     * RRULE 제조
     */
    async makeRrule() : Promise<void> {

        // 매일 반복
        if( this.day_repeat == true ) {
            this.event.event_data.schedule_date.rrule = `FREQ=DAILY;INTERVAL=${this.repeat_value};`;
            this.recurrence_html = `<span class="highlight">${ this.repeat_value == 1 ? "매" : this.repeat_value }일</span> ${this.repeat_value == 1 ? "" : "마다" } 반복`;
        }

        // 매주 반복
        else if( this.week_repeat == true ) {
            let by_day_text : string = "";
            let by_day_hangle_text : string = "";

            // BYDAY 텍스트 제작
            const day_of_week_count : number = this.selected_day_of_week.length;
            for( let i = 0; i < day_of_week_count; i++ ) {
                by_day_text += this.getDayOfWeekForRrule(this.selected_day_of_week[i]);
                by_day_hangle_text += this.getDayOfWeekForRrule(this.selected_day_of_week[i], true);

                if( i != (day_of_week_count - 1) ) {
                    by_day_text += ",";
                    by_day_hangle_text += ",";
                }
            }

            this.event.event_data.schedule_date.rrule = `FREQ=WEEKLY;INTERVAL=${ this.repeat_value };BYDAY=${by_day_text};`;
            this.recurrence_html = `<span class="highlight">${ this.repeat_value == 1 ? "매" : this.repeat_value }주</span> ${this.repeat_value == 1 ? "" : "마다" } <span class="highlight">${by_day_hangle_text}요일</span>에 반복`;
        }

        // 매월 반복
        else if( this.month_repeat == true ) {
            let by_day_or_by_month_text : string = "";
            let recurrence_at_html : string = ""; // ~~에 해당하는 반복 텍스트 

            // 매 월 n일
            if( this.month_year_left_option == true ) {
                by_day_or_by_month_text = `BYMONTHDAY=${new Date(this.event.event_data.schedule_date.start).getDate()}`;
                recurrence_at_html = `${new Date(this.event.event_data.schedule_date.start).getDate()}일`;
            }
            
            // 매 월 n번째 m요일
            else {

                // Date.getDay() 는 일요일이 0부터이므로 일요일이 6이 되도록 계산해줘야한다 
                const day = (new Date(this.event.event_data.schedule_date.start).getDay() + 6) % 7; 
                by_day_or_by_month_text = `BYDAY=${this.getWeekOfMonthNumber(new Date(this.event.event_data.schedule_date.start))}${this.getDayOfWeekForRrule(day)}`
                recurrence_at_html = `${this.getWeekOfMonthNumber(new Date(this.event.event_data.schedule_date.start))}번째 ${this.getDayOfWeekForRrule(day, true)}요일`;
            }

            this.event.event_data.schedule_date.rrule = `FREQ=MONTHLY;INTERVAL=${this.repeat_value};${by_day_or_by_month_text};`;
            this.recurrence_html = `<span class="highlight">${ this.repeat_value == 1 ? "매" : `${this.repeat_value}개` }월</span> ${this.repeat_value == 1 ? "" : "마다" } <span class="highlight">${recurrence_at_html}</span>에 반복`;
        } 
        
        // 매년 반복
        else if( this.year_repeat == true ) {
            let by_day_and_by_month_text : string = "";
            let recurrence_at_html : string = `${new Date(this.event.event_data.schedule_date.start).getDate()}일`;

            // 매 n월 m번째 o요일
            if( this.month_year_left_option == false ) {

                // Date.getDay() 는 일요일이 0부터이므로 일요일이 6이 되도록 계산해줘야한다 
                const day = (new Date(this.event.event_data.schedule_date.start).getDay() + 6) % 7;
                by_day_and_by_month_text = `BYDAY=${this.getWeekOfMonthNumber(new Date(this.event.event_data.schedule_date.start))}${this.getDayOfWeekForRrule(day)};BYMONTH=${new Date(this.event.event_data.schedule_date.start).getMonth() + 1};`;
                recurrence_at_html = `${this.getWeekOfMonthNumber(new Date(this.event.event_data.schedule_date.start))}번째 ${this.getDayOfWeekForRrule(day, true)}요일`;
            }

            this.event.event_data.schedule_date.rrule = `FREQ=YEARLY;INTERVAL=${ this.event.event_data.schedule_date.lunar_yn == true ? 1 : this.repeat_value };${by_day_and_by_month_text};`;
            
            // 양력
            if( this.event.event_data.schedule_date.lunar_yn == false ) {
                this.recurrence_html = `<span class="highlight">${ this.repeat_value == 1 ? "매" : `${this.repeat_value}` }년</span> ${this.repeat_value == 1 ? "" : "마다" } <span class="highlight">${new Date(this.event.event_data.schedule_date.start).getMonth() + 1}월 ${recurrence_at_html}</span>에 반복`;
            }

            // 음력
            else {

                // 음력 날짜 제대로 없을시
                if( this.event.event_data.schedule_date.lunar_start == null ) {
                    this.event.event_data.schedule_date.rrule = undefined;
                    this.recurrence_html = "";
                    return;
                }

                // 음력 문자열 생성
                const lunar_arr : string[] = this.event.event_data.schedule_date.lunar_start.substring(0, 10).split('-');
                
                // 1 ~ 9월이라면 01 ~ 09가 아닌 1 ~ 9 로 나오도록 문자열을 뽑아냄
                let month : string = lunar_arr[1];
                month = month.replace(/(0)(.)/, '$2');

                // 1 ~ 9일이라면 01 ~ 09가 아닌 1 ~ 9 로 나오도록 문자열을 뽑아냄
                let date : string = lunar_arr[2];
                date = date.replace(/(0)(.)/, '$2');

                this.recurrence_html = `<span class="highlight">매년</span> 음력 <span class="highlight">${month}월 ${date}일</span>에 반복`;
            }

        // 그 외의 경우 (에러)
        } else {
            this.event.event_data.schedule_date.rrule = undefined;
        }

        // rrule 존재시 마지막 세미콜론 제거
        while ( this.event.event_data.schedule_date.rrule != undefined && 
            ( this.event.event_data.schedule_date.rrule.lastIndexOf(';') == this.event.event_data.schedule_date.rrule.length - 1) ){
            this.event.event_data.schedule_date.rrule = this.event.event_data.schedule_date.rrule.substring(0, this.event.event_data.schedule_date.rrule.length - 1);
        }

        this.recurrence_html += `<span class="endDay">종료일</span> <span class="repeatEndDate"> ${ this.repeat_infinite == true ? "없음" : this.recurrence_end_text } </span>`;
    }

    /**
     * 일정을 조회 할때 Rrule을 기반으로 html 생성
     */
    async makeStringByRrule() : Promise<void> {
        
        // RRULE이 없다면 빠져 나감
        if( this.event.event_data.schedule_date.rrule == null || this.event.event_data.schedule_date.rrule.length < 1 ) {
            return;
        }

        // reccurence_end를 보고 2050년 12월 31일 23시 59분이면 계속 반복 아니라면 일반 반복
        const infinite_date : Date = new Date();
        infinite_date.setFullYear(2050);
        infinite_date.setMonth(11);
        infinite_date.setDate(31);
        infinite_date.setHours(23);
        infinite_date.setMinutes(59);
        infinite_date.setSeconds(0);
        infinite_date.setMilliseconds(0);

        this.repeat_infinite = infinite_date.getTime() <= new Date(this.event.event_data.schedule_date.recurrence_end).getTime();

        // 일 ~ 년 반복 초기화
        this.day_repeat   = false;
        this.week_repeat  = false;
        this.month_repeat = false;
        this.year_repeat  = false;

        // rrule을 RRule.js 객체로 변환
        const rruleSet : RRule | RRuleSet = rrulestr(this.event.event_data.schedule_date.rrule);

        this.repeat_value = rruleSet.options.interval;
        
        // 일 반복
        if( rruleSet.options.freq == RRule.DAILY ) {
            this.day_repeat = true;
        }

        // 주 반복
        else if ( rruleSet.options.freq == RRule.WEEKLY ) {
            this.week_repeat = true;
            this.selected_day_of_week = this.selected_day_of_week.concat(rruleSet.options.byweekday);
        }

        // 월 반복
        else if ( rruleSet.options.freq == RRule.MONTHLY ) {
            this.month_repeat = true;
            this.month_year_left_option = (rruleSet.origOptions.byweekday == null);
        }

        // 년 반복
        else if ( rruleSet.options.freq == RRule.YEARLY ) {
            this.year_repeat = true;
            this.month_year_left_option = (rruleSet.origOptions.byweekday == null);
        }

        await this.makeRrule();
    }

    /**
     * Rrule용 요일 텍스트를 반환한다 (MO, TU, WE, TH, FR, SA, SU)
     */
    getDayOfWeekForRrule(dayNumber : number, for_hangle : boolean = false) : string {
        switch( dayNumber ) { 
            case 6:
                return for_hangle == false ? "SU" : "일";

            case 0:
                return for_hangle == false ? "MO" : "월";
            
            case 1:
                return for_hangle == false ? "TU" : "화";

            case 2:
                return for_hangle == false ? "WE" : "수";
            
            case 3:
                return for_hangle == false ? "TH" : "목";

            case 4:
                return for_hangle == false ? "FR" : "금";

            case 5:
                return for_hangle == false ? "SA" : "토";
        }

        return "";
    }

    /**
     * 반복 제거
     */
    removeRepeat() : void {

        if( this.event_crud_type == CRUD_TYPE.READ || this.event.event_data.schedule_date.rrule == null || this.event.event_data.schedule_date.rrule == '' ) {
            return;
        }

        // 양력
        if( this.event.event_data.schedule_date.lunar_yn == false ) {
            this.day_repeat   = true;
            this.week_repeat  = false;
            this.month_repeat = false;
            this.year_repeat  = false;
        }

        // 음력
        else {
            this.day_repeat   = false;
            this.week_repeat  = false;
            this.month_repeat = false;
            this.year_repeat  = true;
        }

        this.event.event_data.schedule_date.rrule = undefined;
        this.event.event_data.schedule_date.recurrence_end = new Date(this.event.event_data.schedule_date.end);
        this.recurrence_end = this.event.event_data.schedule_date.recurrence_end;
        this.recurrence_html = "";
        this.repeat_value = null;
        this.repeat_infinite = true;

        // 주반복 요일 제거
        this.selected_day_of_week.splice(0, this.selected_day_of_week.length);

        this.makeDateString();
        this.makeStringByRrule();
    }

    /**
     * 알람 설정 창 toggle
     */
    alarmListOpen() : void {
        this.alarm_list_open = !this.alarm_list_open;
    }

    /**
     * 알람 값 체크 - 해당 알람이 존재하면 true, 없다면 false 반환
     */
    alarmCheck(trigger : string, alarm_gb : string) : boolean {
        if( this.event.event_data.alarm == null ) {
            this.event.event_data.alarm = [];
        }
    
        const alarm_count : number = this.event.event_data.alarm.length;
        for( let i = 0; i < alarm_count; i++ ) {
            if( this.event.event_data.alarm[i].alarm_gb == alarm_gb && this.event.event_data.alarm[i].trigger == trigger ) {
                return true;
            }
        }
        return false;
    }

    /**
     * 알람 세팅
     */
    async alarmSetting(trigger : string, alarm_gb : string, description : string) : Promise<void> {
        if( this.event.event_data.alarm == null ) {
            this.event.event_data.alarm = [];
        }

        // 중복 검사 (중복이라면 off) )
        const alarm_count : number = this.event.event_data.alarm.length;
        for( let i = 0; i < alarm_count; i++ ) {
            if( this.event.event_data.alarm[i].alarm_gb == alarm_gb && this.event.event_data.alarm[i].trigger == trigger ) {
                this.event.event_data.alarm.splice(i, 1);
                return;
            }
        }

        // 알람 추가
        this.event.event_data.alarm.push({
            trigger,
            description,
            alarm_gb,
            action : "",
            repeat : 0, 
        });

        await this.alarmSort();

        if( this.event_crud_type == CRUD_TYPE.READ ) {
            await this.updateEventIndividual();
        }
    }

    /**
     * 알람 취소(위쪽)
     */
    async alarmOff(alarm : t_event_alarm) : Promise<void> {
        if( this.event.event_data.alarm == null || this.event.event_data.alarm.length < 1 ){
            return;
        }

        // 알람 제거용 인덱스 구하기
        const alarm_index : number = this.event.event_data.alarm.indexOf(alarm);
        if( alarm_index == -1 ){
            return;
        }

        // 알람 모델 제거용 인덱스 구하기
        const model_index : number = this.alarm_model.indexOf(alarm.trigger);
        if( model_index == -1 ){
            return;
        }

        // 알람 제거
        this.alarm_model.splice(model_index, 1);
        this.event.event_data.alarm.splice(alarm_index, 1);

        if( this.event_crud_type == CRUD_TYPE.READ ) {
            await this.updateEventIndividual();
        }
    }

    /**
     * 알람 sort (정시에 가까울수록 앞에 둔다)
     */
    async alarmSort() : Promise<void> {
        if( this.event.event_data.alarm == null || this.event.event_data.alarm.length < 1 ){
            return;
        }

        // trigger minute 기준으로 변환
        await this.event.event_data.alarm.sort((a : t_event_alarm, b : t_event_alarm) : number => {
            
            // 알람 A 데이터 가공
            const a_alarm      : string[] = a.trigger.split(' ');

            if( a_alarm.length < 2 ) {
                return 0;
            }

            let   a_timeMinute : number   = Number(a_alarm[0]);

            switch(a_alarm[1]){
                case 'hour':
                    a_timeMinute *= 60; // hour to minute
                    break;

                case 'day':
                    a_timeMinute *= 60 * 24; // day to minute
                    break;
            }

            // 알람 B 데이터 가공
            const b_alarm      : string[] = b.trigger.split(' ');

            if( b_alarm.length < 2 ) {
                return 0;
            }

            let   b_timeMinute : number   = Number(b_alarm[0]);

            switch(b_alarm[1]){
                case 'hour':
                    b_timeMinute *= 60; // hour to minute
                    break;

                case 'day':
                    b_timeMinute *= 60 * 24; // day to minute
                    break;
            }

            return a_timeMinute < b_timeMinute ? -1 : 1;
        });

        // trigger minute 기준으로 변환
        await this.alarm_model.sort((a : string, b : string) : number => {
            
            // 알람 A 데이터 가공
            const a_alarm      : string[] = a.split(' ');

            if( a_alarm.length < 2 ) {
                return 0;
            }

            let   a_timeMinute : number   = Number(a_alarm[0]);

            switch(a_alarm[1]){
                case 'hour':
                    a_timeMinute *= 60; // hour to minute
                    break;

                case 'day':
                    a_timeMinute *= 60 * 24; // day to minute
                    break;
            }

            // 알람 B 데이터 가공
            const b_alarm      : string[] = b.split(' ');

            if( b_alarm.length < 2 ) {
                return 0;
            }

            let   b_timeMinute : number   = Number(b_alarm[0]);

            switch(b_alarm[1]){
                case 'hour':
                    b_timeMinute *= 60; // hour to minute
                    break;

                case 'day':
                    b_timeMinute *= 60 * 24; // day to minute
                    break;
            }

            return a_timeMinute < b_timeMinute ? -1 : 1;
        });
    }

    /**
     * 키다운 이벤트 감지 : 엔터키 누르면 검색
     */
    locationKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }

        this.locationSearchModalOpen();
    }

    /**
     * 장소 검색 Modal Open
     */
    locationSearchModalOpen() : void {
        this.doSetLocationSearchQuery(this.mapSearchQuery.trim());
        this.doSetShowEventDaumMap(true);
    }

    /**
     * 장소 선택값 반영
     */
    changeSeletedLocation() : void {       
        if( this.event.event_data.location == null ) {
            this.event.event_data.location = [];
        }

        this.event.event_data.location.push(JSON.parse(JSON.stringify(this.selected_location)));
        this.mapSearchQuery = "";
    }

    /**
     * 장소 보기
     */
    viewLocation(location : t_event_location) : void {
        this.doSetSelectedLocation(location);
        this.doSetShowEventDaumMap(true);
    }

    /**
     * 장소 삭제
     */
    deleteLocation(location : t_event_location) : void {
        if( this.event.event_data.location == null ) {
            return;
        }

        const location_index : number = this.event.event_data.location.indexOf(location);

        if( location_index == -1 ){
            return;
        }

        this.event.event_data.location.splice(location_index, 1);
    }

    /**
     * 전화번호 입력
     */
    inputTel(e : any) : void {
        if( this.event.event_data.contacts == null || this.event.event_data.contacts.length < 1 ) {
            this.event.event_data.contacts = [{}];
        }

        this.event.event_data.contacts[0].tel = (e.target.value).replace(/[^0-9-+]+/ig, '');
        this.$forceUpdate()
    }

    /**
     * 일정 조회일때 노트 개인 데이터 저장
     */
    @Debounce(100)
    @Watch('event.event_data.note')
    changeNote(after, before) : void {

        after = after ? after : "";
        before = before ? before : "";

        if( this.event_crud_type != CRUD_TYPE.READ ) return;
        if( after == before ) return;
        
        this.updateEventIndividual();
    }

    /**
     * 일정 이미지 추가
     */
    addImageNormal(event) : void {
        const files : File[] = event.target.files;
        if( files.length == 0 ) { return; }
        this.addImage(files);
    }

    /**
     * 이미지 객체 생성
     */
    async addImage(files : File[]) : Promise<void> {
        const vue = this;

        if( this.event.event_data.attachment == null ){
            this.event.event_data.attachment = {
                imgs : [],
                files : []
            }
        }

        if( this.event.event_data.attachment.imgs == null ){
            this.event.event_data.attachment.imgs = [];
        }
        
        let file_count : number = files.length;
        let end_count  : number = 0;

        // 이미지가 최대치 만큼 이미 차 있는 경우
        if( this.event.event_data.attachment.imgs.length >= this.image_max_cnt ) {
            alert(`일정 이미지 최대 개수는 ${this.image_max_cnt}개 입니다`);
            $('#cdImg').val("");
            return;
        }

        // 이미지 여유 공간이 있지만 선택한 이미지 개수 + 기존에 있던 이미지 개수가 최대치를 넘은 경우
        if( this.event.event_data.attachment.imgs.length + file_count > this.image_max_cnt ) {
            if( files instanceof FileList ) {
                files = Array.prototype.slice.call(files, 0, (this.image_max_cnt - this.event.event_data.attachment.imgs.length));
            }

            else {
                alert(`일정 이미지 최대 개수는 ${this.image_max_cnt}개 입니다`);
                $('#cdImg').val("");
                return;
            }

            file_count = files.length;
        }

        await this.hodu_show_indicator();

        for( let i = 0; i < file_count; i++ ) {
            await this.fileReaderPromise(files[i])
                .then(async(pe_fr : any) => {

                    if(pe_fr.target == null || pe_fr.target.result == null || vue.event.event_data.attachment == null || vue.event.event_data.attachment.imgs == null ){
                        return;
                    }
                    
                    let base64url : string = "";

                    if( pe_fr.target.result instanceof ArrayBuffer ){
                        const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                        const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                        base64url = decodeURIComponent(url);
                    } else {
                        base64url = pe_fr.target.result;
                    }

                    // 이미지 리사이즈
                    const blob : Blob = await vue.hodu_image_resize(base64url);
                    
                    // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                    let resize_file : File = files[i];
                    try{
                        resize_file = await this.hodu_blob_to_file(blob, files[i].name);
                    }catch(e){
                        try {
                            (blob as any).lastModifiedDate = new Date();
                            (blob as any).name = files[i].name;
                            resize_file = (blob as any);
                        } catch(e) {
                            this.hodu_error_process(e, false, false, true);
                        }
                    }

                    vue.event.event_data.attachment.imgs.push({
                        name: resize_file.name,         // 원본 파일명
                        mimeType: resize_file.type,     // MIME TYPE
                        url: URL.createObjectURL(blob), // 파일 경로
                        size: resize_file.size,         // 파일 크기
                        date: new Date()                // 저장 날짜
                    })

                    // 업로드용 파일 객체 담기
                    vue.image_files.push({
                        index : ( vue.event.event_data.attachment.imgs.length - 1 ), 
                        file : resize_file
                    });

                    // 마지막 파일 로드 후 input 값 비우기 [안 비우면 똑같은 사진 multiple 아니면 안들어감]
                    if( file_count == ++end_count ) { $('#cdImg').val(""); }

                })
                .catch((e) => {
                    console.log('filereader promise error',e);
                });
        }

        await this.hodu_hide_indicator();
    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File | Blob) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * 이미지 드래그해서 이미지 영역에 올려놨을때
     */
    imageDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.image_drag = true;
    }

    /**
     * 이미지 드래그해서 이미지 영역에서 벗어났을때
     */
    imageDragLeave() : void {
        this.image_drag = false;
    }

    /**
     * 이미지를 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    imageDrop(event) : void {
        this.image_drag = false;
        this.addImage(event.dataTransfer.files);
    }

    /**
     * 이미지 삭제
     */
    deleteImage(image : t_event_file) : void {
        if( this.event.event_data.attachment == null || this.event.event_data.attachment.imgs == null ) {
            return;
        }

        const image_index : number = this.event.event_data.attachment.imgs.indexOf(image);

        if( image_index == -1 ){
            return;
        }

        this.event.event_data.attachment.imgs.splice(image_index, 1);

        // 새로 올리는 파일을 검사해서 같이 삭제한다
        const image_files_count : number = this.image_files.length;
        for( let i = (image_files_count - 1); i >= 0; i--) {
            
            // 삭제하는 인덱스보다 큰 객체는 index를 1 줄인다
            if( this.image_files[i].index > image_index ) {
                this.image_files[i].index--;
            }

            // 삭제할 인덱스가 보이면 삭제후 나간다
            else if( this.image_files[i].index == image_index ) {
                this.image_files.splice(i, 1);
                break;
            }
        }
    } 

    /**
     * 이미지 미리보기
     */
    imagePreviewOpen(image : t_event_file) : void {
        // 일정 조회(읽기모드)에서만 사용가능
        if( this.event_crud_type != CRUD_TYPE.READ ) {
            return;
        }

        // 객체에 첨부 객체 or 이미지객체가 없다면 무시
        if( this.event.event_data.attachment == null || this.event.event_data.attachment.imgs == null ) {
            return;
        }

        const image_files : t_event_file[] = this.event.event_data.attachment.imgs;
        const image_index : number         = this.event.event_data.attachment.imgs.indexOf(image);

        if( image_index == -1 ){
            return;
        }

        this.doSetEventImagePreviewInfo({
            "selected_index" : image_index,
            "files" : image_files
        });
        this.doSetShowEventImagePreview(true);
    }

    /**
     * 파일 추가 - +버튼 눌러서 추가
     */
    addFileNormal(event) : void {
        const vue = this;
        const files : File[] = event.target.files;
        this.addFile(files);
    }

    /**
     * 파일 객체 추가
     */
    addFile(files : File[]) : void {
        if( this.event.event_data.attachment == null ){
            this.event.event_data.attachment = {
                imgs : [],
                files : []
            }
        }

        if( this.event.event_data.attachment.files == null ){
            this.event.event_data.attachment.files = [];
        }
        
        const file_count : number = files.length;
        const event_files : t_event_file[] = [];
        
        const is_premium = this.is_premium_group(this.event.group_id > 0 ? 'GROUP' : this.event!.team_id > 0 ? 'TEAM' : '', this.event.group_id > 0 ? this.event.group_id : this.event.team_id > 0 ? this.event.team_id : 0);

        let max_size : number = is_premium ? this.DEFAULT_FILE_MAX_SIZE : this.NORMAL_GROUP_FILE_MAX_SIZE;
        let max_size_text : string = is_premium ? this.DEFAULT_FILE_MAX_SIZE_TEXT : this.NORMAL_GROUP_FILE_MAX_SIZE_TEXT;

        // 파일 용량 체크 후 t_event_file로 만들어 push
        for( let i = 0; i < file_count; i++ ) {
            if( files[i].size > max_size ) {
                alert(`${max_size_text} 이하의 파일만 업로드 가능 합니다`);
                $('#cdFile').val("");
                return;
            }

            // 확장자가 없는 파일
            if( files[i].name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#cdFile').val("");
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(files[i].name.substring(files[i].name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ files[i].name.substring(files[i].name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#cdFile').val("");
                return;
            }
            
            event_files.push({
                name: files[i].name,     // 원본 파일명
                mimeType: files[i].type, // MIME TYPE
                url: "",                 // 파일 경로
                size: files[i].size,     // 파일 크기
                date: new Date()         // 저장 날짜
            })

            // 업로드용 파일 객체 담기
            this.all_files.push({
                index : ( this.event.event_data.attachment.files.length + i ), 
                file : files[i]
            });
        }

        // 배열 합치기
        this.event.event_data.attachment.files = this.event.event_data.attachment.files.concat(event_files);

        // 파일 input 비우기
        $('#cdFile').val("");
    }

     
    /**
     * 파일 삭제
     */
    deleteFile(file : t_event_file) : void {
        if( this.event.event_data.attachment == null || this.event.event_data.attachment.files == null ) {
            return;
        }

        const file_index : number = this.event.event_data.attachment.files.indexOf(file);

        if( file_index == -1 ){
            return;
        }

        this.event.event_data.attachment.files.splice(file_index, 1);

        // 새로 올리는 파일을 검사해서 같이 삭제한다
        const files_count : number = this.all_files.length;
        for( let i = (files_count - 1); i >= 0; i--) {
            
            // 삭제하는 인덱스보다 큰 객체는 index를 1 줄인다
            if( this.all_files[i].index > file_index ) {
                this.all_files[i].index--;
            }

            // 삭제할 인덱스가 보이면 삭제후 나간다
            else if( this.all_files[i].index == file_index ) {
                this.all_files.splice(i, 1);
                break;
            }
        }
    } 

    /**
     * 파일을 드래그해서 이미지 영역에 올려놨을때
     */
    fileDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.file_drag = true;
    }

    /**
     * 파일을 드래그해서 이미지 영역에서 벗어났을때
     */
    fileDragLeave() : void {
        this.file_drag = false;
    }

    /**
     * 파일을 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    fileDrop(event) : void {
        this.file_drag = false;
        this.addFile(event.dataTransfer.files);
    }

    // 파일 다운로드 (조회)
    fileDownload(file : t_event_file) : void {
        this.hodu_download(`/app_images/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }
    
    /**
     * 카카오 링크를 이용한 카카오 공유   
     */
    async kakaoShareInit() : Promise<void> {
        if( !Kakao.isInitialized() ) { await Kakao.init('c18796cb68948dbecc66ea05f7fa7773'); }
    
        // 제목
        const title : string = `[${this.event.event_data.title}]`;

        let content : string = `\n\n`;

        // 일자 표시
        // 음력
        if( this.event.event_data.schedule_date.lunar_yn == true ) {
            content += "* 일시 : " + moment(this.event.event_data.schedule_date.lunar_start).format('YYYY/MM/DD');
        }

        else {
            const day_of_week : string = this.getDayOfWeekByDate(this.event.event_data.schedule_date.start);
            content += "* 일시 : " + moment(this.event.event_data.schedule_date.start).locale("ko").format(`YYYY/MM/DD (${day_of_week}) A hh:mm`);
        }

        // 장소 표시
        const location_count : number = this.event.event_data.location ? this.event.event_data.location.length : 0;
        for( let i = 0; i < location_count; i++ ) {
            if( this.event.event_data.location == null ) { break; }
            const location : any = this.event.event_data.location[i];

            // 장소명
            if( location.place && location.place.length > 0 ) {
                
                // 장소를 직접 찍어서 가져 왔을 때 장소 정보를 못 찾은 경우 장소와 주소가 주소로 똑같이 입력되는데 그런 경우는 주소 하나만 가도록 한다
                // 도로명 주소와 같은 경우
                if( location.address && location.address.length > 0 && location.address == location.place ) {
                    // DO NOTHING
                }

                // 지번 주소와 같은 경우
                else if ( location.address_old && location.address_old.length > 0 && location.address_old == location.place ) {
                    // DO NOTHING
                }

                // 도로명, 지번 주소와 다른 경우
                else {
                    content += "\n\n* 장소 : " + location.place;
                }
            }

            // 주소(도로명)
            if( location.address && location.address.length > 0 ) {
                content += "\n\n* 주소 : " + location.address;
            }

            // 주소(지번)
            else if( location.address_old && location.address_old.length > 0 ) {
                content += "\n\n* 주소 : " + location.address_old;
            }

            // 전화번호
            if( location.tel && location.tel.length > 0 ) {
                content += "\n\n* 전화 : " + location.tel;
            }
        }

        // 메모
        if( this.event.event_data.memo && this.event.event_data.memo.length > 0 ) {
            content += "\n\n* 메모 : " + this.event.event_data.memo;
        }

        const user_id  : number = this.event.user_id  == null ? 0 : this.event.user_id;
        const group_id : number = this.event.group_id == null ? 0 : this.event.group_id;
        const team_id  : number = this.event.team_id  == null ? 0 : this.event.team_id;

        const scope    : string = user_id > 0 ? OWNER_TYPE.PERSONAL 
                                              : group_id > 0 ? OWNER_TYPE.GROUP : OWNER_TYPE.TEAM;

        const scope_id : number = user_id > 0 ? user_id 
                                              : group_id > 0 ? group_id : team_id;

        const params : string = `event_id=${this.event.event_id}&event_sub_type=${this.event.event_type}&noti_type=PUSH&scope=${scope}&scope_id=${scope_id}&desc=${title} 일정이 공유되었습니다`;

        let send_text : string = title + content;

        // 200자를 넘어가면 잘라내고 ...을 붙인다
        const KAKAO_LINK_MAX_TEXT_LENGTH : number = 200;
        if( send_text.length > KAKAO_LINK_MAX_TEXT_LENGTH ) {
            send_text = send_text.substring(0, KAKAO_LINK_MAX_TEXT_LENGTH - 4) + " ...";
        }

        Kakao.Link.createDefaultButton({
            container: '#kakao_link_btn',
            objectType: 'text',
            text : send_text,
            link: {
                androidExecParams: params,
                iosExecParams: params,
            },
            buttons: [
                {
                    title: '호두 실행',
                    link: {
                        androidExecParams: params,
                        iosExecParams: params,
                    }
                },
            ]
        });
    }

    /**
     * 이메일 공유
     */
    emailShare() : void {
        let calendar_id : string = this.event.calendar_id ? this.event.calendar_id : "";
        let event_id : string = this.event.event_id ? this.event.event_id : "";
        let scope : string = this.share_info.share_scope;
        let scope_id : number = this.share_info.share_scope_id;
        
        const start_date = new Date(this.event.event_data.schedule_date.start);
        const end_date = new Date(this.event.event_data.schedule_date.end);

        const title = `${this.user_name}님이 ${this.event.event_data.title} 일정을 공유했습니다`;

        // this.doSetEventEmailShareModalInfo?.({
        //     show_modal : true,
        //     calendar_id : calendar_id,
        //     scope : scope,
        //     scope_id : scope_id,
        //     event_id : event_id,
        //     start : start_date,
        //     end : end_date,
        //     title : title,
        //     sender_email : this.user_email
        // });

        this.doSetCommonSelectFriendAndGroupTeamModalInfo?.({
            show_modal : true,
            title : "메일 보내기",
            event_calendar_id : calendar_id,
            event_scope : scope,
            event_scope_id : scope_id,
            event_id : event_id,
            event_start : start_date,
            event_end : end_date,
            event_title : title,
            use_need_user_email : true,
        });
    }

    /**
     * 공유 Modal 열기
     */
    async shareModalOpen() : Promise<void> {
        
        this.doSetCommonSelectFriendAndGroupTeamModalInfo?.({
            show_modal : true,
            title : this.isWork(this.event.event_sub_type as EVENT_SUB_TYPE) ? '공유' : '전송',
            event_sub_type : (this.event.event_sub_type as EVENT_SUB_TYPE),
            event_crud_type : (this.event_crud_type as CRUD_TYPE),
            share_option : this.share_option,
            use_share_option : true,
            selected_friend_user_ids : this.selected_friend_user_ids,
            group_user_selected_key : this.group_user_selected_key,
            team_user_selected_key : this.team_user_selected_key,
            selected_user_ids : this.selected_user_ids,
            selected_group_ids : this.selected_group_ids,
            selected_team_ids :this.selected_team_ids,
            callback : async(share_info) => {
                try {

                    console.log(share_info);

                    if( this.event_crud_type == CRUD_TYPE.CREATE ) {
                        this.share_option = share_info.share_option;
                        this.selected_friend_user_ids = share_info.selected_friend_user_ids;
                        this.group_user_selected_key = share_info.group_user_selected_key;
                        this.team_user_selected_key = share_info.team_user_selected_key;
                        this.selected_user_ids = share_info.selected_user_ids;
                        this.selected_group_ids = share_info.selected_group_ids;
                        this.selected_team_ids = share_info.selected_team_ids;

                        this.event.subscribe_users = share_info.selected_user_ids;
                        this.event.subscribe_groups = share_info.selected_group_ids;
                        this.event.subscribe_teams = share_info.selected_team_ids;
                        return;
                    }

                    // 일정 조회        
                    if( share_info.selected_user_ids.length < 1 && share_info.selected_group_ids.length < 1 && share_info.selected_team_ids.length < 1 ) {
                        return;
                    }
                    
                    // const vue = this;

                    let scope : string = "";
                    let scope_id : number | undefined = 0;

                    scope = this.event.event_type ? this.event.event_type : OWNER_TYPE.PERSONAL;

                    scope_id = scope == OWNER_TYPE.PERSONAL ? this.user_id 
                                                            : scope == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id;

                    let response : any = null;
                    let share_text : string = "";
                    
                    const body =  {
                        "subscribe_users"  : share_info.selected_user_ids,
                        "subscribe_groups" : share_info.selected_group_ids,
                        "subscribe_teams"  : share_info.selected_team_ids
                    };

                    if( share_info.share_option == SHARE_OPTION.SHARE ) {
                        response = await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}/share`, API_METHOD.POST, body);
                        share_text = "공유";
                    }

                    else if ( share_info.share_option == SHARE_OPTION.COPY ) {
                        response = await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}/share/copy`, API_METHOD.POST, body);
                        share_text = "복사";
                    }

                    else if ( share_info.share_option == SHARE_OPTION.MOVE ) {
                        response = await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}/share/move`, API_METHOD.POST, body);
                        share_text = "이동";
                    }

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("공유 처리 중 오류 발생");
                    }

                    this.hodu_show_dialog("success", `${share_text} 요청 완료`, ['확인'], [
                        () => {

                            // 그룹 또는 팀에 이동 시킨 경우
                            if( share_info.share_option == SHARE_OPTION.MOVE && (share_info.selected_group_ids.length + share_info.selected_team_ids.length > 0) ) {
                                this.movePrevPage();
                            }

                        }
                    ]);

                } catch(e) {
                    const additional_message = (e as any)?.response?.data ?? "";
                    this.hodu_show_dialog("cancel", "공유 처리 중 오류 발생" + (additional_message.length > 0 ? `\n${additional_message}` : ""), ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });

    }

    /**
     * 공유 수신자보기 Modal 열기
     */
    shareStatusModalOpen() : void {

        // 작성자 본인만 공유 수신자 보기가 가능함
        if( this.isManager() == false ) {
            return;
        }

        let scope : string = "";
        let scope_id : number | undefined = 0;

        scope = this.event.event_type ? this.event.event_type : OWNER_TYPE.PERSONAL;

        scope_id = scope == OWNER_TYPE.PERSONAL ? this.user_id 
                                                : scope == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id;

        this.doSetEventShareStatusInfo({
            show_event_share_status : true,
            event_id : this.event.event_id,
            calendar_id : this.event.calendar_id,
            scope : scope,
            scope_id : scope_id
        });
    }

    /**
     * 공유 내용 저장
     */
    setShareInfo() : void {
        this.share_option           = this.event_share_info.share_option;
        this.event.subscribe_users  = this.event_share_info.user_ids;
        this.event.subscribe_groups = this.event_share_info.group_ids;
        this.event.subscribe_teams  = this.event_share_info.team_ids;
    }

    /**
     * 공유 값 삭제
     */
    deleteShare() : void {

        if( this.isUpdate(this.event_crud_type) == true ) return; 

        if( this.event.subscribe_users != null ) {
            this.event.subscribe_users.splice(0, this.event.subscribe_users.length)
        } 

        if( this.event.subscribe_groups != null ) {
            this.event.subscribe_groups.splice(0, this.event.subscribe_groups.length)
        }

        if( this.event.subscribe_teams != null ) {
            this.event.subscribe_teams.splice(0, this.event.subscribe_teams.length)
        }

        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        this.share_option = SHARE_OPTION.SHARE;
        this.selected_friend_user_ids.splice(0, this.selected_friend_user_ids.length);
        this.group_user_selected_key.splice(0, this.group_user_selected_key.length);
        this.team_user_selected_key.splice(0, this.team_user_selected_key.length);
        this.selected_user_ids.splice(0, this.selected_user_ids.length);
        this.selected_group_ids.splice(0, this.selected_group_ids.length);
        this.selected_team_ids.splice(0, this.selected_team_ids.length);
    }

    /**
     * 조회에서 공유 toggle
     */
    readShareButtonGroupToggle() : void {
        this.read_share_flag = !this.read_share_flag;
    }

    /**
     * 참석여부 변경
     */
    clickAttend(event : any, attend_flag : string) {
        const vue = this;

        // 이미 종료된 일정이라면 불가능
        if( new Date(this.event.event_data.schedule_date.end).getTime() <= new Date().getTime() ) {
            this.hodu_show_dialog('alert', '이미 종료된 일정 입니다', ['확인']);
            event.preventDefault();
            return;
        }

        // 이미 해당 flag를 가지고 있다면 cancelAttend
        if( this.attend_data != null && attend_flag == this.attend_data.attend ) {
            vue.attend_data = null;
            
            this.hodu_api_call(`api/v1/attend/${this.event.event_id}`, API_METHOD.DELETE)
                .then((response) => {
                    console.log(response);
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        // 다른플래그 혹은 아무 플래그도 없다면 추가
        else {
            this.hodu_api_call(`api/v1/attend/${this.event.event_id}`, API_METHOD.POST, { attend : attend_flag })
                .then((response) => {
                    console.log(response);

                    vue.attend_data = {
                        event_id : vue.event.event_id == null ? "" : vue.event.event_id,
                        user_id : vue.user_id == null ? 0 : vue.user_id,
                        user_name : vue.user_name == null ? "" : vue.user_name,
                        attend : attend_flag,
                        audit_modified : new Date()
                    };
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });
        }
    }

    /**
     * 참석여부 결과 Modal 열기 
     */
    attendResultModalOpen() : void {
        this.doSetVoteModifyIndex(0);
        this.doSetShowEventAttendResult(true);
    }

    /**
     * 투표 작성 Modal 열기
     */
    voteMakeModalOpen(index : number = -1) : void {
        if( index == -1 || this.event.event_data.vote == null || this.event.event_data.vote.length < 1) {
            this.doSetVote({
                title : "",
                end_date : new Date(this.event.event_data.schedule_date.end),
                items_type : "T",
                items : ["", "", "", ""],
                secret : true,
                multiple : false,
                ing_report : false,
                is_end : false
            });
        } else {
            this.doSetVote(JSON.parse(JSON.stringify(this.event.event_data.vote[index])));
        }

        this.doSetVoteEndDateLimit(new Date(this.event.event_data.schedule_date.end));
        this.doSetVoteModifyIndex(index);
        this.doSetShowEventVoteCreate(true);
    }

    /**
     * 투표 추가
     */
    createVote(vote : t_event_vote) : void {      
        if( this.event.event_data.vote == null ) {
            this.event.event_data.vote = [];
        }

        // 나머지 vote의 투표종료일도 똑같이 맞춘다
        const vote_length : number = this.event.event_data.vote.length;
        for( let i = 0; i < vote_length; i++ ) {
            this.event.event_data.vote[i].end_date = vote.end_date;
        }

        this.event.event_data.vote.push(JSON.parse(JSON.stringify(vote)));
        this.doSetShowEventVoteCreate(false);

        if( this.event_crud_type != CRUD_TYPE.CREATE && this.vote_result != null ) {
            if( this.vote_result.vote == null ) {
                this.vote_result.vote = [];
            }

            this.vote_result.vote.push([]);
            this.new_vote_index.push(this.vote_result.vote.length - 1);
        }
    }

    /**
     * 투표 수정
     */
    updateVote(index : number, vote : t_event_vote) {

        if( this.event.event_data.vote == null || this.event.event_data.vote.length < 1 ) {
            return;
        }

        // 나머지 vote의 투표종료일도 똑같이 맞춘다
        const vote_length : number = this.event.event_data.vote.length;
        for( let i = 0; i < vote_length; i++ ) {
            this.event.event_data.vote[i].end_date = vote.end_date;
        }

        this.event.event_data.vote.splice(index, 1, vote);
        this.doSetShowEventVoteCreate(false);

    }

    /**
     * 투표 삭제
     */
    deleteVote(index : number) : void {
        if( this.event.event_data.vote == null || this.event.event_data.vote.length < 1 ) {
            return;
        }
        
        // 투표리스트에서 제거
        this.event.event_data.vote.splice(index, 1);
        this.doSetShowEventVoteCreate(false);

        // 조회&수정에서 투표 아이템리스트 삭제
        if( this.event_crud_type != CRUD_TYPE.CREATE && this.vote_result != null ) {
            if( this.vote_result.vote == null ) {
                this.vote_result.vote = [];
            }

            this.vote_result.vote.splice(index, 1);

            // 새로운 투표임을 알렸던 index를 없앤다
            if( this.new_vote_index.indexOf(index) > -1 ) {
                this.new_vote_index.splice(this.new_vote_index.indexOf(index), 1);
            }

            // 새로운 투표임을 알리는 index가 방금 삭제한 index보다 높은 숫자일 경우 -1 해준다
            const new_vote_index_count : number = this.new_vote_index.length;
            for( let i = new_vote_index_count - 1; i >= 0; i-- ) {
                if( this.new_vote_index[i] > index ) {
                    this.new_vote_index.splice(i, 1, this.new_vote_index[i] - 1);
                } 
            }
        }
    }

    /**
     * 투표 결과 Modal 열기
     */
    voteResultModalOpen(index : number) : void {
        this.doSetVoteModifyIndex(index);
        this.doSetShowEventVoteResult(true);
    }

    /**
     * 투표 상태 반환
     */
    getVoteStatus(vote_object : any, index : number) : string {
        
        /**
         * 투표 진행 중 + 사용자가 투표한 결과 없음
         */
        if( vote_object.is_end == false && new Date().getTime() <  new Date(vote_object.end_date).getTime() && ( this.vote_result == null || this.vote_result.vote[index].length < 1 ) ) {
            return '진행 중';
        } 

        /**
         * 투표 진행 중 + 사용자가 투표한 결과 있음
         */
        else if( vote_object.is_end == false && new Date().getTime() <  new Date(vote_object.end_date).getTime() && 
                 this.vote_result != null && this.vote_result.vote[index].length > 0 ) {
            return '진행 중';
        }   
        
        /**
         * 투표 마감
         */
        else if( vote_object.is_end == true  || new Date().getTime() >= new Date(vote_object.end_date).getTime() ) {
            return '마감';
        } 

        return '';
    }

    /**
     * 투표한 아이템 선택 취소
     */
    voteItemDelete(vote_index : number, vote_item_index : number) : void {
        const vue = this;
        
        // 투표 값이 없는 경우, 사용자가 투표한 결과가 없는 경우
        if( this.event.event_data.vote == null || this.vote_result == null ) {
            return;
        }

        // 투표 수가 맞지 않는 경우
        if( this.event.event_data.vote.length < ( vote_index + 1 ) ) {
            return;
        }

        // 투표 아이템 수가 맞지 않는 경우
        if( this.event.event_data.vote[vote_index].items.length < ( vote_item_index + 1 )  ) {
            return;
        }

        // 투표가 이미 종료된 경우
        if( this.event.event_data.vote[vote_index].is_end == true || new Date(this.event.event_data.vote[vote_index].end_date).getTime() <= new Date().getTime() ) {
            return;
        }
        
        // body용 
        const items : Number[][] = JSON.parse(JSON.stringify(this.vote_result.vote));
        const item_index : number = items[vote_index].indexOf(vote_item_index);

        items[vote_index].splice(item_index, 1);

        this.hodu_api_call(`api/v1/vote/voting/${this.event.event_id}`, API_METHOD.POST, { vote : items })
            .then((response) => {
                console.log(response);

                if( vue.vote_result == null ) {
                    return;
                }

                vue.vote_result.vote[vote_index].splice(item_index, 1);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 댓글 창 열기
     */
    showEventReply() : void {
        this.doSetShowEventReply(true);
    }

    /**
     * 댓글 읽음 여부 조회 모달 열기
     */
    showEventRead() : void {

        const scope = this.event.event_type ? this.event.event_type : OWNER_TYPE.PERSONAL;
        const scope_id = scope == OWNER_TYPE.PERSONAL ? this.user_id 
                                                : scope == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id;

        this.doSetEventReadModalInfo?.({
            show_modal : true,
            event_id : (this.event.event_id ?? ""),
            repeat_seq : this.repeat_seq,
            calendar_id : (this.event.calendar_id ?? ""),
            owner_type : scope,
            owner_id : (scope_id ?? 0),
        });
    }

    /**
     * Message Modal
     */
    // exampleMsg(message_type : string, message : string, button_text : string[]) : void {
    //     this.hodu_show_dialog(message_type, message, button_text);
    // }

    /**
     * 일정 저장 (생성 및 수정)
     */
    async saveEvent() : Promise<void> {

        const vue = this;
        
        if( await this.checkValid() == false ) {
            return;
        }

        // 규칙에 따라서 값 세팅
        await this.setEventData();

        let scope : string = "";
        let scope_id : number | undefined = 0;

        // 작성할때는 event_type 기준으로 scope, scope_id 생성
        if( this.event_crud_type == CRUD_TYPE.CREATE ) {
            scope = this.event.event_type ? this.event.event_type : OWNER_TYPE.PERSONAL;
            scope_id = scope == OWNER_TYPE.PERSONAL ? this.user_id 
                                                    : scope == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id;
        }

        // 수정할떄는 share_info 기준으로 scope, scope_id 생성
        else {
            if( this.share_info == null ) { return }
            scope = this.share_info.share_scope;
            scope_id = this.share_info.share_scope_id;
            
            // OWNER 권한으로 체크
            const target = this.share_infos.filter(share_info => share_info.share_type == 'OWNER');
            if( target.length > 0 ) {
                scope = target[0].share_scope;
                scope_id = target[0].share_scope_id;
            }
        }
        
        // 이미지, 파일 임시 업로드
        try {
            await this.eventTempFileProcess();
        } catch(e) {
            this.hodu_error_process(e, true, false);
            return;
        }

        // 일정 생성
        if( this.event_crud_type == CRUD_TYPE.CREATE ) {

            let url : string = "";
            let query : string = ""; // subscribe_users, subscribe_groups, subscribe_teams

            // 일정 저장
            if( this.event.event_sub_type != EVENT_SUB_TYPE.WORK ) {

                query += `share_option=${this.share_option}`;

                // subscribe_users query
                if( this.event.subscribe_users != null && this.event.subscribe_users.length > 0 ) {
                    
                    const user_count : number = this.event.subscribe_users.length;
                    for( let i = 0; i < user_count; i++ ) {
                        if( query != '' ) {
                            query += '&';
                        }
                        query += `subscribe_users=${this.event.subscribe_users[i]}`;
                    }
                    
                }

                // subscribe_groups query
                if( this.event.subscribe_groups != null && this.event.subscribe_groups.length > 0 ) {
                    
                    const group_count : number = this.event.subscribe_groups.length;
                    for( let i = 0; i < group_count; i++ ) {
                        if( query != '' ) {
                            query += '&';
                        }
                        query += `subscribe_groups=${this.event.subscribe_groups[i]}`;
                    }
                    
                }

                // subscribe_teams query
                if( this.event.subscribe_teams != null && this.event.subscribe_teams.length > 0 ) {
                    
                    const team_count : number = this.event.subscribe_teams.length;
                    for( let i = 0; i < team_count; i++ ) {
                        if( query != '' ) {
                            query += '&';
                        }
                        query += `subscribe_teams=${this.event.subscribe_teams[i]}`;
                    }
                    
                }

                url = `api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}?${query}`;
            } 
            
            // 업무 저장
            else if ( this.event.event_sub_type == EVENT_SUB_TYPE.WORK ) {
                query = `event_type=${scope}`;

                if( this.assignment_user_list != null && this.assignment_user_list.length > 0 ) {
                    const assignment_user_list_count : number = this.assignment_user_list.length;
                    for( let i = 0; i < assignment_user_list_count; i++ ) {
                        query += `&assignment_users=${this.assignment_user_list[i]}`;
                    }
                }

                url = `api/v1/calendars/${this.event.calendar_id}/works/${scope}/${scope_id}?${query}`;
            }

            // 일정 or 업무 생성
            await this.hodu_api_call(url, API_METHOD.POST, this.event.event_data)
                .then(async(response) => {
                    console.log(response);

                    // 개인 캘린더에서 일반 일정 작성일때 마지막에 생성한 그룹&팀 기억
                    if( this.scope == OWNER_TYPE.PERSONAL && this.event.event_sub_type == EVENT_SUB_TYPE.SCHEDULE ) {
                        local_storage_info.last_event_created_scope = {
                            "scope" : scope,
                            "scope_id" : scope_id
                        };
                        local_storage_info.last_event_created_color = this.event.event_data.color ?? '#477FFF';
                        hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info)); 
                    }

                    if( vue.event.temp_id != null ) {
                        await vue.deleteTemp(vue.event.temp_id);
                    }
                    
                    vue.movePrevPage();
                    
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        // 일정 수정
        else {
        
            switch( this.repeat_modify_code ) {
                // 일반 일정 수정, 반복 전체 수정
                case "":
                case "A":
                    await this.updateAPICall();
                    break;

                // 반복 이후 일정 수정
                case "F":
                    await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}/repeat`, API_METHOD.POST, this.event.event_data)
                        .then(async(response) => {
                            console.log(response);
                            local_storage_info.last_event_created_color = this.event.event_data.color ?? '#477FFF';
                            hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info)); 
                            vue.movePrevPage();
                        })
                        .catch(async(e) => {
                            this.hodu_error_process(e, true, false);
                        });
                    break;

                // 반복 이 일정만 수정
                case "E":
                    this.event.event_data.schedule_date.isIgnore = false;
                    
                    // 일정 저장
                    await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}`, API_METHOD.POST, this.event.event_data)
                        .then(async(response) => {
                            console.log(response);
                            local_storage_info.last_event_created_color = this.event.event_data.color ?? '#477FFF';
                            hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info)); 
                            vue.movePrevPage();
                        })
                        .catch(async(e) => {
                            this.hodu_error_process(e, true, false);
                        });
                    break;
            }

        }
    }

    /**
     * 일정 임시 저장
     */
    async tempSaveEvent() {

        await this.setEventData();

        let scope : string = "";
        let scope_id : number | undefined = 0;

        // 작성할때는 event_type 기준으로 scope, scope_id 생성
        if( this.event_crud_type == CRUD_TYPE.CREATE ) {
            scope = this.event.event_type ? this.event.event_type : OWNER_TYPE.PERSONAL;
            scope_id = scope == OWNER_TYPE.PERSONAL ? this.user_id 
                                                    : scope == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id;
        }

        // 수정할떄는 share_info 기준으로 scope, scope_id 생성
        else {
            if( this.share_info == null ) { return }
            scope = this.share_info.share_scope;
            scope_id = this.share_info.share_scope_id;
            
            // OWNER 권한으로 체크
            const target = this.share_infos.filter(share_info => share_info.share_type == 'OWNER');
            if( target.length > 0 ) {
                scope = target[0].share_scope;
                scope_id = target[0].share_scope_id;
            }
        }
        
        // 이미지, 파일 임시 업로드
        try {
            await this.eventTempFileProcess();
        } catch(e) {
            this.hodu_error_process(e, true, false);
            return;
        }

        this.event.is_temp = true;
        this.event.scope_group_id = this.scope_group_id;
        this.event.scope_team_id = this.scope_team_id;

        // 업무
        if ( this.event.event_sub_type == EVENT_SUB_TYPE.WORK && this.event.event_data.work != null && this.selected_work_template != null ) {
            this.event.event_data.work.assign_user_ids = this.assignment_user_list;
            this.event.event_data.work.template_id = this.selected_work_template.template_id;
            this.event.event_data.work.template_type = this.selected_work_template.template_type;
        }
        else {
            this.event.share_option = this.share_option;
            this.event.selected_friend_user_ids = this.selected_friend_user_ids;
            this.event.group_user_selected_key = this.group_user_selected_key;
            this.event.team_user_selected_key = this.team_user_selected_key;
            this.event.selected_user_ids = this.selected_user_ids;
            this.event.selected_group_ids = this.selected_group_ids;
            this.event.selected_team_ids = this.selected_team_ids;
        }

        try {

            let response : any = null;

            if( this.event.temp_id != null ) {
                response = await this.hodu_api_call(`api/v1/temp/${this.event.temp_id}`, API_METHOD.PUT, {
                    temp_type : this.event.event_sub_type,
                    temp_data : this.event,
                });
            } 
            else {
                response = await this.hodu_api_call(`api/v1/temp`, API_METHOD.POST, {
                    temp_type : this.event.event_sub_type,
                    temp_data : this.event,
                });
            }

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("임시 저장 실패");
            }

            this.movePrevPage();

        } catch(e) {
            this.hodu_error_process(e, true, false);
        }
        
    }

    async deleteTemp(temp_id) {

        try {
            
            const response = await this.hodu_api_call(`api/v1/temp/${temp_id}`, API_METHOD.DELETE);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("임시보관함 삭제 중 오류 발생");
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "임시보관함 삭제 중 오류 발생", ['확인']);
        }
            
    }

    /**
     * 임시파일 업로드
     */
    async eventTempFileProcess() : Promise<void> {
        try {

            await this.hodu_show_indicator();

            /**
             * 이미지 파일이 있을때 => 이미지 임시파일 생성
             */
            if( this.image_files.length > 0 ) {
                await this.imageTempUpload(); 
            }

            /**
             * 첨부파일이 있을때 
             */
            if( this.all_files.length > 0 ) {
                await this.fileTempUpload();
            }

        } catch(e) {
            throw e;
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    
    /**
     * 이미지 임시파일 업로드
     */
    async imageTempUpload() : Promise<void> {

        const vue = this;

        const form_data : FormData = new FormData();
        
        // FormData 내용 생성
        const image_files_count : number = this.image_files.length;
        for( let i = 0; i < image_files_count; i++ ) {
            form_data.append('file', this.image_files[i].file);
        }

        try {
            const response = await this.hodu_temp_upload(form_data);
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("이미지 임시 업로드 실패");
            }

            const temp_files : t_event_file[] = response.data.data.temp_files;

            // attachment null check
            if( vue.event.event_data.attachment == null ) {
                vue.event.event_data.attachment = {
                    imgs : [],
                    files : []
                };
            }

            // imgs null check
            if( vue.event.event_data.attachment.imgs == null ) {
                vue.event.event_data.attachment.imgs = [];
            }

            // 임시파일 데이터로 객체 대체하기
            const image_files_count : number = this.image_files.length;
            for( let i = 0; i < image_files_count; i++ ) {
                vue.event.event_data.attachment.imgs.splice(this.image_files[i].index, 1, temp_files[i]);    
            }

        } catch(e) {
            throw e;
        }    
    }

    /**
     * 파일 임시 업로드
     */
    async fileTempUpload() : Promise<void> {
        
        const vue = this;

        // await 파일 TEMP 업로드
        const form_data : FormData = new FormData();
        
        // FormData 내용 생성
        const all_files_count : number = this.all_files.length;
        for( let i = 0; i < all_files_count; i++ ) {
            form_data.append('file', this.all_files[i].file);
        }

        try {
            const response = await this.hodu_temp_upload(form_data);
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("파일 임시 업로드 실패");
            }

            const temp_files : t_event_file[] = response.data.data.temp_files;

            // attachment null check
            if( vue.event.event_data.attachment == null ) {
                vue.event.event_data.attachment = {
                    imgs : [],
                    files : []
                };
            }

            // files null check
            if( vue.event.event_data.attachment.files == null ) {
                vue.event.event_data.attachment.files = [];
            }

            // 임시파일 데이터로 객체 대체하기
            const all_files_count : number = this.all_files.length;
            for( let i = 0; i < all_files_count; i++ ) {
                vue.event.event_data.attachment.files.splice(this.all_files[i].index, 1, temp_files[i]);    
            }

        } catch(e) {
            throw e;
        }
        
    }

    /**
     * 일정 수정 API 
     */
    async updateAPICall() : Promise<void> {
        if( this.share_info == null ) { return; }

        const vue = this;

        let scope : string = this.share_info.share_scope;
        let scope_id : number = this.share_info.share_scope_id;

        // OWNER 권한으로 체크
        const target = this.share_infos.filter(share_info => share_info.share_type == 'OWNER');
        if( target.length > 0 ) {
            scope = target[0].share_scope;
            scope_id = target[0].share_scope_id;
        }

        // 일정 수정
        if( this.event.event_sub_type != EVENT_SUB_TYPE.WORK ) {
            this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}`, API_METHOD.PUT, this.event.event_data)
                .then((response) => {
                    console.log(response);

                    // 투표 존재한다면 투표 수정
                    if( this.event.event_data.vote != null && this.event.event_data.vote.length > 0 ) {
                        this.hodu_api_call(`api/v1/vote/${this.event.event_id}`, API_METHOD.PUT, {
                            vote : JSON.parse(JSON.stringify(this.event.event_data.vote))
                        });
                    }

                    local_storage_info.last_event_created_color = this.event.event_data.color ?? '#477FFF';
                    hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info)); 

                    vue.movePrevPage();

                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        // 업무 수정
        else if ( this.event.event_sub_type == EVENT_SUB_TYPE.WORK ) {
            let query = "";

            if( this.assignment_user_list != null && this.assignment_user_list.length > 0 ) {
                query = "?";
                const assignment_user_list_count : number = this.assignment_user_list.length;
                for( let i = 0; i < assignment_user_list_count; i++ ) {
                    if( query != '?' ) {
                        query += '&';
                    }
                    query += `assignment_users=${this.assignment_user_list[i]}`;
                }
            }

            this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/works/${scope}/${scope_id}/${this.event.event_id}${query}`, API_METHOD.PUT, this.event.event_data)
                .then((response) => {
                    console.log(response);
                    local_storage_info.last_event_created_color = this.event.event_data.color ?? '#477FFF';
                    hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info)); 
                    vue.movePrevPage();
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                }); 
        }

        
    }

    /**
     * 반복 수정 옵션 선택
     */
    async repeatModify(code : string) : Promise<void> {
        this.repeat_modify_code = code;
        
        // 전체 반복 수정이라면 start, end 날짜 원본 날짜로 변경
        if( code == 'A' ) {
            this.event.event_data.schedule_date.start = this.event_original_date.original_start;
            this.event.event_data.schedule_date.end   = this.event_original_date.original_end;
        }

        // 이후 일정 수정, 이 일정만 수정인 경우 recurrence_id를 클릭해서 들어온 날짜로 설정한다
        else if( code == 'F' ) {
            this.event.event_data.schedule_date.recurrence_id = this.event.event_data.schedule_date.start;
            await this.downloadImageAndFileForCopy();
        }

        // 이 일정만 수정인 경우 recurrence_id를 클릭해서 들어온 날짜로 설정 & rrule 제거
        else {
            this.event.event_data.schedule_date.recurrence_id = this.event.event_data.schedule_date.start;
            this.event.event_data.schedule_date.rrule         = undefined;
            await this.downloadImageAndFileForCopy();
        }

        await this.makeDateString();
        await this.doSetEventCrudType(CRUD_TYPE.UPDATE);
        await this.setDatePicker();

        // @ts-ignore alarmDivScroll 스크롤
        $('.alarmDivScroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 200,
            autoDraggerLength : false,
        });
    }

    /**
     * 반복 삭제 옵션 선택
     */
    async repeatDelete(code : string) : Promise<void> {
        if( this.share_info == null ) { return; }

        const vue = this;
        
        let scope : string = this.share_info.share_scope;
        let scope_id : number = this.share_info.share_scope_id;

        if( scope_id == null ) {
            scope_id = 0;
        }

        switch( code ) {
            // 전체 일정 삭제
            case "A":
                await this.deleteEventModalCall(this.deleteEventAPICall);
                break;

            // 이후 일정 삭제 ( recurrence_end를 진입 날짜 -1로 설정 후 update )
            case "F":
                await this.deleteEventModalCall(async() => {
                    this.event.event_data.schedule_date.recurrence_end = new Date(this.event.event_data.schedule_date.end);
                    this.event.event_data.schedule_date.recurrence_end.setDate(this.event.event_data.schedule_date.recurrence_end.getDate() - 1);

                    this.event.event_data.schedule_date.start = this.event_original_date.original_start;
                    this.event.event_data.schedule_date.end   = this.event_original_date.original_end;

                    await this.updateAPICall();
                });

                break;

            // 이 일정만 삭제 ( isIgnore = true로 설정 후 create )
            case "E":
                await this.deleteEventModalCall(async() => {
                    this.event.event_data.schedule_date.recurrence_id = this.event.event_data.schedule_date.start;
                    this.event.event_data.schedule_date.isIgnore = true;
                    
                    // 일정 저장
                    await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}`, API_METHOD.POST, this.event.event_data)
                        .then(async(response) => {
                            console.log(response);
                            vue.movePrevPage();
                        })
                        .catch(async(e) => {
                            this.hodu_error_process(e, true, false);
                        });
                });
                
                break;
        }

    }

    /**
     * 저장 전 일정 데이터가 올바른지 체크
     */
    async checkValid() : Promise<boolean> {

        // 업무일때 생성된 업무 템플릿이 없는 경우
        if( this.event.event_sub_type == EVENT_SUB_TYPE.WORK && this.selected_work_template == null ) {
            this.hodu_show_dialog('alert', '프로젝트 템플릿을 먼저 생성 해 주세요', ['확인']);
            return false;
        }

        // 제목 2글자 미만 체크
        if( this.event.event_data.title.trim().length < 2 ) {
            this.hodu_show_dialog('alert', '일정 제목을 입력 해 주세요(최소 2자 이상 입력)', ['확인']);
            return false;
        }

        // 제목 100글자 초과 체크
        if( this.event.event_data.title.trim().length > 100 ) {
            this.hodu_show_dialog('alert', '일정 제목은 100자 까지 입력이 가능합니다', ['확인']);
            return false;
        }

        // 시작일, 종료일 비교 (종료일이 시작일보다 빠른 경우)
        if( new Date(this.event.event_data.schedule_date.start).getTime() > new Date(this.event.event_data.schedule_date.end).getTime() ) {
            this.hodu_show_dialog('alert', '일정 종료시간을 확인하세요', ['확인']);
            return false;
        }

        // 반복종료일 & 시작일 체크
        if( this.event.event_data.schedule_date.rrule != null && this.getDateDiff(this.event.event_data.schedule_date.start, this.event.event_data.schedule_date.recurrence_end) > 0 &&
            new Date(this.event.event_data.schedule_date.start).getTime() > new Date(this.event.event_data.schedule_date.recurrence_end).getTime() ) {
            this.hodu_show_dialog('alert', '반복 종료일이 시작일 보다 빠를 수 없습니다', ['확인']);
            return false;
        }

        const is_premium = this.is_premium_group(this.event.group_id > 0 ? 'GROUP' : this.event!.team_id > 0 ? 'TEAM' : '', this.event.group_id > 0 ? this.event.group_id : this.event.team_id > 0 ? this.event.team_id : 0);

        let max_size : number = is_premium ? this.DEFAULT_FILE_MAX_SIZE : this.NORMAL_GROUP_FILE_MAX_SIZE;
        let max_size_text : string = is_premium ? this.DEFAULT_FILE_MAX_SIZE_TEXT : this.NORMAL_GROUP_FILE_MAX_SIZE_TEXT;

        // 다시 한 번 파일 용량 체크 (일반 그룹 등으로 변경 했을 경우에 저장하는것을 막기 위함)
        // for( const file_obj of this.all_files ) {
        //     if( file_obj.file.size > max_size ) {
        //         this.hodu_show_dialog('alert', `${max_size_text} 이하의 파일만 업로드 가능 합니다`, ['확인']);
        //         return false;
        //     }
        // }
        
        return true;
    }

    /**
     * 규칙에 맞게 event값 세팅
     */
    async setEventData() : Promise<void> {
        
        /**
         * 음력 설정시 lunar_start, lunar_end 설정
         */
        if( this.event.event_data.schedule_date.lunar_yn == true ) {
            const lunar_date : string = await this.hodu_solar_to_lunar(this.event.event_data.schedule_date.start);

            // 음력 문자열 포맷 결정 되어야함
            this.event.event_data.schedule_date.lunar_start = lunar_date;
            this.event.event_data.schedule_date.lunar_end   = lunar_date;
            this.event.event_data.schedule_date.isAllDay    = true;
            this.event.event_data.schedule_date.isContinuos = true;
        }

        /**
         * 양력 설정
         */
        else {

            /**
             * 종일
             */
            if( this.event.event_data.schedule_date.isAllDay == true ) {
                const start : Date = new Date(this.event.event_data.schedule_date.start);
                const end   : Date = new Date(this.event.event_data.schedule_date.end);

                start.setHours(0);
                start.setMinutes(0);
                start.setSeconds(0);

                end.setHours(23);
                end.setMinutes(59);
                end.setSeconds(59);

                this.event.event_data.schedule_date.start       = moment(start).format('YYYY-MM-DDTHH:mm:ssZ');
                this.event.event_data.schedule_date.end         = moment(end).format('YYYY-MM-DDTHH:mm:ssZ');
                this.event.event_data.schedule_date.isContinuos = true;

            }

            /**
             * 종일이 아닐 때
             */
            else {

                // 1일 이상 차이나는 경우 (연속 일정)
                if( this.date_term > 0 ) {
                    this.event.event_data.schedule_date.isContinuos = true;

                    // 종료일이 0시 0분일때 23:59:59로 세팅
                    if( new Date(this.event.event_data.schedule_date.end).getHours() == 0 && new Date(this.event.event_data.schedule_date.end).getMinutes() == 0 ) {
                        const end : Date = new Date(this.event.event_data.schedule_date.end);
                        end.setDate(end.getDate() - 1);
                        end.setHours(23);
                        end.setMinutes(59);
                        end.setSeconds(59);

                        this.event.event_data.schedule_date.end = end;
                    }
                }

                // 일반 일정인 경우
                else {
                    this.event.event_data.schedule_date.isContinuos = false;
                }

            }

        }

        // 반복일정이 아니라면 recurrence_end를 end와 동일하게 맞춘다
        if( this.event.event_data.schedule_date.rrule == null || this.event.event_data.schedule_date.rrule.length < 1 ) {
            this.event.event_data.schedule_date.recurrence_end = new Date(this.event.event_data.schedule_date.end);
        }

        // 반복일정이라면 참석 & 투표 데이터를 없앤다
        else {
            this.event.event_data.vote   = [];
            this.event.event_data.attend = false;

            const dtStart : Date = new Date(this.event.event_data.schedule_date.start);

            // 해당 요일이 주 반복에 포함되어있지 않다면 Rrule의 첫 날짜로 start & end를 변경
            const rruleSet : RRule | RRuleSet = rrulestr(`DTSTART:${this.formatDateForRruleDTSTART(dtStart)}\nRRULE:${this.event.event_data.schedule_date.rrule};UNTIL=20501231`);
            if( rruleSet.options.freq == RRule.WEEKLY && 
                rruleSet.options.byweekday.indexOf(new Date(this.event.event_data.schedule_date.start).getDay() -1 > -1 ? new Date(this.event.event_data.schedule_date.start).getDay() -1 : 6 ) == -1 ) {
                
                const term : number = new Date(this.event.event_data.schedule_date.end).getTime() - new Date(this.event.event_data.schedule_date.start).getTime();

                this.event.event_data.schedule_date.start = rruleSet.all()[0];
                this.event.event_data.schedule_date.end   = new Date(new Date(this.event.event_data.schedule_date.start).getTime() + term);
            }
        }

        // 전화번호 세팅
        if( this.event.event_data.contacts != null && this.event.event_data.contacts[0] != null ) {
            this.event.event_data.contacts[0].tel = this.event.event_data.contacts[0].tel == null ? "" : this.event.event_data.contacts[0].tel.trim();
        }

        // 노트 & 메모 세팅(trim)
        this.event.event_data.note = this.event.event_data.note == null ? "" : this.event.event_data.note.trim();
        this.event.event_data.memo = this.event.event_data.memo == null ? "" : this.event.event_data.memo.trim();
        this.event.event_data.event_push_yn = (this.push_yn == 'Y');

        try {
            let push_yn_preference = await this.get_user_preference("push_yn", false);

            if( push_yn_preference == null ) {
                push_yn_preference = { "user_id" : this.user_id, "cate" : "push_yn", "preference" : { "WEB" : { "report" : true, "meetinglog" : true, "work" : true, "basic" : true } }, "audit_modified" : new Date() };
            }

            let preference = push_yn_preference['preference'];

            if( preference == null ) {
                preference = { "WEB" : { "report" : true, "meetinglog" : true, "work" : true, "basic" : true } };
            }

            let web_push_yn_preference = preference['WEB'];

            if( web_push_yn_preference == null ) {
                web_push_yn_preference = { "report" : true, "meetinglog" : true, "work" : true, "basic" : true };
            }
            
            if( this.isReport(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                web_push_yn_preference['report'] = this.event.event_data.event_push_yn;
            }

            else if( this.isMeetingLog(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                web_push_yn_preference['meetinglog'] = this.event.event_data.event_push_yn;
            }

            else if( this.isWork(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                web_push_yn_preference['work'] = this.event.event_data.event_push_yn;
            }

            else if( this.isEvent(this.event.event_sub_type as EVENT_SUB_TYPE) ) {
                web_push_yn_preference['basic'] = this.event.event_data.event_push_yn;
            }

            preference['WEB'] = JSON.parse(JSON.stringify(web_push_yn_preference));

            this.set_user_preference('push_yn', preference, false);
            
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * DTSTART 제작 : 일정 시작일
     */
    formatDateForRruleDTSTART(date : Date) : string {
        return `${require('dateformat')(date, "UTC:yyyymmdd")}T${require('dateformat')(date, "UTC:HHMMss")}Z`;
    }

    /**
     * 이벤트 조회
     */
    async getEvent() : Promise<void> {
        const vue = this
        
        const user_id  : number = this.event.user_id  == null ? 0 : this.event.user_id;
        const group_id : number = this.event.group_id == null ? 0 : this.event.group_id;
        const team_id  : number = this.event.team_id  == null ? 0 : this.event.team_id;

        const scope    : string = user_id > 0 ? OWNER_TYPE.PERSONAL 
                                              : group_id > 0 ? OWNER_TYPE.GROUP : OWNER_TYPE.TEAM;

        const scope_id : number = user_id > 0 ? user_id 
                                              : group_id > 0 ? group_id : team_id;

        switch( this.event.event_sub_type ) {
            case EVENT_SUB_TYPE.SCHEDULE:
            case EVENT_SUB_TYPE.CARD:
            case EVENT_SUB_TYPE.REPORT:
            case EVENT_SUB_TYPE.MEETINGLOG:
                this.selectScheduleAndCard(scope, scope_id);
                break;

            case EVENT_SUB_TYPE.WORK:
                this.selectWork(scope, scope_id);
                break;
        }

    }

    /**
     * 일정 조회
     */
    async selectScheduleAndCard(scope : string, scope_id : number) : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                const response_data : any = response.data.data;
                vue.share_infos = response_data.event_share;
                
                const is_work = response_data.event_data.event_sub_type == EVENT_SUB_TYPE.WORK;
                const is_report = response_data.event_data.event_sub_type == EVENT_SUB_TYPE.REPORT 
                const is_meetinglog = response_data.event_data.event_sub_type == EVENT_SUB_TYPE.MEETINGLOG;

                // share_info로 공유 받은 일정이고 해당 캘린더의 삭제 권한 으로 deletable 업데이트
                const calendar_scope_id : number = this.scope == OWNER_TYPE.PERSONAL ? this.user_id : (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id);
                for( let share of vue.share_infos ) {
                    if( share.share_scope == vue.scope && share.share_scope_id == calendar_scope_id  ) {
                        vue.share_info = share;

                        // 삭제 권한 업데이트
                        switch( share.share_scope ) {
                            case OWNER_TYPE.PERSONAL:
                                vue.is_deleteable = true;
                                break;

                            case OWNER_TYPE.GROUP:
                                vue.is_deleteable = await ((is_work ? vue.is_group_permmision(share.share_scope_id, 'work', 'delete') :
                                                            is_report ? vue.is_group_permmision(share.share_scope_id, 'report', 'delete') :
                                                            is_meetinglog ? vue.is_group_permmision(share.share_scope_id, 'meetinglog', 'delete') :  
                                                                            vue.is_group_permmision(share.share_scope_id, 'event', 'delete')) || vue.event.event_data.event_owner_id == vue.user_id);
                                break;
                            
                            case OWNER_TYPE.TEAM:
                                vue.is_deleteable = await ((is_work ? vue.is_team_permmision(share.share_scope_id, 'work', 'delete') : 
                                                            is_report ? vue.is_team_permmision(share.share_scope_id, 'report', 'delete') : 
                                                            is_meetinglog ? vue.is_team_permmision(share.share_scope_id, 'meetinglog', 'delete') : 
                                                                            vue.is_team_permmision(share.share_scope_id, 'event', 'delete')) || vue.event.event_data.event_owner_id == vue.user_id);
                                break;
                        }
                        this.is_shared = (share.share_type == 'SHARE'); // 공유 되어서 볼 수 있는 것인지 여부 등록
                        break;
                    }
                }

                // 보고있는 달력과는 다른 share_scope라면 첫번째 share 정보를 사용한다
                if( vue.share_info == null ) {
                    vue.share_info = vue.share_infos[0];

                    // 삭제 권한 업데이트
                    switch( vue.share_info.share_scope ) {
                        case OWNER_TYPE.PERSONAL:
                            vue.is_deleteable = true;
                            break;

                        case OWNER_TYPE.GROUP:
                            vue.is_deleteable = await ((is_work ? vue.is_group_permmision(vue.share_info.share_scope_id, 'work', 'delete') :
                                                        is_report ? vue.is_group_permmision(vue.share_info.share_scope_id, 'report', 'delete') :
                                                        is_meetinglog ? vue.is_group_permmision(vue.share_info.share_scope_id, 'meetinglog', 'delete') :  
                                                                        vue.is_group_permmision(vue.share_info.share_scope_id, 'event', 'delete')) || vue.event.event_data.event_owner_id == vue.user_id);
                            break;
                        
                        case OWNER_TYPE.TEAM:
                            vue.is_deleteable = await ((is_work ? vue.is_team_permmision(vue.share_info.share_scope_id, 'work', 'delete') : 
                                                        is_report ? vue.is_team_permmision(vue.share_info.share_scope_id, 'report', 'delete') : 
                                                        is_meetinglog ? vue.is_team_permmision(vue.share_info.share_scope_id, 'meetinglog', 'delete') : 
                                                                        vue.is_team_permmision(vue.share_info.share_scope_id, 'event', 'delete')) || vue.event.event_data.event_owner_id == vue.user_id);
                            break;
                    }
                }

                // 개인 설정 데이터를 제외하고 데이터 적용
                const new_event : t_event = response_data.event_data;
                new_event.event_data.schedule_date.start          = vue.event.event_data.schedule_date.start; 
                new_event.event_data.schedule_date.end            = vue.event.event_data.schedule_date.end; 
                new_event.event_data.schedule_date.recurrence_end = vue.event.event_data.schedule_date.recurrence_end; 

                // 노트가 아예 없다면 빈값이라도 넣어주도록 처리 undefined 라서 노트 입력이 아예 안됨
                new_event.event_data.note = new_event.event_data.note ? new_event.event_data.note : "";

                this.doSetEvent(new_event);

                if( this.event.event_data.schedule_date.lunar_yn == true ) {
                    this.day_repeat = false;
                    this.week_repeat = false;
                    this.month_repeat = false; 
                    this.year_repeat = true; 
                }
                
                // RGB값만 있다면 그대로 RGB를 사용, ARGB라면 ARGB를 RGB로 변환
                vue.event.event_data.color = vue.hodu_hex_color_process(vue.event.event_data.color);

                // 개인 설정 데이터가 있는 경우
                if( response_data.event_data_indi != null ) {
                        
                    // 노트
                    if( response_data.event_data_indi.note != null ) {
                        vue.event.event_data.note = response_data.event_data_indi.note;
                    }

                    // 알람
                    if( response_data.event_data_indi.alarm != null ) {
                        
                        // 알람이 없는 경우
                        if( vue.event.event_data.alarm == null ) {
                            vue.event.event_data.alarm = [];
                        }

                        vue.event.event_data.alarm.splice(0, vue.event.event_data.alarm.length);
                        vue.event.event_data.alarm = vue.event.event_data.alarm.concat(response_data.event_data_indi.alarm);
                    }

                    // 색상
                    if( response_data.event_data_indi.color != null ) {
                        vue.event.event_data.color = vue.hodu_hex_color_process(response_data.event_data_indi.color);
                    }

                }

                // 투표
                if( response_data.voted_item != null ) {
                    vue.vote_result = response_data.voted_item;
                }

                // 참석 데이터 있을때
                if( response_data.attend_data != null ) {
                    vue.attend_data = response_data.attend_data;
                }

                // 참석 데이터 없을때
                else {
                    vue.attend_data = null;
                }

                // 참석, 투표 가능 여부
                if( response_data.is_attendable != null ) {
                    vue.is_attendable = response_data.is_attendable;
                }

                // 댓글 존재 여부
                if( response_data.is_exist_reply != null ) {
                    vue.is_exist_reply = response_data.is_exist_reply;
                }

                // 참석&투표 참여 가능한 사람 수
                if( response_data.attendable_user_count != null ) {
                    vue.attendable_user_count = response_data.attendable_user_count;
                }

                // 참석 여부 참여 한 사람 수
                if( response_data.attend_user_count != null ) {
                    vue.attend_user_count = response_data.attend_user_count;
                }

                // 투표 참여 한 사람 수 배열
                if( response_data.vote_user_count != null ) {
                    vue.vote_user_count.splice(0, vue.vote_user_count.length);
                    vue.vote_user_count = vue.vote_user_count.concat(response_data.vote_user_count);
                }

                if( response_data.read_info != null ) {
                    vue.read_info.splice(0, vue.read_info.length);
                    vue.read_info = vue.read_info.concat(response_data.read_info);
                }

                this.is_premium_event = response_data.is_premium_event;

                this.date_term = this.getDateDiff(this.event.event_data.schedule_date.start, this.event.event_data.schedule_date.end);

                await this.permissionCheck();

                // 업무일지, 회의록은 반복일정 없으므로 0
                if( this.event.event_sub_type == EVENT_SUB_TYPE.MEETINGLOG || this.event.event_sub_type == EVENT_SUB_TYPE.REPORT ) {
                    this.repeat_seq = 0;
                }
                else {

                    // 음력을 제외한 반복일정
                    if( this.event.event_data.schedule_date.rrule != null && this.event.event_data.schedule_date.rrule.length > 0 && this.event.event_data.schedule_date.lunar_yn == false ) {
                        
                        const rrule = this.event.event_data.schedule_date.rrule;
                        const start = this.event.event_data.schedule_date.start;
                        const original_start = this.event_original_date.original_start;
                        const recurrence_end = this.event.event_data.schedule_date.recurrence_end;

                        const rrule_str = `DTSTART:${moment(original_start).utc().format('YYYYMMDDTHHmmss')}Z\nRRULE:${rrule};UNTIL=${moment(recurrence_end).utc().format('YYYYMMDDTHHmmss')}Z`;

                        const rrule_obj : RRule | RRuleSet = await rrulestr(rrule_str);

                        const repeat_dates = rrule_obj.all();

                        const start_yyyymmdd = moment(start).format('YYYYMMDD');
                        for( const repeat_date of repeat_dates ) {
                            const repeat_date_yyyymmdd = moment(repeat_date).format('YYYYMMDD');
                            if( start_yyyymmdd == repeat_date_yyyymmdd ) {
                                this.repeat_seq = repeat_dates.indexOf(repeat_date);
                                break;
                            }
                        }
                    }

                    // 음력 반복 일정인 경우
                    if( this.event.event_data.schedule_date.rrule != null && this.event.event_data.schedule_date.rrule.length > 0 && this.event.event_data.schedule_date.lunar_yn == true ) {
                        const start = this.event.event_data.schedule_date.start;
                        const original_start = this.event_original_date.original_start;

                        const lunar_start = await this.hodu_solar_to_lunar(start);
                        const lunar_original_start = await this.hodu_solar_to_lunar(original_start);

                        this.repeat_seq = (Number(lunar_start.replaceAll('-', '')) - Number(lunar_original_start.replaceAll('-', ''))) / 10000;
                    }

                }

                // 일정 읽기 처리
                if( this.event.event_data.event_owner_id != this.user_id ) {
                    await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/read/${this.event.event_id}/${this.repeat_seq}`, API_METHOD.POST, null, false);
                }
                
            }).catch(async(e) => {
                this.hodu_error_process(e, true, true);
            });
    }

    /**
     * 업무 조회
     */
    async selectWork(scope : string, scope_id : number) : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/calendars/${ this.event.calendar_id }/works/${ scope }/${ scope_id }/${ this.event.event_id }`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                const response_data : any = response.data.data;

                // 노트가 아예 없다면 빈값이라도 넣어주도록 처리 undefined 라서 노트 입력이 아예 안됨
                response_data.event_data.event_data.note = response_data.event_data.event_data.note ? response_data.event_data.event_data.note : "";

                this.doSetEvent(response_data.event_data);
                vue.share_infos = response_data.event_share;

                // share_info로 공유 받은 일정이고 해당 캘린더의 삭제 권한 으로 deletable 업데이트
                const calendar_scope_id : number = this.scope == OWNER_TYPE.PERSONAL ? this.user_id : (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id);
                for( let share of vue.share_infos ) {
                    if( share.share_scope == vue.scope && share.share_scope_id == calendar_scope_id  ) {
                        vue.share_info = share;

                        // 삭제 권한 업데이트
                        switch( share.share_scope ) {
                            case OWNER_TYPE.PERSONAL:
                                vue.is_deleteable = true;
                                if( share.share_type == 'SHARE' ) {
                                    vue.is_editable = false;
                                    vue.group_color = 'transparent';
                                }
                                break;

                            case OWNER_TYPE.GROUP:
                                vue.is_deleteable = await vue.is_group_permmision(share.share_scope_id, 'work', 'delete') || vue.event.event_data.event_owner_id == vue.user_id;
                                break;
                            
                            case OWNER_TYPE.TEAM:
                                vue.is_deleteable = await vue.is_team_permmision(share.share_scope_id, 'work', 'delete') || vue.event.event_data.event_owner_id == vue.user_id;
                                break;
                        }
                        this.is_shared = (share.share_type == 'SHARE'); // 공유 되어서 볼 수 있는 것인지 여부 등록
                        break;
                    }
                }

                // 보고있는 달력과는 다른 share_scope라면 첫번째 share 정보를 사용한다
                if( vue.share_info == null ) {
                    vue.share_info = vue.share_infos[0];

                    // 삭제 권한 업데이트
                    switch( vue.share_info.share_scope ) {
                        case OWNER_TYPE.PERSONAL:
                            vue.is_deleteable = true;
                            break;

                        case OWNER_TYPE.GROUP:
                            vue.is_deleteable = await vue.is_group_permmision(vue.share_info.share_scope_id, 'work', 'delete') || vue.event.event_data.event_owner_id == vue.user_id;
                            break;
                        
                        case OWNER_TYPE.TEAM:
                            vue.is_deleteable = await vue.is_team_permmision(vue.share_info.share_scope_id, 'work', 'delete') || vue.event.event_data.event_owner_id == vue.user_id;
                            break;
                    }
                }
                
                // RGB값만 있다면 그대로 RGB를 사용, ARGB라면 ARGB를 RGB로 변환
                vue.event.event_data.color = vue.hodu_hex_color_process(vue.event.event_data.color ? vue.event.event_data.color : "#477FFF");
                
                // 개인 설정 데이터가 있는 경우
                if( response_data.event_data_indi != null ) {
                        
                    // 노트
                    if( response_data.event_data_indi.note != null ) {
                        vue.event.event_data.note = response_data.event_data_indi.note;
                    }

                    // 알람
                    if( response_data.event_data_indi.alarm != null ) {
                        
                        // 알람이 없는 경우
                        if( vue.event.event_data.alarm == null ) {
                            vue.event.event_data.alarm = [];
                        }

                        vue.event.event_data.alarm.splice(0, vue.event.event_data.alarm.length);
                        vue.event.event_data.alarm = vue.event.event_data.alarm.concat(response_data.event_data_indi.alarm);
                    }

                    // 색상
                    if( response_data.event_data_indi.color != null ) {
                        vue.event.event_data.color = vue.hodu_hex_color_process(response_data.event_data_indi.color);
                    }

                }

                // 댓글 존재 여부
                if( response_data.is_exist_reply != null ) {
                    vue.is_exist_reply = response_data.is_exist_reply;
                }

                // 업무 관련 데이터
                // target_users - 업무 대상이 될수있는 그룹원or팀원 리스트
                if( response_data.target_users != null ) {
                    vue.target_user_list.splice(0, vue.target_user_list.length);
                    vue.target_user_list = vue.target_user_list.concat(response_data.target_users);

                    vue.all_member_count = vue.target_user_list.length;
                }

                // work_template - 업무 템플릿
                if( response_data.work_template != null ) {
                    vue.selected_work_template = response_data.work_template; 
                }

                // work_status - 업무자별 업무 상태
                vue.work_status_list.splice(0, vue.work_status_list.length);
                vue.user_work_status = null;
                if( response_data.work_status != null ) {
                    vue.work_status_list = vue.work_status_list.concat(response_data.work_status);

                    // assignment_user에 채우기
                    vue.assignment_user_list.splice(0, vue.assignment_user_list.length);
                    const work_status_list_length : number = vue.work_status_list.length;
                    for( let i = 0; i < work_status_list_length; i++ ) {
                        vue.assignment_user_list.push(vue.work_status_list[i].user_id);

                        // 유저가 업무자라면 업무진행상태를 저장한다
                        if( vue.work_status_list[i].user_id == vue.user_id ) {
                            vue.user_work_status = vue.work_status_list[i];
                        }
                    }
                }
                
                this.date_term = this.getDateDiff(this.event.event_data.schedule_date.start, this.event.event_data.schedule_date.end);

                // 반복 프로젝트는 없기 때문에 무조건 0
                this.repeat_seq = 0;

                if( this.event.event_data.event_owner_id != this.user_id ) {
                    await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/read/${this.event.event_id}/${this.repeat_seq}`, API_METHOD.POST, null, false);
                } 
            })
            .catch((e) => {
                this.hodu_error_process(e, true, true);
            });
    }

    /**
     * 일정 권한 체크
     */
    async permissionCheck() : Promise<void> {

        if( this.user_id == null || this.event == null || this.event.user_id == null || this.event.group_id == null || this.event.team_id == null ) {
            return;
        }

        const is_work = this.event.event_sub_type == EVENT_SUB_TYPE.WORK;
        const is_report = this.event.event_sub_type == EVENT_SUB_TYPE.REPORT 
        const is_meetinglog = this.event.event_sub_type == EVENT_SUB_TYPE.MEETINGLOG;

        // 일정 생성 권한 체크
        this.is_createable = this.event.user_id  > 0 ? true :
                             this.event.group_id > 0 ? (is_work ? (this.is_group_permmision(this.event.group_id, 'work' , 'create')) : 
                                                        is_report ? (this.is_group_permmision(this.event.group_id, 'report', 'create')) :
                                                        is_meetinglog ? (this.is_group_permmision(this.event.group_id, 'meetinglog', 'create')) :
                                                                        (this.is_group_permmision(this.event.group_id, 'event', 'create')) ):
                             this.event.team_id  > 0 ? (is_work ? (this.is_team_permmision(this.event.team_id  , 'work' , 'create')) : 
                                                        is_report ? (this.is_team_permmision(this.event.team_id  , 'report' , 'create')) :
                                                        is_meetinglog ? (this.is_team_permmision(this.event.team_id  , 'meetinglog' , 'create')) :
                                                                        (this.is_team_permmision(this.event.team_id  , 'event', 'create')) ): false;

        this.is_copyable = this.scope == OWNER_TYPE.PERSONAL || 
                            ( this.scope != OWNER_TYPE.PERSONAL && this.scope_team_id > 0 ? this.is_team_permmision((this.event.is_temp == true ? this.event.scope_team_id : this.scope_team_id), 'event', 'create') 
                                                                                          : this.is_group_permmision((this.event.is_temp == true ? this.event.scope_group_id : this.scope_group_id), 'event', 'create') );
        

        // 작성자는 일정 수정, 삭제 권한이 무조건 존재한다
        if ( this.user_id == this.event.event_data.event_owner_id ) {
            this.is_editable   = true;
            this.is_deleteable = true;
        }

        // 작성자가 아니라면 수정, 삭제 권한 체크
        else {
            this.is_editable   = this.event.group_id > 0 ? (is_work ? (this.is_group_permmision(this.event.group_id, 'work' , 'modify')) : 
                                                            is_report ? (this.is_group_permmision(this.event.group_id, 'report' , 'modify')) : 
                                                            is_meetinglog ? (this.is_group_permmision(this.event.group_id, 'meetinglog' , 'modify')) : 
                                                                            (this.is_group_permmision(this.event.group_id, 'event', 'modify')) ): 
                                this.event.team_id  > 0 ? (is_work ? (this.is_team_permmision(this.event.team_id, 'work' , 'modify')) :
                                                           is_report ? (this.is_team_permmision(this.event.team_id, 'report' , 'modify')) : 
                                                           is_meetinglog ? (this.is_team_permmision(this.event.team_id, 'meetinglog' , 'modify')) :  
                                                                           (this.is_team_permmision(this.event.team_id, 'event', 'modify')) ): false;

            this.is_deleteable = this.event.group_id > 0 ? (is_work ? (this.is_group_permmision(this.event.group_id, 'work' , 'delete')) : 
                                                            is_report ? (this.is_group_permmision(this.event.group_id, 'report' , 'delete')) : 
                                                            is_meetinglog ? (this.is_group_permmision(this.event.group_id, 'meetinglog' , 'delete')) :         
                                                                      (this.is_group_permmision(this.event.group_id, 'event', 'delete')) || (this.event.subscribe_users != null && this.event.subscribe_users.indexOf(this.user_id) > -1 )) : 
                                this.event.team_id  > 0 ? (is_work ? (this.is_team_permmision(this.event.team_id, 'work' , 'delete')) :
                                                           is_report ? (this.is_group_permmision(this.event.group_id, 'report' , 'delete')) : 
                                                           is_meetinglog ? (this.is_group_permmision(this.event.group_id, 'meetinglog' , 'delete')) :  
                                                                           (this.is_team_permmision(this.event.team_id, 'event', 'delete')) || (this.event.subscribe_users != null && this.event.subscribe_users.indexOf(this.user_id) > -1 )) :
                                this.event.subscribe_users != null && this.event.subscribe_users.indexOf(this.user_id) > -1 ? true : false;
        }

        // 삭제 권한 업데이트
        if( this.share_info != null ) {
            switch( this.share_info.share_scope ) {
                case OWNER_TYPE.PERSONAL:
                    this.is_deleteable = true;
                    if( this.share_info.share_type == 'SHARE' ) {
                        this.is_editable = false;
                        this.group_color = 'transparent';
                    }
                    break;

                case OWNER_TYPE.GROUP:
                    this.is_deleteable = await ((is_work ? this.is_group_permmision(this.share_info.share_scope_id, 'work', 'delete') :
                                                 is_report ? this.is_group_permmision(this.share_info.share_scope_id, 'report', 'delete') : 
                                                 is_meetinglog ? this.is_group_permmision(this.share_info.share_scope_id, 'meetinglog', 'delete') : 
                                                                 this.is_group_permmision(this.share_info.share_scope_id, 'event', 'delete')) || this.event.event_data.event_owner_id == this.user_id || (this.event.subscribe_users != null && this.event.subscribe_users.indexOf(this.user_id) > -1 ));
                    break;
                
                case OWNER_TYPE.TEAM:
                    this.is_deleteable = await ((is_work ? this.is_team_permmision(this.share_info.share_scope_id, 'work', 'delete') :
                                                 is_report ? this.is_team_permmision(this.share_info.share_scope_id, 'report', 'delete') : 
                                                 is_meetinglog ? this.is_team_permmision(this.share_info.share_scope_id, 'meetinglog', 'delete') : 
                                                                 this.is_team_permmision(this.share_info.share_scope_id, 'event', 'delete')) || this.event.event_data.event_owner_id == this.user_id || (this.event.subscribe_users != null && this.event.subscribe_users.indexOf(this.user_id) > -1 ))
                    break;
            }
        }

        // 일정의 경우 그룹, 팀 일정은 작성자여도 이전 일정 수정, 삭제가 불가능한 경우 과거 일정을 삭제할수 없다
        if( (this.event.event_sub_type == EVENT_SUB_TYPE.SCHEDULE || this.event.event_sub_type == EVENT_SUB_TYPE.CARD) && this.event.event_type != OWNER_TYPE.PERSONAL ) {
            
            // 과거 일정 수정 금지, 과거 일정 삭제 금지 권한
            let is_modify_prev = false;
            let is_delete_prev = false;

            switch( this.event.event_type ) {
                case OWNER_TYPE.GROUP:
                    is_modify_prev = this.is_group_permmision(this.event.group_id, 'event', 'modify_prev');
                    is_delete_prev = this.is_group_permmision(this.event.group_id, 'event', 'delete_prev');
                    break;

                case OWNER_TYPE.TEAM:
                    is_modify_prev = this.is_team_permmision(this.event.team_id, 'event', 'modify_prev');
                    is_delete_prev = this.is_team_permmision(this.event.team_id, 'event', 'delete_prev');
                    break;
            }

            if( is_modify_prev || is_delete_prev ) {
                const current : Date = new Date();
                const created : Date = new Date(this.event.audit_created ? this.event.audit_created : 0);
                const end     : Date = new Date(this.event.event_data.schedule_date.recurrence_end);

                // 시간제한에 걸리는지 여부 (작성일 또는 종료일로부터 120시간 이내)
                const LIMIT_TIME : number = 5 * 24 * 60 * 60 * 1000;

                const is_unlimited : boolean = current.getTime() <= (created.getTime() + LIMIT_TIME) || current.getTime() <= (end.getTime() + LIMIT_TIME);

                // 과거 일정 편집 금지
                if( is_modify_prev == true && is_unlimited == false ) {
                    this.is_editable = false;
                }

                // 과거 일정 삭제 금지
                if( is_delete_prev == true && is_unlimited == false ) {
                    this.is_deleteable = false;
                }

            }
            
        }
        
    }

    /**
     * 일정 개인 데이터 업데이트 (컬러, 알람, 노트)
     */
    async updateEventIndividual() : Promise<void> {
        if( this.share_info == null ) { return; }
        
        const vue = this;

        let scope : string = this.share_info.share_scope;
        let scope_id : number = this.share_info.share_scope_id;

        this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}/indi`, API_METHOD.PUT, {
            note : this.event.event_data.note == null ? '' : this.event.event_data.note.trim(),
            color : this.event.event_data.color,
            alarm : this.event.event_data.alarm
        }, false).then(async(response) => {
            console.log(response);
            local_storage_info.last_event_created_color = this.event.event_data.color ?? '#477FFF';
            hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info)); 
        }).catch(async(e) => {
            this.hodu_error_process(e, true, false);
        });
    }

    /**
     * 일정 수정 모드로 변경
     */
    async modifyEvent() : Promise<void> {
        // 음력을 제외한 반복일정
        if( this.event.event_data.schedule_date.rrule != null && this.event.event_data.schedule_date.rrule.length > 0 && this.event.event_data.schedule_date.lunar_yn == false ) {
            this.doSetRepeatModifyAction("수정");
            this.doSetShowEventRepeatModify(true);
            return;
        }

        // 음력 반복 일정인 경우
        if( this.event.event_data.schedule_date.rrule != null && this.event.event_data.schedule_date.rrule.length > 0 && this.event.event_data.schedule_date.lunar_yn == true ) {
            this.event.event_data.schedule_date.start = this.event_original_date.original_start;
            this.event.event_data.schedule_date.end   = this.event_original_date.original_end;
            await this.makeDateString();
        }

        await this.doSetEventCrudType(CRUD_TYPE.UPDATE);
        await this.setDatePicker();

        // @ts-ignore alarmDivScroll 스크롤
        $('.alarmDivScroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 200,
            autoDraggerLength : false,
        });
    }

    /**
     * 일정 삭제
     */
    async deleteEvent() : Promise<void> {

        if( this.event.event_data.schedule_date.rrule != null && this.event.event_data.schedule_date.rrule.length > 0 && this.event.event_data.schedule_date.lunar_yn == false ) {
            this.doSetRepeatModifyAction("삭제");
            this.doSetShowEventRepeatModify(true);
            return;
        }

        await this.deleteEventModalCall(this.deleteEventAPICall);
    }

    /**
     * 일정 삭제 Modal 
     */
    async deleteEventModalCall(func : Function) : Promise<void> {
        
        this.hodu_show_dialog("cancel", "일정을 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            func
        ]);
    }

    /**
     * 일정 삭제 API 
     */
    async deleteEventAPICall() : Promise<void> {
        const vue = this;

        let calendar_id : string = "";
        let scope : string = "";
        let scope_id : number = 0;

        const api_promise_array : Promise<void>[] = [];

        // share_info가 없다면 빠져나가기
        if( this.share_infos == null || this.share_info == null ) { return; }

        const is_work = this.event.event_sub_type == EVENT_SUB_TYPE.WORK;
        const is_report = this.event.event_sub_type == EVENT_SUB_TYPE.REPORT 
        const is_meetinglog = this.event.event_sub_type == EVENT_SUB_TYPE.MEETINGLOG;
        
        const share_infos_count : number = this.share_infos.length;
        for( let i = 0; i < share_infos_count; i++ ) {
            const delete_share_info : any = this.share_infos[i];
            let is_share_deleteable : boolean = false;

            scope = delete_share_info.share_scope;
            scope_id = delete_share_info.share_scope_id;

            /**
             * 개인 일정 삭제
             */
            if( delete_share_info.share_scope == OWNER_TYPE.PERSONAL ) {
                is_share_deleteable = true;
                calendar_id = `personal-${delete_share_info.share_scope_id}`;
            }

            /**
             * 그룹 일정 삭제
             */
            else if( delete_share_info.share_scope == OWNER_TYPE.GROUP ) {
                is_share_deleteable = await ((is_work ? this.is_group_permmision(delete_share_info.share_scope_id, "work", "delete") : 
                                              is_report ? this.is_group_permmision(delete_share_info.share_scope_id, "report", "delete") : 
                                              is_meetinglog ? this.is_group_permmision(delete_share_info.share_scope_id, "meetinglog", "delete") : 
                                                              this.is_group_permmision(delete_share_info.share_scope_id, "event", "delete")) || this.event.event_data.event_owner_id == this.user_id);
                calendar_id = `group-${delete_share_info.share_scope_id}`;
            }

            /**
             * 팀 일정 삭제
             */
            else if( delete_share_info.share_scope == OWNER_TYPE.TEAM ) {
                is_share_deleteable = await ((is_work ? this.is_team_permmision(delete_share_info.share_scope_id, "work", "delete") :
                                              is_report ? this.is_team_permmision(delete_share_info.share_scope_id, "report", "delete") : 
                                              is_meetinglog ? this.is_team_permmision(delete_share_info.share_scope_id, "meetinglog", "delete") : 
                                                              this.is_team_permmision(delete_share_info.share_scope_id, "event", "delete")) || this.event.event_data.event_owner_id == this.user_id);
                calendar_id = `group-${delete_share_info.share_scope_id}`;
            }

            /**
             * 그 외의 경우는 없으므로 넘어간다
             */
            else { continue; }

            // 해당 스코프에 삭제권한이 없으면 넘어간다
            if( is_share_deleteable == false ) { continue; }

            api_promise_array.push(this.deleteEventApiCallPromise(calendar_id, scope, scope_id));
        }

        // 삭제 API 병렬 처리
        Promise.all(api_promise_array).then(() => { vue.movePrevPage(); });
    }
    
    /**
     * 일정 삭제 API 호출 
     */
    async deleteEventApiCallPromise(calendar_id : string, scope : string, scope_id : number) : Promise<void> {
        const url : string = `api/v1/calendars/${ calendar_id }/events/${ scope }/${ scope_id }/${ this.event.event_id }?push_yn=true`;

        await this.hodu_api_call(url, API_METHOD.DELETE)
            .then(async(response) => { console.log(response); })
            .catch(async(e) => { this.hodu_error_process(e, true, false); });
    }

    /**
     * 일정 복사 Modal
     */
    async copyEventModalCall() : Promise<void> {

        this.hodu_show_dialog("alert", "일정을 복사하시겠습니까?", ['아니오', '예'], [
            () => {},
            this.copyEvent
        ]);

    }

    /**
     * 일정 복사
     */
    async copyEvent() : Promise<void> {
        await this.downloadImageAndFileForCopy();
        await this.doSetEventCrudType(CRUD_TYPE.CREATE);
        await this.setDatePicker();

        // 날짜 및 시간 세팅
        const target_date : Date = new Date();
        const start_date  : Date = new Date(target_date.getTime());
        const end_date    : Date = new Date(target_date.getTime());

        // 시작 시간이 '정시' 거나 '23시' 일 경우는 그대로 사용한다 
        if( target_date.getMinutes() != 0 && target_date.getHours() != 23 ){
           start_date.setHours(target_date.getHours() + 1);
        }
        
        start_date.setMinutes(0);
        start_date.setSeconds(0);
        start_date.setMilliseconds(0);

        // 시작시간이 23시라면 23시 50분 고정, 아니라면 시작시간 + 1시간에 0분
        if( start_date.getHours() == 23 ){
            end_date.setHours(23);
            end_date.setMinutes(50);
        } else {
            end_date.setHours(start_date.getHours() + 1);
            end_date.setMinutes(0);
        }

        end_date.setSeconds(0);
        end_date.setMilliseconds(0);

        this.event.event_data.schedule_date.start = start_date;
        this.event.event_data.schedule_date.end   = end_date;
        if( this.event.event_data.schedule_date.rrule == null ) {
            this.event.event_data.schedule_date.recurrence_end = end_date;
        }
        this.date_term = 0;

        // 음력
        if( this.event.event_data.schedule_date.lunar_yn == true ) {
            const lunar_date : string = await this.hodu_solar_to_lunar(this.event.event_data.schedule_date.start);

            this.event.event_data.schedule_date.lunar_start = lunar_date;
            this.event.event_data.schedule_date.lunar_end   = lunar_date;
        }

        this.makeDateString();
        this.makeStringByRrule();

        // 공유 정보 초기화
        if( this.event.subscribe_users != null ) {
            this.event.subscribe_users.splice(0, this.event.subscribe_users.length); 
        }

        if( this.event.subscribe_groups != null ) {
            this.event.subscribe_groups.splice(0, this.event.subscribe_groups.length); 
        }

        if( this.event.subscribe_teams != null ) {
            this.event.subscribe_teams.splice(0, this.event.subscribe_teams.length); 
        }

        // 본인의 개인 일정이라면 그대로 리턴
        if( this.event.event_type == OWNER_TYPE.PERSONAL && this.event.event_data.event_owner_id == this.user_id ) {
            this.doSetEvent(this.event);
            return;
        }

        // 작성자 정보 사용자로 등록
        this.event.event_data.event_owner_id   = this.user_id; 
        this.event.event_data.event_owner_name = this.user_name;

        // 복사 가능 여부 체크 (해당 그룹, 팀에 가입 되었는지, 생성권한이 있는지 한번에 체크 됨)
        if( ( this.is_createable == false && this.scope != OWNER_TYPE.PERSONAL && this.is_copyable == true ) || this.is_createable == true ) {
            
            // 그룹, 팀 달력인데 해당 일정이 공유 받은 일정인 경우
            if( this.is_createable == false && this.scope != OWNER_TYPE.PERSONAL && this.is_copyable == true ) {
                if( this.scope_team_id > 0 ) {
                    this.event.event_type = OWNER_TYPE.TEAM;
                    // this.share_info.share_scope = 'TEAM';
                    // this.share_info.share_scope_id = this.scope_team_id;
                    this.event.user_id = 0;
                    this.event.group_id = 0;
                    this.event.team_id = this.scope_team_id;
                    this.event.event_data.event_owner_group_name = "";
                    this.event.event_data.event_owner_group_id = 0;
                    this.event.event_data.event_owner_team_id = this.scope_team_id;
                    this.event.event_data.event_owner_team_name = this.scope_group_team_option.group_team_name;
                } else {
                    this.event.event_type = OWNER_TYPE.GROUP;
                    // this.share_info.share_scope = 'GROUP';
                    // this.share_info.share_scope_id = this.scope_group_id;
                    this.event.user_id = 0;
                    this.event.group_id = this.scope_group_id;
                    this.event.team_id = 0;
                    this.event.event_data.event_owner_group_name = this.scope_group_team_option.group_team_name;
                    this.event.event_data.event_owner_group_id = 0;
                    this.event.event_data.event_owner_team_id = 0;
                    this.event.event_data.event_owner_team_name = "";
                }

                if( this.share_info == null || this.share_info.share_scope != OWNER_TYPE.PERSONAL || (this.is_shared == false && this.share_info.share_scope == OWNER_TYPE.PERSONAL) ) {
                    this.group_color = this.scope_group_team_option.group_team_color;
                }

                // this.share_info.share_type = 'OWNER';
                // this.share_info.share_created = new Date();
                // this.share_info.share_user_id = this.user_id;
                // this.share_info.share_scope_name = this.scope_group_team_option.group_team_name;
            }

            this.doSetEvent(this.event);
            return;
        }

        this.is_createable = true;

        // 리턴되지 않았다면 가입된 그룹이 아니므로 개인일정으로 세팅한다
        this.event.calendar_id = `personal-${this.user_id}`;
        this.event.event_type  = OWNER_TYPE.PERSONAL;
        
        this.event.user_id  = this.user_id;
        this.event.group_id = 0;
        this.event.team_id  = 0;

        this.event.event_data.event_owner_group_id   = 0;
        this.event.event_data.event_owner_group_name = "";
        this.event.event_data.event_owner_team_id    = 0;
        this.event.event_data.event_owner_team_name  = "";

        // 복사된 이후 최초값을 결과를 저장
        this.doSetEvent(this.event);
    }

    /**
     * 이 일정만 수정, 이후 일정 수정, 일정 복사를 위해서 등록된 이미지, 파일을 다운로드 받는다
     */
    async downloadImageAndFileForCopy() : Promise<void> {
        const vue = this;
        
        if( this.event.event_data.attachment == null ) {
            return;
        }

        this.hodu_show_indicator();

        /**
         * 이미지 복사
         */
        if( this.event.event_data.attachment.imgs != null ) {
            const image_count : number = this.event.event_data.attachment.imgs.length;
            for( let i = 0; i < image_count; i++ ) {
                const file : t_event_file = this.event.event_data.attachment.imgs[i];

                await this.$http({
                    url : `/app_images/${file.url}`,
                    method : 'GET',
                    responseType: 'blob'
                }).then(async(response) => {
                    console.log(response);
                    const blob : Blob = new Blob([response.data]);
                    const copy_file : File = new File([blob], file.name, { type: blob.type, lastModified : new Date().getTime()});

                    vue.image_files.push({
                        index : ( vue.image_files.length ), 
                        file : copy_file
                    });

                }).catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
            }
        }

        /**
         * 파일 복사
         */
        if( this.event.event_data.attachment.files != null ) {
            const file_count : number = this.event.event_data.attachment.files.length;
            for( let i = 0; i < file_count; i++ ) {
                const file : t_event_file = this.event.event_data.attachment.files[i];

                await this.$http({
                    url : `/app_images/${file.url}`,
                    method : 'GET',
                    responseType: 'blob'
                }).then(async(response) => {
                    console.log(response);
                    const blob : Blob = new Blob([response.data]);
                    const copy_file : File = new File([blob], file.name, { type: blob.type, lastModified : new Date().getTime()});

                    vue.all_files.push({
                        index : ( vue.all_files.length ), 
                        file : copy_file
                    });

                }).catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
            }
        }
        
        this.hodu_hide_indicator();

    }
    
    /**
     * 업무 템플릿창 ON / OFF
     */
    workTemplateOnOff() : void {
        this.show_work_template = !this.show_work_template;
        this.alarm_list_open    = false;
        this.time_from_open     = false;
        this.time_to_open       = false;
    }
    
    /**
     * 업무 템플릿 타입 변경
     */
    changeWorkType(type : string) : void {
        this.view_template_type = type;
    }

    /**
     * 업무 템플릿 생성 페이지로 이동
     */
    workTemplateAdd() : void {
        this.doSetEvent(this.event);
        this.doSetWorkTemplateId("");
        this.hodu_router_push(`/work/${ new Date().getTime() }/setting/template`);
    }

    /**
     * 기본 업무 선택
     */
    // selectDefaultWork() : void {
        // if( this.event.event_data.work == null ) {
        //     return;
        // }

        // this.event.event_data.work.template_id = "";
        // this.event.event_data.work.template_type = "DEFAULT";
        // this.selected_work_template = null;
        // this.show_work_template = false;
    // }

    /**
     * 업무 템플릿 선택
     */
    selectWorkTemplate(template : t_work_template) : void {
        if( this.event.event_data.work == null ) {
            return;
        }

        this.event.event_data.work.template_id = template.template_id;
        this.event.event_data.work.template_type = template.template_type;
        this.selected_work_template = template;
        this.show_work_template = false;          
    }

    /**
     * 업무 템플릿 조회
     */
    async getWorkTepmplate() : Promise<void> {
        const vue = this;

        let target_calendar_id = (this.event.is_temp != true ? this.calendar_id : ((this.event.scope_team_id ?? 0) > 0 ? `team-${this.event.scope_team_id}` : `group-${this.event.scope_group_id}`));
        let target_scope = (this.event.is_temp != true ? this.scope : ((this.event.scope_team_id ?? 0) > 0 ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP));
        
        await this.hodu_api_call(`api/v1/calendars/${ target_calendar_id }/works/${ target_scope }/${ (this.event.is_temp == true ? (this.event.scope_team_id ?? 0) : this.scope_team_id) > 0 ? 
                                                                                                  (this.event.is_temp == true ? this.event.scope_team_id : this.scope_team_id) : 
                                                                                                  (this.event.is_temp == true ? this.event.scope_group_id : this.scope_group_id) }/template`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                vue.work_template_list.splice(0, vue.work_template_list.length);
                vue.flow_template_list.splice(0, vue.flow_template_list.length);
                vue.check_template_list.splice(0, vue.check_template_list.length);

                vue.work_template_list = vue.work_template_list.concat(response.data.data.template_list);

                // 업무 템플릿이 없다면 무조건 생성해야함
                if( vue.work_template_list.length < 1 ) {
                    this.hodu_show_dialog("alert", "프로젝트를 생성하려면 프로젝트 템플릿이 필요합니다\n프로젝트 템플릿을 생성 하시겠습니까?", ["아니오", "예"], [
                        () => { this.movePrevPage(); },
                        () => { this.workTemplateAdd(); }
                    ]);
                    return;
                }

                // 마지막에 선택한 업무
                if( response.data.data.last_select_template != null && this.event.event_data.work != null ) { 
                    this.selected_work_template = response.data.data.last_select_template;

                    if( this.selected_work_template != null ) {
                        this.event.event_data.work.template_id = this.selected_work_template.template_id;
                        this.event.event_data.work.template_type = this.selected_work_template.template_type;
                    }
                }
                
                // 마지막에 선택한 업무가 없다면 첫번째
                else {
                    this.selectWorkTemplate(vue.work_template_list[0]);
                }

                let selected_work_index : number = -1;
                const work_template_list_count : number = vue.work_template_list.length;
                for( let i = 0; i < work_template_list_count; i++ ) {
                    if( vue.work_template_list[i].template_type == 'FLOW' ) { vue.flow_template_list.push(vue.work_template_list[i]); } 
                    else { vue.check_template_list.push(vue.work_template_list[i]); }
                }


            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 업무 배정 타입 선택
     */
    workAssignmentChange(event) : void {
        const vue = this;

        if( this.event.event_data.work == null ) {
            return;
        }

        // 업무자 배정이라면 선택하는 창을 띄우고 선택한 결과를 체크 하도록 함 
        if( event.target.value == 'ASSIGN' ) {
            event.target.value = this.event.event_data.work.assignment_type;
            this.workAssign();
            return;
        }

        // 모두라면 바로 적용후 정보를 가져온다
        this.event.event_data.work.assignment_type = event.target.value;
        vue.assignment_user_list.splice(0, vue.assignment_user_list.length);
        vue.getAllAssignmentUser();
    }

    /**
     * 업무 배정 모두 선택 했을때 대상 유저 구하는 API
     */
    async getAllAssignmentUser() : Promise<void> {
        const vue = this; 

        let target_scope = (this.event.is_temp != true ? this.scope : ((this.event.scope_team_id ?? 0) > 0 ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP));

        await this.hodu_api_call(`api/v1/${ target_scope == OWNER_TYPE.GROUP ? 'groups' : 'teams' }/${ target_scope == OWNER_TYPE.GROUP ? 'getGroupMember' : 'getTeamMember' }/${ 
            (this.event.is_temp == true ? this.event.scope_group_id : this.scope_group_id) 
        }${ (this.event.is_temp == true ? (this.event.scope_team_id ?? 0) : this.scope_team_id) > 0 ? `/${ (this.event.is_temp == true ? this.event.scope_team_id : this.scope_team_id) }` : ''
        }`, API_METHOD.GET)
                .then((response) => {
                    console.log(response);
                    const user_list : any[] = response.data.data.list;
                    for( let user of user_list ) {
                        vue.assignment_user_list.push(user.user_id);
                    }
                    vue.all_member_count = user_list.length;
                })
                .catch((e) => {
                    this.hodu_error_process(e, false, false);
                });
    }

    /**
     * 업무 배정 Modal
     */
    workAssign() : void {
        this.doSetEventWorkAssignmentInfo({
            show_event_work_assignment : true,
            selected_user_ids          : this.assignment_user_list,
            confirm_function           : (user_ids : number[], assignment_type : string, user_list_length : number) : void => {

                // 배정받은 유저 등록
                this.assignment_user_list.splice(0, this.assignment_user_list.length);
                const user_ids_count : number = user_ids.length;
                for( let i = 0; i < user_ids_count; i++ ) {
                    this.assignment_user_list.push(user_ids[i]);
                }

                // work 정보 배정(전부 선택하지 않았다면), 모두(전부 선택했다면)로 변경
                if( this.event.event_data.work == null ) {
                    return;
                }
                this.event.event_data.work.assignment_type = assignment_type;
                this.all_member_count = user_list_length;  

                // 모달 닫기
                this.doSetEventWorkAssignmentInfo({
                    show_event_work_assignment : false,
                    selected_user_ids          : [],
                    confirm_function           : () => {}
                })
            }
        });
    }

    /**
     * 사용자가 업무를 시작 했는지 여부 확인
     */
    isWorkStarted() : boolean {
        // 배정받지 않았다면 false
        if( this.user_work_status == null ) {
            return false;
        }

        // 하나라도 진행된게 있는지 확인
        for( let progress of this.user_work_status.work_status_info.progress ) {
            // 하나라도 WAIT가 아니라면 시작중인 업무
            if( progress.status != 'WAIT' ) {
                return true;
            }   
        }

        return false;
    }

    /**
     * 업무 상태에 따른 string 반환
     */
    getWorkStatusMessage() : string {
        if( this.event.event_data.work == null ) {
            return '';
        }

        // 업무 상태에 따라 string 반환
        switch( this.event.event_data.work.work_status_code ) {
            case "WAIT":
                return '대기 중인 프로젝트입니다';

            case "START":
                return '진행 중인 프로젝트입니다';

            case "CANCEL":
                return '중단된 프로젝트입니다';

            case "END":
                return '완료된 프로젝트입니다';
        }

        return '';
    }

    /**
     * 사용자의 업무 상태에 따라서 반환!
     */
    getWorkUserStatusMessage() : string {
        if( this.event.event_data.work == null ) {
            return '';
        }

        // 본인이 배정받지 않은 미정업무인 경우
        if( this.event.event_data.work.assignment_type == 'UNDEF' && this.user_work_status == null ) {
            
            // 기본, 단계식 미정 업무
            if( this.event.event_data.work.template_type != 'CHECK' ) {
                
                // 이미 배정받은 사람이 있는 경우
                if( this.work_status_list.length > 0 ) {
                    return '업무 권한이 없습니다';
                } 
                
                // 배정 받은 사람이 없는 경우
                else {
                    return '시작 전인 선착순 업무입니다';
                }
            }
            
            // 체크식 업무
            else {
                if( this.selected_work_template == null ) {
                    return '';
                }

                let check_flag : boolean = true;

                // 템플릿 단계 / 체크 수만큼 false 추가
                const check_flag_array : boolean[] = [];
                for( let content of this.selected_work_template.template_info.content ) {
                    check_flag_array.push(false);
                }
                
                // 사용자가 어떤 체크를 선택했는지 살핌
                const status_length : number = this.work_status_list.length;
                for( const work_status of this.work_status_list ) {

                    // 사용자들이 어디에 체크했는지 검사한다
                    const user_status_count : number = work_status.work_status_info.progress.length;
                    for( let i = 0; i < user_status_count; i++ ) {
                        // WAIT가 아니면 해당 체크를 선택한것이다
                        if( work_status.work_status_info.progress[i].status != 'WAIT' ) {
                            check_flag_array[i] = true;
                        }
                    }
                }

                // 전부 체크 되었다면 && 연산자에 의해서 true로 나온다
                for( let flag of check_flag_array ) {
                    check_flag = check_flag && flag;
                }

                return check_flag ? '업무 권한이 없습니다' : '시작 할 수 있는 선착순 업무입니다';
            }
        }

        // 배정받지 않았는데 미정업무가 아닌 경우
        else if( this.event.event_data.work.assignment_type != 'UNDEF' && this.user_work_status == null ) {
            return '업무 권한이 없습니다';
        }

        // 그 외 사용자의 업무 정보가 있다면
        else if( this.user_work_status != null ) {
            // is_end가 true라면 완료한 업무
            if( this.user_work_status.work_status_info.is_end == true ) {
                return '완료 한 업무입니다';
            }

            else {
                let all_wait_flag : boolean = true;
                for( let progress of this.user_work_status.work_status_info.progress ) {
                    if( progress.status != 'WAIT' ) {
                        all_wait_flag = false;
                        break;
                    }
                }
                
                // 전부 대기중이라면 시작 전, 하나라도 진행됐다면 업무를 진행 하는 중
                return all_wait_flag ? '프로젝트를 시작 하기 전입니다' : '프로젝트를 진행 하는 중입니다';
            }
        }

        return '';
    }

    /**
     * 업무 상태에 따른 색상 반환
     */
    getWorkStatusColor(isText : boolean = false) : string {
        if( this.event.event_data.work == null ) {
            return '#e1e4e6';
        }

        // 업무 상태에 따라 색상 반환
        switch( this.event.event_data.work.work_status_code ) {
            case "WAIT":
                return isText ? "#b1b4b6" : '#e1e4e6';

            case "START":
                return '#ffcd00';

            case "CANCEL":
                return '#ff6369';

            case "END":
                return '#477fff';
        }

        return '#e1e4e6';
    }

    /**
     * 해당 업무 단계 / 체크를 수행한 경우 (업무 상태가 WAIT가 아닌경우)
     */
    getWorkOn(index : number) : boolean {
        if( this.user_work_status == null ) {
            return false;
        }

        // 해당 아이템이 WAIT가 아닌 경우 해당 단계 / 체크를 수행한 것
        if( this.user_work_status.work_status_info.progress[index].status != 'WAIT' ) {
            return true;
        }

        return false;
    }

    /**
     * 단계식 업무일때 현재 체크가 마지막 진행점일때 true 반환
     */
    getStepNow(index : number) : boolean {

        if( this.user_work_status == null ) {
            return false;
        }

        // 해당 아이템이 START인 경우 || 해당 아이템이 마지막 아이템인데 END인 경우
        if( this.user_work_status.work_status_info.progress[index].status == 'START' ||
            (this.user_work_status.work_status_info.progress.length == index + 1 && this.user_work_status.work_status_info.progress[index].status == 'END') ) {
            return true;
        }

        return false;
    }

    /**
     * 업무 상태 정보 받아서 등록
     */
    async setEventWorkData(work : any, work_status_list : t_work_status[]) : Promise<void> {
        if( this.event.event_data.work == null ) {
            return;
        }

        this.event.event_data.work.work_status_code    = work.work_status_code;
        this.event.event_data.work.work_status_visible = work.work_status_visible;
        this.work_status_list.splice(0, this.work_status_list.length);
        this.work_status_list = this.work_status_list.concat(work_status_list ? work_status_list : []);

        this.user_work_status = null;
        for( let status of this.work_status_list) {
            if( status.user_id == this.user_id ) {
                this.user_work_status = status;
            }
        }

    }

    /**
     * 업무 시작 버튼 클릭
     */
    workStart() : void {

        // 기본업무
        if( this.event.event_data.work != null && this.event.event_data.work.template_type == 'DEFAULT' ) {
            this.defaultWorkStart();
        }

        // 단계, 체크식 업무
        else if ( this.event.event_data.work != null && this.event.event_data.work.template_type != 'DEFAULT' ) {
            this.workModalOpen();
        }
    }

    /**
     * 기본업무 시작
     */
    defaultWorkStart() : void {

        if( this.event.event_data.work == null ) {
            return;
        }

        // 미정이고 다른사람이 이미 시작했다면 시작 불가능
        if( this.event.event_data.work.assignment_type == 'UNDEF' && this.event.event_data.work.template_type == 'DEFAULT' && 
            this.user_work_status == null && this.work_status_list.length > 0 ) {
            return;
        }

        // 배정이고 배정되지 않았다면 시작 불가능
        if( this.event.event_data.work.assignment_type != 'UNDEF' && this.user_work_status == null ) {
            return;
        }

        this.user_work_status = {
            event_id : this.event.event_id ? this.event.event_id : '',
            user_id : this.user_id,
            scope : this.event.event_type ? this.event.event_type : '',
            scope_id : this.event.event_type == OWNER_TYPE.GROUP ? (this.event.group_id ? this.event.group_id : 0) : (this.event.team_id ? this.event.team_id : 0),
            last_update_tag : '0',
            audit_modified : new Date(),
            audit_delete_flag : false,
            audit_user_id : this.user_id,
            work_status_info : {
                is_end : false,
                progress : [{ seq : 0, status : 'START' }]
            }
        };

        this.insertOrUpdateWorkStatus();
    }

    /**
     * 단계식, 체크식 업무 Modal 열기
     */
    workModalOpen() : void {

        if( this.event.event_data.work == null || this.selected_work_template == null ) {
            return;
        }

        // 미정이고 사용자는 업무 상태 데이터가 없을때
        if( this.event.event_data.work.assignment_type == 'UNDEF' && this.user_work_status == null ) {
            
            // 단계식 미정 업무는 다른 누군가 시작했다면 시작할수 없다
            if( this.event.event_data.work.template_type == 'FLOW' && this.work_status_list.length > 0 ) {
                return;
            }

            // 체크식 미정 업무는 체크별 1명이기때문에 남아있는 체크가 없다면 시작할수 없다
            if( this.event.event_data.work.template_type == 'CHECK' ) {

                let check_flag : boolean = true;

                // 템플릿 단계 / 체크 수만큼 false 추가
                const check_flag_array : boolean[] = [];
                for( let content of this.selected_work_template.template_info.content ) {
                    check_flag_array.push(false);
                } 

                // 사용자가 어떤 체크를 선택했는지 살핌
                const status_length : number = this.work_status_list.length;
                for( const work_status of this.work_status_list ) {

                    // 사용자들이 어디에 체크했는지 검사한다
                    const user_status_count : number = work_status.work_status_info.progress.length;
                    for( let i = 0; i < user_status_count; i++ ) {
                        // WAIT가 아니면 해당 체크를 선택한것이다
                        if( work_status.work_status_info.progress[i].status != 'WAIT' ) {
                            check_flag_array[i] = true;
                        }
                    }
                }

                // 전부 체크 되었다면 && 연산자에 의해서 true로 나온다
                for( let flag of check_flag_array ) {
                    check_flag = check_flag && flag;
                }
                
                // 남은 체크가 없는 경우
                if( check_flag ) {
                    return;
                }

            }
            
        }

        // 배정이고 배정되지 않았다면 시작 불가능
        if( this.event.event_data.work.assignment_type != 'UNDEF' && this.user_work_status == null ) {
            return;
        }

        this.doSetWorkStatusInfo({
            calendar_id : this.event.calendar_id,
            event_id : this.event.event_id,
            scope : this.event.event_type,
            scope_id : this.event.event_type == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id
        });
        this.doSetShowEventWorkProcess(true);
    }

    /**
     * 업무 취소 (미정 업무였다면 status 삭제, 아니라면 insertOrUpdate)
     */
    workCancel() : void {
        if( this.event.event_data.work == null || this.user_work_status == null ) {
            return;
        }

        this.hodu_show_dialog('cancel', '진행하신 프로젝트를 취소하시겠습니까?', ['아니오', '예'], [
            () => {},
            () => {
                if( this.event.event_data.work == null || this.user_work_status == null ) {
                    return;
                }

                // 미정업무 (업무 삭제)
                if( this.event.event_data.work.assignment_type == 'UNDEF' ) {
                    this.deleteWorkUserStatus();
                } 

                // 배정업무 (진행 단계 초기화)
                else {

                    // 초기화
                    this.user_work_status.work_status_info.is_end = false;
                    const user_status_count : number = this.user_work_status.work_status_info.progress.length;
                    for( let i = 0; i < user_status_count; i++ ) {
                        const progress_fragment : any = JSON.parse(JSON.stringify(this.user_work_status.work_status_info.progress[i]));
                        progress_fragment.status = 'WAIT';
                        this.user_work_status.work_status_info.progress.splice(i, 1, progress_fragment);
                    }

                    // user_status를 work_status_list 에도 등록
                    const work_status_list_count : number = this.work_status_list.length;
                    for( let i = 0; i < work_status_list_count; i++ ) {
                        if( this.work_status_list[i].user_id == this.user_id ) {
                            this.work_status_list.splice(i, 1, this.user_work_status);
                        }
                    }

                    // 업무 전체 초기화 후 insertOrUpdate
                    this.insertOrUpdateWorkStatus();
                }
            }
        ]);
    }

    /**
     * 업무 완료 (기본, 단계식이면 전부 END로 채우고 완료API 실행, 체크라면 그대로 API를 실행한다)
     */
    workComplete() : void {
        if( this.event.event_data.work == null || this.user_work_status == null ) {
            return;
        }

        this.hodu_show_dialog("alert", "프로젝트를 완료하시겠습니까?", ["아니오", "예"], [
            () => {},
            () => {
                if( this.event.event_data.work == null || this.user_work_status == null ) {
                    return;
                }

                // 업무 완료 플래그 설정
                this.user_work_status.work_status_info.is_end = true;

                // 체크식이 아닌 경우 모든 단계를 END로 설정
                if( this.event.event_data.work.template_type != 'CHECK' ) {
                    const progress_count : number = this.user_work_status.work_status_info.progress.length;
                    for( let i = 0; i < progress_count; i++ ) {
                        this.user_work_status.work_status_info.progress[i].status = 'END';
                    }
                }

                this.insertOrUpdateWorkStatus();
            }
        ]);
    }

    /**
     * 사용자 업무 상태추가 또는 수정
     */
    async insertOrUpdateWorkStatus() : Promise<void> {
        const vue = this;

        if( this.user_work_status == null ) {
            return;
        }

        this.user_work_status.scope = (this.event.event_type ? this.event.event_type : "");
        this.user_work_status.scope_id = this.event.event_type == OWNER_TYPE.GROUP ? (this.event.group_id ? this.event.group_id : 0) : (this.event.team_id ? this.event.team_id : 0);
        this.user_work_status.event_id = (this.event.event_id ? this.event.event_id : "");

        let url : string = `api/v1/calendars/${this.event.calendar_id}/works/${this.user_work_status.scope}/${this.user_work_status.scope_id}/${this.user_work_status.event_id}/status/user?select_seq=-1`;
        await this.hodu_api_call(url, API_METHOD.PUT, this.user_work_status)
            .then(async(response) => {
                console.log(response);
                await vue.getEvent();
            })
            .catch(async(e) => {
                if( e.response && e.response.status == 406 ) {
                    await vue.getEvent();
                } 
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 사용자 업무 상태 삭제
     */
    async deleteWorkUserStatus() : Promise<void> {
        const vue = this;

        let url : string = `api/v1/calendars/${this.event.calendar_id}/works/${this.event.event_type}/${this.event.event_type == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id}/${this.event.event_id}/status/user`;
        await this.hodu_api_call(url, API_METHOD.DELETE)
            .then(async(response) => {
                console.log(response);
                await vue.getEvent();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 업무 현황 Modal 열기
     */
    workStatusModalOpen() : void {
        if( this.event.event_data.work == null) {
            return;
        }

        // 미정업무인데 아무도 시작 안한건 진입불가
        if( this.event.event_data.work.assignment_type == 'UNDEF' && this.work_status_list.length < 1 ) {
            return;
        } 

        // 기본업무 현황 MODAL
        else if( this.event.event_data.work.template_type == 'DEFAULT' ) {
            this.doSetWorkStatusInfo({
                calendar_id : this.event.calendar_id,
                event_id : this.event.event_id,
                scope : this.event.event_type,
                scope_id : this.event.event_type == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id
            });
            this.doSetShowWorkDefaultStatus(true);
        }

        // 단계, 체크식 업무 현황 MODAL
        else {
            this.doSetWorkStatusInfo({
                calendar_id : this.event.calendar_id,
                event_id : this.event.event_id,
                scope : this.event.event_type,
                scope_id : this.event.event_type == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id
            });
            this.doSetShowWorkStatus(true);
        }
        
    }

    /**
     * 업무 상태 변경
     */
    updateWorkEventStatus(js_event, status : string) {
        let scope : string = (this.event.event_type ? this.event.event_type : "");
        let scope_id : number = this.event.event_type == OWNER_TYPE.GROUP ? (this.event.group_id ? this.event.group_id : 0) : (this.event.team_id ? this.event.team_id : 0);

        this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/works/${scope}/${scope_id}/${this.event.event_id}/status`, API_METHOD.PUT, { work_status_type : status })
            .then((response) => {
                console.log(response);
                this.getEvent();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 업무 시작버튼 보여야 하는가에 대한 boolean 값 반환
     */
    getWorkStartButtonVisibility() : boolean {
        if( this.event.event_data.work == null ) {
            return false;
        }

        // 중단 또는 완료된 업무는 안보인다
        if( this.event.event_data.work.work_status_code == 'CANCEL' || this.event.event_data.work.work_status_code == 'END' ) {
            return false;
        }

        let visibility_flag : boolean = false;

        // 미정일때 (기본, 단계는 누군가 수행 중이라면 안 보임, 체크는 남은 체크가 하나도 없다면 안 보임)
        if( this.event.event_data.work.assignment_type == 'UNDEF' ) {
            
            // 체크식 업무
            if( this.event.event_data.work.template_type == 'CHECK' ) {
                if( this.selected_work_template == null ) {
                    return false;
                }

                // 템플릿 단계 / 체크 수만큼 false 추가
                const check_flag_array : boolean[] = [];
                for( let content of this.selected_work_template.template_info.content ) {
                    check_flag_array.push(false);
                } 

                // 사용자가 어떤 체크를 선택했는지 살핌
                const status_length : number = this.work_status_list.length;
                for( const work_status of this.work_status_list ) {

                    // 사용자들이 어디에 체크했는지 검사한다
                    const user_status_count : number = work_status.work_status_info.progress.length;
                    for( let i = 0; i < user_status_count; i++ ) {
                        // WAIT가 아니면 해당 체크를 선택한것이다
                        if( work_status.work_status_info.progress[i].status != 'WAIT' ) {
                            check_flag_array[i] = true;
                        }
                    }
                }

                // 전부 체크 되었다면 && 연산자에 의해서 true로 나온다
                let check_flag = true;
                for( let flag of check_flag_array ) {
                    check_flag = check_flag && flag;
                }
                
                visibility_flag = !check_flag;
            } 
            
            // 기본, 단계식 업무
            else {
                visibility_flag = this.work_status_list.length < 1;
            }

        }

        // 배정일때 (배정 안되어있으면 안보임)
        else {
            visibility_flag = (this.user_work_status != null);
        }

        return visibility_flag;
    }

    /**
     * 업무 취소버튼 보여야 하는가에 대한 boolean 값 반환
     */
    getWorkCancelButtonVisibility() : boolean {
        if( this.event.event_data.work == null || this.user_work_status == null ) {
            return false;
        }

        // 중단 또는 완료된 업무는 안보인다
        if( this.event.event_data.work.work_status_code == 'CANCEL' || this.event.event_data.work.work_status_code == 'END' ) {
            return false;
        }

        // 사용자 업무 상태 체크
        let user_work_start_flag : boolean = false;
        for( let progress of this.user_work_status.work_status_info.progress ) {
            if( progress.status != 'WAIT' ) {
                user_work_start_flag = true;
                break;
            }
        }

        // 업무가 WAIT가 아니고 사용자가 업무 중일때 떠야한다
        return this.event.event_data.work.work_status_code != 'WAIT' && user_work_start_flag;
    }

    /**
     * 업무현황 버튼이 보여야하는가에 대한 boolean 값 반환
     */
    getWorkStatusButtonVisibility() : boolean {
        if( this.event.event_data.work == null ) {
            return false;
        }

        // 미정인데 업무 수행하는사람이 없는 경우 (return false)
        if( this.event.event_data.work.assignment_type == 'UNDEF' && this.work_status_list.length < 1 ) {
            return false;
        }
        
        // 업무 작성자이거나 업무 현황보이기인 경우 true 둘중 하나라도 만족 못하면 false
        return this.event.event_data.event_owner_id == this.user_id || (this.event.event_data.work != null && this.event.event_data.work.work_status_visible == true);
    }

    /**
     * 업무완료 버튼이 보여야하는가에 대한 boolean 값 반환
     */
    getWorkCompleteButtonVisibility() : boolean {
        if( this.event.event_data.work == null || this.user_work_status == null ) {
            return false;
        }

        // 중단 또는 완료된 업무는 안보인다
        if( this.event.event_data.work.work_status_code == 'CANCEL' || this.event.event_data.work.work_status_code == 'END' ) {
            return false;
        }

        // 사용자 업무 상태 체크
        let user_work_start_flag : boolean = false;
        for( let progress of this.user_work_status.work_status_info.progress ) {
            if( progress.status != 'WAIT' ) {
                user_work_start_flag = true;
                break;
            }
        }

        // 업무가 WAIT가 아니고 사용자가 업무 중이고 종료된 업무가 아닐때 떠야한다
        return this.event.event_data.work.work_status_code != 'WAIT' && user_work_start_flag && this.user_work_status.work_status_info.is_end == false;
    }
    
    /**
     * 화상 회의 시작
     */
    videoConferencing() : void {

        let query = `event_id=${encodeURIComponent(this.event.event_id ?? "")}`;
        query += `&title=${encodeURIComponent(this.event.event_data.title)}`;
        query += `&user_id=${encodeURIComponent(this.user_id)}`;
        query += `&user_name=${encodeURIComponent(this.user_name)}`;

        window.open(`/video-conferencing.html?${query}`, "jitsi", "toolbar=1, scrollbars=0, resizable=0, width=" + 1024 + ", height=" + 768);
    }

    /**
     * 화상회의 가능 여부
     * -- 1. 공유 되어 있을것 (그룹, 팀 일정이거나 공유된 일정) !!예전 조건임!!
     * 1. 프리미엄 그룹의 일정일것
     * 2. 시간 조건에 맞을것 (시작 30분 전 부터 종료 30분 후 까지 입장 가능)
     */
    isVideoConference() : boolean {
        
        // let share : boolean = false;
        // if( this.event.event_type == OWNER_TYPE.GROUP ) share = true;
        // if( this.event.event_type == OWNER_TYPE.TEAM ) share = true;
        // if( (this.event?.subscribe_users?.length ?? 0) > 0 ) share = true;
        // if( (this.event?.subscribe_groups?.length ?? 0) > 0 ) share = true;
        // if( (this.event?.subscribe_teams?.length ?? 0) > 0 ) share = true;

        // alert(share);

        let time : boolean = false;

        let start = new Date(this.event.event_data.schedule_date.start);
        let end = new Date(this.event.event_data.schedule_date.end);
        let limit = 30 * 60 * 1000; // 시작 30분 전, 종료 30분 이후까지만 열려있음

        if( (start.getTime() - limit) <= new Date().getTime() && new Date().getTime() <= (end.getTime() + limit) ) time = true;
        
        // alert(time);

        return this.is_premium_event && time;
    }

    /**
     * 상단 타이틀
     */
    getViewTitle() :string {
        
        // 생성
        if( this.isCreate((this.event_crud_type as CRUD_TYPE)) == true ) {
            if( this.isSchedule((  this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) {
                return this.isPersonalScope((this.event.event_type as OWNER_TYPE) ?? OWNER_TYPE.PERSONAL) ? "일정 등록" : 
                       this.isGroupScope((this.event.event_type as OWNER_TYPE) ?? OWNER_TYPE.PERSONAL) ? `[${this.event.event_data.event_owner_group_name}] 일정 등록` 
                                                                                                       : `[${this.event.event_data.event_owner_team_name}] 일정 등록`;
            }
            if( this.isCard((      this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "카드 등록";
            if( this.isWork((      this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "프로젝트 등록";
            if( this.isReport((    this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "업무일지 등록";
            if( this.isMeetingLog((this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "회의록 등록";
        }
        
        // 조회
        if( this.isRead((this.event_crud_type as CRUD_TYPE)) == true ) {
            if( this.isSchedule((  this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "일정";
            if( this.isCard((      this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "카드";
            if( this.isWork((      this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "프로젝트";
            if( this.isReport((    this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "업무일지";
            if( this.isMeetingLog((this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "회의록";
        }

        // 수정
        if( this.isUpdate((this.event_crud_type as CRUD_TYPE)) == true ) {
            if( this.isSchedule((  this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "일정 수정";
            if( this.isCard((      this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "카드 수정";
            if( this.isWork((      this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "프로젝트 수정";
            if( this.isReport((    this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "업무일지 수정";
            if( this.isMeetingLog((this.event.event_sub_type as EVENT_SUB_TYPE)) == true ) return "회의록 수정";
        }
                
        return "";
    }

    /**
     * 메모의 타이틀을 이벤트 서브타입에 따라 반환
     */
    getMemoTitle(event_sub_type : EVENT_SUB_TYPE | string | undefined) : string {

        if( event_sub_type == EVENT_SUB_TYPE.REPORT ) {
            return "업무내용";
        }

        if( event_sub_type == EVENT_SUB_TYPE.MEETINGLOG ) {
            return "회의내용";
        }

        return "메모(공개)";
    }

    /**
     * 메모의 placeholder를 이벤트 서브타입에 따라 반환
     */
    getMemoPlaceholder(event_sub_type : EVENT_SUB_TYPE | string | undefined) : string {

        if( event_sub_type == EVENT_SUB_TYPE.REPORT ) {
            return "업무내용";
        }

        if( event_sub_type == EVENT_SUB_TYPE.MEETINGLOG ) {
            return "회의내용";
        }

        return "공개 (모두에게 보이는 메모)";
    }

    /**
     * 본인이 해당 일정에 대해서 관리지인지 여부 반환
     */
    isManager() : boolean {

        if( this.event == null ) return false;

        if( this.event.event_data.event_owner_id == this.user_id ) {
            return true;
        }

        if( this.event.event_type == OWNER_TYPE.GROUP ) {
            return this.get_group_role(this.event.group_id ? this.event.group_id : 0) == this.GroupAuth.GROUP_MANAGER ||
                   this.get_group_role(this.event.group_id ? this.event.group_id : 0) == this.GroupAuth.ADMIN;
        }

        if( this.event.event_type == OWNER_TYPE.TEAM ) {
            return this.get_team_role(this.event.team_id ? this.event.team_id : 0) == this.GroupAuth.TEAM_MANAGER ||
                   this.get_team_role(this.event.team_id ? this.event.team_id : 0) == this.GroupAuth.ADMIN;
        }
        
        return false;
    }

    /**
     * 문서화로 이동
     */
    workDocumentation() : void {

        if( this.isReport(this.event.event_sub_type as EVENT_SUB_TYPE) == false && this.isMeetingLog(this.event.event_sub_type as EVENT_SUB_TYPE) == false ) {
            this.hodu_show_dialog('cancel', "문서화 기능은 업무일지, 회의록에서만 가능합니다", ['확인']);
            return;
        }

        // popup open
        let query = "";
        query += `calendar_id=${this.event.calendar_id}`;
        query += `event_id=${this.event.event_id}`;
        query += `&event_sub_type=${this.event.event_sub_type}`;
        query += `&document_type=${ this.isReport(this.event.event_sub_type as EVENT_SUB_TYPE) ? 'daily_report_1' : 'meeting_log_1' }`;
        query += `&start=${moment(this.event.event_data.schedule_date.start).utc().format()}`;
        query += `&end=${moment(this.event.event_data.schedule_date.end).utc().format()}`;
        
        query += `&title=${encodeURIComponent(this.event.event_data.title)}`;
        // if( this.event.event_data.memo ) query += `&content=${encodeURIComponent(this.event.event_data.memo)}`;
        if( this.event.event_data.event_owner_name ) query += `&writer=${encodeURIComponent(this.event.event_data.event_owner_name)}`; 

        query += `&request_user_id=${this.user_id}`;
        query += `&scope=${this.event.event_type}`;
        query += `&scope_id=${this.event.event_type == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id}`;
        
        const timezone = -(new Date().getTimezoneOffset());
        query += `&timezone=${timezone ? timezone : 540}`;

        try {
            let type = navigator.appName;
            let country_code = 'ko-KR';
            if (type == 'Netscape') {
                country_code = navigator.language;
            } else {
                country_code = navigator['userLanguage'];
            }
            query += `&country_code=${country_code}`;
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            query += `&country_code=ko-KR`;
        }

        window.open(`/work_pdf.html?${query}`, "work_pdf", "toolbar=0, scrollbars=0, resizable=0, width=" + 1024 + ", height=" + 768);
    }

    /**
     * 반복 종료일 있음 첫 클릭시
     */
    setRepeatDefault() : void {
        if( this.event == null ) return;
        this.repeat_infinite = false;
    }

    /**
     * 반복 종료일 없음
     */
    setRepeatInfinite() : void {
        if( this.event == null ) return;
        this.repeat_infinite = true;
    }

    /**
     * 일정 히스토리
     */
    showEventHistoryModal() : void {
        this.doSetEventHistoryModalInfo?.({
            show_modal : true,
            event_id : this.event.event_id ?? "",
        });
    }

    inputCardContent(event) : void {

        const value = event.target.value;
        
        if( this.event.event_data.card != null ) {
            this.event.event_data.card.card_data.card_content = value;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('.alarmDivScroll').mCustomScrollbar('destroy');

        // 업무에서는 그룹TR 사용 안함
        if( this.event.event_sub_type != EVENT_SUB_TYPE.WORK ) {
            // @ts-ignore
            $('#schedule_group_div').mCustomScrollbar('destroy');
        }

        this.setScroll();
    }

}
</script>

<style scoped>
    .section_ce_fix .title_box { line-height:60px; height:60px; }
    ul#s_group {display:none; width: 100%; background: #fff; top: 61px;left: 0; border-radius: 0;margin-bottom: 0; border-top: 1px solid #f1f3f5; border-left: 1px solid #f1f3f5; height: auto; box-shadow: 0 10px 10px rgba(0,0,0,0.1) !important;}
	ul#s_group.on { display: block; }
    ul#s_group > li {width: 100%; box-sizing: border-box; height: auto; padding-left: 0;}
    ul#s_group > li a {width: 100%; box-sizing: border-box;color: #7c88a2;font-weight:bold; font-size:13px;line-height:50px; height:50px;}
    ul#s_group > li a:hover, ul#s_group > li a.on { color:#202A39 }
	ul#s_group li > a.team {padding-left: 25px;font-size:12px;}
    #grupTr h2 .cl, ul#s_group > li a .cl {display: inline-block; width: 8px; height: 8px; border-radius: 50%; float: left; margin-top: 26px; font-size: 0; margin-right: 15px;}
	ul#s_group > li a .cl { margin-top:21px; }
    #grupTr h2 {width: 100%; font-size: 14px !important; cursor:pointer; height:60px; line-height:60px;}
    .forView #grupTr h2 { cursor: default }
    #grupTr h2.on { color:#477fff; }

    #schedule_group_div { position:absolute; top:61px; left:0; z-index:100000000; overflow: hidden;max-height: 301px;height: auto !important;background:#fff;box-shadow: 0 10px 10px rgba(0,0,0,0.1) !important;overflow:hidden!important;}
    #schedule_group_div.on { width : 100% }

    ul#s_group {  position:static !important; display:none; left:0 !important; top:0 !important; }

    /* 일정 또는 업무 제목 */
    .forView #schDetailScroll #s_mainTitle:disabled {color: #273044;}
    .forView #schDetailScroll #shareMemo:disabled {color: #273044;}
    .forView #schDetailScroll #meeting_location:disabled {color: #273044;}
    .forView #schDetailScroll #attendees:disabled {color: #273044;}
    .forView #schDetailScroll #remark:disabled {color: #273044;}

    .alarmDivScroll {transition:0.1s;display:none;max-height: 256px;overflow: hidden;position: absolute;top: 57px;background: #fff;border-radius: 0 0 5px 5px;width: 100%;z-index: 1000000;box-shadow: 0 10px 10px rgba(0,0,0,0.2);}
    .alarmDivScroll.on { display:block; }
    .alarmDivScroll.toTheBottom {top: 101px;}
    .alarmUl li p span {float: left;display: inline-block;}
    .alarmUl li p {text-align: right;line-height: 50px;height: 50px;padding: 0 15px;font-size: 13px;font-weight: bold;}
    .alarmUl li:hover { background:#f1f3f5; cursor:pointer; }
    .alarmUl li.on:hover { cursor:default; background:#fff; }
    .alarmUl li:hover span, .alarmUl li.on span { color:#477fff; }
    .alarmUl li {position:relative;transition: 0.2s;border-top: 1px solid #f1f3f5;}
    .alarmUl input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 21px;height: 21px;margin-top:13px;border-radius: 50px;border: 2px solid #c1cfd8;background: #c1cfd8 url('../assets/images/contents/checked_fff.png') no-repeat 0 1px; background-size: 21px;font-size: 0;margin-right: 8px}
    .alarmUl input[type='checkbox']:not(old):checked + label {border:2px solid #477fff; background: #477fff url('../assets/images/contents/checked_fff.png') no-repeat 0 1px; background-size: 21px;}
    .alarmUl label:after { position:absolute; left:0; top:0; width:100%; height:100%; display:block; content:''; }
    .alarmDiv {max-width: 560px;display: block;height: auto;overflow: hidden; }

    /* 업무 */
    .forView .taskView { display:block; }
    .forView .started.checkTask .taskView .taskSection { display:block !important; }
    .forView .started .taskView .bg, .forView .started.checkTask .taskView .taskName { display: none !important  }
    .forView  td p.basic {display:none;}
    .taskView { display:none; } 
    .taskView .bg {z-index: 100000000; display:block; width:100%;height:100%; position:absolute; top:0;left:0; background:rgba(255,255,255,0.3);}
    .taskView p { display:inline-block; height:60px; line-height:60px;}
    .taskView div.taskName { display:inline-block; height:60px; line-height:60px;font-weight:bold;}
    .taskView p.taskType { font-size:12px !important; border-radius:5px; padding:0 9px;line-height:30px !important; height:30px; background:#f1f3f5; margin-right:15px; }
    .taskView p.taskType span { color:#477fff;padding-left:5px; }
    .taskView div.taskName { font-size:14px; }
    .taskSection a {float:left;display: inline-block;width: 30px;height: 30px;line-height:30px; font-weight:bold; color:#fff; text-align:center;font-size:14px;border:2px solid #fff;border-radius: 50%;background: #ff8282;transition: 0.1s;}
    .taskSection a:hover span.title { display:block; }
    .taskSection a:hover {  }
    .taskSection .seper { float:left;margin:0 3px;margin-top:17px; width:17px; height:2px;background:#e7e9ea; display:inline-block; }
    .taskSection a span.title {z-index:10000000;position: absolute;display:none; color:#fff; font-weight:bold; padding:0 10px;line-height:30px; height:30px;font-size:13px;border-radius:5px;background: rgba(46, 56, 86,0.9);top:60px;}
    .taskSection {display:none;position: relative;position: relative;height: 65px;box-sizing: border-box;padding-top: 14px;}

    #workTr .dropdown>ul { height: 100%;position: static; }

    /* 시간선택 */
    .chooseTime {display:none !important;position: absolute;width: auto;height: 50px;left: 113px;background: #fff;top: 61px;border-radius: 5px;box-shadow: 0 5px 10px rgba(0,0,0,0.15);z-index: 10000000;}
    .chooseTime.on { display:block !important; }
    .chooseTime .selectboxWrap {width: auto;background: #f1f3f5;padding: 0;position: relative;width: 80px !important;height: 50px;text-align: left;float: left;background: url(../assets/images/side_area/bt_open.png) no-repeat 49px center !important;line-height: 50px;border-right: 1px solid #f1f3f5;}
    .chooseTime .selectboxWrap label {position: absolute;left: 0;width: 100% !important;padding-left: 15px;font-size:13px !important;box-sizing: border-box;padding-right: 10px;}
    .chooseTime .selectboxWrap option {text-align: center;padding-left: 10px;}
    .chooseTime .selectboxWrap select { width:80px !important; }
    .chooseTime .selectboxWrap:hover label {color:#477fff;}
    .chooseTime.to {left: 370px;}

    /* 반복 ul */
    .repeatDivScroll.on { display:block; }
    .repeatDivScroll {display:none;position: absolute;z-index: 1000000;width: 100%;box-shadow: 0 10px 10px rgba(0,0,0,0.15);background: #fff;border-radius: 0 0 5px 5px;top: 60px;}
    .repeatUl {width: 100%;height: 241px;}
    .repeatUl ul {position: absolute;width: 100%;top: 62px;}
    .repeatUl > li {display: inline-block;width: 25%;font-weight: bold;line-height: 40px;height: 40px;font-size: 14px;}
    .repeatUl > li > a {display: inline-block;float: left;width: 100%;text-align: center;line-height: 60px;height: 60px;border-bottom: 2px solid #e7e9ea;}
    .repeatUl > li:hover > a { border-bottom:2px solid #477fff; }
    .repeatUl > li.on > a { color:#477fff; border-bottom:2px solid #477fff;  }
    .repeatUl > li > ul > li {height:60px; line-height:60px; border-bottom:1px solid #f1f3f5; box-sizing: border-box; width: 100%; padding: 0 30px 0 0;}
    .wInput input {transition:0.1s;width: 50px;background: #f1f3f5;line-height: 35px;font-weight: bold;height: 35px;border-radius: 5px;text-align: center;padding: 0 10px;box-sizing: border-box;border:1px solid #f1f3f5;margin:0 8px;margin-top:-5px;color:#477fff;}
    .wInput input:hover { background:#e7e9ea; border:1px solid #e7e9ea; }
    .wInput input:focus { background:#fff; border:1px solid #477fff; }
    .repeatUl > li ul { display:none; position: absolute;left: 0;top: 62px; }
    .repeatUl > li.on ul { display:block; }

    .repeatUl .descript input[type='checkbox']:not(old) + label {float:right; margin-top:11px;text-align: center;font-weight: bold;color: #273044;padding: 0 25px;width: auto;background: #f1f3f5;border-radius: 19px;line-height: 35px;height: 35px;font-size: 13px;font-weight:bold;}
    .repeatUl .descript input[type='checkbox']:not(old) + label:hover { background:#e7e9ea; }
    .repeatUl .descript input[type='checkbox']:checked + label, .repeatUl .descript input[type='checkbox']:checked + label:hover { background: #477fff ;color: #fff;}
    .repeatUl .timeInput {background: none;font-weight: bold;font-size: 14px;padding: 0;margin: 0;display: inline-block;float: left;line-height: 60px;height: 60px;margin-right: 10px;}
    .repeatUl .timeInput:hover, .repeatUl .timeInput:focus { color:#477fff; }
    .repeatUl .intro { display:inline-block; float:left; width:150px; font-size:13px; background:#f1f3f5;margin-right:25px; border-right:1px solid #e7e9ea; border-bottom:1px solid #e7e9ea;padding-left: 25px; box-sizing:border-box;height:60px; }
    .repeatUl li li:hover .intro, .repeatUl li li.hover .intro { opacity:1; background:#fff; }
    .repeatUl li li.on .intro  { color:#477fff; }
    .repeatUl em {font-style: normal;font-weight: bold;color: #477fff;}
    .repeatUl ul.cntrllr {position: absolute;right: 30px;display: inline-block;top: 59px;float: none;left: auto !important;width: 350px !important;text-align: right;}
    .repeatUl ul.cntrllr li {display: inline-block;}
    .repeatUl ul.cntrllr a {    width: 33px;height: 33px;display: inline-block;border: 1px solid #e7e9ea;line-height: 33px;margin-left: 7px;text-align: center;border-radius: 50%;-webkit-transition: 0.1s;transition: 0.1s;}
    .repeatUl ul.cntrllr a:hover { background: #e7e9ea }
    .repeatUl ul.cntrllr a.on,.repeatUl ul.cntrllr a.on:hover { background:#477fff;border:1px solid #477fff; color:#fff; }

    .repeatUl > li.everyM li a, .repeatUl > li.everyY li a { color:#afb2bf; float: left;display: inline-block;box-sizing: border-box;height: 60px;padding: 0 25px;}
    .repeatUl > li.everyM a.on, .repeatUl > li.everyM a.on:hover, .repeatUl > li.everyY a.on, .repeatUl > li.everyY a.on:hover { border-bottom: 2px solid #477fff; color:#477fff }
    .repeatUl > li.everyM a:hover, .repeatUl > li.everyY a:hover  { color:#000; }
    .repeatUl > li.everyM li:first-child .intro, .repeatUl > li.everyY li:first-child .intro {margin-right: 0;}
    .repeatUl span.spanTxt {color: #477fff;margin: 0 6px;}
    .repeatUl .everyL { display:none; }
    .repeatUl.lunar > li { display:none !important; }
    .repeatUl.lunar .everyL {width: 100%; display:block !important; }
    .repeatUl.lunar .everyL > a { border-bottom:2px solid #477fff; cursor:default; }
    .repeatUl > li.everyL ul {display: block;}
    .repeatUl span.lunar {opacity: 0.6;margin-left: 20px;font-size: 13px;}

    #repeatTd .btns {height: 70px;line-height: 70px; border-radius:0 0 5px 5px; overflow:hidden;}
    #repeatTd .btns input {background:#fff;float: left;width: 50%;text-align: center;line-height: 70px;font-weight: bold;font-size: 14px;cursor: pointer;}
    #repeatTd .btns input:hover {background: #f1f3f5;}

    /* 투표 */
    .doc_voteBox  { margin-top:10px; }
    .doc_voteBox li {width: 100%;overflow: hidden;line-height: 50px;}
    .doc_voteBox li * {display: inline-block;float: left;font-weight: bold;}
    .doc_voteBox li .status {padding: 0;text-align: center;width: 50px;border-radius: 5px;background: #f1f3f5;height: 25px;line-height: 25px;margin-right: 15px;margin-top: 13px;font-size: 11px}
    .doc_voteBox li.waiting .status{background:#FFC72F}
    .doc_voteBox li.voted .status{background:#477fff;color:#fff;}
    .doc_voteBox li.finished { opacity:0.5 }
    .doc_voteBox li.finished:hover { opacity:1; }
    .doc_voteBox li.finished .status{background:#c1cfd8;}
    .doc_voteBox li .voteTitle {font-size: 13px;width: 520px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
    .doc_voteBox .result {display:none; position:static;float:right;margin-top: 5px; }
    ul.iVoted {display: none;width: 100%;box-sizing:border-box;padding-left:65px;margin-bottom:10px;}
    .iVoted li span { padding-right:8px;display: inline-block;background: #fff;border-radius: 50%;width: 23px;height: 23px;padding: 0;line-height: 23px;text-align: center;float: left;margin-top: 6px;margin-left: -9px;margin-right: 10px; }
    #voteTr ul.iVoted li {cursor:pointer;display: inline-block;line-height: 35px;height: 35px;border-radius: 20px;margin: 0;padding: 0;font-size: 13px;width: auto;background: #f1f3f5;padding: 0 3px 0 15px; margin-right:10px; margin-bottom:10px;}
    #voteTr ul.iVoted li:hover { background:#e7e9ea }
    #voteTr li.finished ul.iVoted li { padding-right:15px; }
    #voteTr li.finished ul.iVoted li a { display:none; }
    .iVoted a {background:  url(../assets/images/contents/ic_x_new_sm.png) no-repeat center right;font-size: 0;width: 30px;height: 35px;float: right !important;margin-left: 10px;}
    .voteTD a.seeDetail {float: right;}
    .voteTD a.seeDetail:hover { color:#477fff; }

    .count.descript { opacity:0.5 !important }

    .invite.forView .doc_voteBox li .voteTitle { width: 250px; }


    /* 파일 */
    .enabled #fileBox { display:block; }
    #fileBox { display:none; }
    #fileBox li * {display: inline-block;font-weight: bold;color: #273044;padding: 0;opacity: 1;height: 40px;line-height: 40px;float: left;}
    #fileBox li .ex {font-size:12px;width:60px;text-align:center;border-radius: 5px;border: 1px solid #e7e9ea;margin-top:2px;text-transform: uppercase;color: #477fff;margin-right: 10px; line-height:35px; height:35px;}
    #fileBox li { position: relative; overflow:hidden; line-height:40px; height:40px; padding: 5px 0;}
    #fileBox li .fileNm {width: 420px;padding-right: 20px;box-sizing: border-box;text-overflow: ellipsis;overflow: hidden;white-space: nowrap; cursor : pointer; }
    div#fileBox {position: relative;z-index: 100000; margin-top:10px;}
    #fileBox li input {background: none;width: 60px;border-radius: 20px;line-height: 34px;height: 35px;margin-top: 2px;box-sizing: border-box;}
    #fileBox li .dwnldBtn {color: #477fff;width: 100px;text-align: center;margin-right:10px;}
    #fileBox li .delBtn:hover { color:#ff6363; }
    #fileBox p.size {width: 130px;}
    #fileBox li:hover .fileNm {color: #477fff;}

    div.section_ce_fix #attendTr a.result { display:none }
    div.section_ce_fix.forView #attendTr a.result { display:block }

    /* 장소 */
    .schedule_box tr#mapTr td:after { top: 59px; bottom:auto; }
    tr#mapTr .mapUl { margin-top:19px; padding-top:10px; border-top:1px solid #f1f3f5; }
    tr#mapTr .mapUl .mainAddress { font-size:15px; font-weight:bold; line-height:25px; }
    tr#mapTr .mapUl p { line-height:25px; height:25px; font-weight:bold;font-size:12px; margin: 4px 0; }
    tr#mapTr .mapUl p.add1 { font-size:13px; }
    tr#mapTr .mapUl p.add1, tr#mapTr .mapUl p.add2 { margin: 4px 0 4px 0px}
    tr#mapTr .mapUl p.add2 { color:#9ba0a5 }
    tr#mapTr .mapUl p span {font-size:11px; display: inline-block; width:50px;text-align:center; background:#f1f3f5;border-radius:3px;line-height:22px; height:22px;margin-right:10px;}
    tr#mapTr .mapUl p.add2.tel { color:#9ba0a5; }
    tr#mapTr .mapUl p.add2.tel span { color: #273044; }
    tr#mapTr .mapUl input { position:absolute; right:0;top:0;  }
    tr#mapTr .mapUl li { position:relative; overflow:hidden;padding-bottom:10px; margin-bottom:10px; border-bottom:1px solid #F1f3f5;  }
    tr#mapTr .mapUl li:last-child { border-bottom:0 none; margin-bottom:0;}
    tr#mapTr .mapUl input {display: block !important; width: 35px;height: 35px;position: absolute;right: 0;top: 8px;z-index: 100;font-size: 0;border-radius: 50%;border: 1px solid #e6e5e5;background: #fff url(../assets/images/contents/ic_mapPin_red.png) no-repeat center center; background-size:18px;}
    tr#mapTr .mapUl input:hover { background-color: #f3f3f3; }
    tr#mapTr .mapUl input.del {  background-color:#f1f3f5; border:none; background-image: url(../assets/images/contents/ic_close_new.png);background-size:25px; }
    tr#mapTr .mapUl input.del:hover {background-color: #e5e8ea;}
    tr#mapTr .mapUl .seeMap { display:none; }
    .forView tr#mapTr .mapUl .seeMap { display:block !important; }
    .forView tr#mapTr .mapUl input.del { display:none !important; }
    .forView tr#mapTr .mapUl{margin-top: -6px;padding-top: 0;border-top: 0;}
    .forView .searchPl { display:none; }


    /* 공유 */
    .msgModalDiv { position:fixed; z-index: 100; top:300px; right:200px; }
    .msgModalDiv a { display:block; background:#f1f3f5;border-radius:20px; line-height: 30px; height: 30px;padding:0 10px;margin: 10px 0; font-weight:bold; font-size:14px; }

    /* 업무 */
    .forView #workerTr { display : none; }
    .work #repeatTr { display : none; }
    .work #attendTr { display : none; }
    .work #voteTr { display : none; }

    /* 업무 상태 */
    .statusText {display:none;overflow:hidden; position:relative;height: 70px;margin-bottom: 10px;line-height: 50px;text-align: center;border-bottom: 1px solid #f1f3f5;}
    .statusText p {float: left;font-size: 17px;font-weight: bold; }
    .statusText  .btns { float: right; }
    .statusText .startBtn { background:#477fff; color:#fff; border-radius:20px; padding:0 20px; height:35px; line-height:35px;font-weight:bold; }
    .started .statusText .startBtn { display: none; }
    .statusText .cancelWorkBtn { display: none; }
    .started .statusText .cancelWorkBtn { display: inline-block; }
    .cancelWorkBtn, .editWorkBtn, .seeWorkStatus { transition:0.2s; background:#ff6060; margin-left:20px; color: #fff; font-size: 14px; font-weight: bold; border-radius: 20px; line-height: 35px; padding: 0 20px; height: 35px;  }
    .editWorkBtn, .seeWorkStatus { background: #fff; color:#202A39; box-sizing: border-box; border: 1px solid #e3e7ed; line-height: 37px; height: 37px; }
    .editWorkBtn:hover, .seeWorkStatus:hover { background:#f1f3f5; }
    .cancelWorkBtn { opacity:0.7 }
    .cancelWorkBtn:hover { opacity: 1; }
    .taskBtns { float:right;position: relative;z-index: 100; }
    .taskBtns input { background:#fff; font-size: 13px; font-weight:bold; line-height:35px; height:35px;padding: 0 20px; border-radius: 20px; margin-top:7px; }
    .taskBtns input.done { display:none; background-color:#477fff; color:#fff; }
    .started .taskView .taskBtns input.done { display: block; }
    .forView #workTr td p  {  cursor: default; }
    .forView.work .statusText { display: block; }
    #container #section_ce .work.forView .schedule_box.writeSche { border: 2px solid #f1f3f5;border-radius: 10px;padding: 30px 40px 30px 30px;box-sizing: border-box;max-width: 920px;margin-left: 30px;margin: 30px;margin-top:0;background: #fff; }
    #container #section_ce  .work.forView .schedule_box.writeSche .statusClrBar {content: '';position: absolute;top: 0;left: 0;width: 100%;height: 10px;background: red;border-radius: 5px 5px 0 0;}
    #section_ce  .work.forView .schedule_box .tableDefault.tableBorder.th-right { padding-bottom: 30px }
    #container #section_ce  .work.forView .section_scroll { background:#fbfcfd }


    .work.forView .checkTask .taskSection { display:none !important; }
    .work.forView .checkTask .taskName { display:block; }
    .work.forView.started .checkTask .taskSection { display:block !important; }
    /*  완료 전 체크식 체크아이콘과 보더 색상 변경  */
    /* 기본 off */
    .work.forView .checkTask .taskSection a { font-size: 0; background-color:transparent !important; background-repeat: no-repeat; background-position: 5px 7px !important; background-size: 14px !important; width: 26px; height: 26px; margin-top: 2px; }
    .work.forView .checkTask .taskSection .dc0 { border-color:#FF6363 !important;background-image: url('../assets/images/contents/ic_svg_check_dc0.svg');}
    .work.forView .checkTask .taskSection .dc1 { border-color:#FFA70E !important;background-image: url('../assets/images/contents/ic_svg_check_dc1.svg');}
    .work.forView .checkTask .taskSection .dc2 { border-color:#FFC72F !important;background-image: url('../assets/images/contents/ic_svg_check_dc2.svg');}
    .work.forView .checkTask .taskSection .dc3 { border-color:#FF198B !important;background-image: url('../assets/images/contents/ic_svg_check_dc3.svg');}
    .work.forView .checkTask .taskSection .dc4 { border-color:#00B2C7 !important;background-image: url('../assets/images/contents/ic_svg_check_dc4.svg');}
    .work.forView .checkTask .taskSection .dc5 { border-color:#13D08B !important;background-image: url('../assets/images/contents/ic_svg_check_dc5.svg');}
    .work.forView .checkTask .taskSection .dc6 { border-color:#4DBAFF !important;background-image: url('../assets/images/contents/ic_svg_check_dc6.svg');}
    .work.forView .checkTask .taskSection .dc7 { border-color:#477FFF !important;background-image: url('../assets/images/contents/ic_svg_check_dc7.svg');}
    .work.forView .checkTask .taskSection .dc8 { border-color:#6854FF !important;background-image: url('../assets/images/contents/ic_svg_check_dc8.svg');}
    .work.forView .checkTask .taskSection .dc9 { border-color:#35405A !important;background-image: url('../assets/images/contents/ic_svg_check_dc9.svg');}
    /* 파스텔 off */
    .work.forView .checkTask .taskSection .lc0 { border-color:#B9A494 !important;background-image: url('../assets/images/contents/ic_svg_check_lc0.svg');}
    .work.forView .checkTask .taskSection .lc1 { border-color:#E67D9B !important;background-image: url('../assets/images/contents/ic_svg_check_lc1.svg');}
    .work.forView .checkTask .taskSection .lc2 { border-color:#FF9898 !important;background-image: url('../assets/images/contents/ic_svg_check_lc2.svg');}
    .work.forView .checkTask .taskSection .lc3 { border-color:#AB8DD6 !important;background-image: url('../assets/images/contents/ic_svg_check_lc3.svg');}
    .work.forView .checkTask .taskSection .lc4 { border-color:#F3AC77 !important;background-image: url('../assets/images/contents/ic_svg_check_lc4.svg');}
    .work.forView .checkTask .taskSection .lc5 { border-color:#E3D37A !important;background-image: url('../assets/images/contents/ic_svg_check_lc5.svg');}
    .work.forView .checkTask .taskSection .lc6 { border-color:#8DD6A0 !important;background-image: url('../assets/images/contents/ic_svg_check_lc6.svg');}
    .work.forView .checkTask .taskSection .lc7 { border-color:#7197ED !important;background-image: url('../assets/images/contents/ic_svg_check_lc7.svg');}
    .work.forView .checkTask .taskSection .lc8 { border-color:#8DAED6 !important;background-image: url('../assets/images/contents/ic_svg_check_lc8.svg');}
    .work.forView .checkTask .taskSection .lc9 { border-color:#6F7A93 !important;background-image: url('../assets/images/contents/ic_svg_check_lc9.svg');}

    /*  완료 후 체크식 체크아이콘과 보더 색상 변경  */
    .work.forView .checkTask .taskSection a.on {background-position: 7px 8px !important; background-image: url('../assets/images/contents/ic_svg_check_fff.svg'); width: 29px; height: 29px;  margin-top:0; }
    
    /* 기본 on */
    .work.forView .checkTask .taskSection .dc0.on {background-color: #FF6363 !important; border-color: #FF6363 !important;}
    .work.forView .checkTask .taskSection .dc1.on {background-color: #FFA70E !important; border-color:#FFA70E !important;}
    .work.forView .checkTask .taskSection .dc2.on {background-color: #FFC72F !important; border-color:#FFC72F !important;}
    .work.forView .checkTask .taskSection .dc3.on {background-color: #FF198B !important; border-color:#FF198B !important;}
    .work.forView .checkTask .taskSection .dc4.on {background-color: #00B2C7 !important; border-color:#00B2C7 !important;}
    .work.forView .checkTask .taskSection .dc5.on {background-color: #13D08B !important; border-color:#13D08B !important;}
    .work.forView .checkTask .taskSection .dc6.on {background-color: #4DBAFF !important; border-color:#4DBAFF !important;}
    .work.forView .checkTask .taskSection .dc7.on {background-color: #477FFF !important; border-color:#477FFF !important;}
    .work.forView .checkTask .taskSection .dc8.on {background-color: #6854FF !important; border-color:#6854FF !important;}
    .work.forView .checkTask .taskSection .dc9.on {background-color: #35405A !important; border-color:#35405A !important;}
    /* 파스텔 off */
    .work.forView .checkTask .taskSection .lc0.on {background-color: #B9A494 !important; border-color:#B9A494 !important;}
    .work.forView .checkTask .taskSection .lc1.on {background-color: #E67D9B !important; border-color:#E67D9B !important;}
    .work.forView .checkTask .taskSection .lc2.on {background-color: #FF9898 !important; border-color:#FF9898 !important;}
    .work.forView .checkTask .taskSection .lc3.on {background-color: #AB8DD6 !important; border-color:#AB8DD6 !important;}
    .work.forView .checkTask .taskSection .lc4.on {background-color: #F3AC77 !important; border-color:#F3AC77 !important;}
    .work.forView .checkTask .taskSection .lc5.on {background-color: #E3D37A !important; border-color:#E3D37A !important;}
    .work.forView .checkTask .taskSection .lc6.on {background-color: #8DD6A0 !important; border-color:#8DD6A0 !important;}
    .work.forView .checkTask .taskSection .lc7.on {background-color: #7197ED !important; border-color:#7197ED !important;}
    .work.forView .checkTask .taskSection .lc8.on {background-color: #8DAED6 !important; border-color:#8DAED6 !important;}
    .work.forView .checkTask .taskSection .lc9.on {background-color: #6F7A93 !important; border-color:#6F7A93 !important;}


    /* 단계식 */
    /* 기본 off */
    .work.forView .stepTask .taskSection a { font-size: 13px; color: #fff;opacity:0.7; width:30px; height:30px; line-height:30px; margin-top:1px }
    .work.forView .stepTask .taskSection a.on { opacity: 1; }
    .work.forView .stepTask .taskSection a.on.stepNow { opacity: 1; width:34px; height:34px; line-height:34px;font-size:14px; margin-top:-2px; font-size:0;background-position: 8px 10px !important; background-image: url('../assets/images/contents/ic_svg_check_fff.svg'); background-repeat: no-repeat; background-size: 16px; }

    .work.forView .stepTask .taskSection { display:none !important; }
    .work.forView .stepTask .taskName { display:block; }
    .work.forView .started.stepTask .taskSection { display:block !important; }

    .forView .stepTask .dc0 { background-color:#FF6363 !important;}
    .forView .stepTask .dc1 { background-color:#FFA70E !important;}
    .forView .stepTask .dc2 { background-color:#FFC72F !important;}
    .forView .stepTask .dc3 { background-color:#FF198B !important;}
    .forView .stepTask .dc4 { background-color:#00B2C7 !important;}
    .forView .stepTask .dc5 { background-color:#13D08B !important;}
    .forView .stepTask .dc6 { background-color:#4DBAFF !important;}
    .forView .stepTask .dc7 { background-color:#477FFF !important;}
    .forView .stepTask .dc8 { background-color:#6854FF !important;}
    .forView .stepTask .dc9 { background-color:#35405A !important;}
    /* 파스텔 off */
    .forView .stepTask .lc0 { background-color:#B9A494 !important;}
    .forView .stepTask .lc1 { background-color:#E67D9B !important;}
    .forView .stepTask .lc2 { background-color:#FF9898 !important;}
    .forView .stepTask .lc3 { background-color:#AB8DD6 !important;}
    .forView .stepTask .lc4 { background-color:#F3AC77 !important;}
    .forView .stepTask .lc5 { background-color:#E3D37A !important;}
    .forView .stepTask .lc6 { background-color:#8DD6A0 !important;}
    .forView .stepTask .lc7 { background-color:#7197ED !important;}
    .forView .stepTask .lc8 { background-color:#8DAED6 !important;}
    .forView .stepTask .lc9 { background-color:#6F7A93 !important;}

    #app .forView.work .started #workTr td .taskName { transition:0.2s; }
    #app .forView.work .started.checkTask #workTr:hover td .taskName {transition: 0.1s;display: block !important;height: 40px;line-height: 20px;color:#202A39; font-size: 13px;opacity:1;}
    #app .forView.work .started.stepTask #workTr:hover td .taskName {transition: 0.1s;display: block !important;height: 40px;line-height: 20px; color:#202A39; font-size: 13px;opacity:1;}
    #app .forView.work .started.checkTask #workTr td .taskName {transition: 0.4s;display: block !important;height: 0;line-height: 0; opacity:0;}
    #app .forView.work .started.stepTask #workTr td .taskName {transition: 0.4s;display: block !important;height: 0;line-height: 0;opacity:0;}
    .typeOfTask { display: inline-block; font-size:12px; padding: 0 7px;line-height:25px; height:25px; border-radius: 3px; background:#f1f3f5; margin-right:10px; }
    .typeOfTask em { color:#477fff; font-style: normal; }

    .forView.work .taskCircle { float: right; }
    .forView.work .started .taskCircle { display:none; }

    textarea::placeholder { color:#acb2bf }

    .workWriter .wstBtns { display:block; }
    .workWriter .workStatusTxt { text-align:left; width: 70%;float:left; }
    .workStatusTxtDiv {overflow:hidden;font-size: 19px;font-weight: bold;text-align: center;line-height: 80px;max-width: 920px;margin-left: 30px;margin-right: 30px;color: #b1b1b1;}
    .wstBtns { float:right;display:none;  }
    .wstBtns input { margin-left: 5px; border: 0 none; background:transparent !important;  }
    .wstBtns .comBtn:hover { color:#477fff !important; }
    .wstBtns .pauBtn:hover { color:#ff6060 !important; }
    .wstBtns .canBtn:hover { color:#a0a7ab !important; }
    .wstBtns .canBtn.started:hover { color:#ffbc00 !important; }
    #voteList > li > a.result > span { margin-right: 3px; }

    #notificationTr > td> .push_radio { float : right; margin-right: 10px; }
    #schDetailScroll .tableDefault.tableBorder table tbody tr#notificationTr td { padding-top : 19px !important; padding-bottom : 19px !important; }

    /* 업무일지 숨겨야하는 영역 숨기기 */
    .section_ce_fix.report #schDetailScroll #repeatTr { display : none; }
    .section_ce_fix.report #schDetailScroll #alarmTr { display : none; }
    .section_ce_fix.report #schDetailScroll #mapTr { display : none; }
    /* .section_ce_fix.report #schDetailScroll #noteTr { display : none; } */
    .section_ce_fix.report #schDetailScroll #telTr { display : none; }
    /* .section_ce_fix.report #schDetailScroll #shareTr { display : none; } */
    .section_ce_fix.report #schDetailScroll #attendTr { display : none; }
    .section_ce_fix.report #schDetailScroll #voteTr { display : none; }

    /* 회의록 숨겨야하는 영역 숨기기 */
    .section_ce_fix.meetinglog #schDetailScroll #repeatTr { display : none; }
    .section_ce_fix.meetinglog #schDetailScroll #alarmTr { display : none; }
    .section_ce_fix.meetinglog #schDetailScroll #mapTr { display : none; }
    /* .section_ce_fix.meetinglog #schDetailScroll #noteTr { display : none; } */
    .section_ce_fix.meetinglog #schDetailScroll #telTr { display : none; }
    /* .section_ce_fix.meetinglog #schDetailScroll #shareTr { display : none; } */
    .section_ce_fix.meetinglog #schDetailScroll #attendTr { display : none; }
    .section_ce_fix.meetinglog #schDetailScroll #voteTr { display : none; }

    #memoTr #shareMemo, #remarkTr #remark { max-width: 100% !important; font-weight:bold; transition:0.1s; overflow:visible !important; font-size: 13px !important; resize:none; background-color: #fff; width:100% !important; padding: 0!important; box-sizing: border-box; color: #273044; white-space: pre-line; }
    #memoTr #shareMemo.placeholder, #remarkTr #remark.placeholder { color: #acb2bf; }
    #memoTr pre, #remarkTr pre { -webkit-user-select: text; -moz-user-select: text; -ms-user-select: text; user-select: text; }

    #memoTr #shareMemo { max-height : 500px; }
    .forView #memoTr #shareMemo { max-height : none; }
    #noteTr #note { max-height : 500px; }
    .forView #noteTr #note { max-height : none; }

    #schDetailScroll { overflow-y : auto; }
    #hodu_event ::-webkit-scrollbar{width: 6px;}
    #hodu_event ::-webkit-scrollbar-track{background-color: transparent;}
    #hodu_event ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}

    .enabled h2 span.grupArrow { width: 30px; height: 30px; background: url('../assets/images/side_area/ic_downopen_232848.png') no-repeat center; background-size: 17px auto; margin-right: 4px; display: inline-block; border-radius: 50%; float: right; vertical-align: middle; margin-top: 14px; transition: .2s; }
    .enabled h2.on span.grupArrow { background-image: url('../assets/images/side_area/ic_upclose.png'); }

    /* 일정등록 반복 */
    /* .repeatUl .descript .drEndcheck_box { float: left; width: 30%; }
    .repeatUl .descript.endInput input[type='checkbox']:not(old) + label { width: 25px; height: 25px; padding: 0; border-radius: 50%; float: left; margin-top: 15px; background: #c1cfd8 url('../assets/images/contents/checked_fff.png') no-repeat 1px center !important; background-size: 20px; box-sizing: border-box; position: relative; }
    .repeatUl .descript.endInput input[type='checkbox']:not(old):checked + label { background: #477fff url('../assets/images/contents/checked_fff.png') no-repeat 1px center !important; }
    .descript.endInput label span { cursor: pointer; position: absolute; top: 0; left: 0; display: inline-block; margin-left: 5px; line-height: 25px; text-align: left; margin-left: 40px; white-space: nowrap; opacity: .6; }
    #schDetailScroll input[type='checkbox']:not(old):checked + label>span { opacity: 1; } */

    .repeatUl .endInput { position: relative; }
    .repeatUl .descript .drEndcheck_box { float: left; }
    .repeatUl .descript.endInput input[type='radio']:not(old) { position: absolute; top: 45px; left: 180px; }
    .repeatUl .descript.endInput input[type='radio']:not(old) + label { width: 25px; height: 25px; padding: 0; border-radius: 50%; float: left; margin-top: 15px; background: #c1cfd8 url('../assets/images/contents/checked_fff.png') no-repeat 1px center !important; background-size: 20px; box-sizing: border-box; position: relative; margin-right: 230px; }
    .repeatUl .descript.endInput input[type='radio']:not(old):checked + label { background: #477fff url('../assets/images/contents/checked_fff.png') no-repeat 1px center !important;}
    .descript.endInput label span { cursor: pointer; position: absolute; top: 0; left: 0; display: inline-block; margin-left: 5px; line-height: 25px; text-align: left; margin-left: 40px; white-space: nowrap; opacity: .6; }
    .repeatUl .descript.endInput input[type='radio']:not(old):checked + label>span { opacity: 1; }

    /* 일정 클릭시 히스토리 버튼 */
    .section_ce_fix #grupTr .scheduleHisBtn { width: 35px; height: 35px; background: url('../assets/images/contents/ic_history_bk.png') no-repeat center; background-size: 22px auto; margin-right: 4px; border-radius: 30px; float: right; vertical-align: middle; margin-top: 14px; font-size: 0; border: 1px solid #e5e9ec; line-height: 34px; cursor: pointer; }
    .section_ce_fix #grupTr .scheduleHisBtn:hover { width: 120px; background-position: 15px center; box-sizing: border-box; padding: 0 15px 0 45px; font-size: 14px; }
    .section_ce_fix #grupTr .scheduleHisBtn { display: none; }
    .section_ce_fix.forView #grupTr .grupArrow { display: none; }
    .section_ce_fix.forView #grupTr .scheduleHisBtn { display: block; }

    /* 읽정 읽음리스트 */
    .cmmDiv { text-align: center; }
    .cmmDiv input.cmmntBtn { display: inline-block; margin-right: 40px; vertical-align: top; }
    .cmmDiv .readMark { display: none; height: 40px; line-height: 40px; border-radius: 50px; background: #fff; border: 1px solid #e3e7ed; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-top: 30px; font-weight: bold; transition: .2s; }
    .cmmDiv.markDiv .readMark { display: inline-block; }
    .cmmDiv .readMark:hover { background: #477fff; }
    .cmmDiv .readMark:hover a,
    .cmmDiv .readMark:hover a span { color: #fff; }
    .cmmDiv .readMark a { padding: 10px 30px; box-sizing: border-box; }

    /* 공개여부 추가 */
    #schDetailScroll .tableDefault.tableBorder table tbody tr#shareTr td { display: flex; align-items: center; justify-content: space-between; padding: 17px 0 !important; }
    #shareTr td .shareDiv { position: relative; width: 70%; }
    #shareTr td .shareDiv:after { content: ''; width: 2px; height: 35px; background: #e3e7ed; position: absolute; top: -8px; right: 0; }
    #schDetailScroll #shareTr td .inputgroup_txt02 { line-height: 20px; margin-bottom: 0px; margin-right: 25px; }
    #shareTr .shareDiv input { right: 10px; top: -8px; }
    #shareTr td .showDiv { width: 27%; position: relative; display: flex; align-items: center; justify-content: space-between; }
    #shareTr td .showDiv input { position: static; width: 0; }
    #shareTr td .showDiv label.switch { margin-right: 10px; margin-top: 0; }
    #shareTr span.tooltipSpan { width: 20px; height: 20px; line-height: 20px; display: inline-block; background: #c1cfd8; border-radius: 50%; color: #fff; font-size: 12px; text-align: center; vertical-align: middle; margin-right: 5px; }
    .requiredToolBox { width: 335px; min-width: 170px; z-index: 1111; background: #fff; color: #fff; line-height: 20px; position: absolute; top: 35px; left: -12%; padding: 5px 15px; box-sizing: border-box; text-align: center; border-radius: 8px; box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 15%); transition: .2s; }
    .requiredToolBox p { color: #4e4e4e; text-align: left; }
    /* 프로젝트 특정인 지정 추가 */
    #workerTr .showDiv { width: 23%; position: relative; display: flex; align-items: center; justify-content: space-between; margin-top: -2px; }
    #workerTr .showDiv:before { content: ''; width: 2px; height: 35px; background: #e3e7ed; position: absolute; top: -5px; left: -20px; }
    #workerTr .inputgroup_txt02 { line-height: 20px; margin-bottom: 0px; margin-right: 25px; }
    #workerTr .tooltipSpan { width: 20px; height: 20px; line-height: 20px; display: inline-block; background: #c1cfd8; border-radius: 50%; color: #fff; font-size: 12px; text-align: center; vertical-align: middle; margin-right: 5px; }
    #workerTr td .showDiv label.switch { margin-right: 10px; margin-top: 0; }
</style>

<!-- v-html -->
<style>
    #memoTr #shareMemo .hodu-link-url, #remarkTr #remark .hodu-link-url { color : #477fff !important; }
    #repeatTd .highlight {color: #477fff;}
    #repeatTd .endDay {padding-left: 20px;padding-right: 5px;opacity: 0.5;}
</style>

