<template>
    <div id="approval_approver_favorite" class="approval_content up2">
        <div class="filter_tap">
            <div class="content_top">
                <h3>결재자 즐겨찾기</h3>
                <a href="#" class="blue_btn" @click.prevent="createApprovalApproverFavoriteModalInfo">＋결재선 추가</a>
            </div>
        </div>

        <div class="favoriteCon" :style="{ height : `${scroll_height}px` }">

            <!-- 즐겨찾기 결재자가 없을 경우 on -->
            <div class="noResult favoriteNone" :class="{ on : favorites.length < 1 }" v-if="favorites.length < 1">
                <p>등록된 결재자 즐겨찾기가 존재하지 않습니다.</p>
            </div>

            <div class="favoriteWrap" v-if="favorites.length > 0">

                <div class="favorite_list_con" :class="{ off : favorite.on != true }" :key="favorite.favorite_id" v-for="favorite in favorites">

                    <div class="favorite_title eqinfor03">
                        <p><span></span>{{ favorite.favorite_name }}</p>
                        <a href="#" class="eqinfor03_btn off" @click.prevent="favoriteOnOff(favorite)">접기펼치기</a>
                    </div>

                    <div class="favorite_list_div">

                        <div class="btn_group">
                            <input type="button" value="수정" class="input_btn" @click.prevent="updateApprovalApproverFavoriteModalInfo(favorite)">
                            <input type="button" value="삭제" class="input_btn" @click.prevent="deleteApprovalApproverFavoriteModalInfo(favorite)">
                        </div>

                        <!-- v-for approver -->
                        <div class="favorite_people" :key="approver_index" v-for="(approver, approver_index) in favorite.approver">
                            <ul>
                                <li class="title_order" style="position: relative;">
                                    {{ approver_index + 1 }}
                                    <a href="#" class="del" @click.prevent="deleteSingleApprover(favorite, favorite.approver, approver)">아이콘</a>
                                </li>
                                <li class="photo">
                                    <p>
                                        <img :src="getProfileImage(approver)" style="width: 100%;" @error="userImageError($event)">
                                    </p>
                                </li>
                                <li class="name">{{ approver.user_name }} ({{ approver.pos_name }})</li>
                                <li class="position">{{ approver.dept_id == 0 ? '미배정' : approver.dept_name }}</li>
                            </ul>
                        </div>

                        <div class="favorite_reference" v-if="favorite.receive_reference != null && favorite.receive_reference.length > 0">
                            <p class="referenceTit">수신참조</p>
                            <div class="reference_list">
                                <ul>
                                    <!-- v-for receive_reference -->
                                    <li :key="receive_reference_index" v-for="(receive_reference, receive_reference_index) in favorite.receive_reference">
                                        <div class="reference_layout">
                                            <p class="profile">
                                                <img :src="getProfileImage(receive_reference)" style="width: 40px;" @error="userImageError($event)">
                                            </p>
                                            <div class="team_text">
                                                <p class="myname">{{ receive_reference.user_name }} ({{ receive_reference.pos_name }})</p>
                                                <p class="teamname">{{ receive_reference.dept_id == 0 ? '미배정' : receive_reference.dept_name }}</p>
                                            </div>
                                        </div>
                                        <a href="#" class="approver-remove-btn" @click.prevent="deleteSingleReceiveReference(favorite, favorite.receive_reference, receive_reference)">삭제</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>

                <!-- 리스트는 기본 접혀서 보이기 -->
                <!-- <div class="favorite_list_con off">
                    <div class="favorite_title eqinfor03">
                        <p><span></span>사무지출 품의서</p>
                        <a href="#" class="eqinfor03_btn off">접기펼치기</a>
                    </div>
                    
                    <div class="favorite_list_div">
                        <div class="btn_group">
                            <input type="button" value="수정" class="input_btn">
                            <input type="button" value="삭제" class="input_btn">
                        </div>

                        <div class="favorite_people">
                            <ul>
                                <li class="title_order" style="position: relative;"> 1 <a href="#" class="del">아이콘</a></li>
                                <li class="photo">
                                    <p><img src="/img/ic_approval_user.769dd479.png" style="width: 100%;"></p>
                                </li>
                                <li class="name">김호두 (대표(CEO))</li>
                                <li class="position">호두웨어</li>
                            </ul>
                        </div>
                        
                        <div class="favorite_people">
                            <ul>
                                <li class="title_order" style="position: relative;"> 2 <a href="#" class="del">아이콘</a></li>
                                <li class="photo">
                                    <p><img src="/img/ic_approval_user.769dd479.png" style="width: 100%;"></p>
                                </li>
                                <li class="name">Michael (이사)</li>
                                <li class="position">호두웨어</li>
                            </ul>
                        </div>
                        
                        <div class="favorite_people">
                            <ul>
                                <li class="title_order" style="position: relative;"> 3 <a href="#" class="del">아이콘</a></li>
                                <li class="photo">
                                    <p><img src="/img/ic_approval_user.769dd479.png" style="width: 100%;"></p>
                                </li>
                                <li class="name">김총무 (과장)</li>
                                <li class="position">호두웨어/경영지원</li>
                            </ul>
                        </div>

                        <div class="favorite_reference">
                            <p class="referenceTit">수신참조</p>
                            <div class="reference_list">
                                <ul>
                                    <li>
                                        <div class="reference_layout">
                                            <p class="profile"><img src="/img/ic_approval_user.769dd479.png" style="width: 40px;"></p>
                                            <div class="team_text">
                                                <p class="myname">이경영 (과장)</p>
                                                <p data-v-930f8264="" class="teamname">호두웨어/경영지원</p>
                                            </div>
                                        </div>
                                        <a href="#" class="approver-remove-btn">삭제</a>
                                    </li>
                                    <li>
                                        <div class="reference_layout">
                                            <p class="profile"><img src="/img/ic_approval_user.769dd479.png" style="width: 40px;"></p>
                                            <div class="team_text">
                                                <p class="myname">이경영 (과장)</p>
                                                <p data-v-930f8264="" class="teamname">호두웨어/경영지원</p>
                                            </div>
                                        </div>
                                        <a href="#" class="approver-remove-btn">삭제</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="favorite_list_con off">
                    <div class="favorite_title eqinfor03">
                        <p><span></span>타부서승인 휴가신청서</p>
                        <a href="#" class="eqinfor03_btn off">접기펼치기</a>
                    </div>
                    
                    <div class="favorite_list_div">
                        <div class="btn_group">
                            <input type="button" value="수정" class="input_btn">
                            <input type="button" value="삭제" class="input_btn">
                        </div>
                        <div class="favorite_people">
                            <ul>
                                <li class="title_order" style="position: relative;"> 1 <a href="#" class="del">아이콘</a></li>
                                <li class="photo">
                                    <p><img src="/img/ic_approval_user.769dd479.png" style="width: 100%;"></p>
                                </li>
                                <li class="name">김호두 (대표(CEO))</li>
                                <li class="position">호두웨어</li>
                            </ul>
                        </div>
                        
                        <div class="favorite_people">
                            <ul>
                                <li class="title_order" style="position: relative;"> 2 <a href="#" class="del">아이콘</a></li>
                                <li class="photo">
                                    <p><img src="/img/ic_approval_user.769dd479.png" style="width: 100%;"></p>
                                </li>
                                <li class="name">Michael (이사)</li>
                                <li class="position">호두웨어</li>
                            </ul>
                        </div>
                        
                        <div class="favorite_people">
                            <ul>
                                <li class="title_order" style="position: relative;"> 3 <a href="#" class="del">아이콘</a></li>
                                <li class="photo">
                                    <p><img src="/img/ic_approval_user.769dd479.png" style="width: 100%;"></p>
                                </li>
                                <li class="name">김총무 (과장)</li>
                                <li class="position">호두웨어/경영지원</li>
                            </ul>
                        </div>

                        <div class="favorite_reference">
                            <p class="referenceTit">수신참조</p>
                            <div class="reference_list">
                                <ul>
                                    <li>
                                        <div class="reference_layout">
                                            <p class="profile"><img src="/img/ic_approval_user.769dd479.png" style="width: 40px;"></p>
                                            <div class="team_text">
                                                <p class="myname">이경영 (과장)</p>
                                                <p class="teamname">호두웨어/경영지원</p>
                                            </div>
                                        </div>
                                        <a href="#" class="approver-remove-btn">삭제</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { approval_modal_interface, approval_enum } from '@/model/approval';

@Component({
    components: {
        
    },
})
export default class ApprovalApproverFavorite extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;
    @Prop() tab !: approval_enum.APPROVAL_BOX_TAB;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetApprovalApproverFavoriteModalInfo ?: (params : approval_modal_interface.ApprovalApproverFavoriteModalInfo) => void;

    favorites : any[] = [];
    
    scroll_height : number = 0;
    resize_function_submit : boolean = false;

    favorite_on_off_map : Map<string, boolean> = new Map();

    mounted() : void {

        //리스트 on/off
        // $('.eqinfor03_btn').off("click").on("click", function(){
        // 	$(this).toggleClass('off');
        //     if ($(this).hasClass("off")) {
        //         $(this).parent().parent().addClass('off')
        //     } else if (!$(this).hasClass("off")) {
        //         $(this).parent().parent().removeClass('off')
        //     }
        // });

        this.setScroll();
        this.getApprovalApproverFavorites();

        this.event_bus?.$on('getApprovalApproverFavorites', this.getApprovalApproverFavorites);
    }

    /**
     * 스크롤 설정
     */
     setScroll() : void {
        this.$nextTick(() => {
            const titleHeight  : number | undefined = $('.title_box').outerHeight();
            const filterHeight : number | undefined = $('.filter_tap').outerHeight();

            this.scroll_height = window.innerHeight - (titleHeight ? titleHeight : 0)
                                                - (filterHeight ? filterHeight : 0);

            if( this.resize_function_submit == false ) {
                this.event_bus?.$on('approvalApproverFavoriteResize', this.handleResize);
                this.resize_function_submit = true;
            }
        });
    }

    async getApprovalApproverFavorites() {

        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite`, API_METHOD.GET);

            console.log(response);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.favorites ) {
                throw new Error("결재자 즐겨찾기 조회 오류");
            }

            for( let favorite of response.data.data.favorites ) {
                const on_off = this.favorite_on_off_map.get(favorite.favorite_id);
                favorite.on = (on_off == true);
            }

            this.favorites.splice(0, this.favorites.length);
            this.favorites = this.favorites.concat(response.data.data.favorites);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "결재자 즐겨찾기 조회 실패", ['확인']);
        }

    }

    createApprovalApproverFavoriteModalInfo() {

        this.doSetApprovalApproverFavoriteModalInfo?.({
            show_modal : true,
            approver : [],
            receive_reference : [],
            callback : async(favorite_name, approver, receive_reference) => {
                try {

                    const body = {
                        "favorite_name" : favorite_name,
                        "approver" : approver,
                        "receive_reference" : receive_reference
                    }

                    console.log(body);

                    // 추가
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite`, API_METHOD.POST, body);

                    if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                        throw new Error("결재자 즐겨찾기 추가 오류");
                    }
                    
                    this.getApprovalApproverFavorites();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "결재자 즐겨찾기 추가 실패", ['확인']);
                }
            }
        });

    }

    updateApprovalApproverFavoriteModalInfo(approver_favorite) {

        this.doSetApprovalApproverFavoriteModalInfo?.({
            show_modal : true,
            favorite_id : approver_favorite.favorite_id,
            approver : approver_favorite != null && approver_favorite.approver != null ? JSON.parse(JSON.stringify(approver_favorite.approver)) : [],
            receive_reference : approver_favorite != null && approver_favorite.receive_reference != null ? JSON.parse(JSON.stringify(approver_favorite.receive_reference)) : [],
            callback : async(favorite_name, approver, receive_reference) => {
                try {

                    const body = {
                        "favorite_name" : favorite_name,
                        "approver" : approver,
                        "receive_reference" : receive_reference
                    }

                    console.log(body);

                    // 추가
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite/${approver_favorite.favorite_id}`, API_METHOD.PUT, body);

                    if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                        throw new Error("결재자 즐겨찾기 수정 오류");
                    }
                    
                    this.getApprovalApproverFavorites();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "결재자 즐겨찾기 수정 실패", ['확인']);
                }
            }
        });

    }

    deleteApprovalApproverFavoriteModalInfo(favorite) {

        this.hodu_show_dialog('cancel', '정말 즐겨찾기를 삭제하시겠습니까?', ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite/${favorite.favorite_id}`, API_METHOD.DELETE);

                    if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                        throw new Error("결재자 즐겨찾기 삭제 오류");
                    }
                    
                    this.getApprovalApproverFavorites();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "결재자 즐겨찾기 삭제 실패", ['확인']);
                }
            }
        ]);
        
    }

    async deleteSingleApprover(favorite, approver, single_approver) {
        try {

            const index = approver.indexOf(single_approver);
            const temp_approver = JSON.parse(JSON.stringify(approver));
            temp_approver.splice(index, 1);

            const body = {
                "favorite_name" : favorite.favorite_name,
                "approver" : temp_approver,
                "receive_reference" : favorite.receive_reference
            }

            console.log(body);

            // 추가
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite/${favorite.favorite_id}`, API_METHOD.PUT, body);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("결재자 즐겨찾기 수정 오류");
            }
            
            this.getApprovalApproverFavorites();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "결재자 즐겨찾기 수정 실패", ['확인']);
        }
    }

    async deleteSingleReceiveReference(favorite, receive_reference, single_receive_reference) {
        try {

            const index = receive_reference.indexOf(single_receive_reference);
            const temp_receive_reference = JSON.parse(JSON.stringify(receive_reference));
            temp_receive_reference.splice(index, 1);

            const body = {
                "favorite_name" : favorite.favorite_name,
                "approver" : favorite.approver,
                "receive_reference" : temp_receive_reference
            }

            console.log(body);

            // 추가
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite/${favorite.favorite_id}`, API_METHOD.PUT, body);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("결재자 즐겨찾기 수정 오류");
            }

            this.getApprovalApproverFavorites();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "결재자 즐겨찾기 수정 실패", ['확인']);
        }
    }

    favoriteOnOff(favorite) {

        if( favorite.on == null || favorite.on == false ) {
            favorite.on = true;
            this.favorite_on_off_map.set(favorite.favorite_id, true);
        }
        else {
            favorite.on = false;
            this.favorite_on_off_map.set(favorite.favorite_id, false);
        }
        this.$forceUpdate();
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 이미지 에러
     */
    userImageError(event) : void {
        event.target.src = require('@/assets/images/contents/ic_approval_user_on.png');
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        this.setScroll();
    }

}
</script>

<style scoped>
    #approval_approver_favorite .content_top { display: flex; justify-content: space-between; padding: 0 25px; align-items: center; }
    #approval_approver_favorite a.blue_btn { border: 1px solid rgb(233, 235, 235); color: #477fff; padding: 5px 25px; border-radius: 3px; font-size: 15px; font-weight: bold; line-height: 23px; box-sizing: border-box; }
    .favoriteNone { padding: 20px 50px; display: none; }
    .favoriteNone.on { display: block; }
    .favoriteNone p { width: 100%; background: #f1f3f5; border-radius: 5px; line-height: 80px; height: 80px; font-size: 13px; color: #9ea5b3; }

    /* favorite_list_con */
    .favorite_list_con { border-bottom: 2px solid #e7e9ea; padding-bottom: 30px; }
    .favorite_list_con.off { border-bottom: 0px; padding-bottom: 0px; }
    .favorite_title { position: relative; display: flex; justify-content: space-between; align-items: center; font-size: 15px; font-weight: bold; height: 50px; margin-top: 0; border-bottom: 2px solid #fff; box-sizing: border-box; }
    .favorite_list_con.off .favorite_title { border-color: #e7e9ea; }
    .favorite_title p { margin-left: 25px; line-height: 19px; box-sizing: border-box; }
    .favorite_title p span { display: inline-block; width: 3px; height: 20px; border-radius: 10px; background: #477fff; vertical-align: middle; margin-right: 10px; }
    .favorite_title .eqinfor03_btn { top: 10px; right: 30px; }

    .favorite_list_con.off .favorite_list_div { display: none; }
    .favorite_list_div { display: block; border-top: 1px solid #e7e9ea; padding: 0 10px; margin: 0 25px; box-sizing: border-box; }
    .favorite_list_div .btn_group { position: static; width: 100%; margin-top: 10px; }
    .favorite_people { display: inline-block; width: 190px; margin-right: 30px; position: relative; margin-top: 15px; }
    .favorite_people ul { border: 2px solid rgb(231, 233, 234); height: 200px; border-radius: 15px; text-align: center; display: inline-block; width: 100%; }
    .favorite_people ul li.title_order { border-bottom: 2px solid rgb(231, 233, 234); line-height: 30px; padding: 10px 0; text-align: center; font-size: 15px; font-weight: bold; }
    .favorite_people .del { opacity: 0; transition: 0.1s; background: #ff6363; background-image: url('../../assets/images/contents/btn_close_fff.png'); background-repeat: no-repeat; background-position: center; background-size: 30px; font-size: 0; position: absolute; right: -13px; top: -10px; width: 30px; height: 30px; border-radius: 50%; }
    .favorite_people ul:hover .del { opacity: 1; }
    .favorite_people ul li.photo { padding: 20px 0; }
    .favorite_people ul li.photo p { overflow: hidden; height: 50px; width: 50px; border-radius: 30px; margin: 0 auto; }
    .favorite_people ul li.name { font-size: 15px; font-weight: bold; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }
    .favorite_people ul li.position { padding: 10px 0; font-size: 15px; }

    /* 수신참조 */
    .favorite_reference { margin-top: 10px; }
    .favorite_reference .referenceTit { font-size: 14px; font-weight: bold; height: 40px; line-height: 40px; opacity: .7; }
    .reference_list ul { display: flex; width: 100%; flex-wrap: wrap; gap: 20px; }
    .reference_layout { display: flex; align-items: center; position: relative; }
    .reference_layout .profile { overflow: hidden; height: 40px; width: 40px; border-radius: 50%; }
    .reference_layout .team_text { margin-left: 10px; margin-top: 6px; width: 120px; }
    .reference_layout .team_text p { line-height: 18px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .reference_layout .team_text p.myname { font-size: 13px; font-weight: bold; }
    .reference_layout .team_text p.teamname { font-size: 12px; color: rgb(187, 187, 187); }
    .reference_list ul li:hover .reference_layout { opacity: .3; }
    .approver-remove-btn { display: none; position: absolute; right: 10px; top: 10px; height: 25px; width: 25px; font-size: 0; background-image: url('../../assets/images/contents/ic_close_new.png'); background-size: cover; background-repeat: no-repeat; }
    .reference_list ul li:hover .approver-remove-btn { display: block; }
    .favoriteCon { overflow-y : auto; }

</style>