<template>
    <div id="approver_select_modal" class="modal">
        <table style="width:100%;padding:0;border:0;border-spacing:0px;border-collapse:collapse;">
            <thead>
                <tr style="line-height:60px;border-bottom:1px solid #e7e9ea;font-size:18px;text-align:left;">
                    <th colspan="3" style="padding:15px 0 0 30px;width:70%;color:rgb(083, 084, 111)">
                        {{ approver_select_modal_info.is_approver == true ? (approver_select_modal_info.is_self_approve == true ? '자가결재자 선택' : "결재자 선택") : "수신참조 선택" }}
                    </th>
                    <th style="padding:15px 0 0 20px;border-left:1px solid #e7e9ea;color:rgb(083, 084, 111)">
                        {{ approver_select_modal_info.is_approver == true ? (approver_select_modal_info.is_self_approve == true ? '선택된 자가결재자' : "선택된 결재자") : "선택된 수신참조" }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="width:30%;height:57px;border-right:1px solid #e7e9ea;border-bottom:1px solid #e7e9ea;">
                       <div class="title_Chk first" v-if="approver_select_modal_info.is_default_setting == true">
                           <a href="#" class="titleLink" :class="{ on : tab == 'EMPLOYEE' }" @click.prevent="tab = 'EMPLOYEE'" v-if="approver_select_modal_info.is_self_approve == false">직원 선택</a>
                           <a href="#" class="titleLink" :class="{ on : tab == 'POSITION' }" @click.prevent="tab = 'POSITION'" v-if="approver_select_modal_info.is_self_approve == false">직급 선택</a>
                           <a href="#" class="titleLink" v-if="approver_select_modal_info.is_self_approve == true" style="padding-left : 30px; opacity : 1; text-align : left; cursor : default;" @click.prevent="() => {}">부서</a>
                       </div>
                       <div class="title_Chk first" v-else>
                           <p class="titleLink">부서</p>
                       </div>
                    </td>
                    <td colspan="2" class="team_chk">
                        <div class="title_Chk" style="position: relative;">
                            <span style="display: inline-block;font-size: 14px;">{{ selected_department.dept_id == -1 ? '전체' : getDepartmentName(selected_department.dept_id) }}</span>
                            <p style="display: inline-block;position: absolute;top: 17px;right: 20px;"><span :style="{ color : getSelectedCheck() == 0 ? 'rgb(210, 211, 217)' : '#477fff' }">{{ getSelectedCheck() }}</span> / {{ tab == 'EMPLOYEE' ? computedEmployee.length : position.length }}</p>
                        </div>
                    </td>
                    <td class="team_chk" id="select_bg" v-if="approver_select_modal_info.selected != null" :style="approver_select_modal_info.selected == null || approver_select_modal_info.selected.length < 1 ? `` : `background : none;`">
                        <div class="title_Chk" style="position: relative;">
                            <span style="display: inline-block;font-size: 14px;">{{ approver_select_modal_info.selected.length }}명</span>
                            <a href="#" style="display: inline-block;position: absolute;top: 17px;right: 20px;font-size: 14px;" @click.prevent="deleteAllSelected">모두삭제</a>                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3" v-show="tab == 'POSITION'" style="border-right: 1px solid #e7e9ea;">
                        <!-- 스크롤 -->
                        <div class="posDiv" style="height: 322px;">
                            <ul class="posUl">
                                <li :key="pos.pos_id" v-for="(pos, index) in position">
                                    <input type="checkbox" :id="`select_modal_check_${pos.pos_id}`" :checked="approver_select_modal_info.selected != null && approver_select_modal_info.selected.filter(item => item.approver_type == 'POSITION' && item.pos_id == pos.pos_id).length > 0" @change="changeSelectedPosition($event, pos)"/>
                                    <label :for="`select_modal_check_${pos.pos_id}`"></label>
                                    <p class="profile" :class="`dc${index % 10}`">{{ index + 1 }}</p>
                                    <p class="pos">{{ pos.pos_name }}</p>
                                    <div class="autoSelect" v-if="approver_select_modal_info.selected != null && approver_select_modal_info.selected.filter(item => item.approver_type == 'POSITION' && item.pos_id == pos.pos_id).length > 0">
                                        <p class="descript">상위부서 포함</p>
                                        <label :for="`select_modal_switch_${pos.pos_id}`" class="switch">
                                            <input :id="`select_modal_switch_${pos.pos_id}`" type="checkbox" :checked="approver_select_modal_info.selected != null && approver_select_modal_info.selected.filter(item => item.approver_type == 'POSITION' && item.pos_id == pos.pos_id).length > 0 && approver_select_modal_info.selected.filter(item => item.approver_type == 'POSITION' && item.pos_id == pos.pos_id)[0].include_parent == true" @change="changeSelectedPositionIncludeParent($event, pos)">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </li>
                                <!-- <li>
                                    <input type="checkbox" class="" />
                                    <label for=""></label>
                                    <p class="profile dc0">1</p>
                                    <p class="pos">대표</p>
                                    <div class="autoSelect">
                                        <p class="descript">상위부서 포함</p>
                                        <label class="switch">
                                            <input type="checkbox">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <input type="checkbox" class="" />
                                    <label for=""></label>
                                    <p class="profile dc1">2</p>
                                    <p class="pos">부장</p>
                                    <div class="autoSelect">
                                        <p class="descript">상위부서 포함</p>
                                        <label class="switch">
                                            <input type="checkbox">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <input type="checkbox" class="" />
                                    <label for=""></label>
                                    <p class="profile dc2">3</p>
                                    <p class="pos">차장</p>
                                    <div class="autoSelect">
                                        <p class="descript">상위부서 포함</p>
                                        <label class="switch">
                                            <input type="checkbox">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <input type="checkbox" class="" />
                                    <label for=""></label>
                                    <p class="profile dc3">4</p>
                                    <p class="pos">팀장</p>
                                    <div class="autoSelect">
                                        <p class="descript">상위부서 포함</p>
                                        <label class="switch">
                                            <input type="checkbox">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <input type="checkbox" class="" />
                                    <label for=""></label>
                                    <p class="profile dc4">5</p>
                                    <p class="pos">대표</p>
                                    <div class="autoSelect">
                                        <p class="descript">상위부서 포함</p>
                                        <label class="switch">
                                            <input type="checkbox">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <input type="checkbox" class="" />
                                    <label for=""></label>
                                    <p class="profile dc5">6</p>
                                    <p class="pos">부장</p>
                                    <div class="autoSelect">
                                        <p class="descript">상위부서 포함</p>
                                        <label class="switch">
                                            <input type="checkbox">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <input type="checkbox" class="" />
                                    <label for=""></label>
                                    <p class="profile dc6">7</p>
                                    <p class="pos">차장</p>
                                    <div class="autoSelect">
                                        <p class="descript">상위부서 포함</p>
                                        <label class="switch">
                                            <input type="checkbox">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <input type="checkbox" class="" />
                                    <label for=""></label>
                                    <p class="profile dc7">8</p>
                                    <p class="pos">팀장</p>
                                    <div class="autoSelect">
                                        <p class="descript">상위부서 포함</p>
                                        <label class="switch">
                                            <input type="checkbox">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                    </td>
                    <td style="width:30%;height:322px;border-right:1px solid #e7e9ea;border-bottom:1px solid #e7e9ea;" v-show="tab == 'EMPLOYEE'">
                        <div id="wrapper">
			                <!-- 메인  -->
                            <div class="chk" :class="{ click_on : selected_department.dept_id == -1 }" @click="select({ dept_id : -1 })">
                                <p>전체</p>
                            </div>

                            <!-- <div class="chk" :class="{ click_on : computedOrganization != null && selected_department.dept_id == computedOrganization.dept_id }" @click="select(computedOrganization)">
                                <p>{{ computedOrganization != null ? computedOrganization.dept_name : '' }}</p>
                            </div> -->

                            <OrganizationRecursion 
                                :level="2"
                                :content_type="'p'" 
                                :departments="computedOrganization.departments"
                                :selected_department="selected_department"
                                :department_closed_map="department_closed_map"
                                :department_height_map="department_height_map" 
                                @select="select"
                                v-if="computedOrganization != null" />

			                <!-- 첫번쩨 ul (그룹 리스트) -->
                            <ul class="sub1" v-if="false">
                                <li class="fList">
                                    <!-- 상단 삐져나오는 선 가리개 -->
                                    <span class="topBlank"></span>
                                    <!-- 하단 삐져나오는 선 가리개 -->
                                    <span class="btmBlank"></span>
                                    <!-- 길이에 따라 길어지는 세로 선 -->
                                    <span class="vertiLine"></span>
                                    <!-- 화살표 버튼 -->
                                    <input type="button" class="arw" />
                                    <!-- cld : checkbox list div -->
                                    <div class="cld">
                                        <input type="checkbox" id="teamCheck1" />
                                        <label for="teamCheck1"><span>피닉스</span></label>
                                    </div>

                                    <!-- 두번째 ul (팀 리스트) -->
                                    <ul class="sub2">
                                        <li>
                                            <!-- horiLine 가로선 -->
                                            <span class="horiLine"></span>
                                            <div class="cld">
                                                <input type="checkbox" id="teamCheck1_1" />
                                                <label for="teamCheck1_1"><span>개발</span></label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="horiLine"></span>
                                            <div class="cld">
                                                <input type="checkbox" id="teamCheck1_2" />
                                                <label for="teamCheck1_2"><span>교육</span></label>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                <li class="fList">
                                    <!-- 상단 삐져나오는 선 가리개 -->
                                    <span class="topBlank"></span>
                                    <!-- 하단 삐져나오는 선 가리개 -->
                                    <span class="btmBlank"></span>
                                    <!-- 길이에 따라 길어지는 세로 선 -->
                                    <span class="vertiLine"></span>
                                    <!-- 화살표 버튼 -->
                                    <input type="button" class="arw" />
                                    <!-- cld : checkbox list div -->
                                    <div class="cld">
                                        <input type="checkbox" id="teamCheck2"/>
                                        <label for="teamCheck2"><span>IRS</span></label>
                                    </div>
                                    <ul class="sub2">
                                        <li>
                                            <!-- horiLine 가로선 -->
                                            <span class="horiLine"></span>
                                            <div class="cld">
                                                <input type="checkbox" id="teamCheck2_1"/>
                                                <label for="teamCheck2_1"><span>개발</span></label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="horiLine"></span>
                                            <div class="cld">
                                                <input type="checkbox"  id="teamCheck2_2"/>
                                                <label for="teamCheck2_2"><span>심사</span></label>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                <li class="fList">
                                    <!-- 상단 삐져나오는 선 가리개 -->
                                    <span class="topBlank"></span>
                                    <!-- 하단 삐져나오는 선 가리개 -->
                                    <span class="btmBlank"></span>
                                    <!-- 길이에 따라 길어지는 세로 선 -->
                                    <span class="vertiLine"></span>
                                    <!-- 화살표 버튼 -->
                                    <input type="button" class="arw" />
                                    <!-- cld : checkbox list div -->
                                    <div class="cld">
                                        <input type="checkbox" id="teamCheck3"/>
                                        <label for="teamCheck3"><span>모바일</span></label>
                                    </div>
                                    <!-- 두번째 ul (팀 리스트) -->
                                    <ul class="sub2">
                                        <li>
                                            <!-- horiLine 가로선 -->
                                            <span class="horiLine"></span>
                                            <div class="cld">
                                                <input type="checkbox" id="teamCheck3_1"/>
                                                <label for="teamCheck3_1"><span>개발</span></label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="horiLine"></span>
                                            <div class="cld">
                                                <input type="checkbox" id="teamCheck3_2"/>
                                                <label for="teamCheck3_2"><span>디자인</span></label>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                <li class="fList">
                                    <div class="cld">
                                        <input type="checkbox" id="teamCheck4"/>
                                        <label for="teamCheck4"><span>영업</span></label>
                                    </div>
                                </li>
                                <li class="fList">
                                    <div class="cld">
                                        <input type="checkbox" id="teamCheck5"/>
                                        <label for="teamCheck5"><span>경영지원</span></label>
                                    </div>
                                </li>
                            </ul>

                            <div class="chk" :class="{ click_on : computedOrganization != null && selected_department.dept_id == computedOrganization.dept_id }" @click="select(computedOrganization)">
                                <p style="font-size: 13px; font-weight: bold; left: 55px; color: #477fff; opacity: .8;">미배정</p>
                            </div>
		                </div>  
                    </td>
                    <td colspan="2" class="team_chk" v-show="tab == 'EMPLOYEE'">
                        <div style="width: 318px; height: 322px; overflow: auto; white-space: nowrap;"> 
                            <ul class="teamlist" style="height : auto; overflow : hidden; display: inline-block;">

                                <li :key="employee.user_id" v-for="employee of computedEmployee">
                                    <input type="checkbox" :id="`teamChk${employee.user_id}`" @change="changeSelected($event, employee)" 
                                           :checked="approver_select_modal_info.selected != null && approver_select_modal_info.selected.filter(item => item.user_id == employee.user_id).length > 0"/>
                                    <label :for="`teamChk${employee.user_id}`" style="margin-top:5px;margin-right: 15px;"></label>
                                    <p class="profile">
                                        <img :src="getProfileImage(employee)" style="width: 35px;" @error="userImageError($event)">
                                    </p>
                                    <div class="team_text">
                                        <p class="myname">{{ employee.user_name }} ({{ getPositionName(employee.pos_id) }})</p>
                                        <p class="teamname">{{ getDepartmentName(employee.dept_id) }}</p>
                                    </div>
                                </li>

                                <!-- <li style="">
                                    <input type="checkbox" id="teamChk01"/>
                                    <label for="teamChk01" style="margin-top:7px;margin-right: 20px;"></label>
                                    <p class="profile"><img src="../../../assets/images/contents/ic_approval_user.png" style="width: 35px;" alt="" ></p>
                                    <div class="team_text" style="">
                                        <span>관리</span>
                                        <p class="myname">김성림 (팀장)</p>
                                        <p class="teamname">개발 / 모바일</p>
                                    </div>
                                </li>
                                <li style="">
                                    <input type="checkbox" id="teamChk02"/>
                                    <label for="teamChk02" style="margin-top:7px;margin-right: 20px;"></label>
                                    <p class="profile"><img src="../../../assets/images/contents/ic_approval_user.png" style="width: 35px;" alt="" ></p>
                                    <div class="team_text" style="">
                                        <p class="myname">심광용 (과장)</p>
                                        <p class="teamname">개발 / 모바일</p>
                                    </div>
                                </li>
                                <li style="">
                                    <input type="checkbox" id="teamChk03"/>
                                    <label for="teamChk03" style="margin-top:7px;margin-right: 20px;"></label>
                                    <p class="profile"><img src="../../../assets/images/contents/ic_approval_user.png" style="width: 35px;" alt="" ></p>
                                    <div class="team_text" style="">
                                        <p class="myname">임창정 (과장)</p>
                                        <p class="teamname">개발 / 모바일</p>
                                    </div>
                                </li>
                                <li style="">
                                    <input type="checkbox" id="teamChk04"/>
                                    <label for="teamChk04" style="margin-top:7px;margin-right: 20px;"></label>
                                    <p class="profile"><img src="../../../assets/images/contents/ic_approval_user.png" style="width: 35px;" alt="" ></p>
                                    <div class="team_text" style="">
                                        <p class="myname">윤연지 (대리)</p>
                                        <p class="teamname">개발 / 모바일</p>
                                    </div>
                                </li>
                                <li style="">
                                    <input type="checkbox" id="teamChk05"/>
                                    <label for="teamChk05" style="margin-top:7px;margin-right: 20px;"></label>
                                    <p class="profile"><img src="../../../assets/images/contents/ic_approval_user.png" style="width: 35px;" alt="" ></p>
                                    <div class="team_text" style="">
                                        <p class="myname">황인성 (사원)</p>
                                        <p class="teamname">개발 / 모바일</p>
                                    </div>
                                </li>
                                <li style="">
                                    <input type="checkbox" id="teamChk06"/>
                                    <label for="teamChk06" style="margin-top:7px;margin-right: 20px;"></label>
                                    <p class="profile"><img src="../../../assets/images/contents/ic_approval_user.png" style="width: 35px;" alt="" ></p>
                                    <div class="team_text" style="">
                                        <p class="myname">이성재 (사원)</p>
                                        <p class="teamname">개발 / 모바일</p>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                    </td>
                    <td class="team_chk" id="select_bg" v-if="computedSelected != null" :style="computedSelected == null || computedSelected.length < 1 ? `` : `background : none;`">
                        <div style="width : 241px; height : 323px; overflow : auto; white-space : nowrap;"> 
                            <ul class="select_member" :class="{ ref : approver_select_modal_info.is_approver == false }" style="height : auto; overflow : hidden; display: inline-block;">

                                <!-- <li class="btn_over byPos">
                                    <div class="order_updown">
                                        <a href="#" style="border-bottom: 1px solid #e7e9ea;"></a>
                                        <a href="#"></a>
                                    </div>
                                    <div class="right_team_layout">
                                        <p class="turnNum">1</p>
                                        <p class="profile dc4">2</p>
                                        <div class="team_text">
                                            <p class="myname">과장</p>
                                            <p class="descript flot_left_layout">상위부서 미포함</p>
                                        </div>
                                    </div>
                                    <a href="#" class="approver-remove-btn">삭제</a>
                                </li> -->

                                <li class="btn_over" :class="{ byPos : emp.approver_type == 'POSITION' }" :key="`${emp.seq}-${emp.user_id}`" v-for="(emp, index) in computedSelected">
                                    <div class="order_updown" v-if="(emp.approver_type == null || emp.approver_type == 'EMPLOYEE' && approver_select_modal_info.is_approver == true) && approver_select_modal_info.is_self_approve == false">
                                        <a href="#" style="border-bottom: 1px solid #e7e9ea;" :disabled="index == 0" @click.prevent="up(emp)"></a>
                                        <a href="#" :disabled="index == (computedSelected.length - 1)" @click.prevent="down(emp)"></a>
                                    </div>
                                    <div class="right_team_layout" v-if="emp.approver_type == null || emp.approver_type == 'EMPLOYEE'">
                                        <!-- 결재자 순번 표시 추가수정 -->
                                        <p class="turnNum" v-if="!(approver_select_modal_info.is_approver == true && approver_select_modal_info.is_self_approve == true)">{{ index + 1 }}</p>
                                        <p class="profile">
                                            <img :src="getProfileImage(emp)" style="width: 40px;" @error="userImageError($event)">
                                        </p>
                                        <div class="team_text">
                                            <p class="myname">{{ emp.user_name }} ({{ getPositionName(emp.pos_id) }})</p>
                                            <p class="teamname">{{ getDepartmentName(emp.dept_id) }}</p>
                                        </div>
                                    </div>
                                    <div class="order_updown" v-if="emp.approver_type == 'POSITION' && approver_select_modal_info.is_approver == true">
                                        <a href="#" style="border-bottom: 1px solid #e7e9ea;" @click.prevent="up(emp)"></a>
                                        <a href="#" @click.prevent="down(emp)"></a>
                                    </div>
                                    <div class="right_team_layout" v-if="emp.approver_type == 'POSITION'">
                                        <!-- 결재자 순번 표시 추가수정 -->
                                        <p class="turnNum" v-if="!(approver_select_modal_info.is_approver == true && approver_select_modal_info.is_self_approve == true)">{{ index + 1 }}</p>
                                        <p class="profile" :class="`dc${emp.position_seq % 10}`">{{ emp.position_seq + 1 }}</p>
                                        <div class="team_text">
                                            <p class="myname">{{ emp.pos_name }}</p>
                                            <p class="descript flot_left_layout">{{ emp.include_parent ? '상위부서 포함' : '상위부서 미포함' }} </p>
                                        </div>
                                    </div>
                                    <a href="#" class="approver-remove-btn" @click.prevent="remove(emp)">삭제</a>
                                </li>
                                
                                <!-- 직급일 시  -->
                                <!-- <li class="btn_over byPos">
                                    <div class="order_updown" v-if="approver_select_modal_info.is_approver == true">
                                        <a href="#" style="border-bottom: 1px solid #e7e9ea;" @click.prevent="up(emp)"></a>
                                        <a href="#" @click.prevent="down(emp)"></a>
                                    </div>
                                    <div class="right_team_layout">
                                        <p class="profile dc1">1</p>
                                        <div class="team_text">
                                            <p class="myname">대표</p>
                                            <p class="descript flot_left_layout">상위부서 미포함</p>
                                        </div>
                                    </div>
                                </li> -->

                                <!-- <li class="btn_over">
                                    <p class="profile"><img src="../../../assets/images/contents/ic_approval_user.png" style="width: 45px;" alt="" ></p>
                                    <div class="team_text" style="">
                                        <p class="myname">심왕용 (과장)</p>
                                        <p class="teamname">개발 / 모바일</p>
                                    </div>  
                                    <div class="order_updown" style="">
                                        <a href="#" style="border-bottom: 1px solid #e7e9ea;"></a>
                                        <a href="#"></a>
                                    </div>
                                </li>
                                <li class="btn_over" style="">
                                    <p class="profile"><img src="../../../assets/images/contents/ic_approval_user.png" style="width: 45px;" alt="" ></p>
                                    <div class="team_text" style="">
                                        <p class="myname">심왕용 (과장)</p>
                                        <p class="teamname">개발 / 모바일</p>
                                    </div>  
                                    <div class="order_updown" style="">
                                        <a href="#" style="border-bottom: 1px solid #e7e9ea;"></a>
                                        <a href="#"></a>
                                    </div>
                                </li>
                                <li class="btn_over" style="">
                                    <p class="profile"><img src="../../../assets/images/contents/ic_approval_user.png" style="width: 45px;" alt="" ></p>
                                    <span>관리</span>
                                    <div class="team_text" style="">
                                        <p class="myname">심왕용 (과장)</p>
                                        <p class="teamname">개발 / 모바일</p>
                                    </div> 
                                    <div class="order_updown" style="">
                                        <a href="#" style="border-bottom: 1px solid #e7e9ea;"></a>
                                        <a href="#"></a>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                    </td>
                </tr>
                <tr style="line-height: 70px;">
                    <td colspan="4">
                        <a href="#" class="btn_modal" @click.prevent="close">취소</a>
                        <a href="#" class="btn_modal" @click.prevent="save">저장</a>
                    </td>
                    <!-- <td colspan="2"><a href="#" class="btn_modal" @click.prevent="close">취소</a></td> -->
                    <!-- <td colspan="2" style="border-left:1px solid #e7e9ea;"><a href="#" class="btn_modal" @click.prevent="save">저장</a></td> -->
                </tr>
            </tbody>
        </table>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const ApprovalInfo = namespace('ApprovalInfo');

import { approval_enum, approval_interface, approval_modal_interface } from '@/model/approval';

import OrganizationRecursion from '@/components/organization/OrganizationRecursion.vue';
import { hodu_color } from '@/common/color';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        OrganizationRecursion
    },
})
export default class ApproverSelectModal extends Mixins(VueHoduCommon) {

    /**
     * 부서 정보
     */
    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) {
                department['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }

    /**
     * 직원 정보
     */
    get computedEmployee() : any[] {
        let employees = JSON.parse(JSON.stringify(this.employees));

        for( const employee of employees ) employee.approver_type = 'EMPLOYEE';

        employees.sort((o1, o2) => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            const o1_pos_seq = this.getPositionSeq(o1.pos_id);
            const o2_pos_seq = this.getPositionSeq(o2.pos_id);

            // 같은 직급은 이름순
            if( o1_pos_seq == o2_pos_seq ) {
                if( o1_name > o2_name ) return 1;
                else if( o1_name < o2_name ) return -1;
                return 0
            }
            else if( o1_pos_seq > o2_pos_seq ) return  1;
            else if( o1_pos_seq < o2_pos_seq ) return -1;

            return 0;
        });

        if( this.approver_select_modal_info.is_deputy == true ) {
            return employees.filter(item => item.pos_id != null && item.dept_id != null && (this.approver_select_modal_info.is_default_setting == true ? true : (item.user_id != this.approver_select_modal_info.deputy_user_id)));
        }

        return employees.filter(item => item.pos_id != null && item.dept_id != null && (this.approver_select_modal_info.is_default_setting == true ? true : (item.user_id != this.user_id)));
    }

    /**
     * 선택된 것
     */
    get computedSelected() : any[]  {

        let selected : any[] = [];

        if( this.approver_select_modal_info.selected == null ) {
            return [];
        }

        if( this.approver_select_modal_info.is_self_approve == false ) {
            selected = this.approver_select_modal_info.selected;
        }
        else {
            // 직급, 가나다순
            selected = this.approver_select_modal_info.selected.sort((o1, o2) : number => {

                const o1_name = o1.user_name;
                const o2_name = o2.user_name;

                const o1_pos_seq = this.getPositionSeq(o1.pos_id);
                const o2_pos_seq = this.getPositionSeq(o2.pos_id);

                // 같은 직급은 이름순
                if( o1_pos_seq == o2_pos_seq ) {
                    if( o1_name > o2_name ) return 1;
                    else if( o1_name < o2_name ) return -1;
                    return 0
                }
                else if( o1_pos_seq > o2_pos_seq ) return  1;
                else if( o1_pos_seq < o2_pos_seq ) return -1;
                
                return 0;
            });
        }

        return selected;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State approver_select_modal_info !: approval_modal_interface.ApproverSelectModalInfo;

    /**
     * @ModalInfo.Action 
     */
    @ModalInfo.Action doSetApproverSelectModalInfo ?: (params : approval_modal_interface.ApproverSelectModalInfo) => void;

    /**
     * @ApprovalInfo.State
     */
    @ApprovalInfo.State approval !: approval_interface.t_approval;

    departments : any[] = [];
    selected_department : any = { dept_id : -1 }; 

    department_closed_map : Map<number, boolean> = new Map();
    department_height_map : Map<number, number> = new Map();

    position : any[] = [];
    employees : any[] = [];

    tab : string = "EMPLOYEE"; // EMPLOYEE, POSITION
    
    mounted() : void {
        // 버튼 클릭시 라인과 팀 보이기
        $(".arw").click(function(){
            // 리스트 인덱스 가져오기
            var getIndex = $(this).parent().index();
            
            // 버튼 클릭시 라인과 팀 보이기
            $(".fList").eq(getIndex).toggleClass("showTeam");

        });
        
        this.getTotalOrganizationData();
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganizationPosition();
            await this.getOrganization();
            await this.getOrganizationEmp();
        } catch(e) {
            alert("조직도 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            // map에 담겨있는 vertical_height 정보로 업데이트
            for( const department of response.data.data.dept_info ) {
                const vertical_height = this.department_height_map.get(department.dept_id);
                if( vertical_height != null ) {
                    department.vertical_height = vertical_height;
                }
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            if( this.selected_department == null || this.selected_department.dept_id == -1 ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                    throw new Error("조직도 전체 직원 조회 중 오류 발생");
                }

                this.employees.splice(0, this.employees.length);
                this.employees = this.employees.concat(response.data.data.emp_info);
                return;
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/${this.selected_department.dept_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 부서 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 부서 선택
     */
    async select(department : any) : Promise<void> {
        this.selected_department = department;
        this.$forceUpdate();
        this.hodu_show_indicator();
        await this.getTotalOrganizationData();
        this.hodu_hide_indicator();
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 부서 이름 반환
     * level 1 : 최상위 부서 이름 반환
     * level 2 : 최상위 부서 바로 아래의 부서 이름 하나만 반환
     * level n (n > 2) : level 2 / level 3 / level 4 ... 
     */
    getDepartmentName(dept_id : number) : string {
        try {

            if( dept_id == 0 ) return "미배정";
            
            let dept_name = "";
            const target = this.departments.filter(item => item.dept_id == dept_id)[0];

            let target_departments : any[] = [];
            for( const department of this.departments ) {
                if( target.dept_id_array.indexOf(department.dept_id) > -1 ) target_departments.push(department);
            }

            target_departments.sort((o1, o2) : number => {
                const o1_length = o1.dept_id_array.length;
                const o2_length = o2.dept_id_array.length;

                if( o1_length > o2_length ) return 1;
                else if( o1_length < o2_length ) return -1;
                return 0;
            });

            for( const department of target_departments ) {
                if( target_departments.length >= 2 && target_departments.indexOf(department) == 0 ) continue;

                if( dept_name.length > 0 ) dept_name += " / ";
                dept_name += department.dept_name;
            }
        
            return dept_name;
        } catch(e) {
            console.log(e);
            return "-";
        }
    }

    /**
     * 직급 이름 반환
     */
    getPositionName(pos_id : number) : string {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return "-";
            return target[0].pos_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 선택된 부서에서 선택된 사람의 수 또는 선택된 직급 수
     */
    getSelectedCheck() : number {
        if( this.approver_select_modal_info.selected == null ) return 0;

        const ids : number[] = [];

        if( this.tab == 'EMPLOYEE' ) {
            for( const selected_emp of this.approver_select_modal_info.selected ) {
                for( const emp of this.computedEmployee ) {
                    if( selected_emp.user_id == emp.user_id ) ids.push(selected_emp.user_id);
                }
            }
        }

        else if( this.tab == 'POSITION' ) {
            for( const selected_emp of this.approver_select_modal_info.selected ) {
                for( const pos of this.position ) {
                    if( selected_emp.approver_type == 'POSITION' && selected_emp.pos_id == pos.pos_id ) ids.push(selected_emp.user_id);
                }
            }
        }

        return ids.length;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        event.target.src = require('@/assets/images/contents/ic_approval_user.png');
    }

    /**
     * 사람 선택
     */
    changeSelected(event, employee) : void {
        if( this.approver_select_modal_info.selected == null ) return;
        
        try {
            const checked : boolean = event.target.checked;
            if( checked == true && this.approver_select_modal_info.selected.filter(item => item.user_id == employee.user_id).length == 0 ) {
                this.approver_select_modal_info.selected.push(employee);
            }
            else if( checked == false ) {
                while( this.approver_select_modal_info.selected.filter(item => item.user_id == employee.user_id).length > 0 ) {
                    const target = this.approver_select_modal_info.selected.filter(item => item.user_id == employee.user_id)[0];
                    this.approver_select_modal_info.selected.splice(this.approver_select_modal_info.selected.indexOf(target as any), 1);
                }
            }
        } catch(e) {
            console.log(e);
        }

        this.$forceUpdate();
    }

    /**
     * 직급 선택
     */
    changeSelectedPosition(event, position) : void {
        if( this.approver_select_modal_info.selected == null ) return;

        try {
            const checked : boolean = event.target.checked;
            if( checked == true && this.approver_select_modal_info.selected.filter(item => item.approver_type == 'POSITION' && item.pos_id == position.pos_id).length == 0 ) {
                this.approver_select_modal_info.selected.push({
                    "user_id" : 0,
                    "seq" : this.approver_select_modal_info.selected.length + 1,
                    "approver_type" : "POSITION",
                    "position_seq" : this.position.indexOf(position),
                    "user_name" : "",
                    "dept_name" : "",
                    "pos_name" : this.getPositionName(position.pos_id),
                    "pos_id" : position.pos_id,
                    "dept_id" : 0,
                    "color" : hodu_color[`hodu_dc_${this.position.indexOf(position) % 10}`],
                    "include_parent" : false
                });
            }
            else if( checked == false && this.approver_select_modal_info.selected.filter(item => item.approver_type == 'POSITION' && item.pos_id == position.pos_id).length > 0 ) {
                const target = this.approver_select_modal_info.selected.filter(item => item.approver_type == 'POSITION' && item.pos_id == position.pos_id)[0];
                this.approver_select_modal_info.selected.splice(this.approver_select_modal_info.selected.indexOf(target as any), 1);
            }
        } catch(e) {
            console.log(e);
        }

        this.$forceUpdate();
    }

    /**
     * 상위부서 포함 여부 변경
     */
    changeSelectedPositionIncludeParent(event, position) : void {

        if( this.approver_select_modal_info.selected == null ) return;

        try {
            const checked : boolean = event.target.checked;

            if( !(this.approver_select_modal_info.selected.filter(item => item.approver_type == 'POSITION' && item.pos_id == position.pos_id).length > 0) ) {
                return;
            }

            const target = this.approver_select_modal_info.selected.filter(item => item.approver_type == 'POSITION' && item.pos_id == position.pos_id)[0];
            target.include_parent = checked;

        } catch(e) {
            console.log(e);
        }

        this.$forceUpdate();
    }

    /**
     * 모두삭제
     */
    deleteAllSelected() : void {
        this.approver_select_modal_info?.selected?.splice(0, this.approver_select_modal_info.selected.length);
        this.$forceUpdate();
    }

    /**
     * 순서 앞으로 당기기
     */
    up(select) : void {
        if( this.approver_select_modal_info.selected == null ) return;
        const index = this.approver_select_modal_info.selected.indexOf(select);
        const target_index = index - 1;
        const target_seq   = select.seq;
        
        const target = this.approver_select_modal_info.selected[target_index];
        target.seq = target_seq;
        select.seq = select.seq - 1;

        this.approver_select_modal_info.selected[target_index] = select;
        this.approver_select_modal_info.selected[index] = target;

        this.$forceUpdate();
    } 

    /**
     * 순서 뒤로 밀기
     */
    down(select) : void {
        if( this.approver_select_modal_info.selected == null ) return;
        const index = this.approver_select_modal_info.selected.indexOf(select);  
        const target_index = index + 1;
        const target_seq   = select.seq;

        const target = this.approver_select_modal_info.selected[target_index];
        target.seq = target_seq;
        select.seq = select.seq + 1;

        this.approver_select_modal_info.selected[target_index] = select;
        this.approver_select_modal_info.selected[index] = target;

        this.$forceUpdate();
    } 

    /**
     * 삭제
     */
    remove(employee) : void {

        if( this.approver_select_modal_info.selected == null ) return;

        if( employee.user_id > 0 ) {
            while( this.approver_select_modal_info.selected.filter(item => item.user_id == employee.user_id).length > 0 ) {
                const target = this.approver_select_modal_info.selected.filter(item => item.user_id == employee.user_id)[0];
                this.approver_select_modal_info.selected.splice(this.approver_select_modal_info.selected.indexOf(target as any), 1);                
            }
        }
        
        else if( employee.pos_id >= 0 ) {
            while( this.approver_select_modal_info.selected.filter(item => item.pos_id == employee.pos_id).length > 0 ) {
                const target = this.approver_select_modal_info.selected.filter(item => item.pos_id == employee.pos_id)[0];
                this.approver_select_modal_info.selected.splice(this.approver_select_modal_info.selected.indexOf(target as any), 1);
            }
        }

        this.$forceUpdate();
    }

    getProfileImage(approver) {
        if( approver.user_pic != null ) {
            return `/app_images/${approver.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((approver.user_id ? approver.user_id : 0) / 10000)}/${approver.user_id}.jpg`;
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetApproverSelectModalInfo?.({
            show_modal : false,
            is_approver : false,
            is_default_setting : false,
            is_self_approve : false,
        });
    }

    /**
     * 저장
     */
    save() : void {

        if( this.approver_select_modal_info.selected == null || this.approver_select_modal_info.selected.length < 1 ) {
            this.approver_select_modal_info.callback?.([]);
        }
        
        else {

            if( this.approver_select_modal_info.is_default_setting == true ) {
                const data : any[] = [];

                for( const approver of this.approver_select_modal_info.selected ) {
                    
                    const obj : any = {
                        "user_id" : approver.user_id,
                        "seq" : this.approver_select_modal_info.selected.indexOf(approver) + 1,
                        "user_name" : approver.user_name,
                        "dept_name" : this.getDepartmentName(approver.dept_id),
                        "pos_name" : this.getPositionName(approver.pos_id),
                        "pos_id" : approver.pos_id,
                        "dept_id" : approver.dept_id,
                        "approver_type" : approver.approver_type,
                    }

                    if( approver.approver_type == 'POSITION' ) obj.include_parent = approver.include_parent;
                    
                    data.push(obj);
                }

                this.approver_select_modal_info.callback?.(data);
                this.close();
                return;
            }

            const data : approval_interface.t_approval_receive_reference[] | approval_interface.t_approval_approver[] = [];
        
            if( this.approver_select_modal_info.is_approver == true ) {

                for( const approver of this.approver_select_modal_info.selected ) {
                    console.log(approver);
                    (data as approval_interface.t_approval_approver[]).push({
                        "rev" : this.approval.approval_rev,
                        "seq" : this.approver_select_modal_info.selected.indexOf(approver) + 1,
                        "state" : this.approver_select_modal_info.selected.indexOf(approver) == 0 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                        "comment" : "",
                        "user_id" : approver.user_id,
                        "pos_name" : this.getPositionName(approver.pos_id),
                        "dept_name" : this.getDepartmentName(approver.dept_id),
                        "user_name" : approver.user_name,
                        "pos_id" : approver.pos_id,
                        "dept_id" : approver.dept_id,
                        "user_pic" : approver.user_pic,
                    });
                }
            }
            else {
                for( const ref of this.approver_select_modal_info.selected ) {
                    console.log(ref);
                    (data as approval_interface.t_approval_receive_reference[]).push({
                        "user_id" : ref.user_id,
                        "pos_name" : this.getPositionName(ref.pos_id),
                        "dept_name" : this.getDepartmentName(ref.dept_id),
                        "user_name" : ref.user_name,
                        "pos_id" : ref.pos_id,
                        "dept_id" : ref.dept_id,
                        "user_pic" : ref.user_pic,
                    });
                }
            }

            this.approver_select_modal_info.callback?.(data);
        }

        this.close();
    }

}
</script>

<style>
    #approver_select_modal .click_on{z-index: 200;background: rgb(237, 242, 255, 0.8);}
    #approver_select_modal .chk { height: 40px; line-height: 40px; text-align: left;position: relative; cursor: pointer; font-weight: bold; }
    #approver_select_modal .cld { height: 35px; line-height: 35px;  text-align: left;position: relative; cursor: pointer; font-weight: bold;}
    /* 최하위 왼쪽 패딩 더 주기 */
    #approver_select_modal .sub2 .cld { padding-left: 0px; text-align: left;margin-left: 0;box-sizing: border-box;}
    /*체크박스 디자인*/
    #approver_select_modal .chk input{margin: 0;padding: 0;opacity: 0;width: 0;}
    #approver_select_modal .approval_content input[type='checkbox']:not(old)+label{margin-top: 6px;display: inline-block;padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid rgb(210, 211, 217);background: rgb(210, 211, 217) url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;margin-right: 8px;}
    #approver_select_modal .approval_content input[type='checkbox']:not(old):checked + label{background-color: #477fff !important;border-color: #477fff;}
    /*체크박스 텍스트*/
    #approver_select_modal .chk p{position: absolute;left: 35px;top: 0px;}
    #approver_select_modal .cld p{position: absolute;left: 55px;top: 0px;}
    #approver_select_modal .sub2 .cld p{position: absolute;left: 55px;top: 0px;}
    #approver_select_modal ul.level4 .sub2 .cld p {left: 40px !important;}

    /* 그룹과 팀 리스트에 적용 */
    #approver_select_modal ul li { transition:0.2s; position: relative;  }
    #approver_select_modal ul.teamlist li:hover { background: #f6f9fb; }
    /* 버튼 */
    #approver_select_modal .arw, #approver_select_modal .arw2, #approver_select_modal .arw3 { background: none;color: inherit;border: none;padding: 0;font: inherit;cursor: pointer;outline: inherit; z-index: 1000; position: absolute; left: 35px;top:13px; width: 11px; height: 11px; width: 0;height: 0; cursor: pointer;border-bottom: 6px solid transparent;border-top: 6px solid transparent;border-left: 8px solid #000;}
    #approver_select_modal .level4 > li > .arw { top : 6px; }
    #approver_select_modal .level4 > li.showTeam > .arw { top : 10px; }

    /* 상단 삐져나오는 라인 감추기 */
    #approver_select_modal .topBlank { z-index: 100; position: absolute; top: 0; left:35px;width: 15px; height: 14px; background: #fff; display: block; }
    /* 하단 삐져나오는 라인 감추기 */
    #approver_select_modal .btmBlank { z-index: 100; position: absolute; bottom: 1px;left:35px; width: 15px; height: 18px; background: #fff; display: block; }
    /* 세로 선 */
    #approver_select_modal .vertiLine { height: 100%; width: 1px; background: #dadbde; display: none; position: absolute; left: 40px; top: 0; }
    /* 가로 선 */
    #approver_select_modal .horiLine { width: 25px; height: 1px; background: #dadbde; display: none; position: absolute; top: 15px; left: 0px; }
    /* 최하위 그룹 디폴트로 감추기 */
    #approver_select_modal .sub2 { display: none;}
    /* 팀 보일때 선이랑 다같이 보이기 */
    #approver_select_modal .showTeam > .sub2 { display: block; padding-left: 40px;box-sizing: border-box;}
    #approver_select_modal .showTeam .horiLine, #approver_select_modal .showTeam .vertiLine { display: block; }
    #approver_select_modal .showTeam > input.arw, #approver_select_modal .showTeam > input.arw2, #approver_select_modal .showTeam > input.arw3 {border-left: 6px solid transparent;border-right: 6px solid transparent;border-top: 8px solid #000;}
    #approver_select_modal .circle{display: inline-block;width:10px;height:10px;margin-top: 10px;margin-left: 35px;border-radius: 50%;font-size: 0;border: 1px solid #40c8a0; background: #40c8a0;}
</style>

<style scoped>
    .modal { text-align:center; width: 800px; height:525px; border-radius:20px; left: 50%;margin-left: -400px;top: 50%;margin-top: -250px;position: absolute;background: #fff; overflow: hidden; }
    input.input_check[type='checkbox']:not(old) {background: url(/../img/ra_check_on.png) no-repeat 0 9px;margin: 0;padding: 0;opacity: 0;width: 0}
    input.input_check[type='checkbox']:not(old):checked+label {background: url(/../img/ra_check_on.png) no-repeat 0 9px;}
    input.input_check[type='checkbox']:not(old)+label {padding-left: 20px;padding-top: 9px;display: inline-block;color: #4e4e4e;text-align: left;height: 16px;line-height: 130%;vertical-align: top;    padding-bottom: 1px;}

    /* 필요없음 */
    #wrapper { padding: 0; padding-top : 0; width: 100%;height: 100%;box-sizing: border-box; overflow: auto; white-space: nowrap; }
    * {margin:0;padding:0;}

    label { padding-left: 10px }
    /* 필요없음 */
    .chk { height: 35px; line-height: 35px; text-align: left;position: relative;}
    .cld { height: 35px; line-height: 35px; padding-left: 10px; text-align: left;position: relative;margin-left: 20px;}
    /* 최하위 왼쪽 패딩 더 주기 */
    .sub2 .cld { padding-left: 20px; text-align: left;margin-left: 40px;}
    
    /*체크박스 디자인*/
    .chk input{margin: 0;padding: 0;opacity: 0;width: 0;}
    #approver_select_modal input[type='checkbox']:not(old)+label{margin-top: 6px;display: inline-block;padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid rgb(210, 211, 217);background: rgb(210, 211, 217) url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;margin-right: 8px;}
    #approver_select_modal input[type='checkbox']:not(old):checked + label{background-color: #477fff !important;border-color: #477fff;}
    #approver_select_modal label:after { content:''; display: block; position:absolute; left:0; top:0; width:100%; height:100%; }
    /*체크박스 텍스트*/
    .chk label span{position: absolute;left: 30px;top: 9px;}
    .cld label span{position: absolute;left: 40px;top: 9px;}
    .sub2 .cld label span{position: absolute;left: 50px;top: 9px;}

    /* 그룹과 팀 리스트에 적용 */
    ul li { position: relative;  }

    /* 버튼 */
    .arw { background: none;color: inherit;border: none;padding: 0;font: inherit;cursor: pointer;outline: inherit; z-index: 1000; position: absolute; left: 1px;top:10px; width: 11px; height: 11px; width: 0;height: 0;border-bottom: 6px solid transparent;border-top: 6px solid transparent;border-left: 8px solid #000; cursor: pointer; }

    /* 상단 삐져나오는 라인 감추기 */
    .topBlank { z-index: 100; position: absolute; top: 0; width: 15px; height: 14px; background: #fff; display: block; }
    /* 하단 삐져나오는 라인 감추기 */
    .btmBlank { z-index: 100; position: absolute; bottom: 0; width: 15px; height: 19px; background: #fff; display: block; }

    /* 세로 선 */
    .vertiLine { height: 100%; width: 1px; background: #dadbde; display: none; position: absolute; left: 6px; top: 0; }
    /* 가로 선 */
    .horiLine { width: 35px; height: 1px; background: #dadbde; display: none; position: absolute; top: 15px; left: 6px; }
    
    
    /* 최하위 그룹 디폴트로 감추기 */
    .sub2 { display: none; }

    /* 팀 보일때 선이랑 다같이 보이기 */
    .showTeam .sub2 { display: block; }
    .showTeam .horiLine, .showTeam .vertiLine { display: block; }
    .showTeam input.arw {border-left: 6px solid transparent;border-right: 6px solid transparent;border-top: 8px solid #000;}

    .team_chk{border-right:1px solid #e7e9ea;vertical-align: top; border-bottom: 1px solid #e7e9ea}
    .team_chk div.title_Chk{background:#fff;line-height: 20px;font-size: 14px;text-align: left;padding: 18px 30px;box-sizing: border-box;font-weight: bold; padding-left: 28px;}
    .team_chk ul.teamlist li{min-width: 258px; height:45px;text-align: left;padding: 13px 30px 0;border-bottom: 1px solid #e7e9ea;}
    .team_chk ul.teamlist li p.profile{overflow: hidden;height: 35px;width: 35px;border-radius: 18px;display: inline-block;vertical-align: top; margin-top:-2px;}
    .team_chk ul.teamlist li div.team_text{display: inline-block;margin-left: 10px;margin-top:1px}
    .team_chk ul.teamlist li div.team_text span{color:#fff;background: #477fff; padding:5px 8px;border-radius: 3px;margin-right:5px;width: 25px;text-align: center;display: inline-block;}
    .team_chk ul.teamlist li div.team_text p.myname{display: inline-block;font-size: 12px; font-weight: bold; }
    .team_chk ul.teamlist li div.team_text p.teamname{margin-top: 8px;font-size: 12px;color:rgb(187, 187, 187);}
    .team_chk ul.teamlist{height: 322px;overflow-y: auto;padding-bottom: 100px;}
    ::-webkit-scrollbar{width: 6px; height : 6px; }
    ::-webkit-scrollbar-track{background-color: transparent;}
    ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}
    .btn_modal{display: block;font-weight:bold;font-size: 15px;color:rgb(083, 084, 111); float : left; width : 50%; border-top : 1px solid #e7e9ea; box-sizing: border-box; }
    #select_bg{background: rgb(241, 243, 245, 0.5) url(../../../assets/images/contents/ic_team_selct.png) no-repeat center;background-size: 150px;opacity: 0.8;}

    .team_chk ul.select_member li{min-width : 241px; text-align: left; padding: 0; border-bottom: 1px solid #e7e9ea;background: #fff;box-sizing: border-box;height: 70px; transition: 0.1s;}
    /* .team_chk ul.select_member.ref li:hover { padding: 15px 30px; } */
    /* .team_chk ul.select_member li:hover{ padding-left: 57px; } */

    .team_chk ul.select_member li p.profile{overflow: hidden;height: 40px;width: 40px;border-radius: 50%;display: inline-block; position: absolute;top: 21%; margin-left: 15px;}
    .team_chk ul.select_member li div.team_text{display: inline-block;margin-left: 55px;margin-top: 6px;}
    .team_chk ul.select_member li div.team_text p.myname{font-size: 13px; margin-top: 8px; margin-left: 10px; font-weight: bold;}
    .team_chk ul.select_member li div.team_text p.teamname{margin-top: 10px; margin-left: 10px; font-size: 12px;color:rgb(187, 187, 187);}
    div.order_updown{ z-index : 1; display : none; position: absolute; float: left; height: 100%;box-sizing: border-box;width: 47px;border-right: 1px solid #e7e9ea; background: #fff;}
    div.order_updown a{height: 50% !important;box-sizing: border-box;padding: 0 !important;display: block;width: 47px;}
    div.order_updown a:first-child{background:  url(../../../assets/images/side_area/bt_close_b.png) no-repeat center center;}
    div.order_updown a:last-child {background:  url(../../../assets/images/side_area/bt_open_b.png) no-repeat center center;}
    div.order_updown a:first-child:hover {background:rgb(237, 242, 255) url(../../../assets/images/side_area/bt_close_b.png) no-repeat center center;}
    div.order_updown a:last-child:hover {background:rgb(237, 242, 255) url(../../../assets/images/side_area/bt_open_b.png) no-repeat center center;}

    .btn_over:hover .right_team_layout { opacity: 0.3;}
    .btn_over:hover div.order_updown { display : block; }
    
    .btn_over span{color:#fff;background: #477fff; padding:5px 8px;border-radius: 3px;margin-right:5px;width: 25px;text-align: center;display: inline-block;    margin-left: 62px;margin-bottom: -5px;}
    .btn_modal:hover{background: #f1f3f5;}



    /* 추가 사항 */
    .title_Chk.first { overflow: hidden; }
    .title_Chk.first a { transition: 0.1s; opacity:0.5; display:block; width: 50%; font-size: 14px; font-weight: bold; float: left; line-height: 54px; box-sizing: border-box; }
    .title_Chk.first a:hover { opacity: 0.8; }
    .title_Chk.first a.on { border-bottom: 3px solid #4e4e4e; opacity: 1; }
    .title_Chk.first p { width : 100%; font-size: 14px; font-weight: bold; line-height: 57px; box-sizing: border-box; text-align: left; padding-left : 30px; }
    .sub1 .arw:after { position:absolute; z-index: 10000; content: ''; display: block; width: 50px; height: 50px; background: red; }

    .posDiv { overflow: hidden; overflow-y: scroll; }
    .posUl { text-align: left; padding-bottom: 100px }
    .posUl li {border-bottom: 1px solid #e7e9ea; line-height: 55px; box-sizing: border-box; padding: 0 30px; padding-right: 0px; }
    .posUl li:hover { background: #f6f9fb;}
    .posUl li label { margin-top:17px !important }
    .posUl li * { display: inline-block; }
    .posUl li .profile { width: 30px; height: 30px; line-height: 30px; font-weight: bold; text-align: center; font-size: 13px; border-radius: 50%; background:#f1f3f5; margin: 0 20px 0 20px; color:#fff }
    .posUl li .pos { font-size: 13px; font-weight: bold; }

    .btn_over.byPos { box-sizing: border-box; }
    .btn_over.byPos .profile { font-size: 16px; text-align: center; line-height: 40px; font-weight: bold; border-radius: 50%; }
    .btn_over.byPos .myname { line-height: 30px; width: 120px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }

    .autoSelect { width: 30%; line-height: 25px; float: right; }
    .autoSelect p {margin-right: 0px; }
    .switch { margin-left: 10px; margin-right: 10px; float: none; }
    input:checked+.slider:before { -webkit-transform: translateX(35px); transform: translateX(35px);}

    .team_chk ul.select_member li.btn_over.byPos div.team_text p.myname { margin-left : 10px; margin-top: 0px;}
    .right_team_layout{position: relative; height : 100%; float: left; width: 75%; padding : 5px 0; box-sizing: border-box; }
    .flot_left_layout{float: left; color: rgb(187, 187, 187); margin-left: 10px;}

    #approver_select_modal .profile.dc0 { background-color:#ff6363 !important; border-color:#ff6363 !important; color:#fff !important; }
    #approver_select_modal .profile.dc1 { background-color:#FFA70E !important; border-color:#FFA70E !important; color:#fff !important; }
    #approver_select_modal .profile.dc2 { background-color:#FFC72F !important; border-color:#FFC72F !important; color:#fff !important; }
    #approver_select_modal .profile.dc3 { background-color:#FF198B !important; border-color:#FF198B !important; color:#fff !important; }
    #approver_select_modal .profile.dc4 { background-color:#00B2C7 !important; border-color:#00B2C7 !important; color:#fff !important; }
    #approver_select_modal .profile.dc5 { background-color:#13D08B !important; border-color:#13D08B !important; color:#fff !important; }
    #approver_select_modal .profile.dc6 { background-color:#4DBAFF !important; border-color:#4DBAFF !important; color:#fff !important; }
    #approver_select_modal .profile.dc7 { background-color:#477FFF !important; border-color:#477FFF !important; color:#fff !important; }
    #approver_select_modal .profile.dc8 { background-color:#6854FF !important; border-color:#6854FF !important; color:#fff !important; }
    #approver_select_modal .profile.dc9 { background-color:#35405A !important; border-color:#35405A !important; color:#fff !important; }
    
    .btn_over .approver-remove-btn { display : none; position: absolute; right : 10px; top : 24px; height: 25px; width: 25px; font-size: 0; background-image : url('../../../assets/images/contents/ic_close_new.png'); background-size: cover; background-repeat: no-repeat; }
    .btn_over:hover .approver-remove-btn { display : block; }

    /* 21.09.08 결재자 순서 수정 */
    .team_chk ul.select_member li p.turnNum { display: inline-block; box-sizing: border-box; padding-left: 28px; font-weight: bold; vertical-align: top; margin-top: 24px; }
</style>